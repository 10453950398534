import { DomainIcon, FrameworkIcon, TrainingIcon, UserIcon } from '@/assets/images/icons';
import { Icon } from '@/shared/components/Icon';
import { StyledRow } from '@/shared/styles';
import { PackageDetailsProps } from '../../Pricing.consts';
import { StyledFrameWorks, StyledIcons } from '../Package.styles';
import { RowDetail } from './RowDetail';

export const PackageDetails: React.FC<PackageDetailsProps> = ({
  users,
  frameworks,
  supportedFrameworks,
  domains,
  training,
}: PackageDetailsProps) => {
  return (
    <StyledIcons>
      <RowDetail $icon={users ? UserIcon : ''} $size={20} details={users} />
      <RowDetail $icon={frameworks ? FrameworkIcon : ''} $size={20} details={frameworks} />

      <StyledRow>
        <StyledFrameWorks>
          {supportedFrameworks?.map((fw) => {
            return <Icon $src={fw.icon} key={fw.name} $size={17} $alt={fw.name} />;
          })}
        </StyledFrameWorks>
      </StyledRow>

      <RowDetail $icon={domains ? DomainIcon : ''} $size={20} details={domains} />
      <RowDetail $icon={training ? TrainingIcon : ''} $size={20} details={training} />
    </StyledIcons>
  );
};
