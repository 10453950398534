import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import {
  StyleFormItemNoPadding,
  StyledForm,
  StyledFormError,
  StyledLabel,
} from '../../Forms.styles';
import { StyledCol, StyledPasswordInput } from '@/shared/styles';
import { U1Button } from '../../../Button/U1Button';
import { getPwdErrors } from '../../Forms.consts';
import { PasswordValidation } from './PasswordValidation';
import { v4 as uuidv4 } from 'uuid';
import { RES_STATUS } from '@/shared/consts';
import { useAxios } from '@/services/apiClient';
import { usePassword } from './usePassword';
import { useNotification } from '@/hooks/Notification/useNotification';
import { useFormMessages } from '@/hooks/useFormMessages';
import { extractErrors } from '@/shared/utils';
import { useTranslation } from 'react-i18next';

export const ChangePasswordForm: React.FC = () => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const { openNotification } = useNotification();
  const { pwd, handleInputChange, isPasswordValid } = usePassword();
  const { serverError, setError, clearAllMessages } = useFormMessages();
  const { t } = useTranslation();

  const [{ response, error }, updateVendor] = useAxios(
    { url: `/vendor-settings`, method: 'put' },
    { manual: true }
  );

  useEffect(() => {
    if (response?.status === RES_STATUS.OK) {
      openNotification({ successMessage: t('vendor.settings.password.form.success_message') });
      form.resetFields();
      clearAllMessages();
    }
  }, [response]);

  useEffect(() => {
    if (error) {
      const extractedError = extractErrors(error.response);
      setError(extractedError);
    }
  }, [error]);

  const onFinish = (values: any) => {
    updateVendor({ data: { oldPassword: values.current, password: values.create } });
  };

  const checkIfDisabled = () => {
    const isPwdValid = isPasswordValid(form.getFieldsValue());
    setDisabled(!isPwdValid);
  };

  return (
    <StyledForm form={form} onFinish={onFinish} $darkMode={true}>
      {serverError && <StyledFormError>{serverError}</StyledFormError>}
      <StyledCol $gap={8}>
        <StyledCol $gap={8}>
          <StyledLabel>{t('vendor.settings.password.form.current')}</StyledLabel>
          <Form.Item
            name="current"
            rules={[
              {
                required: true,
                message: t('vendor.settings.password.form.current.error'),
              },
            ]}
          >
            <StyledPasswordInput
              placeholder={t('vendor.settings.password.form.current.placeholder')}
              onChange={checkIfDisabled}
            />
          </Form.Item>
          <StyledLabel>{t('vendor.settings.password.form.new')}</StyledLabel>
          <StyleFormItemNoPadding
            name="create"
            rules={[
              {
                required: true,
                message: <></>,
              },
            ]}
          >
            <StyledPasswordInput
              placeholder={t('vendor.settings.password.form.new.placeholder')}
              onChange={(e) => {
                checkIfDisabled();
                handleInputChange(e);
              }}
            />
          </StyleFormItemNoPadding>
          <StyledCol $gap={16} $padding={16}>
            {getPwdErrors().map((err) => (
              <PasswordValidation key={uuidv4()} error={err.text} isValid={err.regex.test(pwd)} />
            ))}
          </StyledCol>
        </StyledCol>
        <StyledLabel>{t('vendor.settings.password.form.repeat')}</StyledLabel>
        <Form.Item
          name="repeat"
          rules={[
            {
              required: true,
              message: t('vendor.settings.password.form.repeat.error'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('create') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('vendor.settings.password.form.no_match.error')));
              },
            }),
          ]}
        >
          <StyledPasswordInput
            placeholder={t('vendor.settings.password.form.placeholder')}
            onChange={checkIfDisabled}
          />
        </Form.Item>
      </StyledCol>
      <Form.Item>
        <U1Button text={t('save_changes')} htmlType="submit" disabled={disabled} />
      </Form.Item>
    </StyledForm>
  );
};
