import { StyledCol, StyledInput, StyledSecondaryText } from '@/shared/styles';
import { StyledForm, StyledFormError, StyledLabel } from '../../Forms.styles';
import { Form } from 'antd';
import { U1Button } from '@/shared/components/Button/U1Button';
import { emailExp, fullNameExp, phoneNumberRegex } from '@/shared/regex';
import { UploadImageButton } from './UploadImageButton/UploadImage';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAxios } from '@/services/apiClient';
import { RES_STATUS } from '@/shared/consts';
import { useFormMessages } from '@/hooks/useFormMessages';
import { useNotification } from '@/hooks/Notification/useNotification';
import { extractVendorDetails, getFormData } from './PersonalDetails.utils';
import { useUserStore } from '@/stores/UserStore';
import { useTranslation } from 'react-i18next';

export const PersonalDetailsForm: React.FC = () => {
  const [disabled, setDisabled] = useState(true);
  const [isAvatarValid, setIsAvatarValid] = useState(false);
  const { serverError, setError } = useFormMessages();
  const { openNotification } = useNotification();
  const [form] = Form.useForm();
  const user = useUserStore();
  const { t } = useTranslation();

  const [{ response: avatarRes, error: avatarErr }, postAvatar] = useAxios(
    { url: '/vendors-settings/avatars', method: 'post' },
    { manual: true }
  );

  const [{ response: updateRes, error: detailsErr }, updateVendor] = useAxios(
    { url: `/vendor-settings`, method: 'put' },
    { manual: true }
  );

  useEffect(() => {
    if (avatarErr) {
      if (avatarErr.response?.status === RES_STATUS.CONTENT_TOO_LARGE) {
        setError(t('vendor.settings.personal_details.update.upload_image.too_large'));
      } else {
        setError(t('vendor.settings.personal_details.update.error_occured'));
      }
    }
  }, [avatarErr]);

  useEffect(() => {
    if (detailsErr) {
      setError(t('vendor.settings.personal_details.update.error_occured'));
    }
  }, [detailsErr]);

  useEffect(() => {
    if (updateRes?.status === RES_STATUS.OK || avatarRes?.status === RES_STATUS.CREATED) {
      openNotification({
        successMessage: t('vendor.settings.personal_details.update.success_message'),
      });
      form.resetFields();
    }
  }, [updateRes]);

  const onFinish = (values: any) => {
    const files = values.upload;
    if (files) {
      postAvatar({ data: getFormData(files) });
    }
    const vendorData = extractVendorDetails(values);
    if (vendorData) {
      updateVendor({
        data: vendorData,
      });
    }
  };

  const checkIfValid = (e: ChangeEvent<HTMLInputElement>) => {
    const isValid = !!e.target?.value.trim();
    setDisabled(!isValid);
  };

  return (
    <StyledForm form={form} onFinish={onFinish} $darkMode={true}>
      {serverError && <StyledFormError>{serverError}</StyledFormError>}
      <StyledCol $gap={8}>
        <StyledLabel>
          {t('vendor.settings.personal_details.update.upload_image')}{' '}
          <StyledSecondaryText fontWeight={400} fontSize={16}>
            ({t('optional')})
          </StyledSecondaryText>
        </StyledLabel>
        <UploadImageButton setIsAvatarValid={setIsAvatarValid} />
        <StyledLabel>{t('form.full_name')}</StyledLabel>
        <Form.Item
          name="userName"
          rules={[
            {
              message: `${t('form.first_and_last_name')} ${t('form.required')}`,
              pattern: fullNameExp,
            },
          ]}
        >
          <StyledInput placeholder={user.fullName} onChange={checkIfValid} />
        </Form.Item>

        <StyledLabel>{t('form.email')}</StyledLabel>
        <Form.Item
          name="email"
          rules={[
            {
              message: `${t('form.valid_email')} ${t('form.required')}`,
              pattern: emailExp,
            },
          ]}
        >
          <StyledInput placeholder={user.email} onChange={checkIfValid} />
        </Form.Item>
        <StyledLabel>{t('vendor.deals.phone_number')}</StyledLabel>
        <Form.Item
          name="phone"
          rules={[
            {
              message: t('vendor.deals.phone_number.required'),
              pattern: phoneNumberRegex,
            },
          ]}
        >
          <StyledInput placeholder={user.phone} onChange={checkIfValid} />
        </Form.Item>
        <StyledLabel>{t('vendor.deals.company_name')}</StyledLabel>
        <Form.Item
          name="companyName"
          rules={[
            {
              message: `${t('vendor.deals.company_name')} ${t('form.required')}`,
            },
          ]}
        >
          <StyledInput placeholder={user.companyName} onChange={checkIfValid} />
        </Form.Item>
      </StyledCol>
      <Form.Item>
        <U1Button
          text={t('save_changes')}
          htmlType="submit"
          disabled={disabled && !isAvatarValid}
        />
      </Form.Item>
    </StyledForm>
  );
};
