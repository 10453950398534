import { ClientDetails } from '@/stores/DealStore/DealsStore.consts';

export const getClientObject = (client: ClientDetails) => {
  let data = {};
  const { company, email, fullname, phone, country } = client;
  data = {
    client: {
      companyName: company,
      fullName: fullname,
      email,
      phone,
      country,
    },
    step: 'CLIENT',
  };
  return data;
};
