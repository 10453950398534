﻿import { DialogApi, DialogProps, DialogOptions } from '@u1/fixers';
import { useU1Base } from './base';
import { BaseOptions } from '../shared/types';
import { allowedOptionsKeys } from './consts';
import { ComponentName } from '@u1/ui-library-mapper';

export const useU1Dialog = (baseOptions?: BaseOptions<DialogProps, DialogOptions>) => {
  return useU1Base(
    DialogApi,
    false,
    allowedOptionsKeys.dialog,
    baseOptions?.name ?? ComponentName.DIALOG,
    baseOptions
  );
};
