import {
  MenuApiV2 as MenuApi,
  MenuOptionsV2 as MenuOptions,
  MenuPropsV2 as MenuProps,
} from '@u1/fixers';
import { useU1Base } from './base';
import { allowedOptionsKeys } from './consts';
import { BaseOptions } from '../shared/types';
import { ComponentName } from '@u1/ui-library-mapper';

export const useU1SideMenu = (baseOptions?: BaseOptions<MenuProps, MenuOptions>) => {
  return useU1Base(MenuApi, false, allowedOptionsKeys.menu, ComponentName.SIDMENU, baseOptions);
};
