import { StyledCol } from '@/shared/styles';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { ExtraSummary } from '../../Checkout/OrderSummary/SummarySections/ExtraSummary';
import { StyledUpgradeSum } from '../Payment.styles';
import { PaymentMessage } from './PaymentMessage';
import { useTranslation } from 'react-i18next';

export const UpgradeSuccess: React.FC = () => {
  const subscription = useSubscriptionStore();
  const { t } = useTranslation();

  return (
    <PaymentMessage
      dataTestId="successPaymentMessage"
      packageText={t('payment.payment_message.upgraded')}
      paymentMessage={t('payment.payment_message.upgrade.subtitle')}
      btnText={t('payment.payment_message.upgrade.package_text.go_to')}
      selectedPackage={subscription}
    >
      <StyledCol $gap={5}>
        <div>{t('payment.payment_message.upgrade.package_text.features')}</div>
        <StyledUpgradeSum>
          <ExtraSummary isUpgrade={true} />
        </StyledUpgradeSum>
      </StyledCol>
    </PaymentMessage>
  );
};
