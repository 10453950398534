import { notification } from 'antd';
import { NotificationProps } from './Notification.consts';
import { vIcon, xIcon } from '@/assets/images/icons';
import { StyledImg } from '@/shared/styles';

export const useNotification = () => {
  const openNotification = ({ successMessage, failMessage }: NotificationProps) => {
    notification.open({
      icon: <StyledImg src={successMessage ? vIcon : xIcon} $size={32} />,
      message: successMessage ?? failMessage,
    });
  };

  return { openNotification };
};
