﻿import { Component, RefId } from '../../../../../types';
import { AbstractProps, MenuPropsV2 as MenuProps } from '@u1/fixers';
import { A11Y_DIRECTIVE_SELECTOR } from '../../../../../consts';
import { DIRECTIVE_SELECTORS, MENU_ATTRIBUTE } from './consts';

const { MENU, MENU_ITEM, HORIZONTAL_MENU, OPEN_BY_FOCUS, OPEN_BY_MOUSEOVER, SUBMENU, TRIGGERS } =
  DIRECTIVE_SELECTORS;

const handleSpecialCases = (props: AbstractProps, el: HTMLElement, refId: RefId) => {
  const compId = `[id='${el.id}']`;
  const { selectors } = props;

  selectors.submenus = getUniqueSubmenu(compId, refId);
};

const getUniqueSubmenu = (idSelector: string, refId: RefId): string => {
  const scopedSubmenus = `${idSelector} [${MENU}] [${SUBMENU}]`;
  const unscopedSubmenus = `[${SUBMENU}][${A11Y_DIRECTIVE_SELECTOR}="${refId}"]`;
  return `${scopedSubmenus}, ${unscopedSubmenus}`;
};

export const menu: Component<MenuProps> = {
  selector: `[${MENU_ATTRIBUTE}]`,
  handleSpecialCases,
  props: {
    selectors: {
      menu: `[${MENU}]`,
      submenus: `[${SUBMENU}]`,
      items: `[${MENU_ITEM}]`,
      triggers: `[${TRIGGERS}]`,
      horizontalMenu: `[${HORIZONTAL_MENU}]`,
      openByFocus: `[${OPEN_BY_FOCUS}]`,
      openByMouseover: `[${OPEN_BY_MOUSEOVER}]`,
    },
  },
};
