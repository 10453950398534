import { SCOPE_PH } from '../../../../../consts';
import { Component } from '../../../../../types';
import { ButtonProps } from '@u1/fixers';
import { A11Y_DIRECTIVE_SELECTOR } from '../../../../../consts';

const BUTTON_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-button`;

export const button: Component<ButtonProps> = {
  selector: `[${BUTTON_ATTRIBUTE}]`,
  props: {
    selectors: {
      element: `${SCOPE_PH} [${BUTTON_ATTRIBUTE}="button"]`,
    },
  },
  handleSpecialCases: (props) => {
    /**
     * this logic is necessary for when the button hook is used
     * if the ref is attached directly to the component that requires remediation (see react playground button ref example)
     * then the id will be placed directly on the component rather than some parent element
     * the selector <SCOPE> [aria-u1-button="button"] therefore won't work
     * the space character is removed to check if it's a possible working selector
     * if so, then the props are updated to the new selector
     * */
    if (document.querySelector(props.selectors.element) === null) {
      const possibleButtonSelector = props.selectors.element.trim();
      if (document.querySelector(possibleButtonSelector) !== null) {
        props.selectors.element = possibleButtonSelector;
      }
    }
  },
};
