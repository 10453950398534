import { Component } from '../../../../../types';
import { LinkProps } from '@u1/fixers';

export const link: Component<LinkProps> = {
  selector: '.ant-anchor-link-title, .ant-custom-link',
  props: {
    selectors: {
      element: `.ant-anchor-link-title, .ant-custom-link`,
    },
  },
};
