import React from 'react';
import { U1Table } from '@/shared/components/Table/U1Table';
import { UsersDataProps } from '../Users.consts';
import { getColumns } from './UsersTable.utils';
import { useUserStore } from '@/stores/UserStore';

export const UsersTable: React.FC<UsersDataProps> = ({
  usersData,
  getUsersData,
}: UsersDataProps) => {
  const currentUserId = useUserStore((state) => state.id);
  return <U1Table dataSource={usersData} columns={getColumns(currentUserId, getUsersData)} />;
};
