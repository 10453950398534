import { isAllPropsUndefined } from '@/shared/utils';

export const extractVendorDetails = (values: any) => {
  if (isAllPropsUndefined(values)) return null;
  return {
    email: values.email,
    phone: values.phone,
    userName: values.userName,
    companyName: values.companyName,
  };
};

export const getFormData = (files: any[]): FormData => {
  const formData = new FormData();
  formData.append('file', files[0].originFileObj);
  return formData;
};
