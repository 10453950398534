import { Icon } from '@/shared/components/Icon';
import { SigninMethodButtonProps } from '@/shared/props';
import {
  SignInMethodButtonWrapper,
  SignInMethodButtonInner,
  SignInMethodText,
} from './Signin.styles';
import { useTranslation } from 'react-i18next';

export const SignInMethodButton: React.FC<SigninMethodButtonProps> = ({
  text,
  $logoSrc,
  $textColor,
  $href,
}: SigninMethodButtonProps) => {
  const { t } = useTranslation();
  return (
    <SignInMethodButtonWrapper
      href={$href}
      aria-label={`${t('sign_in.title')} ${t('with')} ${text}`}
    >
      <SignInMethodButtonInner>
        <Icon $size={32} $top={0} $src={$logoSrc} />
        <SignInMethodText color={$textColor}>{text}</SignInMethodText>
      </SignInMethodButtonInner>
    </SignInMethodButtonWrapper>
  );
};
