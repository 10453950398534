import { useTheme } from 'styled-components';
import { ChartItem, DonutChartProps, InitialValues, chartColors } from './DonutChart.consts';
import {
  StyledBottomText,
  StyledDonutChart,
  StyledDonutSVG,
  StyledDonutText,
  StyledInnerCircle,
  StyledOuterCircle,
  StyledSvgGroup,
  StyledTopText,
} from './DonutChart.styles';
import { hexToRgb } from '@/shared/utils';

export const DonutChart: React.FC<DonutChartProps> = ({
  items,
  totalItems,
  selectedItem,
  setSelected,
}: DonutChartProps) => {
  const { cx, cy, startAngle, radius, dashArray, strokeWidth, circlesRatio } = InitialValues;
  const dashArrayAmount = totalItems > 1 ? 5 : 0;
  const theme = useTheme();

  let filled = 0;
  const sum = items.reduce((sum, item) => {
    return sum + item.value;
  }, 0);

  const ratio = 100 / sum;

  items.forEach((item: ChartItem) => {
    const itemRatio = ratio * item.value;
    item.itemRatio = itemRatio;
    item.angle = (filled * 360) / 100 + startAngle;
    item.offset = dashArray - (InitialValues.dashArray * itemRatio) / 100;
    item.filled = filled;
    filled += itemRatio;
  });

  return (
    <StyledDonutChart>
      <StyledDonutSVG viewBox="0 0 100 100">
        {items.map((item: ChartItem, index: number) => {
          const color = totalItems > 0 ? chartColors[index] : theme.marginsBorder;
          return (
            <StyledSvgGroup
              key={index}
              onMouseOver={() => setSelected && setSelected(item.key)}
              onMouseOut={() => setSelected && setSelected(-1)}
              $color={hexToRgb(color)}
            >
              <StyledOuterCircle
                cx={cx}
                cy={cy}
                r={radius}
                strokeWidth={strokeWidth}
                stroke={color}
                strokeDasharray={dashArray - dashArrayAmount}
                strokeDashoffset={item.offset ?? 0}
                transform={`rotate(${item.angle} ${cx} ${cy})`}
                isSelected={selectedItem === -1 || selectedItem === item.key}
              >
                <animateTransform
                  key={item.key + 1}
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  from={`${startAngle} ${cx} ${cy}`}
                  to={`${item.angle} ${cx} ${cy}`}
                  dur="1s"
                />
              </StyledOuterCircle>
              <StyledInnerCircle
                isSelected={selectedItem === item.key}
                cx={cx}
                cy={cy}
                r={radius / circlesRatio}
                strokeWidth={1}
                strokeDasharray={(dashArray - dashArrayAmount) / circlesRatio}
                strokeDashoffset={(item.offset as number) / circlesRatio}
                transform={`rotate(${item.angle} ${cx} ${cy})`}
              >
                <animateTransform
                  key={item.key + 2}
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  from={`${startAngle} ${cx} ${cy}`}
                  to={`${item.angle} ${cx} ${cy}`}
                  dur="1s"
                />
              </StyledInnerCircle>
            </StyledSvgGroup>
          );
        })}
        <StyledDonutText x="50%" y="50%" textAnchor="middle" alignmentBaseline="middle">
          <StyledTopText>{totalItems}</StyledTopText>
          <StyledBottomText x="50%" dy="1.2em">
            Total
          </StyledBottomText>
        </StyledDonutText>
      </StyledDonutSVG>
    </StyledDonutChart>
  );
};
