import { PAGES } from '@/shared/consts';
import { TitleAndContentModel } from '@/Views/AfterLogin/Main/Client/Projects/projects.consts';
import { StyledCol, StyledText } from '@/shared/styles';
import * as PRIVACY from './PrivacyPolicy.consts';
import { StyledStaticPageParagraph } from '../StaticPage.styles';
import { PinkGradientRouterLink } from '@/shared/components/Link/PinkGradientRouterLink';

export const PrivacyPolicyDictionary: TitleAndContentModel[] = [
  {
    title: 'INTRODUCTION',
    content: PRIVACY.INTRODUCTION,
  },

  {
    title: 'COLLECTION OF PERSONAL DATA',
    content: PRIVACY.COLLECTION_OF_PERSONAL_DATA,
  },

  {
    title: 'Personal data you provided directly to us. ',
    content: PRIVACY.PERSONAL_DATA_YOU_PROVIDED_DIRECTLY_TO_US,
    list: PRIVACY.PERSONAL_DATA_YOU_PROVIDED_DIRECTLY_TO_US_LIST,
  },

  {
    title: 'Personal data we collect automatically ',
    content: PRIVACY.PERSONAL_DATA_WE_COLLECT_AUTOMATICALLY,
    list: PRIVACY.PERSONAL_DATA_WE_COLLECT_AUTOMATICALLY_LIST,
  },

  {
    title: 'Personal data we collect from other sources ',
    content: PRIVACY.PERSONAL_DATA_WE_COLLECT_FROM_OTHER_SOURCES,
  },

  {
    title: 'Personal data we derive ',
    content: PRIVACY.PERSONAL_DATA_WE_DERIVE,
  },

  {
    title: 'USE OF PERSONAL DATA ',
    content: PRIVACY.USE_OF_PERSONAL_DATA,
    list: PRIVACY.USE_OF_PERSONAL_DATA_LIST,
  },
  {
    title: 'DISCLOSURE OF PERSONAL DATA',
    content: PRIVACY.DISCLOSURE_OF_PERSONAL_DATA,
    list: PRIVACY.DISCLOSURE_OF_PERSONAL_DATA_LIST,
  },
  {
    content: PRIVACY.DISCLOSURE_OF_PERSONAL_DATA2,
    list: PRIVACY.DISCLOSURE_OF_PERSONAL_DATA_LIST2,
  },
  {
    title: 'THE LEGAL BASIS FOR USING YOUR PERSONAL INFORMATION',
    content: PRIVACY.LEGAL_BASIS,
    list: PRIVACY.LEGAL_BASIS_LIST,
  },

  {
    title: 'MARKETING COMMUNICATIONS',
    content: PRIVACY.MARKETING_COMMUNICATIONS,
  },

  {
    title: 'DATA SECURITY, TRANSFERS, AND RETENTION',
  },

  {
    title: 'Data Security',
    content: PRIVACY.DATA_SECURITY,
  },

  {
    title: 'Data Transfers',
    content: PRIVACY.DATA_TRANSFERS,
  },

  {
    title: 'Retention of Personal Data',
    content: PRIVACY.RETENTION_OF_PERSONAL_DATA,
  },

  {
    title: 'CHILDREN’S PRIVACY',
    content: PRIVACY.CHILDRENS_PRIVACY,
  },

  {
    title: 'YOUR RIGHTS',
    content: PRIVACY.YOUR_RIGHTS,
  },

  {
    title: 'Correction',
    children: (
      <StyledCol>
        <StyledStaticPageParagraph>
          {PRIVACY.CORRECTION_BEFORE_LINK}
          <PinkGradientRouterLink text={PRIVACY.SUPPORT_EMAIL} href={PRIVACY.SUPPORT_EMAIL} />
          {PRIVACY.CORRECTION_AFTER_LINK}
        </StyledStaticPageParagraph>
      </StyledCol>
    ),
  },

  {
    title: 'Data access',
    children: (
      <StyledCol>
        <StyledStaticPageParagraph>
          {PRIVACY.DATA_ACCESS}
          <PinkGradientRouterLink text={PRIVACY.SUPPORT_EMAIL} href={PRIVACY.SUPPORT_EMAIL} />
        </StyledStaticPageParagraph>
      </StyledCol>
    ),
  },

  {
    title: 'Data Portability',
    content: PRIVACY.DATA_PORTABILITY,
  },

  {
    title: 'Objection to Marketing',
    content: PRIVACY.OBJECTION_TO_MARKETING,
  },

  {
    title: 'Opt-Out Right',
    content: PRIVACY.OPT_OUT_RIGHT,
  },

  {
    title: 'Terms of Use',
    children: (
      <StyledCol>
        <StyledStaticPageParagraph>
          {PRIVACY.TERMS_OF_USE_UNTIL_LINK}
          <PinkGradientRouterLink
            text={PRIVACY.TERMS_OF_USE_LINK}
            href={PAGES.SHARED.TERMS_AND_CONDITIONS}
          />
          {PRIVACY.TERMS_OF_USE_AFTER_LINK}
        </StyledStaticPageParagraph>
      </StyledCol>
    ),
  },

  {
    title: 'COMPLAINTS',
    children: (
      <StyledCol>
        <StyledStaticPageParagraph>
          {PRIVACY.COMPLAINTS_UNTIL_FIRST_LINK}
          <PinkGradientRouterLink text={PRIVACY.SUPPORT_EMAIL} href={PRIVACY.SUPPORT_EMAIL} />
          {PRIVACY.COMPLAINTS_AFTER_FIRST_LINK}{' '}
          <PinkGradientRouterLink text={PRIVACY.SUPPORT_EMAIL} href={PRIVACY.SUPPORT_EMAIL} />
          {PRIVACY.COMPLAINTS_AFTER_SECOND_LINK}
        </StyledStaticPageParagraph>
      </StyledCol>
    ),
  },

  {
    title: 'CHANGES',
    content: PRIVACY.CHANGES,
  },
  {
    title: 'CONTACT US',
    children: (
      <StyledCol>
        <StyledText fontWeight={400}>
          {PRIVACY.CONTACT_US}
          <PinkGradientRouterLink text={PRIVACY.SUPPORT_EMAIL} href={PRIVACY.SUPPORT_EMAIL} />.
        </StyledText>
      </StyledCol>
    ),
  },
];
