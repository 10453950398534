import { Modal } from 'antd';
import styled from 'styled-components';
import { ModalProps } from './Modals.consts';

export const StyledModal = styled(Modal)<ModalProps>`
  color: ${(props) => props.theme.textColor};
  width: ${(props) => props.$width ?? '500'}px !important;

  .ant-modal-body {
    padding: 0px;

    ${(props) => props.$align && `text-align: ${props.$align};`}
    ${(props) =>
      props.$gap &&
      `
  display: flex;
  flex-direction: column;
  gap: ${props.$gap}px;`}
  }

  h1 {
    color: ${(props) => props.theme.textColor};
    font-size: 15px;
  }

  p {
    color: ${(props) => props.theme.secondaryTextColor};
  }

  .ant-modal-content {
    background-color: ${(props) => props.theme.cardsColor};
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: 5px;
  }

  .ant-modal-footer {
    border: none;
    padding: 0px !important;
  }

  .ant-modal-close {
    ${(props) => props.$closeBtn === false && `display: none;`}
    float: right;
    color: ${(props) => props.theme.textColor};
    align-self: end;
    position: relative;

    .ant-modal-close-x {
      line-height: unset;
      height: fit-content;
      width: fit-content;
    }

    svg {
      width: 12px;
    }
  }
`;
