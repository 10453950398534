import { THEME_ICONS } from '@/shared/consts';

export const getTrustedCompaneis = (themeColor: THEME_ICONS) => [
  {
    alt: 'IKEA',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/ikea.png`,
  },
  {
    alt: 'Mount Sinai',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/mount-sinai.png`,
  },
  {
    alt: 'Office Of The Comptroller',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/city-of-new-york.png`,
  },
  {
    alt: 'Eleos',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/eleos.png`,
  },
  {
    alt: 'Amtrak',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/amtrak.png`,
  },
  {
    alt: 'Lending Club',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/lending-club.png`,
  },
  {
    alt: 'Eagle Bank',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/eagle-bank.png`,
  },
  {
    alt: 'Santander',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/santander.png`,
  },
  {
    alt: 'Fannie Mae',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/fannie-mae.png`,
  },
  {
    alt: 'Molina Healthcare',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/molina.png`,
  },
  {
    alt: 'T-Mobile',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/t-mobile.png`,
  },
  {
    alt: 'Lightico',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/lightico.png`,
  },
  {
    alt: 'United Nations',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/un.png`,
  },
  {
    alt: 'IRobot',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/irobot.png`,
  },
  {
    alt: 'Butler University',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/butler-university.png`,
  },
  {
    alt: 'Blink Health',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/blink-health.png`,
  },
  {
    alt: 'IDB',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/idb.png`,
  },
  {
    alt: 'American Century Investments',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/aci.png`,
  },
  {
    alt: 'Orage Country Library',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/ocls.png`,
  },
  {
    alt: 'Bank Leumi',
    src: `https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/trusted/${themeColor}/leumi.png`,
  },
];
