import styled, { css } from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars-2';

export const TrackVertical = styled.div`
  background-color: ${(props) => props.theme.marginsColor};
  position: absolute;
  min-width: 7px !important;
  right: 1px;
  bottom: 2px;
  top: 2px;
  border-radius: 2px;
`;

export const ThumbVertical = styled.div`
  position: absolute;
  border-radius: 10px;
  right: 1px;
  bottom: 2px;
  top: 2px;
  min-width: 7px !important;
  background-color: ${(props) => props.theme.toggleDisabled};
`;

export const StyledScrollbars = styled(Scrollbars)`
  width: auto;
  height: 100%;

  /* Firefox */
  scrollbar-width: auto;
  scrollbar-color: ${(props) => props.theme.toggleDisabled} ${(props) => props.theme.marginsColor};
`;

export const GlobalScrollbarCss = css`
  /* Scrollbars */

  /* Chrome */

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.marginsColor};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.toggleDisabled};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.toggleDisabled};
  }

  /* Firefox */
  // scrollbar-width: auto;
  // scrollbar-color: ${(props) => props.theme.toggleDisabled} ${(props) =>
    props.theme.marginsColor};

  .u1-scrollbar > div:first-child {
    max-height: calc(100vh + 20px) !important;
    margin-right: 0px !important;
    scrollbar-width: none !important;
  }
`;
