import React from 'react';
import { DealInfoProps } from './DealInfoBlock.consts';
import { StyledVendorCard } from '@/Views/AfterLogin/Main/Main.styles';
import { StyledRowOffset, StyledSecondaryText, StyledText, StyledTitle } from '@/shared/styles';

export const DealInfo: React.FC<DealInfoProps> = ({
  title,
  content,
  description,
}: DealInfoProps) => {
  return (
    <StyledVendorCard $gap={16}>
      <StyledTitle level={2} $size={16} $weight={400}>
        {title}
      </StyledTitle>
      <StyledRowOffset>
        <StyledText fontSize="24px" fontWeight={600}>
          {content}
        </StyledText>
        {description && (
          <StyledSecondaryText fontWeight={400} fontSize="16px">
            {description}
          </StyledSecondaryText>
        )}
      </StyledRowOffset>
    </StyledVendorCard>
  );
};
