import { IconButtonProps } from '@/shared/props';
import { v4 as uuidv4 } from 'uuid';
import { StyledWhiteBorderButton } from './U1Button.styles';
import { U1Link } from '@u1/react-a11y-hooks';
import { StyledImg } from '@/shared/styles';

export const WhiteBorderButton: React.FC<IconButtonProps> = ({
  text,
  $width,
  $height,
  $padding,
  onClick,
  disabled,
  htmlType,
  dataTestId,
  triggerRef: a11yRef,
  isLink,
  $icon,
  $alt,
}: IconButtonProps) => {
  const dialogTrigger = a11yRef ? { 'data-a11y-dialog': 'trigger', 'data-a11y-ref': a11yRef } : {};

  const props = {
    'data-testid': dataTestId,
    key: uuidv4(),
    data: text,
    $width,
    $height,
    $padding,
    onClick,
    disabled,
    htmlType,
    className: `ant-custom-${isLink ? `link` : `btn`}`,
    ...dialogTrigger,
  };

  return isLink ? (
    <U1Link>
      <StyledWhiteBorderButton data-u1-link="link" {...props}>
        {$icon && <StyledImg $alt={$alt} $src={$icon} />}
      </StyledWhiteBorderButton>
    </U1Link>
  ) : (
    <StyledWhiteBorderButton {...props}>
      {$icon && <StyledImg alt={$alt} src={$icon} />}
    </StyledWhiteBorderButton>
  );
};
