import { U1Button } from '@/shared/components/Button/U1Button';
import { WhiteBorderButton } from '@/shared/components/Button/WhiteBorderButton';
import {
  StyledCard,
  StyledCol,
  StyledInput,
  StyledRow,
  StyledSecondaryText,
  StyledTitle,
} from '@/shared/styles';
import { DEALS_VIEW } from '../../../../Deals.consts';
import { FormError } from '@/shared/components/Forms/FormError';
import { useDealStore } from '@/stores/DealStore/DealStore';
import { blockNanValues } from '@/shared/utils';
import { useEffect, useState } from 'react';
import { useAxios } from '@/services/apiClient';
import { RES_STATUS } from '@/shared/consts';
import { getDealObject } from './SellingPrice.utils';
import { useVendorsStore } from '@/stores/VendorsStore';
import { DealSteps } from '@/stores/DealStore/DealsStore.consts';
import { useTranslation } from 'react-i18next';

export const SellingPrice: React.FC = () => {
  const [formError, setError] = useState('');
  const [isDraft, setIsDraft] = useState(false);
  const deals = useDealStore();
  const setDealsView = useVendorsStore((state) => state.setDealsView);
  const { t } = useTranslation();

  const [{ response: postStepRes }, postStep1] = useAxios(
    { method: 'post', url: '/deals' },
    { manual: true }
  );

  const [{ response: putDealRes }, putDeal] = useAxios({ method: 'patch' }, { manual: true });

  const saveStep1 = () => {
    const error = deals.getStep1Error();
    if (error) {
      setError(error);
    } else {
      const data = getDealObject(deals);
      if (deals.id) {
        putDeal({ url: `/deals/${deals.id}`, data });
      } else {
        postStep1({
          data,
        });
      }
    }
  };

  const saveAsDarft = () => {
    setIsDraft(true);
    saveStep1();
  };

  const goNext = () => {
    setIsDraft(false);
    saveStep1();
  };

  useEffect(() => {
    if (postStepRes?.status === RES_STATUS.CREATED || putDealRes?.status === RES_STATUS.OK) {
      postStepRes && deals.setDealId(postStepRes.data.id);
      if (isDraft) {
        deals.clearDeal();
        setDealsView(DEALS_VIEW.MAIN);
      } else {
        deals.setDealStep(DealSteps.CLIENT);
      }
    }
  }, [postStepRes, putDealRes]);

  return (
    <StyledCard $width="100%" $padding={32} $align="start" $gap={64}>
      <StyledCol $gap={8}>
        <StyledTitle level={3} $size={16} $weight={400}>
          {t('vendor.deals.selling_price.title')}{' '}
          <StyledSecondaryText fontSize={16} fontWeight={400}>
            {t('vendor.deals.in_usd')}
          </StyledSecondaryText>
        </StyledTitle>
        <StyledSecondaryText fontWeight={400} fontSize={14} flex="start" textAlign="left">
          {t('vendor.deals.selling_price.content')}
        </StyledSecondaryText>
        <StyledInput
          placeholder={t('vendor.deals.amount.placeholder')}
          onKeyDown={(e) => blockNanValues(e)}
          onChange={(e) => deals.setPrice(e.target.value)}
          maxLength={10}
          value={deals.price}
        />
      </StyledCol>
      <StyledCol $gap={12}>
        {formError && <FormError message={formError} />}
        <StyledRow $width="100%" $gap={24}>
          <WhiteBorderButton
            text={t('vendor.deals.save_draft')}
            $width="100%"
            onClick={() => saveAsDarft()}
          />
          <U1Button text={t('next')} $width="100%" onClick={() => goNext()} />
        </StyledRow>
      </StyledCol>
    </StyledCard>
  );
};
