import { isBrowser } from 'react-device-detect';
import { StyledLayoutAfterLogin, StyledRowLayout } from './AfterLogin.styles';
import { Header } from './Header/Header';
import { Main } from './Main/Main';
import { Sider } from './Sider/Sider';

export const AfterLogin: React.FC = () => {
  return (
    <StyledLayoutAfterLogin>
      <Header />
      <StyledRowLayout>
        {isBrowser && <Sider />}
        <Main />
      </StyledRowLayout>
    </StyledLayoutAfterLogin>
  );
};
