import { PackageProps } from '../Pricing.consts';
import { useSelectionStore, useExtraSelectionStore } from '@/stores/SelectionStore';
import { PAGES, STORAGE_KEYS } from '@/shared/consts';
import { StyledPackagesCard, StyledCardDetails } from './Package.styles';
import { useAuthStore } from '@/stores/AuthStore';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { getPackageTopSection, getPackageDetails } from './Package.utils';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const Package: React.FC<PackageProps> = ({
  u1Package,
  price,
  pricingMethod,
}: PackageProps) => {
  const selectPackage = useSelectionStore((state) => state.selectPackage);
  const cleanUserSelection = useExtraSelectionStore((state) => state.cleanSelection);
  const { navigate } = useU1Navigation();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn());
  const { setStorage } = useLocalStorage();
  const {
    id,
    name,
    imageUrl,
    users,
    frameworks,
    domains,
    training,
    supportedFrameworks,
    type,
    priceTitle,
  } = u1Package;

  const choosePackage = () => {
    const selectedPackage = {
      id,
      name,
      price,
      domains,
      frameworks,
      pricingMethod,
      users,
      supportedFrameworks: supportedFrameworks ?? [],
      extraDomains: 0,
      type,
      imageUrl,
    };
    if (isLoggedIn) {
      cleanUserSelection && cleanUserSelection();
      selectPackage && selectPackage(selectedPackage);
      navigate(PAGES.AFTER_LOGIN.CHECKOUT);
    } else {
      setStorage(STORAGE_KEYS.SELECTED_PACKAGE, selectedPackage);
      navigate(PAGES.BEFORE_LOGIN.SIGN_IN);
    }
  };

  return (
    <StyledPackagesCard>
      {
        getPackageTopSection({
          name,
          imageUrl,
          pricingMethod,
          price: priceTitle ?? price,
        })[type]
      }

      <StyledCardDetails>
        {
          getPackageDetails({
            users,
            frameworks,
            supportedFrameworks,
            domains,
            training,
            choosePackage,
          })[type]
        }
      </StyledCardDetails>
    </StyledPackagesCard>
  );
};
