import React from 'react';
import { ClipboardSyntaxHighlighter } from '../ClipboardSyntaxHighlighter/ClipboardSyntaxHighlighter';
import { StyledConigfsListCard } from './ConfigurationPage.styles';
import { shouldShowLineNumbers } from './ConfigurationPage.utils';
import { getConfigTitleDic } from './ConfigurationPage.consts';
import { DocsSection } from '../DocumentationPage/DocsContent/DocsSection/DocsSection';
import { ConfigurationCardProps } from '../../Client/Projects/projects.consts';

export const CongifurationCard: React.FC<ConfigurationCardProps> = ({
  instructions,
  code,
  index,
  name,
}) => {
  return (
    <StyledConigfsListCard data-testid="configurationCard">
      {instructions && name && (
        <DocsSection
          title={getConfigTitleDic()[name]}
          content={instructions}
          objkeys={['instructions']}
          index={index}
        />
      )}

      {code?.length > 0 &&
        code.map((el) => (
          <ClipboardSyntaxHighlighter
            key={`code-${index}`}
            data-testid="exampleCode"
            showLineNumbers={shouldShowLineNumbers(el.split('\n').length)}
            copywithoutcomments={true}
            content={el}
            objkeys={['code']}
            index={index}
          />
        ))}
    </StyledConigfsListCard>
  );
};
