import styled from 'styled-components';
import { PackageContainerType } from './PakageDetails.consts';
import { isMobile } from 'react-device-detect';

export const StyledPackageInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 35px;
`;

export const StyledPackageContainer = styled.div<PackageContainerType>`
  background-image: url(${(props) => props.$bg});
  background-repeat: no-repeat;
  background-position: ${(props) =>
    isMobile
      ? `top -2% ${props.$direction ?? 'right'} -20%`
      : props.$position ?? `top -5% ${props.$direction ?? 'right'} -1%`};
  padding: 35px;
  background-size: ${() => (isMobile ? '200' : '250')}px;
  width: 100%;
`;

export const StyledPackageSection = styled.div`
  margin-top: 50px;
`;
