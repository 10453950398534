import { U1Button } from '@/shared/components/Button/U1Button';
import { PAGES } from '@/shared/consts';
import { NotFound } from './NotFound';
import { useTranslation } from 'react-i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const DocsNotFound: React.FC = () => {
  const { navigate } = useU1Navigation();
  const { t } = useTranslation();

  return (
    <NotFound
      text={t('not_found.docs.text')}
      backButton={
        <U1Button
          text={t('not_found.docs.back_button')}
          $height="50px"
          onClick={() => navigate(PAGES.AFTER_LOGIN.PROJECTS)}
        />
      }
    />
  );
};
