import { StyledCol } from '@/shared/styles';
import { ClipboardSyntaxHighlighter } from '../../../ClipboardSyntaxHighlighter/ClipboardSyntaxHighlighter';
import { useDocsStore } from '@/stores/ProjectStore/ProjectStore';
import { DocsSection } from '../DocsSection/DocsSection';
import { ListSection } from '../DocsSection/ListSection';
import { useTranslation } from 'react-i18next';

export const Page1: React.FC = () => {
  const selectedComponent = useDocsStore((state) => state.selectedComponentDoc);
  const usage = selectedComponent?.usage;
  const { t } = useTranslation();

  return (
    <StyledCol $gap={32}>
      {/* Accessibility */}
      {selectedComponent?.accessibility && (
        <DocsSection
          title={t('projects.docs.component.accessibility.title')}
          objkeys={['accessibility']}
          content={selectedComponent.accessibility}
        />
      )}
      {/* Description */}
      {usage?.description?.text && (
        <DocsSection
          title={t('projects.docs.component.usage.title.h2')}
          secondTitle="Description"
          objkeys={['usage', 'description', 'text']}
          content={usage.description.text}
        />
      )}
      {usage?.description?.example && (
        <ClipboardSyntaxHighlighter
          objkeys={['usage', 'description', 'example']}
          content={usage.description.example}
        />
      )}
      {/* Test */}
      <ListSection
        title={t('projects.docs.component.how_to_test.title.h2')}
        objkeys={['usage', 'test']}
        listItems={usage?.test as string[]}
      />
    </StyledCol>
  );
};
