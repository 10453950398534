import { Component } from '../../../../../types';
import { CarouselProps } from '@u1/fixers';

export const carousel: Component<CarouselProps> = {
  selector: '.slick-slide',
  props: {
    selectors: {
      carouselContainer: '.ant-carousel',
      slide: '.slick-slide:not(.slick-cloned)',
      slidePickerButtons: '.slick-dots button',
    },
  },
};
