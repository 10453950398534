import { VerticalDropdownIcon } from '@/assets/images/icons';
import { RES_STATUS } from '@/shared/consts';
import { useAxios } from '@/services/apiClient';
import { Dropdown } from 'antd';
import React, { useEffect, useState } from 'react';
import { ActionsDropdownProps } from '../../Users.consts';
import { AdminMenu } from './AdminMenu';
import { U1Listbox } from '@u1/react-a11y-hooks';
import { StyledButton } from '@/shared/styles/dropdownMenu.styles';
import { useTranslation } from 'react-i18next';
import { Image } from '@/shared/components/Image/Image';

export const ActionsDropdown: React.FC<ActionsDropdownProps> = ({
  userId,
  userRole,
  userName,
  getUsersData,
}: ActionsDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const [{ response: changeRoleRes }, postChangeRole] = useAxios(
    { method: 'put', url: `/users/${userId}` },
    { manual: true }
  );

  const [{ response: deleteUserRes }, deleteUser] = useAxios(
    { method: 'delete', url: `/users/${userId}` },
    { manual: true }
  );

  useEffect(() => {
    if (changeRoleRes && changeRoleRes.status === RES_STATUS.OK) {
      setIsOpen(false);
      getUsersData();
    }
  }, [changeRoleRes]);

  useEffect(() => {
    if (deleteUserRes) {
      setIsOpen(false);
      getUsersData();
    }
  }, [deleteUserRes]);

  return (
    <U1Listbox>
      <Dropdown
        open={isOpen}
        dropdownRender={() => (
          <AdminMenu
            postChangeRole={postChangeRole}
            deleteUser={deleteUser}
            userRole={userRole}
            userName={userName}
            setIsOpen={setIsOpen}
          />
        )}
      >
        <StyledButton
          aria-label={`${t('user.admin_menu.user_actions')} ${userName}`}
          onClick={() => setIsOpen(!isOpen)}
          data-testid="manageUserButton"
          className="listbox-trigger"
        >
          {<Image $src={VerticalDropdownIcon} />}
        </StyledButton>
      </Dropdown>
    </U1Listbox>
  );
};
