export type LangDto = {
  id: string;
  code: string;
  name: string;
  active: boolean;
  direction: 'rtl' | 'ltr';
};

export type AvailableLang = {
  value: number;
  label: string;
  code: string;
  direction: 'rtl' | 'ltr';
};

export type LangStore = {
  availableLangs: AvailableLang[];
  getLanguageCode: (value: string) => string | undefined;
  getAllLanguages: () => void;
  getSupportedLanguages: () => string[];
  isLanguageSupported: (language: string) => boolean;
  getSelectedLang: () => string;
  getLangDir: () => 'rtl' | 'ltr' | undefined;
  isRtl: () => boolean;
};

export const defaultLanguage = 'en';

export const defaultLanguages: AvailableLang[] = [
  { value: 1, label: 'English', code: defaultLanguage, direction: 'ltr' },
  { value: 2, label: 'Hebrew', code: 'he', direction: 'rtl' },
];
