import { useOutsideDropdownClick } from '@/hooks/useOutsideDropdownClick';
import React, { useRef } from 'react';
import { StyledMenu } from '@/shared/styles/dropdownMenu.styles';
import { ActionsDropdownProps } from '../Panel.consts';

export const ActionsDropdown: React.FC<ActionsDropdownProps> = ({
  children,
  setIsOpen,
}: ActionsDropdownProps) => {
  const dropdownRef = useRef(null);
  useOutsideDropdownClick(dropdownRef, () => {
    setIsOpen && setIsOpen(false);
  });

  return (
    <StyledMenu
      ref={dropdownRef}
      onClick={() => setIsOpen && setIsOpen(false)}
      className="listbox-element"
    >
      {children}
    </StyledMenu>
  );
};
