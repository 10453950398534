import { StyledCol, StyledPurpleGradTitle, StyledRow, StyledText } from '@/shared/styles';
import { StyledLeftCol, StyledOurSolution } from './OurSolution.styles';
import { useTranslation } from 'react-i18next';
import { useLangStore } from '@/stores/LangStore/LangStore';

export const OurSolution: React.FC = () => {
  const { t } = useTranslation();
  const isRtl = useLangStore((state) => state.isRtl());
  return (
    <StyledOurSolution $gap={40}>
      <StyledPurpleGradTitle level={2}>
        {t('landing_page.our_solution.title')}
      </StyledPurpleGradTitle>
      <StyledRow $justify={isRtl ? 'flex-end' : 'space-between'}>
        <StyledLeftCol>
          {[...Array(3)].map((_, i) => (
            <StyledCol key={`solution${i}`} $gap={16}>
              <StyledPurpleGradTitle
                level={3}
                $size={20}
                $align={isRtl ? 'right' : 'left'}
                $width={isRtl ? 'fit-content' : '100%'}
              >
                {t(`landing_page.our_solution.block${i + 1}.title`)}:
              </StyledPurpleGradTitle>
              <StyledText fontSize={'18px'} fontWeight={400}>
                {t(`landing_page.our_solution.block${i + 1}.content`)}
              </StyledText>
            </StyledCol>
          ))}
        </StyledLeftCol>
      </StyledRow>
    </StyledOurSolution>
  );
};
