import React, { useEffect } from 'react';
import { Form } from 'antd';
import { StyleFormItemNoPadding, StyledForm, StyledLabel } from '../../Forms.styles';
import { StyledCol, StyledPasswordInput } from '@/shared/styles';
import { U1Button } from '../../../Button/U1Button';
import { useSearchParams } from 'react-router-dom';
import { useAxios } from '@/services/apiClient';
import { getPwdErrors } from '../../Forms.consts';
import { PasswordValidation } from './PasswordValidation';
import { v4 as uuidv4 } from 'uuid';
import { RES_STATUS } from '@/shared/consts';
import { usePassword } from './usePassword';
import { useTranslation } from 'react-i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const CreatePasswordForm: React.FC = () => {
  const { navigate } = useU1Navigation();
  const { pwd, handleInputChange, isPasswordValid } = usePassword();
  const [params] = useSearchParams();
  const queryParams = Object.fromEntries(params);
  const { t } = useTranslation();

  const [{ response }, postResetPwd] = useAxios(
    {
      method: 'post',
      url: '/passwords/reset',
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (response?.status === RES_STATUS.CREATED) {
      navigate(`/${queryParams.user_type.toLowerCase()}`);
    }
  }, [response]);

  const onFinish = (values: any) => {
    if (isPasswordValid(values)) {
      postResetPwd({ data: { password: values.create, code: queryParams.code } });
    }
  };

  return (
    <StyledForm name="basic" onFinish={onFinish} autoComplete="off">
      <StyledCol $gap={8}>
        <StyledCol $gap={8}>
          <StyledLabel>{t('create_password.create.label')}</StyledLabel>
          <StyleFormItemNoPadding
            name="create"
            rules={[
              {
                required: true,
                message: <></>,
              },
            ]}
          >
            <StyledPasswordInput
              $width="440px"
              placeholder={t('create_password.create.placeholder')}
              onChange={handleInputChange}
            />
          </StyleFormItemNoPadding>
          <StyledCol $gap={16} $padding={16}>
            {getPwdErrors().map((err) => (
              <PasswordValidation key={uuidv4()} error={err.text} isValid={err.regex.test(pwd)} />
            ))}
          </StyledCol>
        </StyledCol>
        <StyledLabel>{t('create_password.repeat.label')}</StyledLabel>
        <Form.Item
          name="repeat"
          rules={[
            {
              required: true,
              message: `${t('create_password.repeat.error')}`,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('create') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('create_password.no_match')));
              },
            }),
          ]}
        >
          <StyledPasswordInput
            $width="440px"
            placeholder={t('create_password.repeat.placeholder')}
          />
        </Form.Item>
      </StyledCol>
      <Form.Item>
        <U1Button text={t('create_password.title')} htmlType="submit" />
      </Form.Item>
    </StyledForm>
  );
};
