import { U1Table } from '@/shared/components/Table/U1Table';
import { FormError } from '@/shared/components/Forms/FormError';
import { PanelTableProps } from './Panel.consts';

export const PanelTable: React.FC<PanelTableProps> = ({ data, columns, type }: PanelTableProps) => {
  return (
    <U1Table
      columns={columns}
      dataSource={[...data]}
      noDataMsg={
        <FormError
          message={`There are no ${type} under this name`}
          $width="fit-content"
          $align="center"
        />
      }
    />
  );
};
