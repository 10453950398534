import { Component } from '../../../../../types';
import { AccordionProps } from '@u1/fixers';
import { A11Y_DIRECTIVE_SELECTOR } from '../../../../../consts';

const ACCORDION_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-accordion`;
const DISABLED_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-disabled`;
const ACCORDION_COMPONENTS = {
  HEADER: 'header',
  CONTENT: 'content',
};

export const accordion: Component<AccordionProps> = {
  selector: `[${ACCORDION_ATTRIBUTE}]`,
  props: {
    selectors: {
      headerSelector: `[${ACCORDION_ATTRIBUTE}=${ACCORDION_COMPONENTS.HEADER}]`,
      contentSelector: `[${ACCORDION_ATTRIBUTE}=${ACCORDION_COMPONENTS.CONTENT}]`,
      disabledElementsSelector: `[${ACCORDION_ATTRIBUTE}=${ACCORDION_COMPONENTS.HEADER}][${DISABLED_ATTRIBUTE}="true"]`,
    },
    headingLevel: undefined,
  },
};
