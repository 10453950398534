import { StyledCard, StyledCol, StyledText, StyledTitle } from '@/shared/styles';
import { StyledSignIn } from '../SignIn.styles';
import { U1Logo } from '@/shared/components/U1Logo';
import { MfaForm } from '@/shared/components/Forms/SignIn/MFA/MfaForm';
import { useTranslation } from 'react-i18next';

export const Mfa: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledSignIn>
      <StyledCard $self="center" $padding={40} $gap={32} $width="520px">
        <U1Logo $size={42} />
        <StyledCol $align="center">
          <StyledTitle $size={28}>{t('2fa.title')}</StyledTitle>
          <StyledText fontSize={'16px'} fontWeight={400} textAlign="center">
            {t('2fa.content')}
          </StyledText>
        </StyledCol>
        <MfaForm />
      </StyledCard>
    </StyledSignIn>
  );
};
