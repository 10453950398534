export const useLocalStorage = () => {
  const setStorage = (key: string, value: object | string) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const getStorage = (key: string) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  };

  const removeStorage = (key: string) => {
    localStorage.removeItem(key);
  };

  return { setStorage, getStorage, removeStorage };
};
