import { U1Button } from '@/shared/components/Button/U1Button';
import { CancelSubscriptionModal } from '@/shared/components/Modals/SubscriptionModal/CancelSubscriptionModal';
import { RenewSubscriptionModal } from '@/shared/components/Modals/SubscriptionModal/RenewSubscription';
import { LOCALES, PAGES, SUBSCRIPTION_TYPE2 } from '@/shared/consts';
import { StyledColOffset, StyledContent, StyledRowOffset, StyledTitle } from '@/shared/styles';
import { getDateTemplate, getFrameworksWithComma } from '@/shared/utils';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import React from 'react';
import { StyledBillingCard, StyledDetailsText } from '../Billing.styles';
import { useTranslation } from 'react-i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const PackageDetails: React.FC = () => {
  const subscription = useSubscriptionStore();
  const { navigate } = useU1Navigation();
  const { t } = useTranslation();

  return (
    <StyledBillingCard
      $padding={40}
      $height={subscription.isFreemium ? '260px' : 'fit-content'}
      $gap={12}
    >
      <StyledTitle level={2} $size={20} $weight={600} $flex="start">
        {t('billing.package_details.title')}:
      </StyledTitle>

      <StyledColOffset>
        <StyledContent role="list">
          {!subscription.isFreemium && (
            <StyledRowOffset role="listitem">
              <StyledDetailsText>{t('subscription_type')}</StyledDetailsText>
              <StyledDetailsText>
                {subscription.isActive ? SUBSCRIPTION_TYPE2[subscription.pricingMethod] : '-'}
              </StyledDetailsText>
            </StyledRowOffset>
          )}
          {!subscription.isFreemium && (
            <StyledRowOffset role="listitem">
              <StyledDetailsText>{t('billing.package_details.available_till')}</StyledDetailsText>
              <StyledDetailsText data-testid="availableTillDate">
                {subscription.isActive
                  ? getDateTemplate(subscription.expiryDate, LOCALES.DAY_MONTH_YEAR)
                  : '-'}
              </StyledDetailsText>
            </StyledRowOffset>
          )}
          <StyledRowOffset role="listitem">
            <StyledDetailsText>{t('pricing.package.framework.multiple')}</StyledDetailsText>
            <StyledDetailsText data-testid="availableFrameworks">
              {subscription.isActive ? getFrameworksWithComma(subscription.frameworks) : '-'}
            </StyledDetailsText>
          </StyledRowOffset>
          {!subscription.isFreemium && (
            <StyledRowOffset role="listitem">
              <StyledDetailsText>
                {t('billing.package_details.additional_services')}
              </StyledDetailsText>
              <StyledDetailsText data-testid="additionalServicesIncluded">
                {subscription.isActive
                  ? subscription.additionalServices
                    ? t('yes')
                    : t('no')
                  : '-'}
              </StyledDetailsText>
            </StyledRowOffset>
          )}
          <StyledRowOffset role="listitem">
            <StyledDetailsText>{t('pricing.package.domain.multiple')}</StyledDetailsText>
            <StyledDetailsText data-testid="domains">
              {subscription.isActive ? subscription.domains : '-'}
            </StyledDetailsText>
          </StyledRowOffset>
        </StyledContent>
        {subscription.isFreemium ? (
          <U1Button
            dataTestId="upgradePackageButton"
            text={t('billing.package_details.upgrade')}
            $height="50px"
            $width="100%"
            onClick={() => navigate(PAGES.AFTER_LOGIN.PRICING)}
          />
        ) : (
          <>
            {subscription.isActive && subscription.autoPayment && <CancelSubscriptionModal />}
            {subscription.isActive && !subscription.autoPayment && (
              <RenewSubscriptionModal $buttonType="activate" />
            )}
          </>
        )}
      </StyledColOffset>
    </StyledBillingCard>
  );
};
