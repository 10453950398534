import { StyledCol, StyledRow } from '@/shared/styles';
import {
  StyledDealProgress,
  StyledFirstStep,
  StyledLastStep,
  StyledStep,
  StyledStepText,
} from '../Deals.styles';
import { StepProps } from '../Deals.consts';

export const CreateDealProgress: React.FC<StepProps> = ({ step }: StepProps) => {
  const currentStep = step as number;
  return (
    <StyledCol $gap={0}>
      <StyledRow $gap={4}>
        <StyledFirstStep isActive={currentStep === 1} isComplete={currentStep > 1} />
        <StyledStep isActive={currentStep === 2} isComplete={currentStep > 2} />
        <StyledLastStep isActive={currentStep === 3} isComplete={currentStep > 3} />
      </StyledRow>
      <StyledDealProgress>
        <StyledRow $justify="space-around" $width="100%" aria-label="Breadcrumb" role="navigation">
          <StyledStepText isActive={currentStep === 1} aria-current={currentStep === 1}>
            Package
          </StyledStepText>
          <StyledStepText isActive={currentStep === 2} aria-current={currentStep === 2}>
            Client Details
          </StyledStepText>
          <StyledStepText isActive={currentStep === 3} aria-current={currentStep === 3}>
            Payment
          </StyledStepText>
        </StyledRow>
      </StyledDealProgress>
    </StyledCol>
  );
};
