import { TextEditorProps } from '../EditDocsModal.consts';
import { useRef } from 'react';
import { EditDocsModal } from '../EditDocsModal';
import * as monaco from 'monaco-editor';
import { CodeEditor } from '@/shared/components/CodeEditor/CodeEditor';

export const CodeEditorModal: React.FC<TextEditorProps> = ({
  docs,
  setDocs,
  setIsEditMode,
}: TextEditorProps) => {
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);

  const savePreview = () => {
    if (editorRef.current) {
      setDocs(editorRef.current.getValue());
      setIsEditMode(true);
    }
  };

  return (
    <EditDocsModal onSave={savePreview}>
      <CodeEditor value={docs} editorRef={editorRef} />
    </EditDocsModal>
  );
};
