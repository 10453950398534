import { Select } from 'antd';
import { SimpleSelectProps } from './SimpleSelect.consts';
import { ArrowDownIcon } from '@/assets/images/icons';
import { Icon } from '../Icon';
import { U1Listbox } from '@u1/react-a11y-hooks';

export const SimpleSelect: React.FC<SimpleSelectProps> = ({
  label,
  value,
  options,
  className,
  onSelect,
  showIcon,
}: SimpleSelectProps) => {
  const icon = showIcon === false ? {} : { suffixIcon: <Icon $src={ArrowDownIcon} $alt="" /> };
  return (
    <U1Listbox>
      <span className="u1st-sr-only simple-select-label">{label}</span>
      <Select
        placement="bottomRight"
        className={`${className ?? ``} simple-select-trigger`}
        popupClassName="simple-select"
        value={value}
        onChange={onSelect}
        options={options}
        {...icon}
      />
    </U1Listbox>
  );
};
