import { StyledCol, StyledText } from '@/shared/styles';
import { useTranslation } from 'react-i18next';

export const AdditionalInfo: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledCol $gap={24}>
      {[...Array(3)].map((_, i) => (
        <StyledText key={`institutions${i}`} fontSize={'18px'} fontWeight={400}>
          {t(`landing_page.institutions.block${i + 1}`)}
        </StyledText>
      ))}
    </StyledCol>
  );
};
