import { InfoIcon } from '@/assets/images/icons';
import { Icon } from '@/shared/components/Icon';
import { U1Tooltip } from '@/shared/components/Tooltip/Tooltip';
import {
  StyledCol,
  StyledContent,
  StyledRow,
  StyledSecondaryText,
  StyledTitle,
} from '@/shared/styles';
import { isMobile } from 'react-device-detect';
import { StyledPackageSection } from '../PackageDetails.styles';
import { ExtraSelectionProps } from '../PakageDetails.consts';
import { useTranslation } from 'react-i18next';
import { useLangStore } from '@/stores/LangStore/LangStore';

export const ExtraSelection: React.FC<ExtraSelectionProps> = ({ extras }: ExtraSelectionProps) => {
  const { t } = useTranslation();
  const isRtl = useLangStore((state) => state.isRtl);
  return (
    <>
      {extras.map((section) => {
        return (
          <StyledPackageSection key={section.title} data-testid={section.title}>
            <StyledCol>
              <StyledRow>
                <StyledTitle level={2} $size={isMobile ? 16 : 20} $margin="0px">
                  {section.title}
                </StyledTitle>
                {section.optional && (
                  <StyledSecondaryText margin="0px 5px">({t('optional')})</StyledSecondaryText>
                )}
                {section.info && (
                  <U1Tooltip $text={section.info} $padding="0px 5px">
                    <Icon $src={InfoIcon} $size={17} $className="u1-button ant-tooltip-button" />
                  </U1Tooltip>
                )}
              </StyledRow>

              <StyledContent textAlign={isRtl() ? `right` : `left`} fontSize="16px">
                {section.content}
              </StyledContent>
              {section.extraComp && <StyledRow>{section.extraComp}</StyledRow>}
            </StyledCol>
          </StyledPackageSection>
        );
      })}
    </>
  );
};
