import { InfoIcon } from '@/assets/images/icons';
import React from 'react';
import { Icon } from '../Icon';
import { InfoMessageProps } from './InfoMessage.consts';
import { StyledInfo } from './InfoMessage.styles';

export const InfoMessage: React.FC<InfoMessageProps> = ({
  message,
  $margin,
  $justify,
}: InfoMessageProps) => {
  return (
    <StyledInfo $margin={$margin} $justify={$justify}>
      <Icon $src={InfoIcon} />
      <span>{message}</span>
    </StyledInfo>
  );
};
