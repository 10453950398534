import { Image } from '@/shared/components/Image/Image';
import introImage from '@/assets/images/landingPage/introImg.png';
import { Intro } from './Intro';
import { StyledDesktopIntro } from './Intro.styles';
import { useLangStore } from '@/stores/LangStore/LangStore';

export const DesktopIntro: React.FC = () => {
  const isRtl = useLangStore((state) => state.isRtl());
  return (
    <StyledDesktopIntro $justify={isRtl ? 'flex-end' : 'space-between'}>
      <Intro />
      <Image $src={introImage} $position="absolute" $width="45%" />
    </StyledDesktopIntro>
  );
};
