import styled from 'styled-components';

export const StyledPricing = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 50px 0px;
`;

export const StyledPackages = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: center;
  align-self: center;
  max-width: 1500px;
`;

export const StyledShimmerPricing = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-top: 150px;
`;
