import { leftArrow, rightArrow } from '@/assets/images/icons';
import { PaginationProps } from 'antd';
import React from 'react';
import { U1PaginationProps } from './Pagination.consts';
import { StyledPagination } from './Pagination.styles';
import { Icon } from '../Icon';
import { U1Pagination } from '@u1/react-a11y-hooks';

export const Pagination: React.FC<U1PaginationProps> = ({
  totalAmount,
  pageSize,
  onChange,
}: U1PaginationProps) => {
  const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return <Icon $src={leftArrow} />;
    }
    if (type === 'next') {
      return <Icon $src={rightArrow} />;
    }
    return originalElement;
  };
  return (
    <U1Pagination>
      <StyledPagination
        total={totalAmount}
        showSizeChanger={false}
        itemRender={itemRender}
        onChange={onChange}
        pageSize={pageSize}
      />
    </U1Pagination>
  );
};
