import { InviteModal } from '@/shared/components/Modals/InviteModal/InviteModal';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { UsersDataProps } from '../Users.consts';
import { StyledActions, StyledLicences, StyledUserHeaderLayout } from './UsersHeader.styles';
import { StyledTitle } from '@/shared/styles';
import { useTranslation } from 'react-i18next';

export const UsersHeader: React.FC<UsersDataProps> = ({
  usersData,
  getUsersData,
}: UsersDataProps) => {
  const subscriptionUsers = useSubscriptionStore((state) => state.users);
  const [users, setUsers] = useState({ maxUsers: 0, totalUsers: 0 });
  const { t } = useTranslation();

  useEffect(() => {
    if (usersData) {
      setUsers({
        maxUsers: subscriptionUsers,
        totalUsers: usersData.length,
      });
    }
  }, [usersData]);

  return (
    <StyledUserHeaderLayout>
      <StyledTitle $weight={600} $size={28}>
        {t('users.title')}
      </StyledTitle>
      <StyledActions>
        <StyledLicences data-testid="usersCount">
          {users.totalUsers}/{users.maxUsers} {t('users.title')}
        </StyledLicences>
        <InviteModal getUsersData={getUsersData} />
      </StyledActions>
    </StyledUserHeaderLayout>
  );
};
