import { Component } from '../../../../../types';
import { AbstractProps, GridProps } from '@u1/fixers';
import { hideElement } from '../utils';

export const grid: Component<GridProps> = {
  selector: '.ant-grid-table table',
  props: {
    selectors: {
      grid: '.ant-grid-table table',
      cell: 'td.ant-table-cell:not([aria-hidden])',
      row: 'tr',
      columnheader: 'th.ant-table-cell:not([aria-hidden])',
    },
  },
  handleSpecialCases: (props: AbstractProps, el: HTMLElement) => {
    hideAllEmptyColums(el);
  },
};

const hideAllEmptyColums = (el: HTMLElement) => {
  const allHeaders = el.querySelectorAll('th');
  allHeaders?.forEach((header, i) => {
    if (!header.innerText.trim()) {
      const numOfRowsWithTds = el.querySelectorAll('tr:has(td)').length;
      const tds = el.querySelectorAll(`tr td:nth-child(${i + 1}):empty`);
      if (numOfRowsWithTds === tds.length) {
        hideElement(header);
        tds?.forEach((td) => {
          hideElement(td);
        });
      }
    }
  });
};
