import React, { useEffect, useRef, useState } from 'react';
import { StyledDocsContentCard } from '../DocumentationPage.styles';
import { StyledTitle, StyledCapitalized, StyledCol, StyledRowOffset } from '@/shared/styles';
import { GettingStarted } from './GettingStarted/GettingStartedDoc';
import { ComponentDoc } from './DocsPage/ComponentDoc';
import { PageSlider } from './PageSlider';
import { SinglePageDoc } from './DocsPage/SinglePageDoc';
import { useDocsStore } from '@/stores/ProjectStore/ProjectStore';
import { U1Button } from '@/shared/components/Button/U1Button';
import { ConfirmModal } from '@/shared/components/Modals/ConfirmModal/ConfirmModal';
import { RES_STATUS, USER_TYPE } from '@/shared/consts';
import { useUserStore } from '@/stores/UserStore';
import { useAxios } from '@/services/apiClient';
import { useTranslation } from 'react-i18next';

export const DocsContent: React.FC = () => {
  const [page, setPage] = useState(1);
  const [successMsg, setSuccessMsg] = useState('');
  const docsStore = useDocsStore();
  const selectedComponent = docsStore.selectedComponentDoc;
  const userType = useUserStore((state) => state.type);
  const ref = useRef(null);
  const { t } = useTranslation();

  const isComponentDoc =
    !!selectedComponent?.category && selectedComponent.category === 'components';
  const singlePageComp = !selectedComponent?.usage?.description && !selectedComponent?.usage?.test;

  const [{ response: publishedData }, publishChanges] = useAxios(
    {
      method: 'put',
      url: `frameworks/${docsStore.projectId}`,
      data: {
        docs: docsStore.docsObj,
      },
    },
    { manual: true }
  );

  useEffect(() => {
    setPage(1);
  }, [selectedComponent?.name]);

  useEffect(() => {
    if (publishedData?.status === RES_STATUS.OK) {
      setSuccessMsg(t('projects.docs.component.published'));
    }
  }, [publishedData]);

  return selectedComponent ? (
    <StyledCol $align="center">
      <StyledDocsContentCard id="docs-content" ref={ref}>
        <StyledRowOffset $align="center">
          <StyledTitle level={1} $weight={600} $size={20}>
            <StyledCapitalized data-testid="componentTitle">
              {selectedComponent.name ?? t('projects.docs.component.select_component')}
            </StyledCapitalized>
          </StyledTitle>
          {userType === USER_TYPE.ADMIN && (
            <ConfirmModal
              trigger={<U1Button text={t('projects.docs.component.publish')} />}
              onConfirm={() => {
                publishChanges();
              }}
              title={t('projects.docs.component.sure_you_want_to_publish')}
              successMsg={successMsg}
              setSuccessMsg={setSuccessMsg}
            />
          )}
        </StyledRowOffset>

        {isComponentDoc ? (
          singlePageComp ? (
            <SinglePageDoc />
          ) : (
            <ComponentDoc page={page} />
          )
        ) : (
          <GettingStarted page={page} />
        )}
      </StyledDocsContentCard>
      <PageSlider
        docsRef={ref}
        page={page}
        setPage={setPage}
        numOfPages={singlePageComp ? 0 : isComponentDoc ? 3 : 4}
      />
    </StyledCol>
  ) : (
    <></>
  );
};
