import { USER_TYPE } from '@/shared/consts';
import { PropsWithChildren } from 'react';

export enum SIGN_IN_VIEW {
  MAIN,
  RESET_PASSWORD,
  EMAIL_SENT,
  MFA,
}
export type SignInProps = PropsWithChildren & {
  userType: USER_TYPE;
};
