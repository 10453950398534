import { MenuProps } from 'antd';
import { TreeItem } from '@/Views/AfterLogin/Main/Docs/DocumentationPage/SearchTree/Tree/TreeItem';
import { TreeItemIcon } from '@/Views/AfterLogin/Main/Docs/DocumentationPage/SearchTree/Tree/TreeItemIcon';

export const getCurrentJson = (originalJson: any, json: any, objkeys: string[]) => {
  let currentObj: any = originalJson;
  for (const key of objkeys?.slice(0, -1)) {
    if (currentObj[key] === undefined) {
      currentObj[key] = {};
    }
    currentObj = currentObj[key];
  }
  return currentObj;
};

export const updateJson = (
  json: any,
  objkeys: string[],
  updatedPart: string | string[],
  index?: number
) => {
  const isIndexed = typeof index === 'number';
  const originalJson = json;
  const currentObj = getCurrentJson(originalJson, json, objkeys);
  const key = objkeys[objkeys.length - 1];
  if (isIndexed) {
    currentObj[index][key] = updatedPart;
  } else {
    currentObj[key] = updatedPart;
  }
  return { ...originalJson, ...currentObj };
};

export const removeFromJson = (json: any, objkeys: string[], index: number) => {
  const originalJson = json;
  const currentObj = getCurrentJson(originalJson, json, objkeys);
  currentObj.splice(index, 1);
  return { ...originalJson, ...currentObj };
};

export const extractTreeItems = (
  componentsTreeList: string[],
  updatedList: string[]
): MenuProps['items'] => {
  return componentsTreeList
    .map((menuItem, i) => {
      return updatedList.includes(menuItem)
        ? {
            'data-testid': `componentDocsTab`,
            key: i,
            label: <TreeItem compName={menuItem} />,
            icon: <TreeItemIcon compName={menuItem} />,
          }
        : null;
    })
    .filter((item) => item !== null);
};
