import { useSubscription } from '@/hooks/useSubscription';
import { useSelectionStore } from '@/stores/SelectionStore';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { PaymentSuccessProps } from '../Payment.consts';
import { PaymentMessage } from './PaymentMessage';
import { useTranslation } from 'react-i18next';

export const PaymentSuccess: React.FC<PaymentSuccessProps> = ({ message }: PaymentSuccessProps) => {
  const selectedPackage = useSelectionStore();
  const { getSubscription } = useSubscription();
  const { t } = useTranslation();

  useEffect(() => {
    getSubscription();
  }, []);

  return (
    <PaymentMessage
      dataTestId="successPaymentMessage"
      btnText={t('projects.create_project')}
      packageText={t('billing.current_package')}
      paymentMessage={message ?? t('payment.payment_message.success.subtitle')}
      selectedPackage={selectedPackage}
    >
      {isMobile
        ? t('payment.payment_message.success.create_project.message.mobile')
        : t('payment.payment_message.success.create_project.message')}
    </PaymentMessage>
  );
};
