import { StyledCol } from '@/shared/styles';
import styled from 'styled-components';
import prodsPreview from '@/assets/images/landingPage/prodsPreview.png';
import { isMobile } from 'react-device-detect';

export const StyledOurSolution = styled(StyledCol)`
  border: 1px solid ${(props) => props.theme.landingPageBorder};
  border-radius: 16px;
  padding: 40px 30px;

  background-image: url(${prodsPreview});
  background-repeat: no-repeat;
  background-position: right bottom;
  min-height: ${isMobile ? '1200' : '748'}px;
  ${isMobile && `background-size: calc(100% - 30px) 310px;`}

  @media  (min-width: 601px) and (max-width: 1229px) {
    background-size: 60% 80%;
  }
`;

export const StyledLeftCol = styled(StyledCol)`
  ${!isMobile && `flex: 0 0 35%;`}
  gap: 32px;
`;
