import React from 'react';
import { StyledHomepage } from '../MainPage.styles';
import { StyledContainer } from '@/shared/styles';
import { MainPageIntro } from './MainPageIntro';
import { U1Benefits } from './Benefits/U1Benefits';
import { U1Features } from './U1Features';
import { FrameworkSupport } from './FrameworksSupport/FrameworkSupport';
import { U1TrustedBy } from './TrustedBy/U1TrustedBy';

export const MainPageContent: React.FC = () => {
  return (
    <StyledHomepage>
      <StyledContainer>
        <MainPageIntro />

        <U1Benefits />

        <U1Features />

        <FrameworkSupport />

        <U1TrustedBy />
      </StyledContainer>
    </StyledHomepage>
  );
};
