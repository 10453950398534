import { ButtonProps } from '@/shared/props';
import { useThemeStore } from '@/stores/ThemeStore';
import { Button } from 'antd';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const StyledButton = styled(Button)<ButtonProps>`
  background: ${(props) => (props.$bgColor ? props.$bgColor : props.theme.buttonsBG)};
  ${(props) => props.disabled && `background: ${props.theme.disabledColor} !important`};
  color: ${(props) =>
    `${
      props.disabled
        ? props.theme.disabledText
        : props.$textColor || props.theme.gradientButtonInnerText
    }`} !important;
  border: none;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 100px;
  width: ${(props) => (props.$width ? `${props.$width}` : `100%`)};
  height: ${(props) => (props.$height ? `${props.$height}` : `50px`)};
  ${(props) => props.$padding && `padding: ${props.$padding};`}
  cursor: pointer;

  ${isMobile &&
  `
  min-height: 42px;`}

  &:hover,
  &:focus {
    background: ${(props) =>
      `${
        props.disabled ? props.theme.disabledColor : props.$bgColor || props.theme.buttonsBG
      }`} !important;
    color: ${(props) =>
      `${
        props.disabled
          ? props.theme.disabledText
          : props.$textColor || props.theme.gradientButtonInnerText
      }`} !important;
  }
`;

export const StyledGradientButton = styled.button<ButtonProps>`
  width: ${(props) => props.$width ?? `fit-content`};
  height: ${(props) => props.$height ?? `fit-content`};
  padding: ${(props) => props.$padding ?? `14px 60px;`};
  border: none;
  outline: none;
  position: relative;
  border-radius: 100px;
  background: ${(props) => (props.disabled ? props.theme.disabledColor : props.theme.buttonsBG)};
  cursor: ${(props) => (props.disabled ? `disabled` : `pointer`)};
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    border-radius: 100px;
    background-color: ${(props) => props.theme.cardsColor};
    z-index: -1;
  }

  &::after {
    content: attr(data);
    font-size: 16px;
    font-weight: 800;
    background: ${(props) => (props.disabled ? props.theme.disabledColor : props.theme.buttonsBG)};
    -webkit-background-clip: text;
    color: transparent;
  }
`;

export const StyledRoundedButton = styled.button<ButtonProps>`
  width: ${(props) => props.$width ?? `fit-content`};
  height: ${(props) => props.$height ?? `fit-content`};
  padding: ${(props) => (props.$padding ?? isMobile ? `10px 24px` : `14px 60px;`)};
  border: none;
  outline: none;
  position: relative;
  border-radius: 100px;
  background: ${(props) =>
    props.disabled ? props.theme.disabledColor : props.theme.buttonsBGHorizontal};
  cursor: ${(props) => (props.disabled ? `disabled` : `pointer`)};
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    border-radius: 100px;
    background-color: ${(props) =>
      useThemeStore().isDarkMode ? props.theme.marginsColor : props.theme.sameColorBG};
    z-index: -1;
  }

  &::after {
    content: attr(data);
    font-size: 16px;
    font-weight: 800;
    background: ${(props) =>
      props.disabled
        ? props.theme.disabledColor
        : useThemeStore().isDarkMode
        ? props.theme.textColor
        : props.theme.sameColorText};
    -webkit-background-clip: text;
    color: transparent;
  }
`;

export const StyledWhiteBorderButton = styled(StyledGradientButton)<ButtonProps>`
  background: ${(props) => (props.disabled ? props.theme.disabledColor : props.theme.secondBorder)};
  padding: ${(props) => props.$padding ?? '0'}px;
  height: 50px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;

  &::after {
    content: attr(data);
    font-size: 16px;
    font-weight: 800;
    background: ${(props) => (props.disabled ? props.theme.disabledColor : props.theme.textColor)};
    -webkit-background-clip: text;
    color: transparent;
    font-family: system-ui !important;
  }
`;

export const StyledIconButton = styled(Button)<ButtonProps>`
  ${(props) => props.$height && `height: ${props.$height};`}
  ${(props) => props.$width && `width: ${props.$width};`}
  ${(props) => props.$padding && `padding: ${props.$padding};`}
  ${(props) => props.$textColor && `color: ${props.$textColor};`}
  ${(props) => props.$invert && `filter: invert(100%);`}
  background: ${(props) => props.$bgColor ?? 'none'};
  border: none;

  &:hover,
  &:focus {
    ${(props) => props.$textColor && `color: ${props.$textColor};`}
    background: ${(props) => props.$bgColor ?? 'none'};
    border: none;
  }

  span {
    margin: 8px;
    font-size: 16px;
  }
`;
