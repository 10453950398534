import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { StyledOrangeGradText } from '@/shared/styles';
import { StyledStaticPageContainer } from '@/shared/pages/StaticPages/StaticPage.styles';
import { BeforeLoginMainProps } from './BeforeLogin.consts';

export const StyledMain = styled.main`
  width: 100%;
  height: fit-content;
  min-height: 80%;
  color: ${(props) => props.theme.textColor};
  ${() => isMobile && `padding: 16px;`};
`;

export const StyledBgLayout = styled.div`
  width: 100%;
  height: 100%;

  ${StyledStaticPageContainer} {
    padding: 60px;
  }
`;

export const StyledMainBeforeLogin = styled(StyledMain)<BeforeLoginMainProps>`
  color: ${(props) => props.theme.textColor};
  display: flex;
  flex-direction: column;
  gap: ${isMobile ? `100` : `120`}px;
  ${!isMobile && `padding: 60px;`};

  background-color: ${(props) => props.theme.backgroundColor};
  ${(props) => props.$bg && `background-image: url('${props.$bg}');`}
  background-repeat: no-repeat;

  @media only screen and (min-width: 2000px) {
    height: 120%;
  }
`;
export const StyledOrangeGradDot = styled(StyledOrangeGradText)`
  font-weight: inherit !important;
  line-height: inherit !important;
  font-size: inherit !important;
`;
