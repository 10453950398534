import styled from 'styled-components';
import { StepProps } from './Deals.consts';
import { StyledCard } from '@/shared/styles';

export const StyledStep = styled.div<StepProps>`
  background: ${(props) =>
    props.isActive
      ? props.theme.linkGradient
      : props.isComplete
      ? props.theme.successfulProgress
      : props.theme.marginsBorder};
  height: 4px;
  width: 100%;
`;

export const StyledFirstStep = styled(StyledStep)`
  border-top-left-radius: 5px;
`;

export const StyledLastStep = styled(StyledStep)`
  border-top-right-radius: 5px;
`;

export const StyledStepText = styled.span<StepProps>`
  font-size: 16px;
  ${(props) =>
    props.isActive
      ? `
  color: ${props.theme.textColor};
  font-weight: 600;
  `
      : `
        color: ${props.theme.secondaryTextColor};
        font-weight: 400;
      `};
`;

export const StyledDealProgress = styled(StyledCard)`
  width: 100%;
  height: 52px;
  justify-content: center;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
`;
