import { LinkProps } from '@/shared/props';
import { StyledPinkRouterLink } from './Links.styles';
import { v4 as uuidv4 } from 'uuid';
import { U1Button } from '@u1/react-a11y-hooks';

export const PinkGradientRouterLink: React.FC<LinkProps> = ({
  text,
  href,
  onClick,
  dataTestId,
  isButton,
  ariaLabel,
  excludeEl = false,
}: LinkProps) => {
  const props = {
    to: `${href || '#'}`,
    key: uuidv4(),
    onClick,
    'data-testid': dataTestId,
    'aria-label': ariaLabel,
    'data-u1-exclude': excludeEl,
  };

  return isButton ? (
    <U1Button>
      <StyledPinkRouterLink data-u1-button="button" {...props}>
        {text}
      </StyledPinkRouterLink>
    </U1Button>
  ) : (
    <StyledPinkRouterLink {...props}>{text}</StyledPinkRouterLink>
  );
};
