import { StyledCol } from '@/shared/styles';
import { ClipboardSyntaxHighlighter } from '../../../ClipboardSyntaxHighlighter/ClipboardSyntaxHighlighter';
import { ApiTable } from '../Api/ApiTable';
import { useDocsStore } from '@/stores/ProjectStore/ProjectStore';
import { DocsSection } from '../DocsSection/DocsSection';
import { useTranslation } from 'react-i18next';
import { FrameworkPlatformPrefixes } from '@/Views/AfterLogin/Main/Client/Projects/Projects.utils';

export const Page2: React.FC = () => {
  const selectedComponent = useDocsStore((state) => state.selectedComponentDoc);
  const platform = useDocsStore((state) => state.platform);
  const usage = selectedComponent?.usage;
  const { t } = useTranslation();

  return (
    <StyledCol $gap={32}>
      {/* Attributes */}
      {usage?.attributes?.text && (
        <DocsSection
          title={`${FrameworkPlatformPrefixes[platform]} ${t(
            'projects.docs.component.attributes.title.h2'
          )}`}
          objkeys={['usage', 'attributes', 'text']}
          content={usage.attributes.text}
        />
      )}

      {/* Example */}
      {usage?.attributes?.example && (
        <ClipboardSyntaxHighlighter
          objkeys={['usage', 'attributes', 'example']}
          content={usage.attributes.example}
        />
      )}

      {/* Table */}
      {usage?.attributes?.table && (
        <ApiTable dataTable={usage.attributes.table} objkeys={['usage', 'attributes', 'table']} />
      )}
    </StyledCol>
  );
};
