import { Cookies } from 'react-cookie';
import { COOKIES } from '../shared/consts';

const cookie = new Cookies();

export const setThemeCookie = (theme: string) => {
  const expireDate = new Date();
  expireDate.setFullYear(expireDate.getFullYear() + 1);
  cookie.set(COOKIES.THEME, theme, {
    path: '/',
    expires: expireDate,
    secure: true,
  });
};

export const getThemeCookie = () => {
  return cookie.get(COOKIES.THEME);
};

export const removeThemeCookie = () => {
  cookie.remove(COOKIES.THEME, { path: '/' });
};
