import { StyledCol, StyledPurpleGradTitle } from '@/shared/styles';
import { LogoSlider } from './LogoSlider';
import { useTranslation } from 'react-i18next';

export const Trusted: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledCol $gap={16}>
      <StyledPurpleGradTitle level={2} $align="center">
        {t('landing_page.trusted.title')}
      </StyledPurpleGradTitle>
      <LogoSlider />
    </StyledCol>
  );
};
