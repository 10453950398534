import { Image } from '@/shared/components/Image/Image';
import { StyledCol, StyledPurpleGradTitle, StyledRow, StyledText } from '@/shared/styles';
import { isDesktop, isMobile } from 'react-device-detect';
import { AdditionalInfo } from './AdditionalInfo';
import {
  StyledAdaptiveInstitutions,
  StyledInstiturionsCol,
  StyledInstitutionBox,
} from './Institutions.styles';
import { useTranslation } from 'react-i18next';
import { getInstitutionImages } from './Institutions.consts';

export const Institutions: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledCol $gap={40}>
      <StyledRow $gap={40}>
        <StyledInstiturionsCol>
          <StyledPurpleGradTitle level={2}>
            {t('landing_page.institutions.title')}:
          </StyledPurpleGradTitle>
          <StyledText fontSize={'18px'} fontWeight={400}>
            {t('landing_page.institutions.content')}
          </StyledText>
        </StyledInstiturionsCol>
        {isDesktop && <AdditionalInfo />}
      </StyledRow>
      <StyledAdaptiveInstitutions>
        {getInstitutionImages().map((ins) => (
          <StyledInstitutionBox key={ins.alt}>
            <Image $src={ins.img} $alt={ins.alt} />
          </StyledInstitutionBox>
        ))}
      </StyledAdaptiveInstitutions>
      {isMobile && <AdditionalInfo />}
    </StyledCol>
  );
};
