import { DealsData, PaymentDetails } from '@/shared/dto';
import { CheckboxGroupProps } from 'antd/es/checkbox/Group';

export type ClientDetails = {
  company: string;
  fullname: string;
  email: string;
  phone: string;
  country: string;
};

export const DealSteps: Record<string, number> = {
  PRODUCT: 1,
  CLIENT: 2,
  PAYMENT: 3,
};

export type DealStore = {
  id?: string;
  idNumber?: number;
  subscriptionType: number;
  users: string;
  frameworks: CheckboxGroupProps[];
  domains: string;
  additonalServices: number;
  price: string;
  dealStep: number;
  client?: ClientDetails;
  paymentDetails?: PaymentDetails;
  getDeal: (id?: string) => Promise<number>;
  setDealStep: (step: number) => void;
  setDeal: (deal: DealsData) => void;
  setDealId: (id: string) => void;
  setClient: (client: ClientDetails) => void;
  getStep1Error: () => string;
  setPrice: (price: string) => void;
  setSubscriptionType: (subscriptionType: number) => void;
  setUsers: (users: string) => void;
  setFrameworks: (frameworks: CheckboxGroupProps[]) => void;
  setDomains: (domains: string) => void;
  setAdditonalServices: (additonalServices: number) => void;
  clearDeal: () => void;
};
