import { Form } from 'antd';
import { StyledForm } from '../Forms.styles';
import { StyledCol, StyledSecondaryText, StyledTitle } from '@/shared/styles';
import { useEffect } from 'react';
import { useAxios } from '@/services/apiClient';
import { FormProps } from '../Forms.consts';
import { useAdminsStore } from '@/stores/AdminsStore';
import { FormError } from '../FormError';
import { extractErrors } from '@/shared/utils';
import { useFormMessages } from '@/hooks/useFormMessages';
import { VendorInnerForm } from './VendorInnerForm';
import { useTranslation } from 'react-i18next';

export const AddVendorForm: React.FC<FormProps> = ({ onCancel }: FormProps) => {
  const [vendorForm] = Form.useForm();
  const getVendors = useAdminsStore((state) => state.getVendors);
  const { message, setMessage, serverError, setError, clearAllMessages } = useFormMessages();
  const { t } = useTranslation();

  const [{ data, error }, postAddVendor] = useAxios(
    { url: '/vendors', method: 'post' },
    { manual: true }
  );

  const closeAndResetForm = (onCancel: () => void) => {
    onCancel();
    vendorForm.resetFields();
    getVendors();
    clearAllMessages();
  };

  useEffect(() => {
    if (data && onCancel) {
      setMessage(t('vendor.new_vendor.vendor_added'));
      setTimeout(() => {
        closeAndResetForm(onCancel);
      }, 1000);
    }
  }, [data]);

  useEffect(() => {
    if (error && error.response?.data.properties) {
      const errors = extractErrors(error.response);
      setError(errors);
    }
  }, [error]);

  const onFinish = (values: any) => {
    postAddVendor({
      data: {
        email: values?.email?.trim(),
        userName: values?.fullname?.trim(),
        companyName: values?.company,
        phone: values?.phone,
      },
    });
  };

  const closeDialog = (e: Event) => {
    e.preventDefault();
    onCancel && onCancel();
  };

  return message ? (
    <StyledSecondaryText fontWeight={400} fontSize={16}>
      {message}
    </StyledSecondaryText>
  ) : (
    <StyledForm name="basic" onFinish={onFinish} autoComplete="off" form={vendorForm}>
      {serverError && <FormError message={serverError} />}
      <StyledCol>
        <StyledTitle $size={18}>{t('vendor.new_vendor.title')}</StyledTitle>
        <StyledSecondaryText fontWeight={400} fontSize={16} flex="start">
          {t('vendor.new_vendor.content')}
        </StyledSecondaryText>
      </StyledCol>
      <VendorInnerForm
        onCancel={(e) => closeDialog(e)}
        submitBtnText={t('vendor.new_vendor.create_vendor')}
      />
    </StyledForm>
  );
};
