import { Utils } from '@u1/fixers';

export const getElementIdAttribute = (element: HTMLElement) => {
  if (!element.id) {
    element.id = Utils.uuid();
  }
  return element.id;
};

export const getElementIdSelector = (element: any): string => {
  const id = getElementIdAttribute(element);
  return `[id='${id}']`;
};

export const setElementIdSelector = (element: HTMLElement): void => {
  getElementIdAttribute(element);
};

export const getSelectorExtension = (uID: string): string => {
  return uID.length > 0 ? `='${uID}'` : '';
};
export const checkOptionalSelectors = (
  optionalSelectors: any,
  mandatorySelectors: any,
  domTree = document.querySelector('body') as HTMLElement
) => {
  Object.entries(optionalSelectors).forEach(([key, value]) => {
    const selectoreExists = !!domTree!.querySelector(`${value}`);
    if (!selectoreExists) {
      delete optionalSelectors[key];
    }
  });
  return { ...optionalSelectors, ...mandatorySelectors };
};
