import { Icon } from '@/shared/components/Icon';
import React, { useEffect } from 'react';
import {
  StyledFrameworkCard,
  StyledFrameworkStateText,
  StyledFrameworkCardInner,
  StyledFrameworkCardItem,
} from './NewProjectFormFrame.styles';
import { U1Button } from '@u1/react-a11y-hooks';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { useNewProjectStore } from '@/stores/ProjectStore/ProjectStore';
import { useTranslation } from 'react-i18next';

export const FrameworkCardItems: React.FC = () => {
  const subscription = useSubscriptionStore();
  const projectStore = useNewProjectStore();
  const currentFramework = projectStore.projectFramework;
  const setProjectFramework = projectStore.setProjectFramework;
  const supportedFrameworks = subscription.supportedFrameworks;
  const { t } = useTranslation();

  useEffect(() => {
    if (subscription.id) setProjectFramework(subscription.frameworks[0]);
  }, [subscription]);

  const getIcon = (currentFw: string) => {
    return supportedFrameworks?.find((fw) => fw.name === currentFw)?.icon;
  };

  return (
    <>
      {subscription.frameworks.map((fw) => {
        return (
          <StyledFrameworkCardItem key={fw}>
            <U1Button>
              <StyledFrameworkCard
                data-testid="frameworkCard"
                onClick={() => setProjectFramework(fw)}
                $width="119px"
                $height="116px"
                $isCurrent={currentFramework === fw}
                data-u1-button="button"
                aria-label={fw}
              >
                <StyledFrameworkCardInner $isCurrent={currentFramework === fw}>
                  <Icon $size={38} $src={getIcon(fw)} />
                </StyledFrameworkCardInner>
              </StyledFrameworkCard>
            </U1Button>

            <StyledFrameworkStateText $isCurrent={currentFramework === fw}>
              {currentFramework === fw
                ? t('projects.new_project.project_details.choose_framework.current')
                : ''}
            </StyledFrameworkStateText>
          </StyledFrameworkCardItem>
        );
      })}
    </>
  );
};
