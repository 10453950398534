import { StyledCard, StyledCol, StyledImg, StyledText, StyledTitle } from '@/shared/styles';
import { StyledSignIn } from '../SignIn.styles';
import { U1Logo } from '@/shared/components/U1Logo';
import { emailIcon } from '@/assets/images/icons';
import { WhiteBorderButton } from '@/shared/components/Button/WhiteBorderButton';
import { PinkGradientLink } from '@/shared/components/Link/PinkGradientLink';
import { SignInFormProps } from '@/shared/components/Forms/Forms.consts';
import { SIGN_IN_VIEW } from '../SignIn.consts';
import { useTranslation } from 'react-i18next';

export const EmailSent: React.FC<SignInFormProps> = ({ setView }: SignInFormProps) => {
  const { t } = useTranslation();
  return (
    <StyledSignIn>
      <StyledCard $self="center" $padding={40} $gap={32} $width="520px" $height="566px">
        <StyledCol $gap={32} $align="center">
          <U1Logo $size={42} />
          <StyledCol $align="center">
            <StyledTitle $size={28}>{t('form.email_sent')}</StyledTitle>
            <StyledText fontSize={'16px'} fontWeight={400} textAlign="center">
              {t('form.check_inbox')}
            </StyledText>
          </StyledCol>
          <StyledImg src={emailIcon} $size={160} alt="" />
          <StyledCol $align="center" $gap={16}>
            <WhiteBorderButton
              text={t('form.go_to_sign_in')}
              $width="290px"
              onClick={() => setView(SIGN_IN_VIEW.MAIN)}
            />
            <StyledText fontSize="16px" fontWeight={400}>
              {t('form.receive_link')}{' '}
              <PinkGradientLink
                text={t('form.resend')}
                onClick={() => setView(SIGN_IN_VIEW.RESET_PASSWORD)}
              />
            </StyledText>
          </StyledCol>
        </StyledCol>
      </StyledCard>
    </StyledSignIn>
  );
};
