﻿import { Component } from '../../../../../types';
import { TooltipProps } from '@u1/fixers';
import { A11Y_DIRECTIVE_SELECTOR } from '../../../../../consts';
import { SCOPE_PH, SUFFIX_PH } from '../../../../../consts';

const TOOLTIP_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-tooltip`;

export const tooltip: Component<TooltipProps> = {
  selector: `[${TOOLTIP_ATTRIBUTE}]`,
  props: {
    selectors: {
      trigger: `${SCOPE_PH} [${TOOLTIP_ATTRIBUTE}='trigger']`,
      tooltip: `${SCOPE_PH} [${TOOLTIP_ATTRIBUTE}='tooltip']${SUFFIX_PH}`,
    },
  },
};
