import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { LanguageDetectorModule } from 'i18next';
import { detectLanguageFromState, isValidLanguage } from '@/shared/utils';

const LanguageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  detect: () => {
    let language: string | null = location.pathname.split('/')[1];
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('state')) {
      language = detectLanguageFromState(queryParams.get('state'));
    }
    return language && isValidLanguage(language) ? language : 'en';
  },
};

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: false, // to not send 2 requests to backend side
    backend: {
      loadPath: `${import.meta.env.VITE_BASE_URL}/translations/all`,
      requestOptions: () => {
        const lang = i18n.language;
        return {
          headers: {
            'Accept-Language': lang,
          },
        };
      },
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
