import React, { useEffect, useState } from 'react';
import { Panel } from '../../../SearchPanel/Panel';
import { DealsTable } from './DealsTable/DealsTabls';
import { useVendorsStore } from '@/stores/VendorsStore';
import { PanelType } from '../../../SearchPanel/Panel.consts';
import { U1Button } from '@/shared/components/Button/U1Button';
import { DEALS_VIEW } from './Deals.consts';
import { CreateDeal } from './CreateDeal/CreateDeal';
import { ViewDeal } from './ViewDeal/VIewDeal';
import { useDealStore } from '@/stores/DealStore/DealStore';
import { DealSteps } from '@/stores/DealStore/DealsStore.consts';
import { useTranslation } from 'react-i18next';

export const DealsSection: React.FC = () => {
  const vendorsStore = useVendorsStore();
  const deals = useDealStore();
  const [companyName, setName] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    vendorsStore.getDeals();
  }, [vendorsStore.dealsView]);

  const getDeal = (company: string) => {
    setName(company);
    vendorsStore.getDeals(company);
  };

  const changePage = (currentPage: number) => {
    vendorsStore.getDeals(companyName, currentPage);
  };

  const createDeal = () => {
    deals.clearDeal();
    deals.setDealStep(DealSteps.PRODUCT);
    vendorsStore.setDealsView(DEALS_VIEW.CREATE_DEAL);
  };

  return vendorsStore.dealsView === DEALS_VIEW.MAIN ? (
    <Panel
      isLoading={vendorsStore.isLoading}
      panelType={PanelType.DEALS}
      title={t('vendor.deals.title')}
      name={companyName}
      totalResults={vendorsStore.totalDeals}
      createButton={
        <U1Button text={t('vendor.deals.create_deal')} $width="250px" onClick={createDeal} />
      }
      table={<DealsTable />}
      changePage={changePage}
      getSearch={getDeal}
    />
  ) : vendorsStore.dealsView === DEALS_VIEW.CREATE_DEAL ? (
    <CreateDeal />
  ) : vendorsStore.dealsView === DEALS_VIEW.VIEW_DEAL ? (
    <ViewDeal />
  ) : (
    <></>
  );
};
