import { StyledbulletList, StyledCapitalized, StyledCol, StyledSubTitle } from '@/shared/styles';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { StyledFreeVersionContainer, StyledProjectPageBodyText } from '../../ProjectsPage.style';
import { useTranslation } from 'react-i18next';

export const FreeVersionFrame: React.FC = () => {
  const freemiumComponents = useSubscriptionStore((state) => state.freemiumComponents);
  const { t } = useTranslation();

  return (
    <StyledFreeVersionContainer>
      <StyledCol $gap={12}>
        <StyledSubTitle>{t('projects.freemium.title2')}</StyledSubTitle>
        <StyledProjectPageBodyText fontWeight={200}>
          {t('projects.freemium.content2')}
        </StyledProjectPageBodyText>
      </StyledCol>
      <StyledbulletList $style="auto">
        {freemiumComponents?.map((comp) => {
          return (
            <li key={comp}>
              <StyledCapitalized>{comp}</StyledCapitalized>
            </li>
          );
        })}
      </StyledbulletList>
    </StyledFreeVersionContainer>
  );
};
