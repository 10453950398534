import bankingImage from '@/assets/images/landingPage/banking.svg';
import tourism from '@/assets/images/landingPage/tourism.svg';
import insurance from '@/assets/images/landingPage/insurance.svg';
import retail from '@/assets/images/landingPage/retail.svg';
import multiNationalGov from '@/assets/images/landingPage/multiNationalGov.svg';
import government from '@/assets/images/landingPage/government.svg';
import { t } from 'i18next';

export const getInstitutionImages = () => [
  { img: bankingImage, alt: t('landing_page.institutions.banking') },
  { img: tourism, alt: t('landing_page.institutions.tourism') },
  { img: insurance, alt: t('landing_page.institutions.insurance') },
  { img: retail, alt: t('landing_page.institutions.retail') },
  { img: multiNationalGov, alt: t('landing_page.institutions.multi_national_gov') },
  { img: government, alt: t('landing_page.institutions.gov') },
];
