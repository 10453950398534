import { StyledSeparator } from '@/shared/styles';
import React from 'react';
import { StyledCountriesLi, StyledCountriesList, StyledCountriesName } from './TopCountries.styles';
import { ChartItemsProps } from '@/shared/components/Charts/DonutChart/DonutChart.consts';

export const TopCountriesList: React.FC<ChartItemsProps> = ({
  items,
  selectedItem,
}: ChartItemsProps) => {
  return (
    <StyledCountriesList>
      {items.length && items[0].value > 0 ? (
        items.map((item, i) => (
          <StyledCountriesLi key={item.key}>
            <StyledCountriesName isSelected={selectedItem === item.key}>
              <span>{item.name}</span>
              <span>{item.amount}</span>
            </StyledCountriesName>
            {i !== items.length - 1 && <StyledSeparator $width="100%" />}
          </StyledCountriesLi>
        ))
      ) : (
        <></>
      )}
    </StyledCountriesList>
  );
};
