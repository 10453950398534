import React from 'react';
import { useThemeStore } from '@/stores/ThemeStore';
import { StyledThemeSwitcher } from './ThemeSwitcher.styles';

export const ThemeSwitcher: React.FC = () => {
  const isDarkMode = useThemeStore((state) => state.isDarkMode);
  const toggleTheme = useThemeStore((state) => state.toggleTheme);

  const switchThemes = () => {
    toggleTheme();
    window.location.reload();
  };

  return (
    <StyledThemeSwitcher
      checked={isDarkMode}
      onChange={switchThemes}
      aria-label={`Switch to ${isDarkMode ? 'Light' : 'Dark'} Mode`}
    />
  );
};
