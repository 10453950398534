import { GradientCopyIcon } from '@/assets/images/icons';
import { CopyIconButtonProps } from '@/shared/props';
import React, { useState } from 'react';
import { IconButton } from './IconButton';
import { U1Tooltip } from '../Tooltip/Tooltip';

export const GradientCopyButton: React.FC<CopyIconButtonProps> = ({ textToCopy }) => {
  const [isCopied, setisCopied] = useState(false);
  const onCopyClick = () => {
    setisCopied(true);
    navigator.clipboard.writeText(textToCopy);
    setTimeout(() => {
      setisCopied(false);
    }, 1500);
  };
  return (
    <U1Tooltip $text="Copied!" label={`copy ${textToCopy}`} open={isCopied}>
      <IconButton onClick={onCopyClick} $icon={GradientCopyIcon} />
    </U1Tooltip>
  );
};
