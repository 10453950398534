import { RenewSubscriptionModal } from '@/shared/components/Modals/SubscriptionModal/RenewSubscription';
import { StyledOrangeGradTitle } from '@/shared/pages/Pricing/Intro/Intro.styles';
import { StyledSecondaryText } from '@/shared/styles';
import { StyledBillingCard, StyleNoPackages } from '../Billing.styles';

export const NoActivePackages: React.FC = () => {
  return (
    <StyledBillingCard $padding={35}>
      <StyleNoPackages>
        <div>
          <StyledOrangeGradTitle level={2} $size={20} $flex="start">
            No active packages
          </StyledOrangeGradTitle>
          <StyledSecondaryText fontWeight={200} fontSize={'14'} flex="start">
            Renew your subscription to have access to the package
          </StyledSecondaryText>
        </div>
        <RenewSubscriptionModal $buttonType="renew" />
      </StyleNoPackages>
    </StyledBillingCard>
  );
};
