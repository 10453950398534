export const supportedFrameworks = [
  {
    alt: 'React',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/icons/reactjs.svg',
  },
  {
    alt: 'Angular',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/icons/ngMainPage.svg',
  },
  {
    alt: 'Vue JS',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/icons/vueMainPage.svg',
  },
  {
    alt: 'Vanilla JS',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/icons/java-script.svg',
  },
  {
    alt: 'Android',
    src: 'https://u1-prod-frontend-cdn.s3.eu-west-1.amazonaws.com/images/icons/android.svg',
  },
];
