import React from 'react';
import { ConfigProvider, Dropdown } from 'antd';
import { StyledShimmer } from '@/shared/styles';
import { StyledDropdownButton } from './UserDropdown.styles';
import { useAuth } from '@/hooks/useAuth';
import { useUserStore } from '@/stores/UserStore';
import { UserDetails } from './UserDetails';
import { U1Dropdown } from '@u1/react-a11y-hooks';
import { getItems } from './UserDropdown.consts';
import { useLangStore } from '@/stores/LangStore/LangStore';

export const UserDropdown: React.FC = () => {
  const { logout, loadingAuth } = useAuth();
  const userDetails = useUserStore();
  const userType = useUserStore((state) => state.type);
  const items = getItems(userDetails, userType, logout);
  const getLangDir = useLangStore((state) => state.getLangDir);

  return loadingAuth ? (
    <StyledShimmer $width={283} $height={52} />
  ) : (
    <ConfigProvider direction={getLangDir()}>
      <U1Dropdown>
        <Dropdown menu={{ items }} overlayClassName={'user-dropdown'} trigger={['click', 'hover']}>
          <StyledDropdownButton>
            <UserDetails />
          </StyledDropdownButton>
        </Dropdown>
      </U1Dropdown>
    </ConfigProvider>
  );
};
