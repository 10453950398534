import React from 'react';
import Editor, { OnMount } from '@monaco-editor/react';
import { CodeEditorProps } from './CodeEditor.consts';

export const CodeEditor: React.FC<CodeEditorProps> = ({ editorRef, value }: CodeEditorProps) => {
  const handleEditorDidMount: OnMount = (editor) => {
    if (editorRef) editorRef.current = editor;
  };

  return (
    <Editor
      height="400px"
      width="100%"
      defaultLanguage="html"
      value={value ?? ''}
      theme="vs-dark"
      onMount={handleEditorDidMount}
    />
  );
};
