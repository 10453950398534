import { useAxios } from '@/services/apiClient';
import { GradientBorderButton } from '@/shared/components/Button/GradientBorderButton';
import { StyledCol, StyledInput } from '@/shared/styles';
import {
  useDiscountStore,
  useSelectionStore,
  useExtraSelectionStore,
} from '@/stores/SelectionStore';
import { useEffect, useState } from 'react';
import { StyledCodeError, StyledDiscount, StyledDiscountInput } from '../OrderSummary.styles';
import { calcCouponDiscount } from '../../Checkout.utils';
import { UpgradePackageProps } from '../../../UpgradePackage/UpgradePackage.consts';
import { SelectionStore, SubscriptionDetails } from '@/stores/Stores.consts';
import { useTranslation } from 'react-i18next';

export const DiscountSection: React.FC<UpgradePackageProps> = ({
  currentPlan,
}: UpgradePackageProps) => {
  const discountStore = useDiscountStore();
  const userSelection = useExtraSelectionStore();
  const setUserSelection = useExtraSelectionStore((state) => state.setSelection);
  const selection = useSelectionStore();
  const [selectedPackage, setSelected] = useState<SubscriptionDetails | SelectionStore>();
  const { t } = useTranslation();

  useEffect(() => {
    setSelected(currentPlan ?? selection);
  }, []);

  const [{ data: couponData, error: couponError }, getCouponData] = useAxios(
    {
      method: 'get',
      url: `/products/${selectedPackage?.id}/coupons/${discountStore.discountCode}`,
    },
    { manual: true }
  );

  useEffect(() => {
    discountStore.clearDiscount();
  }, []);

  useEffect(() => {
    if (couponError) {
      discountStore.handleDiscont(false, t('billing.package_details.discount.invalid'));
    }
  }, [couponError]);

  useEffect(() => {
    if (couponData) {
      if (couponData.minTransactionAmount <= userSelection.totalPrice.total) {
        const discountSum = calcCouponDiscount(couponData, userSelection);
        setUserSelection &&
          setUserSelection({
            ...userSelection,
            discount: { id: couponData.id, value: discountSum },
          });
        discountStore.handleDiscont(true, t('billing.package_details.discount_applied'));
      } else {
        discountStore.handleDiscont(false, t('billing.package_details.reached_minimum'));
      }
    }
  }, [couponData]);

  const validateCode = (e: Event) => {
    getCouponData();
    e.preventDefault();
  };

  return (
    <StyledCol>
      <StyledDiscountInput>
        <StyledInput
          data-testid="discountCodeField"
          placeholder={t('billing.package_details.discount.placeholder')}
          $height={'45px'}
          $width={'75%'}
          $error={discountStore.codeError}
          onChange={(e) => discountStore.setDiscountCode(e.target.value)}
          value={discountStore.discountCode}
        />
        <GradientBorderButton
          dataTestId="applyDiscountButton"
          text={discountStore.codeApplied ? t('applied') : t('apply')}
          $height={'45px'}
          $padding={'8px 20px'}
          onClick={(e: Event) => validateCode(e)}
          disabled={discountStore.codeApplied}
          htmlType={'button'}
        />
      </StyledDiscountInput>
      {discountStore.codeApplied && (
        <StyledDiscount>
          <span>{discountStore.discountMsg}</span>
          <span data-testid="discountValue">-${userSelection.discount?.value}</span>
        </StyledDiscount>
      )}
      {discountStore.codeError && (
        <StyledCodeError>
          <span data-testid="discountCodeError">{discountStore.discountMsg}</span>
        </StyledCodeError>
      )}
    </StyledCol>
  );
};
