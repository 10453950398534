import { StyledRow } from '@/shared/styles';
import { BuildPackages } from './BuildPackage/BuildPackage';
import { SellingPrice } from './SellingPrice/SellingPrice';

export const PackageStep: React.FC = () => {
  return (
    <StyledRow
      $width="100%"
      $justify="space-between"
      $gap={32}
      id="step-1-form"
      className="u1-form"
    >
      <BuildPackages />
      <SellingPrice />
    </StyledRow>
  );
};
