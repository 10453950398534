import { toTitleCase } from '@/shared/utils';
import { useDocsStore } from '@/stores/ProjectStore/ProjectStore';
import { StyledMenuItem } from '../../DocumentationPage.styles';
import { TreeItemProps } from '../../../../Client/Projects/projects.consts';

export const TreeItem: React.FC<TreeItemProps> = ({ compName }: TreeItemProps) => {
  const docsStore = useDocsStore();

  return (
    <StyledMenuItem key={compName} isSelected={docsStore.selectedComponentDoc?.name === compName}>
      {toTitleCase(compName)}
    </StyledMenuItem>
  );
};
