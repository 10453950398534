import { StyledCol, StyledIcon } from '@/shared/styles';
import { StyledDetailsRow, StyledDetilsTitle } from '../Settings.styles';
import { PinkGradientLink } from '@/shared/components/Link/PinkGradientLink';
import { pinkBackArrow } from '@/assets/images/icons';
import { StyledVendorCard } from '../../../../Main.styles';
import { SETTINGS_VIEW } from '@/stores/SettingsStore/SettingsStore.consts';
import { useSettingsStore } from '@/stores/SettingsStore/SettingsStore';
import { SettingsViewPrpos } from '../Settings.consts';
import { useTranslation } from 'react-i18next';

export const SettingsView: React.FC<SettingsViewPrpos> = ({
  title,
  children,
}: SettingsViewPrpos) => {
  const setView = useSettingsStore((state) => state.setView);
  const { t } = useTranslation();

  return (
    <StyledCol $align="center" $gap={24}>
      <StyledDetailsRow>
        <PinkGradientLink
          onClick={() => setView(SETTINGS_VIEW.MAIN)}
          text={t('vendor.settings.back_to_settings')}
          inRow
        >
          <StyledIcon src={pinkBackArrow} />
        </PinkGradientLink>
        <StyledDetilsTitle>{title}</StyledDetilsTitle>
      </StyledDetailsRow>
      <StyledVendorCard $width="618px">{children}</StyledVendorCard>
    </StyledCol>
  );
};
