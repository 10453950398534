import { AdminDetails, VendorDetails } from '@/stores/Stores.consts';
import { ReactElement } from 'react';

export const USER_TYPE = {
  ADMIN: 'admin',
  VENDOR: 'vendor',
};

export type DeleteUserProps = {
  id: string;
  userName: string;
  userType: string;
  getUsers: () => VendorDetails[] | AdminDetails[];
};

export type AddUserProps = {
  form: ReactElement;
  btnText: string;
};

export type EditUserProps = {
  form: ReactElement;
};

export type ResendUserProps = {
  id: string;
  userName: string;
};
