import { StyledCapitalized } from '@/shared/styles';
import { ColumnsType } from 'antd/lib/table';
import { RefetchFunction } from 'axios-hooks';
import { UserDetails } from '../Users.consts';
import { ActionsDropdown } from './ActionsDropdown/ActionsDropdown';
import { AxiosResponse } from 'axios';
import { t } from 'i18next';

export const getColumns = (
  currentUserId: string,
  getUsersData: RefetchFunction<unknown, AxiosResponse<UserDetails[]>>
): ColumnsType<object> => {
  return [
    {
      title: t('users.table.full_name'),
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: t('users.table.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('users.table.last_activity'),
      dataIndex: 'lastActivity',
      key: 'lastActivity',
    },
    {
      title: t('users.table.role'),
      key: 'role',
      dataIndex: 'role',
      render: (role: string) => <StyledCapitalized>{role}</StyledCapitalized>,
    },
    {
      key: 'action',
      render: (user: UserDetails) =>
        user?.id !== currentUserId && (
          <ActionsDropdown
            userId={user.id}
            userRole={user.role}
            getUsersData={getUsersData}
            userName={user.fullName}
          />
        ),
    },
  ];
};
