import { StyledVendorCard } from '@/Views/AfterLogin/Main/Main.styles';
import { LineChart } from '@/shared/components/Charts/LineChart/LineChart';
import { StyledTitle } from '@/shared/styles';
import { useStatsStore } from '@/stores/StatsStore.tsx/StatsStore';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const DealsProgress: React.FC = () => {
  const stats = useStatsStore();
  const { t } = useTranslation();

  useEffect(() => {
    stats.getActivityStats();
  }, []);

  return (
    <StyledVendorCard>
      <StyledTitle level={2} $size={16} $weight={400}>
        {t('vendor.stats.total_deals_progress.title')}
      </StyledTitle>
      <LineChart data={stats.dealsProgress} />
    </StyledVendorCard>
  );
};
