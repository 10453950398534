import { StyledCol, StyledRow } from '@/shared/styles';
import styled from 'styled-components';
import { StepProps } from '../../../Deals.consts';
import { FlexboxProps } from '@/shared/props';

export const StyledBuildPackage = styled(StyledCol)<FlexboxProps>`
  gap: ${(props) => props.$gap ?? '12'}px;

  .ant-space {
    justify-content: space-between;
  }

  .ant-radio-group,
  .ant-space {
    width: 100%;
  }

  .ant-space-item {
    background: ${(props) => props.theme.cardsBgColor};
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.marginsColor};
    padding: 16px;
    width: 174px;
  }

  .ant-space-item:has(.ant-radio-wrapper-checked) {
    border: 1px solid ${(props) => props.theme.marginsBorder};
    background: ${(props) => props.theme.marginsColor};
  }

  .ant-radio-inner {
    background: linear-gradient(
          ${(props) => props.theme.cardsBgColor},
          ${(props) => props.theme.cardsBgColor}
        )
        padding-box,
      ${(props) => props.theme.buttonsBG} border-box;
  }

  .ant-radio-wrapper {
    color: ${(props) => props.theme.textColor};
  }

  .ant-checkbox-group {
    gap: 32px;
    flex-wrap: wrap;
    max-height: 192px;
  }

  .ant-checkbox-group,
  .ant-checkbox-group > div {
    width: fit-content;
  }
`;

export const StyledHourButton = styled.span<StepProps>`
  cursor: pointer;
  color: ${(props) => props.theme.textColor};
  font-size: 16px;
  font-weight: 400;
  padding: 6px 12px;
  text-align: center;

  ${(props) =>
    props.isActive &&
    `background: ${props.theme.marginsBorder};
  border-radius: 5px;
  `};
`;

export const StyledAdditonalHours = styled(StyledRow)`
  background: ${(props) => props.theme.marginsColor};
  width: 100%;
  height: 52px;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${(props) => props.theme.marginsBorder};
  border-radius: 5px;
`;
