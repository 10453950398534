import { Component } from '../../../../../types';
import { ButtonProps } from '@u1/fixers';

export const button: Component<ButtonProps> = {
  selector: '.u1-button',
  props: {
    selectors: {
      element: `.u1-button`,
    },
  },
};
