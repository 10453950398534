export const fullNameExp = /^((\b[a-zA-Z]{1,20}\b)\s*){2,}$/;
export const emailExp = /[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}[\s]*$/;
export const domainRegex =
  /^(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+)$/;
export const comaRegex = /,([^,]*)$/;
export const removeProtocolPrefixRegex = /^(https?:\/\/)/;
export const phoneNumberRegex =
  /^\+(?:\d{1,3}\s?)?(?:\d{1,4}\s?)?(?:\(\d{1,5}\)\s?)?\d{1,10}[\s\-]?\d{1,10}$/;
export const eightCharsExp = /.{8,}/;
export const oneLowerCaseExp = /^(?=.*[a-z]).*$/;
export const oneUpperCaseExp = /^(?=.*[A-Z]).*$/;
export const oneNumberExp = /^(?=.*\d).*/;
export const appIdExp =
  /^[a-zA-Z]([a-zA-Z0-9-]*[a-zA-Z0-9])*(\.[a-zA-Z]([a-zA-Z0-9-]*[a-zA-Z0-9])*)+$/;
