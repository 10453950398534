import styled from 'styled-components';
import { Table } from 'antd';
import { TableProps } from '@/shared/props';
import { IconButton } from '../Button/IconButton';

export const StyledTableWrapper = styled(Table)<TableProps>`
  .ant-table-cell {
    color: ${(props) => props.theme.textColor};
    font-weight: 400;
    width: calc(100% / ${(props) => props.cellsInRow});
  }
  .ant-table-content table,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background-color: ${(props) => props.theme.cardsColor} !important;
  }

  tbody td {
    border-top: 1px solid ${(props) => props.theme.marginsColor};
    border-bottom: none;
  }

  .ant-table-thead > tr {
    box-shadow: 3px 19px 26px -9px ${(props) => props.theme.shadowColor};
  }

  .ant-table-thead > tr > th {
    border-width: 1px;
    border-color: ${(props) => props.theme.tableHeadBorderColor};
    border-bottom: 1px solid ${(props) => props.theme.tableHeadBorderColor};
  }

  .ant-table-container {
    background-color: ${(props) => props.theme.cardsColor};
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: ${(props) => props.theme.menuItemHoverColor};
  }
  table {
    background-color: ${(props) => props.theme.cardsColor};
  }

  .ant-table {
    margin: auto;
    width: 100%;
    border-radius: 5px;
  }

  .ant-table-column-sorter {
    display: none;
  }

  .ant-dropdown-trigger {
    float: right;
  }

  .ant-empty-description {
    color: ${(props) => props.theme.textColor};
  }

  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: unset;
  }

  /* Scrollbars */

  /* Chrome */

  /* width */
  &&::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &&::-webkit-scrollbar-track {
    background: ${(props) => props.theme.marginsColor};
  }

  /* Handle */
  &&::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.toggleDisabled};
  }

  /* Handle on hover */
  &&::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.toggleDisabled};
  }

  /* Firefox */
  scrollbar-width: auto;
  scrollbar-color: ${(props) => props.theme.toggleDisabled} ${(props) => props.theme.marginsColor};
`;

export const StyledSortIcon = styled.img`
  margin-bottom: 2px;
  margin-left: 5px;
`;

export const StyledTableErr = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
