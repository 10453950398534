import { COLORS } from '../colors';
import { ThemeSchema } from './themeSchema';

export const whiteTheme: ThemeSchema = {
  textColor: `${COLORS.DARK_BLUE_MAGENTA}`,
  textGradient: `${COLORS.YELLOW_ORANGE_LIGHT_GRADIENT}`,
  secondaryTextColor: `${COLORS.GRAYISH_PURPLE}`,
  backgroundColor: `${COLORS.GHOST_WHITE}`,
  cardsColor: `${COLORS.WHITE}`,
  marginsColor: `${COLORS.GHOST_WHITE}`,
  secondMarginsColor: `${COLORS.GHOST_WHITE}`,
  marginsBorder: `${COLORS.BRIGHT_GRAY}`,
  borderColor: `${COLORS.GHOST_WHITE}`,
  detailsBorder: `${COLORS.BRIGHT_GRAY}`,
  menuTextColor: `${COLORS.DUSTY_PINK}`,
  inputColor: `${COLORS.WHITE}`,
  inputBorder: `${COLORS.OLD_LAVENDER}`,
  errorColor: `${COLORS.PALE_RED}`,
  errorBG: `${COLORS.DUSTY_RED}`,
  buttonsBG: `${COLORS.PURPLE_GRADIENT}`,
  twoColorButtonBG: `${COLORS.TWO_COLOR_PURPLE_GRADIENT}`,
  selectedButtonBG: `${COLORS.BRIGHT_GRAY}`,
  signInButtonsBgColor: `${COLORS.WHITE}`,
  signInButtonsBasicFontColor: `${COLORS.BLACK}`,
  disabledColor: `${COLORS.BRIGHT_GRAY}`,
  disabledText: `${COLORS.DEEP_GRAYISH_VIOLET}`,
  buttonHoverBG: `${COLORS.LAVENDER_GRADIENT}`,
  tableHeadBorderColor: `${COLORS.BRIGHT_GRAY}`,
  shadowColor: `${COLORS.EXTRA_LIGHT_GRAY_OPACITY}`,
  disabledItemColor: `${COLORS.DEEP_GRAYISH_VIOLET}`,
  menuItemHoverColor: `${COLORS.LIGHT_SILVER}`,
  cardsBgColor: `${COLORS.GHOST_WHITE}`,
  gradientBorder: `${COLORS.YELLOW_ORANGE_GRADIENT_LIGHT}`,
  linkGradient: `${COLORS.PINK_TO_WHITE_GRADIENT_LIGHT}`,
  successStatus: `${COLORS.GREEN}`,
  shimmerColor: `${COLORS.LIGHT_PURPLE_GRADIENT}`,
  toggleDisabled: `${COLORS.BRIGHT_GRAY}`,
  a11yBorder: `${COLORS.BLACK}`,
  secondBorder: `${COLORS.BRIGHT_GRAY}`,
  gradientBg: `${COLORS.PURPLE_GRADIENT}`,
  successfulProgress: `${COLORS.GREEN_GRADIENT}`,
  darkInputColor: `${COLORS.WHITE_SMOKE}`,
  graphStrokeStartColor: `${COLORS.MEDIUM_YELLOW}`,
  graphStrokeEndColor: `${COLORS.REDDISH_PINK}`,
  graphMarkerColor: `${COLORS.REDDISH_PINK}`,
  graphCrosshair: `${COLORS.PURPLE_OPACITY}`,
  selectedDay: `${COLORS.GHOST_WHITE}`,
  selectedRange: `${COLORS.SIMPLE_PURPLE_GRAD}`,
  focusedText: `${COLORS.WHITE}`,
  bgContrast: `${COLORS.WHITE}`,
  bgNoContrast: `${COLORS.BLACK}`,
  textContrast: `${COLORS.MEDIUM_YELLOW}`,
  textNoContrast: `${COLORS.LIGHT_GRAY}`,
  darkShadow: `${COLORS.BLACK_OPACITY}`,
  gradientButtonInnerText: `${COLORS.WHITE}`,
  copyButtonBG: `${COLORS.BRIGHT_GRAY}`,
  placeholderColor: `${COLORS.GRAYISH_PURPLE}`,
  buttonsBGHorizontal: `${COLORS.PURPLE_GRADIENT_HORIZONTAL}`,
  landingPageBorder: `${COLORS.SHINY_PURPLE}`,
  orangeTextGradient: `${COLORS.YELLOW_ORANGE_GRADIENT}`,
  purpleTextGradient: `${COLORS.LIGHT_DARK_PURPLE_GRADIENT}`,
  accordionBorder: `${COLORS.BLACK}`,
  sameColorBG: `${COLORS.PURPLE_GRAY}`,
  sameColorText: `${COLORS.WHITE}`,
  sameColorBox: `${COLORS.DARK_GRAY}`,
  sameColorGradient: `${COLORS.PURPLE_WHITE_GRADIENT}`,
  sameColorBorder: `${COLORS.GRAY_PURPLE}`,
};
