import i18n from '@/services/localization';

export const getBenefitsArr = (): string[] => {
  const numOfBenefits = 6;
  const benefits = [];
  for (let i = 1; i <= numOfBenefits; i++) {
    const translation = i18n.t(`homepage.benefits.block${i}`);
    benefits.push(translation);
  }
  return benefits;
};
