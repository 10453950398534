import { SigninMethodesListItemProps, SigninMethodesListProps } from '@/shared/props';
import React from 'react';
import { StyledSigninMethodesList } from './Signin.styles';
import { SigninMethodesListItem } from './SigninMethodesListItem';

export const SigninMethodsList: React.FC<SigninMethodesListProps> = ({ signInMethods }) => {
  return (
    signInMethods && (
      <StyledSigninMethodesList>
        {signInMethods.map((el: SigninMethodesListItemProps, index: number) => (
          <SigninMethodesListItem
            signinMethodName={el.signinMethodName}
            signinMethodLogoSrc={el.signinMethodLogoSrc}
            signinMethodTextColor={el.signinMethodTextColor}
            signinMethodUrl={el.signinMethodUrl}
            key={index}
          />
        ))}
      </StyledSigninMethodesList>
    )
  );
};
