import { RES_STATUS } from '@/shared/consts';
import { emailExp, fullNameExp } from '@/shared/regex';
import { useAxios } from '@/services/apiClient';
import { StyledCol, StyledInput, StyledSecondaryText, StyledTitle } from '@/shared/styles';
import { extractErrors } from '@/shared/utils';
import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { U1Button } from '../../Button/U1Button';
import { InviteModalProps } from '../../Modals/Modals.consts';
import { FormError } from '../FormError';
import { StyledForm, StyledLabel } from '../Forms.styles';
import { U1Form } from '@u1/react-a11y-hooks';
import { useTranslation } from 'react-i18next';

export const InviteUserForm: React.FC<InviteModalProps> = ({
  getUsersData,
  onOK,
}: InviteModalProps) => {
  const [isEmailSent, setIsSent] = useState(false);
  const [serverErr, setServerErr] = useState('');
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [{ response: inviteResponse, error: inviteError }, postInvite] = useAxios(
    {
      method: 'post',
      url: '/users',
    },
    { manual: true }
  );

  const onFinish = (values: any) => {
    postInvite({
      data: {
        fullName: values.fullname.trim(),
        email: values.email.trim(),
      },
    });
  };

  useEffect(() => {
    if (inviteResponse && inviteResponse.status === RES_STATUS.CREATED) {
      setIsSent(true);
      onOK && onOK();
      getUsersData();

      setTimeout(() => {
        setIsSent(false);
        form.resetFields();
      }, 2000);
    }
  }, [inviteResponse]);

  useEffect(() => {
    if (inviteError) {
      const errorsText = extractErrors(inviteError.response);
      setServerErr(errorsText);
    }
  }, [inviteError]);

  return isEmailSent ? (
    <StyledSecondaryText fontSize="15" fontWeight={100}>
      {t('sent_successfully')}
    </StyledSecondaryText>
  ) : (
    <U1Form>
      <StyledForm form={form} name="basic" autoComplete="off" onFinish={onFinish}>
        {serverErr && <FormError message={serverErr} />}
        <StyledTitle $size={18}>{t('users.invite_user')}</StyledTitle>
        <StyledCol>
          <StyledLabel>{t('form.full_name')}</StyledLabel>
          <Form.Item
            name="fullname"
            rules={[
              {
                required: true,
                message: `${t('form.first_and_last_name')} ${t('form.required')}`,
                pattern: fullNameExp,
              },
            ]}
          >
            <StyledInput
              placeholder={t('form.first_and_last_name')}
              $height="45px"
              data-testid="fullNameField"
            />
          </Form.Item>
          <StyledLabel>{t('form.email')}</StyledLabel>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: `${t('form.valid_email')} ${t('form.required')}`,
                pattern: emailExp,
              },
            ]}
          >
            <StyledInput placeholder="example@email.com" $height="45px" data-testid="emailField" />
          </Form.Item>
        </StyledCol>
        <Form.Item>
          <U1Button
            text={t('users.invite')}
            $height={'50px'}
            htmlType="submit"
            dataTestId="modalInviteButton"
          />
        </Form.Item>
      </StyledForm>
    </U1Form>
  );
};
