import { U1Button } from '@/shared/components/Button/U1Button';
import { StyledCol, StyledImg, StyledOrangeGradTitle, StyledText } from '@/shared/styles';
import { successImg } from './Images';
import { useVendorsStore } from '@/stores/VendorsStore';
import { DEALS_VIEW } from '../../../../Deals.consts';
import { usePaymentStore } from '@/stores/PaymentStore';
import { Payment_Status } from '@/stores/Stores.consts';
import { useTranslation } from 'react-i18next';

export const SuccessfulPayment: React.FC = () => {
  const setDealsView = useVendorsStore((state) => state.setDealsView);
  const setPaymentStatus = usePaymentStore((state) => state.setPaymentStatus);
  const { t } = useTranslation();

  const viewDeal = () => {
    setDealsView(DEALS_VIEW.VIEW_DEAL);
    setPaymentStatus(Payment_Status.NOT_PAYED);
  };

  return (
    <StyledCol $align="center">
      <StyledCol $maxWidth="375px" $gap={36}>
        <StyledImg src={successImg} width={300} />
        <StyledCol $gap={12} $align="center">
          <StyledOrangeGradTitle>
            {t('vendor.deals.create_deal.payment.successful_payment')}
          </StyledOrangeGradTitle>
          <StyledText textAlign="center" fontWeight={400} fontSize="18px">
            {t('vendor.deals.create_deal.payment.successful_payment.content')}
          </StyledText>
        </StyledCol>
        <U1Button text={t('vendor.deals.create_deal.payment.view_deal')} onClick={viewDeal} />
      </StyledCol>
    </StyledCol>
  );
};
