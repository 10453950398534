import { t } from 'i18next';

export const getStatsFilters = () => [
  {
    text: t('vendor.stats.deals_stats.filters.1d'),
    label: t('vendor.stats.deals_stats.filters.one_day'),
  },
  {
    text: t('vendor.stats.deals_stats.filters.1m'),
    label: t('vendor.stats.deals_stats.filters.one_month'),
  },
  {
    text: t('vendor.stats.deals_stats.filters.3m'),
    label: t('vendor.stats.deals_stats.filters.three_months'),
  },
  {
    text: t('vendor.stats.deals_stats.filters.1y'),
    label: t('vendor.stats.deals_stats.filters.one_year'),
  },
  {
    text: t('vendor.stats.deals_stats.filters.all'),
    label: t('vendor.stats.deals_stats.filters.all_periods'),
  },
];
