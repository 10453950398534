import { StyledCard, StyledText, StyledTitle } from '@/shared/styles';
import {
  StyledCircleFlag,
  StyledClientText,
  StyledClientTitle,
  StyledDealList,
  StyledDealListItem,
} from '../DealsPayment.styles';
import { hasFlag } from 'country-flag-icons';
import * as RectangleFlags from 'country-flag-icons/react/3x2';
import * as SquareFlags from 'country-flag-icons/react/1x1';
import countryList from 'react-select-country-list';
import { useDealStore } from '@/stores/DealStore/DealStore';
import { useTranslation } from 'react-i18next';

export const ClientDetailsSection: React.FC = () => {
  const deals = useDealStore();
  const PhoneFlag = RectangleFlags[deals.client?.country as keyof typeof RectangleFlags];
  const CountryFlag = SquareFlags[deals.client?.country as keyof typeof SquareFlags];
  const clientCountry = deals.client && countryList().getLabel(deals.client?.country);
  const { t } = useTranslation();

  const isFlagExist = (): boolean => {
    return !!deals.client?.country && hasFlag(deals.client?.country);
  };

  return (
    <StyledCard $width="100%" $padding={40} $align="start" $gap={32}>
      <StyledTitle $size={18} level={2}>
        {t('vendor.panel.deals.create_deal.client_details')}:
      </StyledTitle>
      <StyledDealList>
        <StyledDealListItem>
          <StyledClientTitle>{t('vendor.deals.company_name')}</StyledClientTitle>
          <StyledText fontWeight={400} fontSize={'16px'}>
            {deals.client?.company}
          </StyledText>
        </StyledDealListItem>
        <StyledDealListItem>
          <StyledClientTitle>{t('vendor.deals.admin_name')}</StyledClientTitle>
          <StyledText fontWeight={400} fontSize={'16px'}>
            {deals.client?.fullname}
          </StyledText>
        </StyledDealListItem>
        <StyledDealListItem>
          <StyledClientTitle>{t('vendor.deals.email')}</StyledClientTitle>
          <StyledText fontWeight={400} fontSize={'16px'}>
            {deals.client?.email}
          </StyledText>
        </StyledDealListItem>
        <StyledDealListItem>
          <StyledClientTitle>{t('vendor.deals.phone_number')}</StyledClientTitle>
          <StyledClientText>
            {isFlagExist() && (
              <PhoneFlag aria-hidden="true" title={deals.client?.country} width={25} />
            )}
            {deals.client?.phone}
          </StyledClientText>
        </StyledDealListItem>
        <StyledDealListItem>
          <StyledClientTitle>{t('vendor.deals.country')}</StyledClientTitle>
          <StyledClientText>
            {isFlagExist() && (
              <StyledCircleFlag aria-hidden="true">
                <CountryFlag title={deals.client?.country} width={25} />
              </StyledCircleFlag>
            )}
            {clientCountry}
          </StyledClientText>
        </StyledDealListItem>
      </StyledDealList>
    </StyledCard>
  );
};
