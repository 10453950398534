import { ComponentName, Library } from '../../../../types';
import { listbox } from './listbox/listbox';
import { form } from './form/form';
import { accordion } from './accordion/accordion';
import { combobox } from './combobox/combobox';
import { tabs } from './tabs/tabs';
import { radio } from './radio/radio';
import { checkbox } from './checkbox/checkbox';
import { button } from './button/button';
import { link } from './link/link';
import { table } from './table/table';
import { grid } from './grid/grid';
import { dialog } from './dialog/dialog';
import { menu } from './menu/menu';
import { carousel } from './carousel/carousel';
import { datepicker } from './datepicker/datepicker';
import { canvas } from './canvas/canvas';
import { tooltip } from './tooltip/tooltip';

const { ACCORDION, BUTTON, CAROUSEL, CHECKBOX, COMBOBOX, DATEPICKER, DIALOG } = ComponentName;
const { FORM, GRID, LINK, LISTBOX, MENU, RADIO, TABLE, TABS, CANVAS, TOOLTIP } = ComponentName;

export const u1_react: Library = {
  [ACCORDION]: accordion,
  [BUTTON]: button,
  [CAROUSEL]: carousel,
  [CHECKBOX]: checkbox,
  [COMBOBOX]: combobox,
  [DATEPICKER]: datepicker,
  [DIALOG]: dialog,
  [FORM]: form,
  [GRID]: grid,
  [LINK]: link,
  [LISTBOX]: listbox,
  [MENU]: menu,
  [RADIO]: radio,
  [TABLE]: table,
  [TABS]: tabs,
  [CANVAS]: canvas,
  [TOOLTIP]: tooltip,
};
