import { COLORS } from '@/shared/colors';
import { Tooltip } from 'antd';
import { isMobile } from 'react-device-detect';
import { useTheme } from 'styled-components';
import { TooltipProps } from './Tooltip.consts';
import { StyledInnerTooltip } from './Tooltip.styles';
import { U1Button } from '@u1/react-a11y-hooks';

export const U1Tooltip: React.FC<TooltipProps> = ({
  children,
  $text,
  $placement,
  $padding,
  open,
  label,
}: TooltipProps) => {
  const theme = useTheme();
  const tooltipWidth = !isMobile && $text.length > 45 ? '330px' : 'fit-content';
  const props = open != undefined ? { open } : {};

  return (
    <U1Button>
      <Tooltip
        overlayInnerStyle={{
          width: tooltipWidth,
          textAlign: 'center',
          borderRadius: '5px',
        }}
        color={theme?.marginsColor ?? COLORS.PURPLE_GRAY}
        placement={$placement ?? 'top'}
        title={<div role="alert">{$text}</div>}
        trigger={['click', 'hover']}
        {...props}
      >
        <StyledInnerTooltip $padding={$padding} aria-label={label ?? 'More info'}>
          {children}
        </StyledInnerTooltip>
      </Tooltip>
    </U1Button>
  );
};
