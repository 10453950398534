import { Icon } from '@/shared/components/Icon';
import { Styled404Layout, Styled404Title, StyledLine } from './NotFound.styles';
import { StyledCol, StyledText } from '@/shared/styles';
import { PageNotFoundBg } from '@/assets/images/backgrounds';
import { NotFoundProps } from './NotFound.consts';
import { useTranslation } from 'react-i18next';

export const NotFound: React.FC<NotFoundProps> = ({ backButton, text }: NotFoundProps) => {
  const { t } = useTranslation();
  return (
    <Styled404Layout data-testid="404PageLayout" $gap={36}>
      <Icon $src={PageNotFoundBg} />
      <StyledCol $gap={12}>
        <Styled404Title>{t('not_found.oops')}</Styled404Title>
        <StyledText fontWeight={400} fontSize="18px" textAlign="center">
          {text.split('/n').map((line, index) => (
            <StyledLine key={index}>{line}</StyledLine>
          ))}
        </StyledText>
      </StyledCol>
      {backButton}
    </Styled404Layout>
  );
};
