import { InfoMessage } from '@/shared/components/InfoMessage/InfoMessage';
import { OrderSummary } from '@/shared/pages/Checkout/OrderSummary';
import { StyledSeparator } from '@/shared/styles';
import React from 'react';
import { PaymentSummary } from '../../Checkout/OrderSummary/SummarySections/PaymentSummery';
import { UpgradePackageProps } from '../UpgradePackage.consts';
import { CurrentPackage } from './SummarySections/CurrentPackage';
import { Upgrading } from './SummarySections/Upgrading';
import { useTranslation } from 'react-i18next';

export const UpgradeSummary: React.FC<UpgradePackageProps> = ({
  currentPlan,
}: UpgradePackageProps) => {
  const { t } = useTranslation();
  return (
    <OrderSummary>
      <CurrentPackage currentPlan={currentPlan} />

      <StyledSeparator $margin={'0px 0px 20px 0px'} />

      <Upgrading currentPlan={currentPlan} />

      <StyledSeparator $margin={'30px 0px 30px 0px'} />

      <InfoMessage message={t('billing.upgarde_package.summary')} />

      <PaymentSummary currentPlan={currentPlan} />
    </OrderSummary>
  );
};
