import { U1Logo } from '@/shared/components/U1Logo';
import { StyledRow, StyledSeparator } from '@/shared/styles';
import {
  StyledBottomFooter,
  StyledFooterEmail,
  StyledFooterItem,
  StyledFooterLink,
  StyledFooterTitle,
  StyledMainFooter,
  StyledSocialLinks,
  StyledPolicies,
  StyleMainFooterItems,
  StyleMainFooterLinks,
} from './Footer.styles';
import { FooterProps, socialMedia, getStatements } from './Footer.consts';
import { Icon } from '@/shared/components/Icon';
import { v4 as uuidv4 } from 'uuid';
import { useMenuItems } from '@/hooks/MenuItems/useMenuItems';

export const MainFooter: React.FC<FooterProps> = ({ topPageRef }: FooterProps) => {
  const { getFooterMenuItems } = useMenuItems();

  const scrollToTop = () => {
    topPageRef?.current &&
      (topPageRef.current as HTMLElement).scrollIntoView({
        behavior: 'smooth',
      });
  };
  return (
    <StyledMainFooter>
      <StyleMainFooterItems>
        <U1Logo />

        <StyleMainFooterLinks>
          {getFooterMenuItems().map((menuitem) => (
            <StyledFooterItem key={menuitem.key}>
              <StyledFooterTitle level={2}>{menuitem.text}</StyledFooterTitle>
              <StyledRow $gap={16}>
                {menuitem.children?.map((child) => (
                  <StyledFooterLink to={child.href ?? '#'} key={child.key} onClick={scrollToTop}>
                    {child.text}
                  </StyledFooterLink>
                ))}
              </StyledRow>
            </StyledFooterItem>
          ))}
        </StyleMainFooterLinks>

        <StyledSocialLinks>
          {socialMedia.map((social) => (
            <a
              aria-label={social.name}
              href={social.href}
              key={social.name}
              target="_blank"
              rel="noreferrer"
            >
              <Icon $src={social.icon} $loading="lazy" $size={32} />
            </a>
          ))}
        </StyledSocialLinks>
      </StyleMainFooterItems>

      <StyledSeparator />

      <StyledBottomFooter>
        <StyledPolicies>
          {getStatements().map((statement) => (
            <StyledFooterLink
              key={uuidv4()}
              to={statement.path}
              onClick={scrollToTop}
              target="_blank"
              rel="noreferrer"
            >
              {statement.name}
            </StyledFooterLink>
          ))}
        </StyledPolicies>

        <StyledFooterEmail href="mailto:support@user1st.com">support@user1st.com</StyledFooterEmail>
      </StyledBottomFooter>
    </StyledMainFooter>
  );
};
