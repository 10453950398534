import { FcWithChildren } from '@/shared/props';
import { TrackVertical, ThumbVertical, StyledScrollbars } from './U1Scrollbar.styles';
import React from 'react';

export const U1Scrollbar: React.FC<FcWithChildren> = ({ children }) => {
  return (
    <StyledScrollbars
      thumbSize={100}
      autoHide
      autoHeight
      autoHeightMax={'100vh'}
      renderTrackHorizontal={() => <div aria-hidden="true"></div>}
      renderTrackVertical={(props) => <TrackVertical {...props} aria-hidden="true" />}
      renderThumbVertical={(props) => <ThumbVertical {...props} />}
      className="u1-scrollbar"
    >
      {children}
    </StyledScrollbars>
  );
};
