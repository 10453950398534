import { CardProps, TitleProps } from '@/shared/props';
import { StyledCard, StyledCol, StyledRowOffset } from '@/shared/styles';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

export const StyledPaymentCard = styled(StyledCard)<CardProps>`
  width: ${() => (isMobile ? '100%' : '795px')};
  min-height: 400px;
  height: ${(props) => (props.$height ? `${props.$height}` : `100%`)};
  ${() => isMobile && `padding: 0px 24px 24px 24px;`}
`;

export const StyledPackageBg = styled.div`
  padding-bottom: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  ${() => isMobile && `width: 100%;`}
`;

export const StyledPaymentFailed = styled(StyledCol)`
  ${() => !isMobile && `padding: 35px;`}
  align-items: center;
  gap: 32px;
`;

export const StyledPayment = styled(StyledCol)`
  align-items: center;
  letter-spacing: 0.5px;
  gap: 24px;
`;

export const StyledPaymentDetails = styled(StyledCol)`
  border: 1px solid ${(props) => props.theme.detailsBorder};
  border-radius: 5px;
`;

export const StyledPaymentSection = styled(StyledRowOffset)`
  padding: 25px 15px 25px 15px;
`;

export const StyledText = styled.span<TitleProps>`
  color: ${(props) => props.theme.textColor};
  ${(props) => props.$size && `font-size: ${props.$size}px`};
`;

export const StyledPackageName = styled(StyledCol)`
  align-items: flex-end;
  width: fit-content;
`;

export const StyledUpgradeSum = styled.div`
  width: 357px;
  height: fit-content;
  background: ${(props) => props.theme.marginsColor};
  font-weight: 400;
  font-size: 16px;
  align-self: center;
  border-radius: 5px;
  padding: 16px;
`;
