import { StyledPackageTitle } from '@/shared/styles';
import { StyledCol, StyledCard, StyledSeparator, StyledTitle, StyledRow } from '@/shared/styles';
import { useSelectionStore } from '@/stores/SelectionStore';
import { StyledPackageContainer, StyledPackageInfo } from '../PackageDetails/PackageDetails.styles';
import { Icon } from '@/shared/components/Icon';
import { InfoIcon } from '@/assets/images/icons';
import { U1Tooltip } from '@/shared/components/Tooltip/Tooltip';
import { FreeExtraSelection } from '../PackageDetails/ExtraSelection/FreeExtraSelection';
import { useTranslation } from 'react-i18next';

export const FreePackageDetails: React.FC = () => {
  const selectedPackage = useSelectionStore();
  const { t } = useTranslation();

  return (
    <StyledCard $width={'60%'}>
      <StyledPackageContainer $bg={selectedPackage.imageUrl} $position={'top -20% right -1%'}>
        <StyledPackageInfo>
          <StyledCol>
            <StyledRow>
              <StyledTitle data-testid="packageName" $size={25}>
                {selectedPackage.name}
              </StyledTitle>
              <U1Tooltip $text={t('pricing.freemium.info')} $padding="5px">
                <Icon $src={InfoIcon} $size={17} $className="u1-button ant-tooltip-button" />
              </U1Tooltip>
            </StyledRow>

            <StyledPackageTitle data-testid="packagePrice">
              ${selectedPackage.price}
            </StyledPackageTitle>
          </StyledCol>
        </StyledPackageInfo>

        <StyledSeparator />

        <FreeExtraSelection />
      </StyledPackageContainer>
    </StyledCard>
  );
};
