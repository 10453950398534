import { StyledRow, StyledText } from '@/shared/styles';
import React from 'react';
import { FilterBar } from './FilterBar';
import { t } from 'i18next';

export const StatsFilters: React.FC = () => {
  return (
    <StyledRow $gap={12}>
      <StyledText fontSize={'16px'} fontWeight={400} align="center">
        {t('vendor.stats.stats_filters.title')}
      </StyledText>
      <FilterBar />
    </StyledRow>
  );
};
