import React from 'react';
import { StyledSearchTreeCard } from '../DocumentationPage.styles';
import { Search } from './Search/Search';
import { Tree } from './Tree/Tree';

export const SearchTree: React.FC = () => {
  return (
    <StyledSearchTreeCard id="tabs-list">
      <Search />
      <Tree />
    </StyledSearchTreeCard>
  );
};
