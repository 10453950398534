import { U1Button } from '@/shared/components/Button/U1Button';
import { Icon } from '@/shared/components/Icon';
import { PAGES } from '@/shared/consts';
import { MobileDisabled } from '@/shared/mobile/UnavailableOnMobile/MobileDisabled';
import { StyledContent, StyledPackageTitle, StyledTitle } from '@/shared/styles';
import { isMobile } from 'react-device-detect';
import { StyledPackageBg, StyledPayment, StyledPaymentCard } from '../Payment.styles';
import { PaymentMessageProps } from '../Payment.consts';
import { useTranslation } from 'react-i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const PaymentMessage: React.FC<PaymentMessageProps> = ({
  paymentMessage,
  btnText,
  packageText,
  children,
  selectedPackage,
  dataTestId,
}: PaymentMessageProps) => {
  const { navigate } = useU1Navigation();
  const { t } = useTranslation();

  return (
    <StyledPayment>
      <StyledTitle
        $size={isMobile ? 20 : 28}
        $flex={'start'}
        $align="center"
        $width={'100%'}
        data-testid={dataTestId}
      >
        {t('payment.payment_message.title')}
      </StyledTitle>
      <StyledContent fontSize={'16px'}>{paymentMessage}</StyledContent>
      <StyledPaymentCard>
        <StyledPackageBg>
          <Icon $src={selectedPackage.imageUrl} $size={350} />
          <StyledTitle level={2} $size={20} data-testid="packageName">
            <span>{packageText}</span>
            <StyledPackageTitle $size={20}> {selectedPackage.name}</StyledPackageTitle>
          </StyledTitle>

          <StyledContent fontSize={'17px'}>{children}</StyledContent>
          {isMobile ? (
            <MobileDisabled />
          ) : (
            <U1Button
              dataTestId="createProjectButton"
              text={btnText}
              $width={isMobile ? '100%' : '370px'}
              $height={'50px'}
              onClick={() => navigate(PAGES.AFTER_LOGIN.NEW_PROJECT)}
            />
          )}
        </StyledPackageBg>
      </StyledPaymentCard>
    </StyledPayment>
  );
};
