type ChartItemSettings = {
  angle?: number;
  offset?: number;
  filled?: number;
  itemRatio?: number;
};

export type ChartItem = ChartItemSettings & {
  key: number;
  name: string;
  amount: number;
  value: number;
};

export type ChartItemsProps = {
  items: ChartItem[];
  selectedItem?: number;
};

export type DonutChartProps = ChartItemsProps & {
  totalItems: number;
  setSelected?: React.Dispatch<React.SetStateAction<number>>;
};

export const chartColors = ['#3495EE', '#CE29B0', '#E4963E', '#B9E43E', '#3EE49F'];
export const disabledColor = ['#564C66'];

export const InitialValues = {
  cx: 50,
  cy: 50,
  strokeWidth: 10,
  radius: 45,
  startAngle: -90,
  circlesRatio: 1.3,
  get dashArray() {
    return 2 * Math.PI * this.radius;
  },
};

export type SelectedElementProps = { isSelected: boolean };
