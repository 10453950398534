import { SigninMethodesListItemProps } from '@/shared/props';
import {
  GithubIcon,
  BitBucketIcon,
  GoogleIcon,
  LinkedinIcon,
  MicrosoftIcon,
} from '@/assets/images/icons';

export const signInMethods: SigninMethodesListItemProps[] = [
  {
    signinMethodName: 'Github',
    signinMethodLogoSrc: GithubIcon,
  },
  {
    signinMethodName: 'BitBucket',
    signinMethodLogoSrc: BitBucketIcon,
    signinMethodTextColor: '#2684FF',
  },
  {
    signinMethodName: 'Google',
    signinMethodLogoSrc: GoogleIcon,
  },
  {
    signinMethodName: 'Microsoft',
    signinMethodLogoSrc: MicrosoftIcon,
  },
  {
    signinMethodName: 'Linkedin',
    signinMethodLogoSrc: LinkedinIcon,
    signinMethodTextColor: '#0073B1',
  },
];
