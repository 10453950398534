import { create } from 'zustand';
import {
  DiscountStore,
  SelectionStore,
  ExtraSelectionStore,
  ExtraSelectionType,
} from './Stores.consts';

export const useSelectionStore = create<SelectionStore>((set) => ({
  id: '',
  name: '',
  price: 0,
  pricingMethod: '',
  users: { title: '', quantity: 0, quantityTitle: '' },
  domains: { title: '', quantity: 0, quantityTitle: '' },
  extraDomains: 0,
  frameworks: { title: '', quantity: 0, quantityTitle: '' },
  type: 'MAIN',
  imageUrl: '',
  supportedFrameworks: [],
  selectPackage: ({
    id,
    name,
    price,
    domains,
    frameworks,
    pricingMethod,
    users,
    supportedFrameworks,
    extraDomains,
    type,
    imageUrl,
  }: SelectionStore) =>
    set((state) => ({
      ...state,
      id,
      name,
      price,
      domains,
      frameworks,
      pricingMethod,
      users,
      supportedFrameworks,
      extraDomains,
      type,
      imageUrl,
    })),
  setExtraDomains: (extraDomains: number) => set((state) => ({ ...state, extraDomains })),
  setPackageId: (id: string) => set((state) => ({ ...state, id })),
}));

export const useExtraSelectionStore = create<ExtraSelectionStore>((set) => ({
  frameworks: [],
  users: 0,
  domains: 0,
  additionalService: false,
  totalPrice: {
    total: 0,
    package: 0,
    extraFrameworks: 0,
    extraDomains: 0,
    additionalService: 0,
  },
  setSelection: ({
    frameworks,
    users,
    domains,
    additionalService,
    discount,
    totalPrice,
    freeFramework,
  }: ExtraSelectionType) =>
    set(() => ({
      frameworks,
      users,
      domains,
      additionalService,
      discount,
      totalPrice,
      freeFramework,
    })),
  setPackagePrice: (price: number) =>
    set((state) => ({
      ...state,
      totalPrice: {
        total: state.totalPrice.total,
        package: price,
        extraFrameworks: state.totalPrice.extraFrameworks,
        extraDomains: state.totalPrice.extraDomains,
        additionalService: state.totalPrice.additionalService,
      },
    })),
  cleanSelection: () =>
    set(() => ({
      frameworks: [],
      users: 0,
      domains: 0,
      additionalService: false,
      totalPrice: {
        total: 0,
        package: 0,
        extraFrameworks: 0,
        extraDomains: 0,
        additionalService: 0,
      },
      discount: { id: '', value: 0 },
    })),
}));

export const useDiscountStore = create<DiscountStore>((set) => ({
  codeApplied: false,
  codeError: false,
  discountCode: '',
  setCodeApplied: (value: boolean) => set(() => ({ codeApplied: value })),
  setCodeError: (value: boolean) => set(() => ({ codeError: value })),
  setDiscountCode: (value: string) => set(() => ({ discountCode: value })),
  handleDiscont: (value: boolean, msg: string) =>
    set(() => ({
      codeApplied: value,
      codeError: !value,
      discountCode: '',
      discountMsg: msg,
    })),
  clearDiscount: () =>
    set(() => ({
      codeApplied: false,
      codeError: false,
      discountCode: '',
    })),
}));
