import { U1Button } from '@/shared/components/Button/U1Button';
import { StyledTitle } from '@/shared/styles';
import React, { useEffect, useState } from 'react';
import { EmptyProjectsView } from './ProjecsPageViews/EmptyProjectsView';
import { StyledProjectLayout, StyledHeaderLayout } from './ProjectsPage.style';
import { PAGES, USER_ROLE } from '@/shared/consts';
import { useProjectsArrStore } from '@/stores/ProjectStore/ProjectStore';
import { ProjectsList } from './ProjecsPageViews/ProjectsList';
import { useProjects } from '@/hooks/useProjects';
import { Loading } from '@/shared/components/Loading/Loading';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { UpgradeModal } from './ProjecsPageViews/FreemiumView/UpgradeModal';
import { FreemiumView } from './ProjecsPageViews/FreemiumView/FreemiumView';
import { useUserStore } from '@/stores/UserStore';
import { StoreState } from '@/stores/Stores.consts';
import { useTranslation } from 'react-i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const ProjectsPage: React.FC = () => {
  const { navigate } = useU1Navigation();
  const projectsArrStore = useProjectsArrStore();
  const { getProjects } = useProjects();
  const userDetails = useUserStore();
  const subscription = useSubscriptionStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getProjects();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const createNewProject = () => {
    if (subscription.isFreemium && projectsArrStore.projectsArr.length) {
      showModal();
    } else {
      navigate(PAGES.AFTER_LOGIN.NEW_PROJECT);
    }
  };

  return projectsArrStore.projectsState === StoreState.NOT_INITIATED ? (
    <Loading />
  ) : (
    <StyledProjectLayout>
      <StyledHeaderLayout>
        <StyledTitle $weight={600} $size={28}>
          {t('projects.new_project.title.h1')}
        </StyledTitle>
        {userDetails.role === USER_ROLE.ADMIN && subscription.isActive && (
          <U1Button
            dataTestId="createNewProjectButton"
            onClick={createNewProject}
            text={t('projects.create_new_project')}
            $height="50px"
            $width="250px"
          />
        )}
      </StyledHeaderLayout>
      {projectsArrStore.projectsArr.length ? (
        <ProjectsList projectsList={projectsArrStore.projectsArr} />
      ) : (
        <EmptyProjectsView />
      )}
      {subscription.isFreemium && (
        <>
          <FreemiumView />
          <UpgradeModal handleCancel={() => setIsModalOpen(false)} isOpen={isModalOpen} />
        </>
      )}
    </StyledProjectLayout>
  );
};
