import { StyledOrangeGradTitle } from '@/shared/pages/Pricing/Intro/Intro.styles';
import { StyledText } from '@/shared/styles';
import React from 'react';
import { StyledNoRecords } from '../Billing.styles';
import { useTranslation } from 'react-i18next';

export const NoRecords: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledNoRecords>
      <StyledOrangeGradTitle level={3}>
        {t('billing.billing_history.no_records.title')}
      </StyledOrangeGradTitle>
      <StyledText>{t('billing.billing_history.no_records.content')}</StyledText>
    </StyledNoRecords>
  );
};
