import { Switch } from 'antd';
import styled from 'styled-components';

export const StyledSwitch = styled(Switch)`
  background: ${(props) => props.theme.buttonsBG};
  width: 60px;
  height: 26px;

  .ant-switch-handle::before {
    top: 1px;
    bottom: -2px;
  }
`;
