import React from 'react';
import { ActionsDropdown } from '../../../../../SearchPanel/Actions/ActionsDropdown';
import { StyledSeparator } from '@/shared/styles';
import { DealsActionsProps } from '@/Views/AfterLogin/Main/SearchPanel/Actions/UserActions.consts';
import { DEAL_STATUS } from '../DealsTable.consts';
import { EditDealItem } from './Actions/EditDealItem';
import { ViewDealItem } from './Actions/ViewDealItem';
import { SendCredentialsItem } from './Actions/SendCredentialsItem';
import { DownloadReceiptItem } from './Actions/DownloadReceiptItem';
import { useTranslation } from 'react-i18next';

export const DealsActionsDropdown: React.FC<DealsActionsProps> = ({
  deal,
  setIsOpen,
}: DealsActionsProps) => {
  const { t } = useTranslation();
  return deal.status === t(DEAL_STATUS.DRAFT) ? (
    <ActionsDropdown setIsOpen={setIsOpen}>
      <EditDealItem deal={deal} />
    </ActionsDropdown>
  ) : (
    <ActionsDropdown setIsOpen={setIsOpen}>
      <ViewDealItem deal={deal} />
      <StyledSeparator />
      <SendCredentialsItem deal={deal} />
      <StyledSeparator />
      <DownloadReceiptItem deal={deal} />
    </ActionsDropdown>
  );
};
