﻿import React from 'react';
import { BaseOptions, ComponentProps } from '../shared/types';
import { AbstractOptions, AbstractProps } from '@u1/fixers';

export const U1Base = <Props extends AbstractProps, Options extends AbstractOptions>(
  useHook: (baseOptions: BaseOptions<Props, Options>) => React.MutableRefObject<HTMLDivElement>,
  { children, ...baseOptions }: ComponentProps<Props, Options>
) => {
  const ref = useHook(baseOptions);

  return <div ref={ref}>{children}</div>;
};
