import { SigninMethodesListItemProps } from '@/shared/props';
import { SignInMethodButton } from './SignInMethodButton';
import i18n from '@/services/localization';

export const SigninMethodesListItem: React.FC<SigninMethodesListItemProps> = ({
  signinMethodName,
  signinMethodLogoSrc,
  signinMethodTextColor,
}: SigninMethodesListItemProps) => {
  const url =
    `${import.meta.env.VITE_BASE_URL}/sso/${signinMethodName.toLowerCase()}?lang=` + i18n.language;

  return (
    <SignInMethodButton
      text={signinMethodName}
      $textColor={signinMethodTextColor}
      $logoSrc={signinMethodLogoSrc}
      $href={url}
    />
  );
};
