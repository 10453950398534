import { create } from 'zustand';
import { CheckboxGroupProps } from 'antd/es/checkbox/Group';
import { SUBSCRIPTION_TYPE } from '@/Views/AfterLogin/Main/Vendor/Panels/Deals/Deals.consts';
import { DealsData } from '@/shared/dto';
import {
  extractDeal,
  extractClient,
  extractPaymentDetails,
  getStep1FormErrors,
  removedDealDetails,
} from './DealStore.utils';
import { ClientDetails, DealSteps, DealStore } from './DealsStore.consts';
import { _axios } from '@/services/apiClient';
import { RES_STATUS } from '@/shared/consts';

export const useDealStore = create<DealStore>((set, get) => ({
  subscriptionType: SUBSCRIPTION_TYPE.TWO_YEARS,
  users: '',
  frameworks: [],
  domains: '',
  additonalServices: 0,
  price: '',
  dealStep: DealSteps.PRODUCT,
  getDeal: async (id?: string) => {
    try {
      const res = await _axios.get(`/deals/${id ?? get().id}`);
      if (res.status === RES_STATUS.OK) {
        get().setDeal(res.data);
      }
      return res.status;
    } catch (error) {
      throw error;
    }
  },
  setDeal: (deal: DealsData) => {
    set((state) => ({
      ...state,
      ...extractDeal(deal),
    }));
    const client = deal.client;
    if (client) {
      set((state) => ({
        ...state,
        ...extractClient(client),
      }));
    }
    const paymentDetails = deal.paymentDetails;
    if (paymentDetails) {
      set((state) => ({
        ...state,
        ...extractPaymentDetails(paymentDetails),
      }));
    }
  },
  setDealStep: (dealStep: number) => set((state) => ({ ...state, dealStep })),
  setDealId: (id: string) => set((state) => ({ ...state, id })),
  setClient: (client: ClientDetails) => set((state) => ({ ...state, client })),
  getStep1Error: () => {
    return getStep1FormErrors(get);
  },
  setSubscriptionType: (subscriptionType: number) =>
    set((state) => ({ ...state, subscriptionType })),
  setUsers: (users: string) => set((state) => ({ ...state, users })),
  setFrameworks: (frameworks: CheckboxGroupProps[]) => set((state) => ({ ...state, frameworks })),
  setDomains: (domains: string) => set((state) => ({ ...state, domains })),
  setAdditonalServices: (additonalServices: number) =>
    set((state) => ({ ...state, additonalServices })),
  setPrice: (price: string) => set((state) => ({ ...state, price })),
  clearDeal: () =>
    set(() => ({
      ...removedDealDetails(),
    })),
}));
