import { extractPackageDetails } from '@/shared/pages/Pricing/Pricing.utils';
import { useAxios } from '@/services/apiClient';
import { usePackagesStore } from '@/stores/PackagesStore';
import { useEffect } from 'react';

export const useProducts = () => {
  const packagesStore = usePackagesStore();

  const [{ data: productsData, loading: productsLoading }, getProducts] = useAxios(
    {
      method: 'get',
      url: '/products',
    },
    { manual: true }
  );

  useEffect(() => {
    if (productsData) {
      packagesStore.setPackages(extractPackageDetails(productsData));
    }
  }, [productsData]);

  return { getProducts, productsLoading };
};
