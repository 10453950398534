import { U1Table } from '@/shared/components/Table/U1Table';
import { useAxios } from '@/services/apiClient';
import { StyledCol, StyledTitle } from '@/shared/styles';
import React, { useEffect, useState } from 'react';
import { exctractOrderHistory } from '../../Billing.utils';
import { NoRecords } from '../../NoActiveSubscription/NoRecords';
import { getColumns, InvoiceDataType } from './BillingHistory.consts';
import { useTranslation } from 'react-i18next';

export const BillingHistoy: React.FC = () => {
  const [dataTable, setDataTable] = useState<InvoiceDataType[]>([]);
  const { t } = useTranslation();

  const [{ data: orderHistory }] = useAxios({
    method: 'get',
    url: '/orders/history',
  });

  useEffect(() => {
    if (orderHistory) {
      const historyDataTable = exctractOrderHistory(orderHistory);
      setDataTable(historyDataTable);
    }
  }, [orderHistory]);

  return (
    <StyledCol $gap={20}>
      <StyledTitle level={2}>{t('billing.billing_history.title')}</StyledTitle>
      {dataTable.length ? (
        <U1Table dataTestId="billingHistoryTable" dataSource={dataTable} columns={getColumns()} />
      ) : (
        <NoRecords />
      )}
    </StyledCol>
  );
};
