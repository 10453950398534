import { useUserStore } from '@/stores/UserStore';
import { ClientView } from './Client/Client';
import { USER_TYPE } from '@/shared/consts';
import { AdminView } from './Admin/Admin';
import { useEffect } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { Loading } from '@/shared/components/Loading/Loading';
import { VendorView } from './Vendor/Vendor';

export const Main: React.FC = () => {
  const userStore = useUserStore();
  const { getUser } = useAuth();

  useEffect(() => {
    getUser();
  }, [userStore.type]);

  return userStore.type === USER_TYPE.ADMIN ? (
    <AdminView />
  ) : userStore.type === USER_TYPE.CLIENT ? (
    <ClientView />
  ) : userStore.type === USER_TYPE.VENDOR ? (
    <VendorView />
  ) : (
    <Loading />
  );
};
