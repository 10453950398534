import { dottedBG } from '@/assets/images/backgrounds';
import styled from 'styled-components';

export const StyledSelectOptions = styled.div`
  color: ${(props) => props.theme.disabledItemColor};
  font-weight: 300;
  text-align: center;
  background-image: ${dottedBG};
  border-radius: 15px;
  padding: 15px;
  margin-top: 15px;
`;
