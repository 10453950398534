import React, { useEffect } from 'react';
import { StyledComponentTree } from '../../DocumentationPage.styles';
import { useDocsStore } from '@/stores/ProjectStore/ProjectStore';
import { StyledTitle } from '@/shared/styles';
import { useTranslation } from 'react-i18next';

export const Tree: React.FC = () => {
  const docsStore = useDocsStore();
  const { t } = useTranslation();

  useEffect(() => {
    docsStore.setSelectedComponent(0);
  }, [docsStore.compTreeItems]);

  const selectedItem = (item: any) => {
    docsStore.setSelectedComponent(Number(item.key));
  };

  return (
    <>
      <StyledTitle level={2} $weight={600} $size={24}>
        {t('projects.docs.tree.title')}
      </StyledTitle>
      <StyledComponentTree
        title={t('projects.docs.tree.title')}
        items={docsStore.compTreeItems}
        activeKey={String(docsStore.selectedComponent)}
        mode="inline"
        onClick={(item) => selectedItem(item)}
      />
    </>
  );
};
