import axios from 'axios';
import { makeUseAxios } from 'axios-hooks';
import { getToken } from './tokenService';

export const _axios = axios.create({
  baseURL: `${import.meta.env.VITE_BASE_URL}`,
});

_axios.interceptors.request.use(
  (request) => {
    const accessToken = getToken();
    if (accessToken && request.headers) request.headers.Authorization = `Bearer ${accessToken}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const useAxios = makeUseAxios({
  axios: _axios,
});
