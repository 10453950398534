import { SelectionProps } from '@/shared/props';
import { StyledRow } from '@/shared/styles';
import styled from 'styled-components';

export const StyledFilterBar = styled(StyledRow)`
  background: ${(props) => props.theme.cardsColor};
  border-radius: 5px;
  padding: 6px;
`;

export const StyledPeriodFilter = styled.span<SelectionProps>`
  ${(props) => props.isSelected && `background: ${props.theme.marginsBorder};`};
  color: ${(props) => props.theme.textColor};
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  align-self: center;
  padding: 6px 12px;
`;
