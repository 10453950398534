import { U1Table } from '@/shared/components/Table/U1Table';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { TableContent } from '@/Views/AfterLogin/Main/Client/Projects/projects.consts';
import { generateApiTableColumns, generateApiTableData } from '../../DocumentationPage.utils';
import { useDocsStore } from '@/stores/ProjectStore/ProjectStore';
import { ApiTableProps } from './ApiTable.consts';
import { StyledAddIcon, StyledEditTable } from './ApiTable.styles';
import { IconButton } from '@/shared/components/Button/IconButton';
import { useTranslation } from 'react-i18next';
import { addIcon } from '@/assets/images/icons';

export const ApiTable: React.FC<ApiTableProps> = ({ dataTable, objkeys }: ApiTableProps) => {
  const [keys, setKeys] = useState<TableContent>({
    property: '',
    description: '',
    value: '',
  });
  const [columns, setColumns] = useState<ColumnsType>([]);
  const [data, setData] = useState<TableContent[]>([]);
  const docsStore = useDocsStore();
  const { t } = useTranslation();

  //update keys
  useEffect(() => {
    if (dataTable) setKeys(dataTable[0]);
  }, [dataTable]);

  //generate columns and data
  useEffect(() => {
    if (dataTable && keys.property && objkeys) {
      setColumns(generateApiTableColumns(keys, objkeys));
      setData(generateApiTableData(dataTable));
    }
  }, [keys, docsStore.selectedComponentDoc]);

  const addProp = () => {
    setData(generateApiTableData(dataTable, true));
    docsStore.addNewProps(objkeys);
  };

  return columns.length && data.length ? (
    <StyledEditTable>
      <StyledAddIcon>
        <IconButton
          $alt={t('projects.docs.component.edit.add')}
          onClick={addProp}
          $icon={addIcon}
        />
      </StyledAddIcon>
      <U1Table columns={columns} dataSource={data} cellsInRow={columns.length} />
    </StyledEditTable>
  ) : (
    <></>
  );
};
