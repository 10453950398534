import React, { useState } from 'react';
import { StyledModal } from '../Modals.styles';
import { GradientBorderButton } from '../../Button/GradientBorderButton';
import { CancellationForm } from '../../Forms/SubscriptionCancellation/CancellationForm';
import { U1Dialog } from '@u1/react-a11y-hooks';
import { ComponentName } from '@u1/ui-library-mapper';
import { useTranslation } from 'react-i18next';

export const CancelSubscriptionModal: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { t } = useTranslation();

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  return (
    <U1Dialog name={ComponentName.CANCEL_SUBSCRIPTION_DIALOG}>
      <GradientBorderButton
        dataTestId="cancelSubscriptionButton"
        text={t('billing.package_details.cancel_subscription')}
        $width="100%"
        $padding="12px 60px"
        onClick={showModal}
        triggerRef="cancel-subscription"
      />
      <StyledModal
        width={550}
        open={open}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        footer={[]}
        data-a11y-ref="cancel-subscription"
      >
        <CancellationForm onOk={handleOk} onCancel={handleCancel} />
      </StyledModal>
    </U1Dialog>
  );
};
