import { SelectionProps } from '@/shared/props';
import { StyledListNoBullets, StyledRowOffset } from '@/shared/styles';
import styled from 'styled-components';

export const StyledCountriesList = styled(StyledListNoBullets)`
  width: 50%;
`;

export const StyledCountriesLi = styled.li`
  font-size: 14px;
  font-weight: 400;

  hr {
    margin: 16px 0px;
  }
`;

export const StyledCountriesName = styled(StyledRowOffset)<SelectionProps>`
  span {
    ${(props) =>
      props.isSelected ? `font-weight: 600;` : `color: ${props.theme.secondaryTextColor};`}
  }
`;
