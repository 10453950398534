import { Modifications_Code } from '@/shared/consts';
import { Modification, Options } from '@/shared/dto';
import { FrameworksObj } from './CreateDeal/Steps/Package/BuildPackage/BuildPackage.consts';

export const getSubscriptionTypes = (data: any): string[] => {
  return data
    .find((d: Modification) => d.code === Modifications_Code.PAYMENT_INTERVAL)
    .options.map((option: Options) => option.title);
};

export const getFrameworksOptions = (data: any): FrameworksObj => {
  const frameworkData = data.find((d: Modification) => d.code === Modifications_Code.FRAMEWORKS);
  return {
    titles: frameworkData.options.map((option: Options) => option.title),
    icons: frameworkData.options.map((option: Options) => option.icon),
  };
};

export const getAdditionalHoursOptions = (data: any): number[] => {
  return data
    .find((d: Modification) => d.code === Modifications_Code.ADDITIONAL_AUDITING_SERVICE)
    .options.map((option: Options) => Number(option.title));
};

export const getUsersAmount = (data: any): string => {
  return data.find((d: Modification) => d.code === Modifications_Code.USERS)?.quantity;
};

export const getDomainsAmount = (data: any): string => {
  return data.find((d: Modification) => d.code === Modifications_Code.DOMAINS)?.quantity;
};

export const getAdditionalHoursAmount = (data: any): number => {
  return Number(
    data.find((d: Modification) => d.code === Modifications_Code.ADDITIONAL_AUDITING_SERVICE)
      ?.options[0] ?? 0
  );
};

export const getUserFrameworks = (data: any): string[] => {
  return data.find((d: Modification) => d.code === Modifications_Code.FRAMEWORKS).options;
};
