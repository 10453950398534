import { TabsProps } from '@u1/fixers';
import { Component } from '../../../../../types';
import { SELECTORS } from './consts';

export const tabs: Component<TabsProps> = {
  selector: `[${SELECTORS.TABS}]`,
  props: {
    selectors: {
      tab: `[${SELECTORS.TAB}]`,
      tabPanel: `[${SELECTORS.TAB_PANEL}]`,
      tabList: `[${SELECTORS.TAB_LIST}]`,
    },
  },
};
