import { create } from 'zustand';
import { SubscriptionDetails, SubscriptionStore, SUB_STATUS } from './Stores.consts';

export const useSubscriptionStore = create<SubscriptionStore>((set) => ({
  id: '',
  name: '',
  price: 0,
  pricingMethod: '',
  users: 0,
  domains: 0,
  extraDomains: 0,
  frameworks: [],
  additionalServices: false,
  expiryDate: new Date(),
  nextPaymentAmount: 0,
  autoPayment: false,
  isTrial: false,
  paymentMethodId: '',
  isFreemium: false,
  imageUrl: '',
  supportedFrameworks: [],
  subscriptionStatus: SUB_STATUS.NOT_INITIATED,
  freemiumComponents: [],
  requiredFields: {},
  setSubscription: ({
    id,
    name,
    price,
    pricingMethod,
    users,
    domains,
    frameworks,
    additionalServices,
    isActive,
    expiryDate,
    nextPaymentAmount,
    autoPayment,
    isTrial,
    paymentMethodId,
    isFreemium,
    imageUrl,
    supportedFrameworks,
    freemiumComponents,
    requiredFields,
  }: SubscriptionDetails) =>
    set((state) => ({
      ...state,
      id,
      name,
      price,
      pricingMethod,
      users,
      domains,
      frameworks,
      additionalServices,
      isActive,
      expiryDate,
      nextPaymentAmount,
      autoPayment,
      isTrial,
      paymentMethodId,
      isFreemium,
      imageUrl,
      supportedFrameworks,
      freemiumComponents,
      requiredFields,
    })),
  setIsActive: (isActive: boolean) => set((state) => ({ ...state, isActive })),
  setSubscriptionStatus: (subscriptionStatus: SUB_STATUS) =>
    set((state) => ({ ...state, subscriptionStatus })),
  setAutoPayment: (isAuto: boolean) => set((state) => ({ ...state, autoPayment: isAuto })),
}));
