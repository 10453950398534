import { useTranslation } from 'react-i18next';
import { SettingsView } from '../SettingsView';
import { ChangePasswordForm } from '@/shared/components/Forms/SignIn/Passwords/ChangePasswordForm';

export const ChangePasswordView: React.FC = () => {
  const { t } = useTranslation();
  return (
    <SettingsView title={t('vendor.settings.password.title')}>
      <ChangePasswordForm />
    </SettingsView>
  );
};
