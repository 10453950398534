import { css } from 'styled-components';

export const ConfirmPopupCss = css`
  .ant-popconfirm {
    .ant-popover-inner {
      background: ${(props) => props.theme.cardsColor};
    }

    .ant-popover-message {
      color: ${(props) => props.theme.textColor};
    }
    .ant-popover-arrow-content::before {
      background: ${(props) => props.theme.cardsColor};
    }
    .ant-popover-inner .ant-btn.ant-btn-primary {
      color: ${(props) => props.theme.textColor};
      background: ${(props) => props.theme.buttonsBG};
      border: 1px solid ${(props) => props.theme.buttonsBG};

      &:hover,
      &:focus {
        color: ${(props) => props.theme.textColor};
      }
    }

    .ant-popover-inner .ant-btn {
      color: ${(props) => props.theme.cardsColor};
      border: none;
      &:hover,
      &:focus {
        color: ${(props) => props.theme.cardsColor};
      }
    }
  }
`;
