import { PAGES, RES_STATUS } from '@/shared/consts';
import { useAxios } from '@/services/apiClient';
import { usePaymentStore } from '@/stores/PaymentStore';
import { useSelectionStore } from '@/stores/SelectionStore';
import { Payment_Status } from '@/stores/Stores.consts';
import { useEffect } from 'react';
import { useU1Navigation } from './useU1Navigation';

export const useOrder = () => {
  const setClientSecret = usePaymentStore((state) => state.setClientSecret);
  const { navigate } = useU1Navigation();
  const selectedPackage = useSelectionStore();
  const setPaymentStatus = usePaymentStore((state) => state.setPaymentStatus);

  const [{ data: productsData }] = useAxios({
    method: 'get',
    url: '/products',
  });

  const [{ data: orderId }, postOrder] = useAxios(
    {
      method: 'post',
      url: '/orders',
    },
    { manual: true }
  );

  const [{ response: freemiumRes }, postFreemium] = useAxios(
    {
      method: 'post',
      url: '/freemium',
    },
    { manual: true }
  );

  const [{ data: upgradeData }, postUpgrade] = useAxios(
    {
      method: 'post',
      url: '/orders/upgrade',
    },
    { manual: true }
  );

  const [{ data: paymentData }, postPaymentSession] = useAxios(
    {
      method: 'post',
    },
    { manual: true }
  );

  useEffect(() => {
    if (freemiumRes && freemiumRes?.status === RES_STATUS.CREATED) {
      setPaymentStatus(Payment_Status.FREE_CHECKOUT);
      navigate(PAGES.AFTER_LOGIN.PAYMENT);
    }
  }, [freemiumRes]);

  useEffect(() => {
    if (productsData && selectedPackage.name) {
      const prodId = productsData.find((d: any) => d.name === selectedPackage.name).id;
      selectedPackage.setPackageId && selectedPackage.setPackageId(prodId);
    }
  }, [productsData]);

  useEffect(() => {
    if (orderId) {
      postPaymentSession({ url: `/orders/${orderId.id}/payments/sessions` });
    }
  }, [orderId]);

  useEffect(() => {
    if (paymentData) {
      setClientSecret(paymentData.token);
      setPaymentStatus(Payment_Status.NOT_PAYED);
      navigate(PAGES.AFTER_LOGIN.PAYMENT);
    }
  }, [paymentData]);

  useEffect(() => {
    if (upgradeData) {
      setPaymentStatus(Payment_Status.UPGRADED);
      navigate(PAGES.AFTER_LOGIN.PAYMENT);
    }
  }, [upgradeData]);

  return { postOrder, postFreemium, postUpgrade };
};
