import { StyledCol, StyledText } from '@/shared/styles';
import styled from 'styled-components';

export const StyledNewProjectLayout = styled(StyledCol)`
  width: 100%;
  gap: 20px;
`;

export const StyledPageDecription = styled(StyledText)`
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
`;
