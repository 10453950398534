import { Icon } from '@/shared/components/Icon';
import { StyledCol, StyledRow } from '@/shared/styles';
import { TopSectionProps } from '../../Pricing.consts';
import { StyledCustomTopCard, StyledTitle } from '../Package.styles';
import { StyledPackageTitle } from '@/shared/styles';

export const CustomTopSection: React.FC<TopSectionProps> = ({
  name,
  price,
  imageUrl,
}: TopSectionProps) => {
  return (
    <StyledCustomTopCard>
      <StyledCol $align="center">
        <StyledTitle>{name}</StyledTitle>
        <StyledRow>
          <StyledPackageTitle>{price}</StyledPackageTitle>
        </StyledRow>
        <Icon $src={imageUrl} $size={300} $position="absolute" />
      </StyledCol>
    </StyledCustomTopCard>
  );
};
