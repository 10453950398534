import React from 'react';
import { Checkbox } from 'antd';
import { CheckboxProps } from './Checkbox.consts';
import { StyledCheckboxWrapper } from './Checkbox.styles';
import { U1Checkbox } from '@u1/react-a11y-hooks';

export const SingleCheckbox: React.FC<CheckboxProps> = ({
  onChange,
  value,
  defaultChecked,
  defaultValueDisabled,
  dataTestId,
  hideFromSR,
}: CheckboxProps) => {
  const defaultProps = {
    onChange,
    defaultChecked,
    value,
  };

  const finalProps = defaultChecked
    ? { ...defaultProps, disabled: defaultValueDisabled }
    : defaultProps;

  return (
    <U1Checkbox>
      <StyledCheckboxWrapper>
        <Checkbox data-testid={dataTestId} {...finalProps}>
          <div aria-hidden={hideFromSR ? 'true' : 'false'}>{value}</div>
        </Checkbox>
      </StyledCheckboxWrapper>
    </U1Checkbox>
  );
};
