import { create } from 'zustand';
import { _axios } from '@/services/apiClient';
import { DealsDetails, VendorsStore } from './Stores.consts';
import { DealsDto } from '@/shared/dto';
import { capitalizeStr, getNumericDateTemplate, getPriceFormat } from '@/shared/utils';
import { RES_STATUS, SUBSCRIPTION_TYPE2 } from '@/shared/consts';
import { DEALS_VIEW } from '@/Views/AfterLogin/Main/Vendor/Panels/Deals/Deals.consts';

export const useVendorsStore = create<VendorsStore>((set, get) => ({
  deals: [],
  isLoading: true,
  totalDeals: 0,
  dealsView: DEALS_VIEW.MAIN,
  credentialsSent: false,
  sendCredentials: (id: string) => {
    _axios.post(`/deals/${id}/clients/send-credentials`).then((res) => {
      if (res.status === RES_STATUS.CREATED) {
        set(() => ({ credentialsSent: true }));
        setTimeout(() => {
          set(() => ({ credentialsSent: false }));
        }, 2000);
      }
    });
  },
  setDealsView: (dealsView: DEALS_VIEW) => set((state) => ({ ...state, dealsView })),
  setDeals: (dealsDto: DealsDto) => {
    const deals: DealsDetails[] = dealsDto.data.map((deal) => {
      const { id, idNumber, client, paymentInterval, startAt, endAt, product, status } = deal;
      const period = `${getNumericDateTemplate(new Date(startAt))} - ${getNumericDateTemplate(
        new Date(endAt)
      )}`;
      return {
        key: id,
        id,
        idNumber: String(idNumber),
        companyName: capitalizeStr(client?.companyName) ?? '',
        subscriptionType: SUBSCRIPTION_TYPE2[paymentInterval],
        period,
        price: `$${getPriceFormat(product.price)}`,
        admin: client?.email ?? '',
        status: capitalizeStr(status),
      };
    });
    set((state) => ({ ...state, deals, totalDeals: dealsDto.pagination.total }));
  },
  getDeals: (name?: string, pageNumber?: number) => {
    let query = '';

    if (pageNumber) {
      const skipAmount = (pageNumber - 1) * 3;
      query += `&skip=${skipAmount}`;
    }

    if (name) {
      query += `&companyName=${name}`;
    }
    _axios.get(`deals?limit=3${query}`).then((res) => {
      get().setDeals(res.data);
      set((state) => ({ ...state, isLoading: false }));
    });
    return get().deals;
  },
}));
