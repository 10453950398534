import { VisibilityProps } from '@/shared/props';
import { StyledRow } from '@/shared/styles';
import styled from 'styled-components';

export const StyledEditIcons = styled(StyledRow)<VisibilityProps>`
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
`;

export const StyledCellData = styled.span`
  white-space: nowrap;
`;

export const StyledAddIcon = styled.div`
  width: 100%;
  position: absolute;
  justify-content: end;
  z-index: 9999;
  justify-content: flex-end;
  display: flex;
`;

export const StyledEditTable = styled.div`
  position: relative;
`;
