import { StyledPurpleGradTitle } from '@/shared/styles';
import a11yImportanceImg from '@/assets/images/landingPage/a11yImportanceImg.png';
import { Image } from '@/shared/components/Image/Image';
import { ImportanceDescription } from './ImportanceDescription';
import { isDesktop, isMobile } from 'react-device-detect';
import { StyledImportance, StyledImportanceCol } from './Importance.styles';
import { useTranslation } from 'react-i18next';

export const Importance: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledImportance>
      <StyledImportanceCol>
        <StyledPurpleGradTitle level={2}>
          {t('landing_page.importance.title')}
        </StyledPurpleGradTitle>
        {isMobile && <Image $src={a11yImportanceImg} />}
        <ImportanceDescription />
      </StyledImportanceCol>
      {isDesktop && <Image $src={a11yImportanceImg} $width="40rem" $height="30rem" />}
    </StyledImportance>
  );
};
