import React from 'react';
import { ActionsDropdown } from '../../../../SearchPanel/Actions/ActionsDropdown';
import { EditUserModal } from '@/shared/components/Modals/UserActionModals/EditUserModal';
import { EditVendorForm } from '@/shared/components/Forms/VendorForms/EditVendor';
import { StyledMenuItemRow } from '@/shared/styles/dropdownMenu.styles';
import { StyledSeparator } from '@/shared/styles';
import { DeleteUserModal } from '@/shared/components/Modals/UserActionModals/DeleteUserModal';
import { USER_TYPE } from '@/shared/components/Modals/UserActionModals/UserModals.consts';
import { useAdminsStore } from '@/stores/AdminsStore';
import { ResendDetailsModal } from '@/shared/components/Modals/UserActionModals/ResendDetailsModal';
import { VendorActionsProps } from '@/Views/AfterLogin/Main/SearchPanel/Actions/UserActions.consts';

export const VendorActionsDropdown: React.FC<VendorActionsProps> = ({
  setIsOpen,
  vendor,
}: VendorActionsProps) => {
  const getVendors = useAdminsStore((state) => state.getVendors);
  return (
    <ActionsDropdown setIsOpen={setIsOpen}>
      <StyledMenuItemRow>
        <EditUserModal form={<EditVendorForm selectedUser={vendor} />} />
      </StyledMenuItemRow>
      <StyledSeparator />
      <StyledMenuItemRow>
        <ResendDetailsModal id={vendor.id} userName={vendor.userName} />
      </StyledMenuItemRow>
      <StyledSeparator />
      <StyledMenuItemRow>
        <DeleteUserModal
          id={vendor.id}
          userName={vendor.companyName}
          userType={USER_TYPE.VENDOR}
          getUsers={getVendors}
        />
      </StyledMenuItemRow>
    </ActionsDropdown>
  );
};
