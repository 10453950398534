import { RefObject, useEffect } from 'react';

export const useOutsideDropdownClick = (ref: RefObject<any>, onClickOutside: VoidFunction) => {
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (ref.current.menu.list && !ref.current.menu.list.contains(event.target)) {
        onClickOutside();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside]);
};
