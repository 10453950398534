import { StyledCol, StyledTitle } from '@/shared/styles';
import { Page2 } from './Page2';
import { Page3 } from './Page3';
import { useDocsStore } from '@/stores/ProjectStore/ProjectStore';
import { DocsSection } from '../DocsSection/DocsSection';
import { useTranslation } from 'react-i18next';

export const SinglePageDoc: React.FC = () => {
  const selectedComponent = useDocsStore((state) => state.selectedComponentDoc);
  const { t } = useTranslation();

  return (
    <StyledCol $gap={32}>
      {selectedComponent?.accessibility && (
        <DocsSection
          title={t('projects.docs.component.accessibility.title')}
          objkeys={['accessibility']}
          content={selectedComponent.accessibility}
        />
      )}

      <StyledCol $gap={12}>
        <StyledTitle level={2} $weight={600} $size={18}>
          {t('projects.docs.component.usage.title.h2')}
        </StyledTitle>
        <Page2 />
      </StyledCol>
      <Page3 />
    </StyledCol>
  );
};
