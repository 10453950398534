import { PackagesProps } from '@/shared/pages/Pricing/Pricing.consts';
import { create } from 'zustand';
import { PackagesStore } from './Stores.consts';

export const usePackagesStore = create<PackagesStore>((set, get) => ({
  packages: [],
  setPackages: (packages: PackagesProps[]) => set(() => ({ packages })),
  getPricing: (name: string, method: string, extra: string) => {
    const selectedPackage = get().packages.find((pkg) => pkg.name === name) as PackagesProps;
    return selectedPackage.prices[method][extra];
  },
}));
