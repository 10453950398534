import { useSettingsStore } from '@/stores/SettingsStore/SettingsStore';
import { SETTINGS_VIEW } from '@/stores/SettingsStore/SettingsStore.consts';
import { PersonalDetailsView } from './SettingsView/PersonalDetails/PersonalDetailsView';
import { SettingsMain } from './Sections/SettingsMain';
import { ChangePasswordView } from './SettingsView/Password/ChangePasswordView';

export const SettingsSection: React.FC = () => {
  const settings = useSettingsStore();

  return settings.view === SETTINGS_VIEW.MAIN ? (
    <SettingsMain />
  ) : settings.view === SETTINGS_VIEW.PERSONAL_DETAILS ? (
    <PersonalDetailsView />
  ) : settings.view === SETTINGS_VIEW.PASSWORD ? (
    <ChangePasswordView />
  ) : (
    <></>
  );
};
