import React from 'react';
import { useDocsStore } from '@/stores/ProjectStore/ProjectStore';
import { SearchInput } from '@/shared/components/Search/Search';

export const Search: React.FC = () => {
  const docsStore = useDocsStore();

  const filterBySearch = (e: any) => {
    const queryString = e.target.value;
    const updatedList = [...docsStore.componentsTreeList].filter((compName) => {
      return compName.toLowerCase().includes(queryString.toLowerCase());
    });
    docsStore.setFilteredTreeList(updatedList);
  };

  return (
    <SearchInput
      testId="searchField"
      onChange={filterBySearch}
      label="Component documentation search"
    />
  );
};
