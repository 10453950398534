import React from 'react';
import { MainPageContent } from './MainPageContent/MainPageContent';
import { StyledMainPage } from './MainPage.styles';
import { purpleArc } from '@/assets/images/backgrounds';

export const MainPage: React.FC = () => {
  return (
    <StyledMainPage $bg={purpleArc}>
      <MainPageContent />
    </StyledMainPage>
  );
};
