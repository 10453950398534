import { t } from 'i18next';

export const npmRcFrameworks = ['REACT', 'ANGULAR'];

export type ConfigSecondaryTextProps = {
  isBold: boolean;
};

export const getConfigTitleDic = (): Record<string, string> => ({
  import_js_title: t('projects.configuration.script.title'),
  authorization_token: t('projects.configuration.authorization.title'),
});
