import styled from 'styled-components';
import { StyledPollProps } from './FrameworkStats.consts';
import { keyframes } from 'styled-components';
import { StyledCol, StyledText } from '@/shared/styles';

const fillAnimation = keyframes`
  from {
    height: 0;
  }

  to {
    height: 100%;
  }
`;

export const StyledPoll = styled.div<StyledPollProps>`
  border-radius: 5px;
  background-color: ${(props) => props.$color};
  animation: ${fillAnimation} 1s ease forwards;

  &:hover {
    box-shadow: ${(props) => `0 0 10px ${props.$color}`};
  }
`;

export const StyledPollContainer = styled(StyledCol)<StyledPollProps>`
  height: ${(props) => props.$height}%;
  justify-content: flex-end;
`;

export const StyledPollList = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
`;

export const StylePollLi = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 24px;
  width: 100%;
  max-width: 100px;
  padding: 0px 8px;

  &.inactive {
    .poll {
      background-color: ${(props) => props.theme.marginsBorder};
    }

    span {
      color: ${(props) => props.theme.secondaryTextColor};
    }
  }
`;

export const FrameworkText = styled(StyledText)`
  font-size: 16px;
  font-weight: 400;
`;
