import { useUserStore } from '@/stores/UserStore';
import { ClientSideMenu } from './SideMenu/ClientSideMenu';
import { StyledSider } from './Sider.styles';
import { USER_TYPE } from '@/shared/consts';
import { AdminSideMenu } from './SideMenu/AdminSideMenu';
import { VendorSideMenu } from './SideMenu/VendorSideMenu';

export const Sider: React.FC = () => {
  const userType = useUserStore((state) => state.type);

  return (
    <StyledSider $isClient={userType === USER_TYPE.CLIENT}>
      {userType === USER_TYPE.ADMIN ? (
        <AdminSideMenu />
      ) : userType === USER_TYPE.CLIENT ? (
        <ClientSideMenu />
      ) : userType === USER_TYPE.VENDOR ? (
        <VendorSideMenu />
      ) : (
        <></>
      )}
    </StyledSider>
  );
};
