import { useAxios } from '@/services/apiClient';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { extractSubscriptionDetails } from '@/hooks/hooks.utils';
import { useEffect } from 'react';
import { PAGES, RES_STATUS } from '@/shared/consts';
import { SUB_STATUS } from '@/stores/Stores.consts';
import { useU1Navigation } from './useU1Navigation';

export const useSubscription = () => {
  const subscription = useSubscriptionStore();
  const setSubscription = subscription.setSubscription;
  const setAutoPayment = subscription.setAutoPayment;
  const setSubscriptionStatus = subscription.setSubscriptionStatus;
  const { navigate } = useU1Navigation();

  const [
    { data: subscriptionData, loading: subscriptionLoading, error: subscriptionFailed },
    getSubscription,
  ] = useAxios({ method: 'get', url: '/subscriptions/active' }, { manual: true });

  const [{ response: activateResponse, error: activateErr }, activateSubscription] = useAxios(
    {
      method: 'patch',
      url: '/subscriptions/active',
      data: { autoPayment: true },
    },
    { manual: true }
  );

  const [{ response: cancelResponse }, cancelSubscription] = useAxios(
    {
      method: 'patch',
      url: '/subscriptions/active',
    },
    { manual: true }
  );

  const [{ data: prodData }, getProd] = useAxios(
    {
      method: 'get',
    },
    { manual: true }
  );

  useEffect(() => {
    if (prodData) {
      const subscriptionDetails = extractSubscriptionDetails(subscriptionData, prodData);
      setSubscription(subscriptionDetails);
      setSubscriptionStatus(
        subscriptionDetails.isActive
          ? SUB_STATUS.HAS_SUBSCRIPTION
          : SUB_STATUS.SUBSCRIPTION_DISABLED
      );
    }
  }, [prodData]);

  useEffect(() => {
    if (subscriptionData) {
      getProd({ url: `products/${subscriptionData.productId}` });
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (subscriptionFailed) {
      setSubscriptionStatus(SUB_STATUS.NO_SUBSCRIPTION);
    }
  }, [subscriptionFailed]);

  useEffect(() => {
    if (activateResponse?.status === RES_STATUS.OK) {
      setAutoPayment(true);
    }
  }, [activateResponse]);

  useEffect(() => {
    if (activateErr) {
      if (activateErr?.response?.status === RES_STATUS.FORBIDDEN) {
        getSubscription();
        navigate(PAGES.AFTER_LOGIN.PRICING);
      }
    }
  }, [activateErr]);

  useEffect(() => {
    if (cancelResponse?.status === RES_STATUS.OK) {
      setAutoPayment(false);
    }
  }, [cancelResponse]);

  return {
    getSubscription,
    activateSubscription,
    cancelSubscription,
    activateResponse,
    cancelResponse,
    subscriptionLoading,
  };
};
