import { AbstractOptions, AbstractProps, fromSelector } from '@u1/fixers';
import { MutableRefObject, useEffect, useRef } from 'react';
import { getUiProps, getElementIdSelector } from '../shared/utils';
import { API, BaseOptions } from '../shared/types';
import { DATA_COMPONENT } from './consts';

//TOOD: change back to (keyof Options)[] type in allowedOptionsKeys after publishing fixers
export const useU1Base = <Props extends AbstractProps, Options extends AbstractOptions>(
  api: API<any>, // any needs to be change to Props after fixing Datepicker and Tabs' props
  canBeScoped: boolean,
  allowedOptionsKeys: any,
  componentName: string,
  baseOptions?: BaseOptions<Props, Options>
): MutableRefObject<any> => {
  if (baseOptions) {
    const { options } = baseOptions;
    validateOptions(allowedOptionsKeys, options);
  }
  const ref = useRef(null); // initialize with null to support class component

  useEffect(() => {
    if (ref.current) {
      fix(api, canBeScoped, ref.current as unknown as HTMLElement, componentName, baseOptions);
      try {
      } catch (error) {
        console.log(error);
      }
    }
  }, [ref]);

  return ref;
};

const fix = <Props extends AbstractProps, Options extends AbstractOptions>(
  api: API<AbstractProps>,
  canBeScoped: boolean,
  el: HTMLElement,
  componentName: string,
  baseOptions?: BaseOptions<Props, Options>
) => {
  const { options, refId, manual } = baseOptions ?? {};

  const elementId: string = getElementIdSelector(el);

  el.setAttribute(DATA_COMPONENT, componentName);

  const uiLibraryProps = getUiProps(el, refId);
  if (!uiLibraryProps) return;

  const selector = uiLibraryProps.selector;

  fromSelector(selector).subscribe(() => {
    const props: AbstractProps | null = manual ?? { ...uiLibraryProps.props, ...options };
    if (!props) return;

    const scope: string = canBeScoped ? elementId : 'body';
    api(scope)(props);
  });
};

const validateOptions = <Options extends AbstractOptions>(
  allowedKeys: (keyof Options)[],
  options?: Options
): void => {
  options &&
    Object.keys(options).forEach((optionKey: string) => {
      if (!allowedKeys.includes(optionKey)) {
        throw new Error(`${optionKey} is not an allowed key.`);
      }
    });
};
