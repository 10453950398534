import { StyledCard, StyledCol, StyledRow } from '@/shared/styles';
import { Menu } from 'antd';
import styled, { css } from 'styled-components';
import { StyledColorComp } from '../../Client/Projects/projects.consts';

export const StyledDocsContentCard = styled(StyledCard)`
  padding: 40px;
  display: flex;
  gap: 32px;
  min-width: 900px;
  max-width: 900px;

  $ p {
    color: ${(props) => props.theme.secondaryTextColor};
  }
  align-items: flex-start;
  float: right;

  pre {
    background: ${(props) => props.theme.cardsBgColor} !important;
  }
  pre * {
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
  }
  code {
    background: ${(props) => props.theme.cardsBgColor} !important;
  }
`;

export const StyledDataItem = styled(StyledCol)`
  width: 100%;
  gap: 12px;
`;

export const StyledSearchTreeCard = styled(StyledCard)`
  padding: 40px;
  display: flex;
  min-width: 390px;
  align-items: flex-start;
  float: left;
  gap: 32px;

  [data-component] {
    width: 100%;
  }
`;

export const StyledMenuItem = styled.span<{ isSelected: boolean }>`
  margin-left: 10px !important;
  color: ${(props) => props.theme.textColor} !important;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  .ant-menu-item:active &&&&,
  .ant-menu-item-active &&&&,
  .ant-menu-item-selected &&&& {
    background: ${(props) =>
      props.isSelected ? props.theme.linkGradient : props.theme.textColor} !important;

    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background-repeat: repeat;
    color: unset;
  }
  .ant-menu-item-active::before &&&,
  .ant-menu-item-selected::before &&& {
    border: 3px solid green;
    content: '';
  }
`;

export const StyledComponentTree = styled(Menu)`
  background: none !important;
  border: none;
  color: ${(props) => props.theme.textColor};
  gap: 16px;
  display: flex;
  flex-direction: column;

  .ant-menu-item {
    margin: 0 !important;
    padding-left: 12px !important;
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: ${(props) => props.theme.textColor};
  }

  .ant-menu {
    background: none !important;
  }

  .ant-menu-item:active,
  .ant-menu-submenu-title:active,
  .ant-menu-item-selected {
    background: none !important;
  }

  .ant-menu-item-active::before {
    border-left: solid 0.5px ${(props) => props.theme.linkGradient} !important;
  }

  .ant-menu-item::after {
    border: none !important;
  }

  .ant-menu-item-active {
    border-style: solid;
    border-image: ${(props) => props.theme.linkGradient};
    border-image-slice: 0.5;
    border-width: 0px 0px 0px 2px;
    height: 32px;
  }
`;

export const StyledCellInnerRow = styled(StyledRow)`
  align-items: center;
  justify-content: space-between;
  pre {
    background: unset !important;
  }
  pre * {
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
  }
  code {
    background: unset !important;
  }
`;

export const StyledColorExamples = css`
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
`;

export const StyledCompText = styled.div<StyledColorComp>`
  color: ${(props) => props.theme.secondaryTextColor};
  font-size: 16px;
  font-weight: 400;

  ${(props) =>
    props.$isValid === true &&
    `
  color: ${props.theme.textContrast};
  background: ${props.theme.bgContrast};
  ${StyledColorExamples};
  `}

  ${(props) =>
    props.$isValid === false &&
    `
  color: ${props.theme.textNoContrast};
  background: ${props.theme.bgNoContrast};
  ${StyledColorExamples};
  `}
`;

export const StyledTestList = styled.ul`
  color: ${(props) => props.theme.secondaryTextColor};
  font-size: 16px;
  font-weight: 400;
`;

export const StyledTextEditor = styled.textarea`
  color: ${(props) => props.theme.secondaryTextColor};
  font-size: 16px;
  font-weight: 400;

  min-height: 100px;
  resize: vertical;
  overflow-y: auto;
  background: none;
  border: none;
`;
