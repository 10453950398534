import { StyledCard, StyledTitle } from '@/shared/styles';
import {
  StyledPackageLi,
  StyledPackageUl,
  StyledSpan,
} from '../CreateDeal/Steps/Payment/DealsPayment.styles';
import { useDealStore } from '@/stores/DealStore/DealStore';
import { capitalizeStr, getPriceFormat } from '@/shared/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLangStore } from '@/stores/LangStore/LangStore';

export const PaymentDetails: React.FC = () => {
  const paymentDetails = useDealStore((state) => state.paymentDetails);
  const deals = useDealStore();
  const { t } = useTranslation();
  const isRtl = useLangStore((state) => state.isRtl);

  useEffect(() => {
    if (!paymentDetails) {
      deals.id && deals.getDeal(deals.id);
    }
  }, [paymentDetails]);

  return (
    <StyledCard $width="100%" $gap={32} $align="left" $padding={32}>
      <StyledTitle level={2} $size={18}>
        {t('vendor.deals.create_deal.payment.view_deal.payment_detail')}:
      </StyledTitle>
      <StyledPackageUl>
        <StyledPackageLi>
          <StyledSpan alignRight={isRtl()}>
            {t('vendor.deals.create_deal.payment.view_deal.payment_detail.paid_with')}
          </StyledSpan>
          <StyledSpan alignRight={!isRtl()}>
            {paymentDetails?.paymentMethodBrand && capitalizeStr(paymentDetails.paymentMethodBrand)}{' '}
            {t('billing.payment_method.ending_in')} {paymentDetails?.cardLast4}
          </StyledSpan>
        </StyledPackageLi>
        <StyledPackageLi>
          <StyledSpan alignRight={isRtl()}>
            {t('vendor.deals.create_deal.payment.view_deal.payment_detail.payment_date')}
          </StyledSpan>
          <StyledSpan alignRight={!isRtl()}>{paymentDetails?.paidDate}</StyledSpan>
        </StyledPackageLi>
        <StyledPackageLi>
          <StyledSpan alignRight={isRtl()}>{t('vendor.deals.selling_price.title')}</StyledSpan>
          <StyledSpan alignRight={!isRtl()}>
            ${getPriceFormat(paymentDetails?.paidAmount as number)}
          </StyledSpan>
        </StyledPackageLi>
      </StyledPackageUl>
    </StyledCard>
  );
};
