import { Invoice } from '@/shared/dto';
import { getDateTemplate, getPriceFormat } from '@/shared/utils';
import { INVOICE_STATUS } from './Billing.consts';
import { InvoiceDataType } from './BillingSections/BillingHistory/BillingHistory.consts';

export const exctractOrderHistory = (invoices: Invoice[]) => {
  const orderHistory: InvoiceDataType[] = [];
  invoices.forEach((invoice) => {
    const isDraft = invoice.status === INVOICE_STATUS.DRAFT;
    const shouldShowDate = invoice.issuedAt && !isDraft;
    orderHistory.push({
      key: invoice.id,
      amount: `$${getPriceFormat(invoice.amount)}`,
      date: shouldShowDate ? getDateTemplate(new Date(invoice.issuedAt)) : ``,
      invoice: isDraft ? `` : `#${invoice.invoiceNumber}`,
      status: invoice.status,
    });
  });
  return orderHistory;
};
