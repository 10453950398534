import { StyledCol, StyledRow, StyledTitle } from '@/shared/styles';
import { IconButton } from '@/shared/components/Button/IconButton';
import { useVendorsStore } from '@/stores/VendorsStore';
import { backArrow } from '@/assets/images/icons';
import { DEALS_VIEW } from '../Deals.consts';
import { PackagesDetailsSection } from '../CreateDeal/Steps/Payment/Sections/PackageDetailsSection';
import { ClientDetailsSection } from '../CreateDeal/Steps/Payment/Sections/ClientDetails';
import { PaymentDetails } from './PaymentDetails';
import { DealActions } from './DealActions';
import { useDealStore } from '@/stores/DealStore/DealStore';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const ViewDeal: React.FC = () => {
  const deal = useDealStore();
  const vendorsStore = useVendorsStore();
  const { t } = useTranslation();

  useEffect(() => {
    deal.getDeal();
  }, []);

  return (
    <StyledCol $gap={24}>
      <StyledRow $gap={12} $align="center">
        <IconButton
          $size={24}
          $icon={backArrow}
          $alt={t('vendor.deals.create_deal.payment.view_deal.back')}
          onClick={() => {
            vendorsStore.setDealsView(DEALS_VIEW.MAIN);
          }}
        />
        <StyledTitle $size={24} $margin="0px">
          {t('vendor.deals.create_deal.payment.view_deal')} ({deal.idNumber})
        </StyledTitle>
      </StyledRow>
      <DealActions />
      <StyledRow $gap={24}>
        <StyledCol $gap={24}>
          <PackagesDetailsSection />
          <PaymentDetails />
        </StyledCol>
        <ClientDetailsSection />
      </StyledRow>
    </StyledCol>
  );
};
