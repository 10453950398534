/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { DialogProps, DialogType } from '@u1/fixers';
import { Component } from '../../../../../types';

const handleSpecialCases = (props: DialogProps) => {
  const triggers = document.querySelectorAll(props.selectors.trigger);
  const isActionsDopdownTrigger = triggers[0].tagName === 'LI';
  if (isActionsDopdownTrigger) {
    triggers?.forEach((trigger) => {
      trigger.addEventListener('click', (e: Evant) => {
        const trigger = e.target as HTMLElement;
        setTimeout(() => {
          const dialogs = document.querySelectorAll(props.selectors.dialog);
          dialogs.forEach((dialog) => {
            const dialogBtns = dialog.querySelectorAll('button');
            const noButton = dialogBtns[0];
            const yesButton = dialogBtns[1];
            const focusBackOnTrigger = () => {
              setTimeout(() => {
                const listbox = document.querySelector(
                  '.ant-dropdown:not(.ant-dropdown-hidden) ul.listbox-element'
                );
                listbox.setAttribute('tabindex', '0');
                listbox.focus();
              }, 1000);
            };

            noButton.addEventListener('click', () => {
              focusBackOnTrigger();
            });

            yesButton.addEventListener('click', () => {
              trigger.focus();
            });

            dialogBtns.forEach((btn, i) => {
              const indexToFocus = i === 1 ? 0 : 1;
              btn.addEventListener('keydown', (e: Event) => {
                if (e.key === 'Tab') {
                  setTimeout(() => {
                    dialogBtns[indexToFocus].focus();
                  }, 200);
                }
                if (e.key === 'Escape') {
                  focusBackOnTrigger();
                  document.querySelector(props.selectors.dialog + ' button').click();
                }
              });
            });
          });
        }, 500);
      });
    });
  }
};

export const confirmPopup: Component<DialogProps> = {
  selector: `.popup-trigger`,
  handleSpecialCases,
  props: {
    options: {
      type: DialogType.NON_MODAL,
    },
    selectors: {
      trigger: `.popup-trigger`,
      dialog: `.ant-popover.ant-popconfirm`,
      heading: `.ant-popover-message-title`,
    },
  },
};
