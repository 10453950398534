import { DealsActionsProps } from '@/Views/AfterLogin/Main/SearchPanel/Actions/UserActions.consts';
import { StyledMenuItem, StyledMenuItemRow } from '@/shared/styles/dropdownMenu.styles';
import { downloadItem } from '@/shared/utils';
import { useTranslation } from 'react-i18next';

export const DownloadReceiptItem: React.FC<DealsActionsProps> = ({ deal }: DealsActionsProps) => {
  const { t } = useTranslation();
  return (
    <StyledMenuItemRow>
      <StyledMenuItem onClick={() => downloadItem(`/deals/${deal.id}/receipts/download`)}>
        {t('vendor.deals.create_deal.payment.view_deal.action.send_credentials.download_receipt')}
      </StyledMenuItem>
    </StyledMenuItemRow>
  );
};
