export const TERMS_AND_CONDITIONS_TITLE = `User1st Terms & Conditions`;
export const INTRODUCTION_TEXT_UNTILL_LINK = `These Website Terms and Conditions (these “Terms” or these “Website Terms and Conditions”) contained herein on this webpage shall govern your use of this website, www.user1st.com, including all pages within this website (collectively referred to herein below as this “Website”). These Terms apply in full force and effect to your use of this Website, and by using this Website, you expressly accept all terms and conditions contained herein in full. You must not use this Website if you have any objection to any of these Website's Standard Terms and Conditions.

This Website is not for use by any minors (defined as those who are not at least 18 years of age), and you must not use this Website if you a minor.

In addition to these Terms and Conditions, please read the Privacy Policy to explain how user information is collected and used by the Website. A copy of this Privacy Policy can be found `;

export const INTRODUCTION_TEXT_AFTER_LINK = `and is incorporated by reference into these Terms and Conditions. Accessing or using any of our websites signifies your acknowledgment and agreement to the Privacy Policy.
`;
export const INTELLECTUAL_PROPERTY_RIGHTS = `Under these Terms, User1st (“Company”) and/or its affiliates and/or licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for viewing the material on this Website.`;

export const RESTRICTIONS = `You are expressly and emphatically restricted from all the following:

    1.  Publishing any Website material in any media.
    2.  Selling, sublicensing, and/or otherwise commercializing any Website material.
    3.  Using this Website in any way that is, or maybe, damaging to this Website.
    4.  Using this Website in any way that impacts user access to this Website.
    5.  Using this Website contrary to applicable laws and regulations or in a way that causes, or may cause, harm to the Website or to any person or business entity.
    6.  Engaging in any data mining, data harvesting, data extracting, or other similar activity in relation to this Website or while using this Website.
    7.  Using this Website to engage in any advertising or marketing.
`;

export const NO_WARRANTIES = `This Website is provided “as is,” with all faults, and Company makes no express or implied representations or warranties of any kind related to this Website or the materials contained on this Website. Additionally, nothing contained on this Website shall be construed as providing consult or advice to you.
Our websites may contain links to other websites maintained by third parties. We do not operate or control, in any respect, or necessarily endorse the content on these third-party websites. You assume sole responsibility for your use of third-party links. We are not responsible for any content posted on third-party websites or liable to you for any loss or damage of any sort incurred because of your dealings with any third party or their website.
`;

export const LIMITATION_OF_LIABILITY = `In no event shall Company, nor any of its affiliates, licensors, officers, directors, and employees, be liable to you for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract, tort or otherwise, and Company, including its affiliates, licensors, officers, directors, and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.`;

export const INDEMNIFICATION = `You hereby fully indemnify Company from and against all liabilities, costs, demands, causes of action, damages, and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach of any of the provisions of these Terms.`;

export const TERMINATION = `We reserve the right in our sole discretion and at any time to block your access to our websites for any reason, including, without limitation if you have failed to comply with the letter and spirit of these Terms and Conditions. You agree that Company shall not be liable to you or any third party for blocking your access to our Websites.
Any blocking shall not affect your obligations to us under these Terms and Conditions. The provisions of these Terms and Conditions which by their nature should survive the blocking shall survive, including, but not limited to, the rights and licenses that you have granted hereunder, indemnities, releases, disclaimers, limitations on liability, provisions related to the choice of law, dispute resolution, no class action, and all the miscellaneous provisions.
`;

export const SEVERABILITY = `Suppose any provision of these Terms is found to be unenforceable or invalid under applicable law. In that case, such unenforceability or invalidity shall not render these Terms unenforceable or invalid, and such provisions shall be deleted without affecting the remaining provisions herein.`;

export const VARIATION_OF_TERMS = `Company is permitted to revise these Terms at any time as it sees fit, and by using this Website, you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing the use of this Website.`;
export const ASSIGNMENT = `Company shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification or consent required. However, you shall not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.`;

export const GOVERNING_LAW_JURISDICTION = `These Terms will be governed by and construed in accordance with the laws of the State of Israel. You submit to the non-exclusive jurisdiction of the courts located in Tel Aviv–Jaffa for the resolution of any disputes.
To the extent allowable by law, we each waive any right to pursue disputes on a class-wide basis; that is, to either join a claim with the claim of any other person or entity or assert a claim in a representative capacity on behalf of anyone else in any lawsuit, arbitration, or other proceedings.
`;

export const ENTIRE_AGREEMENT = `These Terms, including any legal notices and disclaimers on this Website, constitute the entire agreement between the Company and you in relation to your use of this Website and supersede all prior agreements and understandings with respect to the same.`;

export const LAST_UPDATED = `Updated 1 January 2023.`;
