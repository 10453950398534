export * from './components';
export * from './hooks';
export { setU1Configuration, DialogType } from '@u1/fixers';

export type {
  U1Configuration,
  AccordionProps,
  AccordionOptions,
  ButtonProps,
  CarouselProps,
  CarouselOptions,
  CheckboxProps,
  CheckboxOptions,
  ComboboxProps,
  ComboboxOptions,
  DatepickerProps,
  DatepickerOptions,
  DialogProps,
  DialogOptions,
  FormProps,
  GridProps,
  GridOptions,
  LinkProps,
  ListboxProps,
  ListboxOptions,
  MenuPropsV2 as MenuProps,
  MenuSelectorsV2 as MenuSelectors,
  MenuOptionsV2 as MenuOptions,
  RadioProps,
  TableProps,
  TabsProps,
  TabsOptions,
  TooltipProps,
} from '@u1/fixers';

import './u1.css';

if (typeof window !== 'undefined') {
  import('@u1/fixers').then(({ visualFocusApi, focusToApi, excludeElApi, statusApi }) => {
    visualFocusApi();
    focusToApi();
    excludeElApi();
    statusApi();
  });
}
