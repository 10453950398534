import { DefaultAvatar } from '@/assets/images/icons';
import { getBase64 } from '@/shared/components/Forms/VendorForms/PersonalDetailsForm/UploadImageButton/UploadImage.utils';
import { useUserStore } from '@/stores/UserStore';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useEffect, useState } from 'react';

export const useUploadImage = () => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const user = useUserStore();

  useEffect(() => {
    if (user.avatarUrl && user.avatarUrl !== DefaultAvatar) {
      setImageUrl(user.avatarUrl);
    }
  }, [user.avatarUrl]);

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
    if (info.file.status === 'error') {
      removeImg();
    }
  };

  const removeImg = () => {
    setImageUrl('');
  };

  return { loading, imageUrl, handleChange, removeImg };
};
