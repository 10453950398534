import introImgMobile from '@/assets/images/landingPage/introImgMobile.png';
import { Image } from '@/shared/components/Image/Image';
import { Intro } from './Intro';
import { StyledCol } from '@/shared/styles';

export const MobileIntro: React.FC = () => {
  return (
    <StyledCol $gap={40}>
      <Intro />
      <Image $src={introImgMobile} $margin="0px -16px 0px 0px" $position="relative" />
    </StyledCol>
  );
};
