/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { AbstractProps, MenuPropsV2 } from '@u1/fixers';
import { Component } from '../../../../../types';
import { addRolePresentation, removeAllAccessibilityAttributes } from '../utils';
import { ANTD_SELECTORS } from './consts';

const handleSpecialCases = (props: AbstractProps, el: HTMLElement) => {
  const { selectors } = props;
  setTimeout(() => {
    selectors.submenus = getSubmenus();
    removeAllAccessibilityAttributes(el);
    addClickEventToMenuItems();
    addRolePresentation(document.querySelectorAll(`${selectors.menu} li`));
  }, 100);
};

export const menu: Component<MenuPropsV2> = {
  selector: ANTD_SELECTORS.MENU,
  handleSpecialCases,
  props: {
    selectors: {
      menu: ANTD_SELECTORS.MENU,
      submenus: ANTD_SELECTORS.SUBMENU,
      items: ANTD_SELECTORS.MENU_ITEM,
      triggers: ANTD_SELECTORS.TRIGGERS,
      openByMouseover: ANTD_SELECTORS.OPEN_BY_MOUSEOVER,
      horizontalMenu: ANTD_SELECTORS.HORIZONTAL_MENU,
    },
  },
};

const getSubmenus = () => {
  let allSubmenuSelctors = ANTD_SELECTORS.SUBMENU;
  const menuElement = document.querySelector(ANTD_SELECTORS.MENU);
  const submenuTriggers = menuElement?.querySelectorAll(ANTD_SELECTORS.TRIGGERS);
  if (submenuTriggers?.length) {
    allSubmenuSelctors = '';
    submenuTriggers.forEach((submenuElement) => {
      const submenuSelector = `${submenuElement.getAttribute('aria-controls')}`;
      if (!submenuSelector || submenuSelector.includes(ANTD_SELECTORS.OVERFLOW_KEY)) return; //OVERFLOW_KEY is for AntD redundant <li>
      allSubmenuSelctors += `#${submenuSelector}:has(li), `;
    });
    allSubmenuSelctors = allSubmenuSelctors.slice(0, -2);
  }
  return allSubmenuSelctors;
};

const addClickEventToMenuItems = () => {
  const menuItems = document.querySelectorAll('.ant-menu-submenu-title');
  menuItems?.forEach((el: Element) => {
    el.addEventListener('keyup', (e: Event) => {
      const event = e as KeyboardEvent;
      if (event.key === 'Enter') {
        el.querySelector('a')?.click();
      }
    });
  });
};
