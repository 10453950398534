import { Form } from 'antd';
import { StyledCol, StyledSecondaryText, StyledTitle } from '@/shared/styles';
import { useEffect } from 'react';
import { useAxios } from '@/services/apiClient';
import { RES_STATUS } from '@/shared/consts';
import { useAdminsStore } from '@/stores/AdminsStore';
import { extractErrors } from '@/shared/utils';
import { useFormMessages } from '@/hooks/useFormMessages';
import { StyledForm } from '../Forms.styles';
import { FormError } from '../FormError';
import { VendorInnerForm } from './VendorInnerForm';
import { EditFormProps } from '../Forms.consts';
import { VendorDetails } from '@/stores/Stores.consts';
import { useTranslation } from 'react-i18next';

export const EditVendorForm: React.FC<EditFormProps> = ({
  onCancel,
  selectedUser,
}: EditFormProps) => {
  const { message, setMessage, serverError, setError, clearAllMessages } = useFormMessages();
  const [vendorForm] = Form.useForm();
  const getVendors = useAdminsStore((state) => state.getVendors);
  const { t } = useTranslation();

  const [{ response, error }, putUpdateVendor] = useAxios(
    { url: `/vendors/${selectedUser.id}`, method: 'put' },
    { manual: true }
  );

  const closeAndResetForm = (onCancel: () => void) => {
    onCancel();
    vendorForm.resetFields();
    clearAllMessages();
    getVendors();
  };

  useEffect(() => {
    if (response?.status === RES_STATUS.OK) {
      setMessage(t('saved_successfully'));
      setTimeout(() => {
        onCancel && closeAndResetForm(onCancel);
      }, 1000);
    }
  }, [response]);

  useEffect(() => {
    if (error && error.response?.data.properties) {
      const errors = extractErrors(error.response);
      setError(errors);
    }
  }, [error]);

  const onFinish = (values: any) => {
    putUpdateVendor({
      data: {
        email: values.email,
        userName: values.fullname,
        phone: values.phone,
        companyName: values.company,
      },
    });
  };

  const closeDialog = (e: Event) => {
    e.preventDefault();
    onCancel && onCancel();
  };

  return message ? (
    <StyledSecondaryText fontWeight={400} fontSize={16}>
      {message}
    </StyledSecondaryText>
  ) : (
    <StyledForm
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
      form={vendorForm}
      initialValues={{
        email: selectedUser.email,
        fullname: selectedUser.userName,
        phone: (selectedUser as VendorDetails).phone,
        company: (selectedUser as VendorDetails).companyName,
      }}
    >
      {serverError && <FormError message={serverError} />}
      <StyledCol>
        <StyledTitle $size={18}>{t('vendor.update_details.title')}</StyledTitle>
        <StyledSecondaryText fontSize={'15'} fontWeight={400} flex={'start'}>
          {t('vendor.update_details.content')}
        </StyledSecondaryText>
      </StyledCol>
      <VendorInnerForm onCancel={(e) => closeDialog(e)} />
    </StyledForm>
  );
};
