import { PAGES } from '@/shared/consts';
import { TitleAndContentModel } from '@/Views/AfterLogin/Main/Client/Projects/projects.consts';
import { StyledCol } from '@/shared/styles';
import * as TERMS from './TermsAndConditions.consts';
import { StyledStaticPageParagraph } from '../StaticPage.styles';
import { PinkGradientRouterLink } from '@/shared/components/Link/PinkGradientRouterLink';

export const TermsAndConditionsDictionary: TitleAndContentModel[] = [
  {
    title: 'Introduction',
    children: (
      <StyledCol>
        <StyledStaticPageParagraph>
          {TERMS.INTRODUCTION_TEXT_UNTILL_LINK}
          <PinkGradientRouterLink
            text="here"
            href={PAGES.SHARED.PRIVACY_POLICY}
          ></PinkGradientRouterLink>{' '}
          {TERMS.INTRODUCTION_TEXT_AFTER_LINK}
        </StyledStaticPageParagraph>
      </StyledCol>
    ),
  },
  {
    title: 'Intellectual Property Rights',
    content: TERMS.INTELLECTUAL_PROPERTY_RIGHTS,
  },
  {
    title: 'Restrictions',
    content: TERMS.RESTRICTIONS,
  },
  {
    title: 'No warranties',
    content: TERMS.NO_WARRANTIES,
  },
  {
    title: 'Limitation of liability',
    content: TERMS.LIMITATION_OF_LIABILITY,
  },
  {
    title: 'Indemnification',
    content: TERMS.INDEMNIFICATION,
  },
  {
    title: 'Termination',
    content: TERMS.TERMINATION,
  },
  {
    title: 'Severability',
    content: TERMS.SEVERABILITY,
  },
  {
    title: 'Variation of Terms',
    content: TERMS.VARIATION_OF_TERMS,
  },
  {
    title: 'Assignment',
    content: TERMS.ASSIGNMENT,
  },
  {
    title: 'Governing Law & Jurisdiction',
    content: TERMS.GOVERNING_LAW_JURISDICTION,
  },
  {
    title: 'Entire Agreement',
    content: TERMS.ENTIRE_AGREEMENT,
  },
  {
    title: '',
    content: TERMS.LAST_UPDATED,
  },
];
