export const SUFFIX_PH = '{suffix}';
export const SCOPE_PH = '{scope}';
export const COMP_ID = '{compId}';

export const A11Y_DIRECTIVE_SELECTOR = 'data-u1';
export const A11Y_DIRECTIVE_SELECTOR_ANGULAR = 'u1-a11y';
export const A11Y_OPTIONS_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-options`;
export const A11Y_MANUAL_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-manual`;
export const A11Y_DISABLED_SELECTOR = `${A11Y_DIRECTIVE_SELECTOR}-disabled`;
export const A11Y_REF_SELECTOR = `${A11Y_DIRECTIVE_SELECTOR}-ref`;
export const A11Y_REF_SELECTOR_ANGULAR = `${A11Y_DIRECTIVE_SELECTOR_ANGULAR}-ref`;

export const DATA_COMPONENT = 'data-component';
export const VALIDATORS_DOM_HANDLER_ELEMENT_ID = 'u1-validators-id-';
export const ARIA_SELECTORS =
  '[aria-labelledby], [aria-modal], [aria-hidden], [aria-activedescendant], [aria-expanded], [aria-haspopup], [aria-selected], [aria-label], [aria-required], [aria-invalid], [aria-describedby], [aria-owns], [aria-controls], [aria-autocomplete], [aria-live], [aria-atomic], [aria-disabled], [aria-pressed], [aria-level], [aria-roledescription], [aria-checked], [aria-sort]';
