import { StyledCard, StyledCol, StyledRow, StyledTitle } from '@/shared/styles';
import { useFrameworkStore } from '@/stores/ProjectStore/ProjectStore';
import { useEffect } from 'react';
import { FrameworkButton } from './FrameworkButton';
import { Loading } from '@/shared/components/Loading/Loading';
import { useTranslation } from 'react-i18next';

export const AdminDocs: React.FC = () => {
  const frameworksStore = useFrameworkStore();
  const { t } = useTranslation();

  useEffect(() => {
    frameworksStore.getFrameworksData();
  }, []);

  return frameworksStore.isLoading ? (
    <Loading />
  ) : (
    <StyledCol $gap={32}>
      <StyledTitle>{t('admin.docs.title')}</StyledTitle>
      <StyledCard $padding={40}>
        <StyledRow $gap={50} $justify="center" $wrap="wrap">
          {frameworksStore.data.map((fw, i) => {
            return <FrameworkButton key={i} frameworkData={fw} />;
          })}
        </StyledRow>
      </StyledCard>
    </StyledCol>
  );
};
