import React from 'react';
import { TitleAndContentItem } from '../TitleAndContentItem';
import * as A11Y from './AccessibilityStatement.consts';
import { AccessibilityStatementDictionary } from './AccessibilityStatementDictionary';
import { StyledStaticPageContainer } from '../StaticPage.styles';

export const AccessibilityStatement: React.FC = () => {
  return (
    <StyledStaticPageContainer>
      <TitleAndContentItem title={A11Y.MAIN_TITLE} />
      {AccessibilityStatementDictionary &&
        AccessibilityStatementDictionary.map((item) => (
          <TitleAndContentItem
            key={item.title}
            title={item.title}
            content={item.content}
            titleLevel={2}
            $flex="start"
          >
            {item.children}
          </TitleAndContentItem>
        ))}
    </StyledStaticPageContainer>
  );
};
