import { StyledRow } from '@/shared/styles';
import styled from 'styled-components';

export const StyleNoBreakSpan = styled.span`
  overflow-wrap: break-word;
`;

export const StyledDesktopIntro = styled(StyledRow)`
  img {
    width: 45rem;
  }
`;
