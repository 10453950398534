export const SUPPORT_EMAIL = `support@user1st.com`;

export const MAIN_TITLE = `Accessibility Statement`;

export const INTRODUCTION = `User1st Inc is deeply committed to ensuring digital accessibility for all users, including those with disabilities. We've introduced a product that embeds accessibility directly within the code, providing a seamless and native experience for all.

Staying abreast with the latest guidelines, including WCAG 2.1 and the emerging WCAG 2.2, we've engineered our product to anticipate and meet accessibility needs at the core coding level.

This site undergoes regular testing for accessibility compliance. If you encounter any accessibility issues, please reach out to us. We apologize for any inconvenience and appreciate your feedback. Your feedback is invaluable in our mission to maintain and improve the accessibility of our platform.
`;

export const PRODUCT_OVERVIEW = `Our new product focuses on in-code accessibility implementation. By seamlessly integrating into the frontend, it removes the need for overlays or afterthought solutions, ensuring that accessibility is a foundational aspect of the digital experience from the outset.`;

export const INTEGRATED_ACCESSIBILITY_FEATURES = `1. Screen Reader Adaptability: The product's code recognizes and cooperates with leading screen readers, creating a harmonious interaction for users.
2. Advanced Keyboard Navigation: Integrated into the core code, our product ensures the website or application is fully navigable without a mouse.
3. Dynamic Visual Compatibility: Our product natively supports user-defined color profiles, ensuring optimal viewing based on individual preferences.
4. Customizable User Settings: Directly embedded in the code, users can modify their accessibility preferences which the website or application will remember and apply in future sessions.
5. Gesture and Orientation Flexibility: The product's code ensures content is not locked to a specific display orientation. It also recognizes single-pointer gestures.
`;
export const ACCESSIBILITY_AT_THE_CORE = `What sets our product apart is its foundational approach. Instead of applying patches or solutions on top of existing structures, we've made accessibility an integral part of the coding process. This ensures not just compliance with standards, but a genuinely inclusive experience for all users.`;

export const REGULAR_TESTING_AND_COMPLIANCE = `User1st Inc continuously subjects its product to rigorous testing protocols, ensuring it remains in sync with the latest guidelines and user needs. Our testing spans various devices, browsers, and assistive tools, reinforcing our commitment to universal accessibility.`;

export const FEEDBACK_AND_CONTINUOUS_IMPROVEMENT_UNTIL_LINK = `As ever, we treasure feedback from our community. Please share your experiences, suggestions, and insights regarding our in-code accessibility product at the following email: `;

export const FEEDBACK_AND_CONTINUOUS_IMPROVEMENT_AFTER_LINK = `. Each piece of feedback brings us closer to creating a fully accessible digital experience.`;
