import { PAGES } from '@/shared/consts';
import { MainNotFound } from '@/shared/pages/NotFound/MainNotFound';
import { usePaymentStore } from '@/stores/PaymentStore';
import { Payment_Status } from '@/stores/Stores.consts';
import React from 'react';
import { PaymentDetails } from './PaymentDetails';
import { PaymentFailed } from './PaymentMessage/PaymentFailed';
import { PaymentSuccess } from './PaymentMessage/PaymentSucess';
import { UpgradeSuccess } from './PaymentMessage/UpgradeSuccess';
import { useTranslation } from 'react-i18next';

export const Payment: React.FC = () => {
  const paymentStatus = usePaymentStore((state) => state.paymentStatus);
  const { t } = useTranslation();

  return paymentStatus === Payment_Status.NOT_PAYED ? (
    <PaymentDetails />
  ) : paymentStatus === Payment_Status.FREE_CHECKOUT ? (
    <PaymentSuccess message={t('payment.payment_message.success.free_checkout.subtitle')} />
  ) : paymentStatus === Payment_Status.SUCCESS ? (
    <PaymentSuccess />
  ) : paymentStatus === Payment_Status.FAILED ? (
    <PaymentFailed />
  ) : paymentStatus === Payment_Status.UPGRADED ? (
    <UpgradeSuccess />
  ) : (
    <MainNotFound $homePath={PAGES.AFTER_LOGIN.PROJECTS} />
  );
};
