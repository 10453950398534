import React from 'react';
import {
  StyledUpgradeFrameContainer,
  StyledOrangeGradSubTitle,
  StyledProjectPageBodyText,
} from '../../ProjectsPage.style';
import { U1Button } from '@/shared/components/Button/U1Button';
import { PAGES } from '@/shared/consts';
import { StyledCol } from '@/shared/styles';
import { useTranslation } from 'react-i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const UpgradeFrame: React.FC = () => {
  const { navigate } = useU1Navigation();
  const { t } = useTranslation();
  return (
    <StyledUpgradeFrameContainer>
      <StyledCol $gap={12}>
        <StyledOrangeGradSubTitle>{t('projects.freemium.title')}</StyledOrangeGradSubTitle>
        <StyledProjectPageBodyText fontWeight={200}>
          {t('projects.freemium.title')}
        </StyledProjectPageBodyText>
      </StyledCol>
      <U1Button
        onClick={() => {
          navigate(PAGES.AFTER_LOGIN.PRICING);
        }}
        dataTestId="checkPackagesButtons"
        text={t('projects.freemium.check_packages')}
        $width="250px"
        $height="50px"
      />
    </StyledUpgradeFrameContainer>
  );
};
