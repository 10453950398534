import { U1Button } from '@/shared/components/Button/U1Button';
import { SingleCheckbox } from '@/shared/components/Checkbox/Checkbox';
import { StyledPackageTitle, StyledText } from '@/shared/styles';
import { StyledRow, StyledRowOffset, StyledTitle } from '@/shared/styles';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  StyledAgreeText,
  StyledSmallPrint,
  StyledSummary,
  StyledTerms,
} from '../OrderSummary.styles';
import {
  calcTotalPrice,
  generateFreemium,
  generateOrder,
  generateUpgrade,
  getFormError,
} from '../../Checkout.utils';
import { useEffect, useState } from 'react';
import { DiscountSection } from './DiscountSection';
import { FormError } from '@/shared/components/Forms/FormError';
import { useSelectionStore, useExtraSelectionStore } from '@/stores/SelectionStore';
import { calcUpgradePrice } from '../../../UpgradePackage/UpgradePackage.utils';
import { LINKS } from '@/shared/consts';
import { getPostTrialDate, getPriceFormat } from '@/shared/utils';
import { PinkGradientLink } from '@/shared/components/Link/PinkGradientLink';
import { PaymentSummaryProps } from '../../Checkout.consts';
import { useOrder } from '@/hooks/useOrder';
import { useTranslation } from 'react-i18next';

export const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  currentPlan,
  isFree,
}: PaymentSummaryProps) => {
  const selectedPackage = useSelectionStore();
  const userSelection = useExtraSelectionStore();
  const [formError, setFormError] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { postOrder, postFreemium, postUpgrade } = useOrder();
  const [totalPrice, setTotalPrice] = useState('0');
  const { t } = useTranslation();

  const onTermsChange = (val: CheckboxChangeEvent): void => {
    setTermsAccepted(val.target.checked);
  };

  const validateForm = (e: Event): void => {
    const error = getFormError(userSelection, termsAccepted, !!currentPlan, isFree);
    setFormError(error);
    if (!error) {
      if (isFree) {
        placeFreeOrder();
      } else if (currentPlan) {
        upgradePlan();
      } else {
        placeOrder();
      }
    }
    e.preventDefault();
  };

  const placeOrder = () => {
    postOrder({
      data: generateOrder(
        userSelection,
        selectedPackage.id as string,
        selectedPackage.pricingMethod
      ),
    });
  };

  const upgradePlan = () => {
    postUpgrade({ data: generateUpgrade(userSelection) });
  };

  const placeFreeOrder = () => {
    userSelection.freeFramework &&
      postFreemium({
        data: generateFreemium(selectedPackage.id as string, userSelection.freeFramework?.value),
      });
  };

  useEffect(() => {
    const price = getPriceFormat(
      currentPlan ? calcUpgradePrice(userSelection) : calcTotalPrice(userSelection, selectedPackage)
    );
    setTotalPrice(price);
  }, [userSelection]);

  return (
    <StyledSummary isFree={isFree}>
      {!isFree && <DiscountSection currentPlan={currentPlan} />}
      <StyledRowOffset>
        <StyledTitle level={3} $size={20}>
          {t('total')}
        </StyledTitle>
        <StyledRow>
          <StyledPackageTitle data-testid="totalPrice">${totalPrice}</StyledPackageTitle>
        </StyledRow>
      </StyledRowOffset>

      <StyledTerms>
        <SingleCheckbox
          dataTestId="termsAndConditionsCheckbox"
          defaultChecked={false}
          value={
            <StyledAgreeText>
              <StyledText fontWeight={400} aria-hidden="true">
                {t('billing.package_details.agree_with')}{' '}
              </StyledText>
              <PinkGradientLink
                dataTestId="termsAndConditionsLink"
                href={LINKS.TERMS}
                text={t('billing.package_details.terms_and_conditions')}
              />
            </StyledAgreeText>
          }
          onChange={onTermsChange}
        />
      </StyledTerms>

      {formError && <FormError message={formError} />}

      <U1Button
        dataTestId="submitPaymentButton"
        text={
          currentPlan
            ? t('projects.upgrade.title')
            : isFree
            ? t('projects.upgrade.confirm_plan')
            : t('projects.upgrade.complete_payment')
        }
        $height="45px"
        htmlType="submit"
        onClick={validateForm}
      />
      {!currentPlan && !isFree && (
        <StyledSmallPrint>
          {t('billing.package_details.billed1')} {getPostTrialDate()},{' '}
          {t('billing.package_details.billed2')}
        </StyledSmallPrint>
      )}
    </StyledSummary>
  );
};
