import { css } from 'styled-components';
import Poppins100 from '@/assets/fonts/Poppins-Thin.ttf';
import Poppins200 from '@/assets/fonts/Poppins-ExtraLight.ttf';
import Poppins300 from '@/assets/fonts/Poppins-Light.ttf';
import Poppins400 from '@/assets/fonts/Poppins-Regular.ttf';
import Poppins600 from '@/assets/fonts/Poppins-SemiBold.ttf';

export const GlobalFonts = css`
  @font-face {
    font-family: 'U1Poppins';
    src: url('U1Poppins'), url(${Poppins100}) format('truetype');
    font-style: normal;
    font-weight: 100;
    font-display: swap;
  }

  @font-face {
    font-family: 'U1Poppins';
    src: url('U1Poppins'), url(${Poppins200}) format('truetype');
    font-style: normal;
    font-weight: 200;
    font-display: swap;
  }

  @font-face {
    font-family: 'U1Poppins';
    src: url('U1Poppins'), url(${Poppins300}) format('truetype');
    font-style: normal;
    font-weight: 300;
    font-display: swap;
  }

  @font-face {
    font-family: 'U1Poppins';
    src: url('U1Poppins'), url(${Poppins400}) format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: 'U1Poppins';
    src: url('U1Poppins'), url(${Poppins600}) format('truetype');
    font-style: normal;
    font-weight: 600;
    font-display: swap;
  }
`;
