import { DonutChartProps } from '@/shared/components/Charts/DonutChart/DonutChart.consts';
import { AxisProps } from '@/shared/components/Charts/LineChart/LineChart.consts';

type ActiveDeals = {
  sum: number;
  count: number;
};

export type DealsStats = {
  activeDeals: ActiveDeals;
  averagePrice: number;
  draftDeals: number;
};

export type FrameworkPollStats = {
  framework: string;
  count: number;
};

export const frameworkDisplayNames: Record<string, string> = {
  REACT: 'React',
  VUEJS: 'Vue Js',
  ANGULAR: 'Angular',
  JAVASCRIPT: 'JavaScript',
  ANDROID_XML_SDK: 'Android',
  IOS_SDK: 'IOS',
  REACT_NATIVE_SDK: 'Native',
  KOTLIN: 'Kotlin',
};

export const initialCountryItems = {
  items: [
    {
      key: 1,
      name: '',
      value: 0,
      amount: 0,
    },
  ],
  totalItems: 0,
};

export type StatsStore = {
  filterIndex: number;
  dealsStats: DealsStats;
  frameworksStats: FrameworkPollStats[];
  topCountries: DonutChartProps;
  totalFrameworks: number;
  dealsProgress: AxisProps[];
  getGeneralStats: (dates?: string) => void;
  getFrameworksStats: (dates?: string) => void;
  getCountryStats: (dates?: string) => void;
  getActivityStats: (dates?: string) => void;
  getStatsByDate: (startDate?: string, endDate?: string) => void;
  getStatsByFilter: (filterIndex: number) => void;
};

export const datesDictionary: Record<string, string> = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

export enum DateType {
  DAY,
  MONTH,
  YEAR,
}
