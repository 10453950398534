import { StyledVendorCard } from '@/Views/AfterLogin/Main/Main.styles';
import { StyledRow, StyledTitle } from '@/shared/styles';
import React, { useEffect, useState } from 'react';
import { DonutChart } from '@/shared/components/Charts/DonutChart/DonutChart';
import { TopCountriesList } from './TopCountriesList';
import { useStatsStore } from '@/stores/StatsStore.tsx/StatsStore';
import { useTranslation } from 'react-i18next';

export const TopCountries: React.FC = () => {
  const [selectedItem, setSelected] = useState(-1);
  const stats = useStatsStore();
  const topCountries = stats.topCountries;
  const { t } = useTranslation();

  useEffect(() => {
    stats.getCountryStats();
  }, []);

  return (
    <StyledVendorCard>
      <StyledTitle level={2} $size={16} $weight={400}>
        {t('vendor.stats.top_countries.title')}
      </StyledTitle>
      <StyledRow $gap={32} $width="100%">
        <TopCountriesList items={topCountries.items} selectedItem={selectedItem} />
        <DonutChart
          items={topCountries.items}
          totalItems={topCountries.totalItems}
          setSelected={setSelected}
          selectedItem={selectedItem}
        />
      </StyledRow>
    </StyledVendorCard>
  );
};
