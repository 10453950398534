import { StyledCol } from '@/shared/styles';
import React, { useState } from 'react';
import { FrameworkPollProps, fwStatsColor } from './FrameworkStats.consts';
import {
  FrameworkText,
  StylePollLi,
  StyledPoll,
  StyledPollContainer,
} from './FrameworkStats.styles';
import { useTheme } from 'styled-components';
import { useStatsStore } from '@/stores/StatsStore.tsx/StatsStore';
import { frameworkDisplayNames } from '@/stores/StatsStore.tsx/StatsStore.consts';

export const FrameworkPoll: React.FC<FrameworkPollProps> = ({
  index,
  framework,
  count,
  isHovered,
  setisHovered,
}: FrameworkPollProps) => {
  const totalFrameworks = useStatsStore((state) => state.totalFrameworks);
  const [hoveredItem, setHoveredItem] = useState<number>();
  const theme = useTheme();

  const handleMouseEnter = (i: number) => {
    setisHovered(true);
    setHoveredItem(i);
  };

  const handleMouseLeave = () => {
    setisHovered(false);
    setHoveredItem(undefined);
  };

  return count ? (
    <StylePollLi
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={handleMouseLeave}
      className={`${hoveredItem === index ? 'active' : isHovered ? 'inactive' : ''}`}
    >
      <StyledPollContainer $height={(count / totalFrameworks) * 100}>
        <StyledPoll
          $color={count > 0 ? fwStatsColor[index] : theme.marginsBorder}
          className="poll"
        />
      </StyledPollContainer>
      <StyledCol $gap={8}>
        <FrameworkText>{frameworkDisplayNames[framework]}</FrameworkText>
        <FrameworkText>{count}</FrameworkText>
      </StyledCol>
    </StylePollLi>
  ) : (
    <></>
  );
};
