import { PersonalDetailsForm } from '@/shared/components/Forms/VendorForms/PersonalDetailsForm/PersonalDetailsForm';
import { SettingsView } from '../SettingsView';
import { useTranslation } from 'react-i18next';

export const PersonalDetailsView: React.FC = () => {
  const { t } = useTranslation();
  return (
    <SettingsView title={t('vendor.settings.personal_details')}>
      <PersonalDetailsForm />
    </SettingsView>
  );
};
