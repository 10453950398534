import { TitleAndContentItemProps } from '@/shared/props';
import React from 'react';
import { StyledStaticPageParagraph, StyledStaticPageTitle } from './StaticPage.styles';
import { StyledCol, StyledbulletList } from '@/shared/styles';

export const TitleAndContentItem: React.FC<TitleAndContentItemProps> = ({
  title,
  titleLevel,
  content,
  list,
  children,
  $flex,
}) => {
  return (
    <StyledCol $gap={12}>
      {title && (
        <StyledStaticPageTitle $flex={$flex} level={titleLevel || 1}>
          {title}
        </StyledStaticPageTitle>
      )}
      {content ? <StyledStaticPageParagraph>{content}</StyledStaticPageParagraph> : children}
      {list && (
        <StyledbulletList>
          {list.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </StyledbulletList>
      )}
    </StyledCol>
  );
};
