import { DealsActionsProps } from '@/Views/AfterLogin/Main/SearchPanel/Actions/UserActions.consts';
import { RES_STATUS } from '@/shared/consts';
import { StyledMenuItem, StyledMenuItemRow } from '@/shared/styles/dropdownMenu.styles';
import { useDealStore } from '@/stores/DealStore/DealStore';
import { useVendorsStore } from '@/stores/VendorsStore';
import { DEALS_VIEW } from '../../../Deals.consts';
import { useTranslation } from 'react-i18next';

export const EditDealItem: React.FC<DealsActionsProps> = ({ deal }: DealsActionsProps) => {
  const deals = useDealStore();
  const vendorsStore = useVendorsStore();
  const { t } = useTranslation();

  const getDeal = async () => {
    deals.clearDeal();
    const status = await deals.getDeal(deal.id);
    if (status === RES_STATUS.OK) {
      vendorsStore.setDealsView(DEALS_VIEW.CREATE_DEAL);
    }
  };

  return (
    <StyledMenuItemRow>
      <StyledMenuItem onClick={() => getDeal()}>
        {t('vendor.deals.create_deal.payment.view_deal.action.edit')}
      </StyledMenuItem>
    </StyledMenuItemRow>
  );
};
