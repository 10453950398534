import {
  StyledDetails,
  StyledLoggedInText,
  StyledUsernname,
  StyledWelcome,
} from './UserDropdown.styles';
import { USER_TYPE } from '@/shared/consts';
import { StyledCapitalized, StyledSeparator } from '@/shared/styles';
import { GradientBorderButton } from '@/shared/components/Button/GradientBorderButton';
import { UserType } from '@/stores/Stores.consts';
import { MenuProps } from 'antd';
import { t } from 'i18next';

export const getItems = (userDetails: UserType, userType: USER_TYPE, logout: VoidFunction) => {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <StyledWelcome>
          <StyledDetails>
            <StyledUsernname id="user-dropdown-title">
              {t('header.signed_in.welcome')}
              {`, ${userDetails.firstName || userDetails.fullName}`}!
            </StyledUsernname>
            {userType === USER_TYPE.CLIENT ? (
              <StyledLoggedInText id="user-dropdown-content">
                {t('header.signed_in.logged_in')}{' '}
                <StyledCapitalized data-testid="accountTypeText">
                  {userDetails.accountType}
                </StyledCapitalized>{' '}
                {t('header.signed_in.account')}
              </StyledLoggedInText>
            ) : (
              <StyledLoggedInText>{userDetails.companyName}</StyledLoggedInText>
            )}
          </StyledDetails>
          <StyledSeparator $margin="20px 0px 0px 0px" $width="100%" />
        </StyledWelcome>
      ),
    },
    {
      key: '2',
      label: (
        <GradientBorderButton
          dataTestId="logoutButton"
          onClick={logout}
          text={t('header.signed_in.log_out')}
          $width={'100%'}
        />
      ),
    },
  ];
  return items;
};
