import { SUBSCRIPTION_METHOD } from '@/shared/consts';
import { PropsWithChildren, useEffect, useState } from 'react';
import { PackagesIntro } from './Intro/Intro';
import { Package } from './Packages/Package';
import { StyledPricing, StyledPackages } from './Pricing.styles';
import { v4 as uuidv4 } from 'uuid';
import { PricingLoading } from './PricingLoading/PricingLoading';
import { usePackagesStore } from '@/stores/PackagesStore';
import { useProducts } from '@/hooks/useProducts';

export const Pricing: React.FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const [pricingMethod, setPricingMethod] = useState(SUBSCRIPTION_METHOD.YEAR);
  const packagesStore = usePackagesStore();
  const { getProducts, productsLoading } = useProducts();

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <StyledPricing>
      {productsLoading ? (
        <PricingLoading />
      ) : (
        <>
          <PackagesIntro pricingMethod={pricingMethod} setPricingMethod={setPricingMethod}>
            {children}
          </PackagesIntro>
          <StyledPackages>
            {packagesStore.packages.map((u1Package) => {
              return (
                <Package
                  key={uuidv4()}
                  u1Package={u1Package}
                  pricingMethod={pricingMethod}
                  price={u1Package.prices[pricingMethod].default}
                />
              );
            })}
          </StyledPackages>
        </>
      )}
    </StyledPricing>
  );
};
