import { TitleProps } from '@/shared/props';
import { StyledCol, StyledTitle } from '@/shared/styles';
import styled from 'styled-components';

export const StyledStaticPageTitle = styled(StyledTitle)<TitleProps>`
  ${(props) => props.$flex && `align-self: flex-${props.$flex} !important`};
  padding-top: 10px;
  text-align: center;
`;

export const StyledStaticPageParagraph = styled.span`
  color: ${(props) => props.theme.textColor};
  font-weight: 400;
  font-size: 16px;
  white-space: break-spaces;
`;

export const StyledStaticPageContainer = styled(StyledCol)`
  gap: 20px;
  padding: 60px;
`;
