import { StyledCol } from '@/shared/styles';
import { ClipboardSyntaxHighlighter } from '../../../ClipboardSyntaxHighlighter/ClipboardSyntaxHighlighter';
import { useDocsStore } from '@/stores/ProjectStore/ProjectStore';
import { DocsSection } from '../DocsSection/DocsSection';
import { useTranslation } from 'react-i18next';

export const Page4: React.FC = () => {
  const selectedComponent = useDocsStore((state) => state.selectedComponentDoc);
  const usage = selectedComponent?.usage;
  const { t } = useTranslation();

  return (
    <StyledCol $gap={32}>
      {/* Images */}
      {usage?.images?.text && (
        <DocsSection
          title={t('projects.docs.component.getting_started.images.title')}
          objkeys={['usage', 'images', 'text']}
          content={usage.images.text}
        />
      )}
      {usage?.images?.example && (
        <ClipboardSyntaxHighlighter
          language="html"
          content={usage.images.example}
          objkeys={['usage', 'images', 'example']}
        />
      )}

      {/* ARIA */}
      {usage?.images?.aria?.before && (
        <DocsSection
          secondTitle={t('projects.docs.component.getting_started.shared.aria.title')}
          objkeys={['usage', 'images', 'aria', 'before']}
          content={usage.images.aria.before}
        />
      )}
      {usage?.images?.aria?.example && (
        <ClipboardSyntaxHighlighter
          language="html"
          objkeys={['usage', 'images', 'aria', 'example']}
          content={usage.images.aria.example}
        />
      )}

      {/* COLORS */}
      {usage?.colors?.text && (
        <DocsSection
          secondTitle={t('projects.docs.component.getting_started.images.colors.title')}
          objkeys={['usage', 'colors', 'text']}
          content={usage.colors.text}
        />
      )}
      <StyledCol $gap={12}>
        {usage?.colors?.example?.invalid && (
          <DocsSection
            objkeys={['usage', 'colors', 'example', 'invalid']}
            content={usage.colors.example.invalid}
            $isValid={false}
          />
        )}
        {usage?.colors?.example?.valid && (
          <DocsSection
            objkeys={['usage', 'colors', 'example', 'valid']}
            content={usage.colors.example.valid}
            $isValid={true}
          />
        )}
      </StyledCol>
    </StyledCol>
  );
};
