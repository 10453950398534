﻿import { CheckboxApi, CheckboxProps, CheckboxOptions } from '@u1/fixers';
import { useU1Base } from './base';
import { BaseOptions } from '../shared/types';
import { allowedOptionsKeys } from './consts';
import { ComponentName } from '@u1/ui-library-mapper';

export const useU1Checkbox = (baseOptions?: BaseOptions<CheckboxProps, CheckboxOptions>) => {
  return useU1Base(
    CheckboxApi,
    true,
    allowedOptionsKeys.checkbox,
    ComponentName.CHECKBOX,
    baseOptions
  );
};
