﻿import { DialogProps, DialogType } from '@u1/fixers';
import { A11Y_DIRECTIVE_SELECTOR } from '../../../../../consts';
import { Component, RefId } from '../../../../../types';

const DIALOG_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-dialog`;
const DIALOG_TYPE_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-dialog-type`;
const ID_SELECTOR = `${A11Y_DIRECTIVE_SELECTOR}-ref`;

const handleSpecialCases = (props: DialogProps, el: HTMLElement, refId: RefId) => {
  const compId = `[id='${el.id}']`;
  const { selectors } = props;

  selectors.dialog = `${compId} [${DIALOG_ATTRIBUTE}='dialog']`;

  if (refId) {
    selectors.trigger = `${compId} [${DIALOG_ATTRIBUTE}='trigger']`;
    selectors.dialog = `[${DIALOG_ATTRIBUTE}='dialog-modal'][${ID_SELECTOR}='${refId}']`;
  }

  const type = el.querySelector(`[${DIALOG_TYPE_ATTRIBUTE}]`)?.getAttribute(DIALOG_TYPE_ATTRIBUTE);
  const isModalType = type == DialogType.MODAL;

  props.type = isModalType ? DialogType.MODAL : DialogType.NON_MODAL;
};

export const dialog: Component<DialogProps> = {
  selector: `[${DIALOG_ATTRIBUTE}]`,
  handleSpecialCases,
  props: {
    type: DialogType.MODAL,
    selectors: {
      dialog: 'YOU SHOULD ADD THIS',
      closeBtn: `[${DIALOG_ATTRIBUTE}='close-btn']`,
      heading: `[${DIALOG_ATTRIBUTE}='heading']`,
      textContent: `[${DIALOG_ATTRIBUTE}='content']`,
      dynamicContent: `[${DIALOG_ATTRIBUTE}='content-dynamic']`,
    },
  },
};
