import { Component } from '../../../../../types';
import { ButtonProps } from '@u1/fixers';

export const search: Component<ButtonProps> = {
  selector: '.ant-input-search-with-button',
  props: {
    selectors: {
      element: `.ant-input-search-with-button .u1-search-btn`,
    },
  },
  handleSpecialCases: () => {
    const inputWrapper = document.querySelector('.ant-input-wrapper');
    const input = inputWrapper?.querySelector('.ant-input');
    const button = inputWrapper?.querySelector('.ant-input-search-button');
    button?.remove();
    button && input && inputWrapper?.insertBefore(button, input);
  },
};
