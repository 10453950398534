import { StyledCol, StyledOrangeGradTitle, StyledTitle } from '@/shared/styles';
import React, { useEffect, useState } from 'react';
import {
  BackgroundContainer,
  StyledAppPreviewImgContainer,
  StyledCursor,
  StyledIntro,
  StyledSecondRow,
  TypewriterContainer,
} from '../MainPage.styles';
import Typewriter from 'typewriter-effect';
import { AppPreview } from '@/assets/images/backgrounds';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Icon } from '@/shared/components/Icon';
import { useTranslation } from 'react-i18next';

export const MainPageIntro: React.FC = () => {
  const [isDoneTyping, setIsDoneTyping] = useState(false);
  const [hideCursor, setHideCursor] = useState(false);
  const { t } = useTranslation();

  const typeWriterTitle = t('homepage.app_preview.type_writer');
  const typeWriterTitle2 = t('homepage.app_preview.type_writer2');
  const fullTitle = typeWriterTitle + typeWriterTitle2;

  useEffect(() => {
    if (!isDoneTyping) return;
    const cursorInterval = setInterval(
      () => setHideCursor((hideCursor: boolean) => !hideCursor),
      500
    );

    return () => clearInterval(cursorInterval);
  }, [isDoneTyping]);
  return (
    <StyledCol>
      <StyledIntro>
        {/* intro */}
        <StyledOrangeGradTitle>{t('homepage.app_preview.title')}</StyledOrangeGradTitle>
        <StyledTitle level={2} aria-label={fullTitle}>
          <MobileView>{fullTitle}</MobileView>
          <BrowserView>
            <TypewriterContainer>
              <Typewriter
                options={{
                  cursorClassName: 'type-cursor',
                  wrapperClassName: 'type-wrapper',
                  delay: 50,
                }}
                onInit={(typewriter) => {
                  typewriter.typeString(typeWriterTitle).start();
                }}
              />

              <StyledSecondRow>
                <Typewriter
                  options={{
                    cursorClassName: 'type-cursor',
                    wrapperClassName: 'type-wrapper',
                    delay: 50,
                  }}
                  onInit={(typewriter) => {
                    setTimeout(() => {
                      typewriter
                        .typeString(typeWriterTitle2)
                        .callFunction(() => {
                          setIsDoneTyping(true);
                        })
                        .start();
                    }, 2630);
                  }}
                />
                <StyledCursor hideCursor={hideCursor}>{isDoneTyping ? '_' : ''}</StyledCursor>
              </StyledSecondRow>
            </TypewriterContainer>
          </BrowserView>
        </StyledTitle>
      </StyledIntro>

      {/* app preview + background shape */}
      <BackgroundContainer>
        <StyledAppPreviewImgContainer>
          <Icon
            $alt={t('homepage.app_preview.alt')}
            $src={AppPreview}
            $width={isMobile ? 328 : 854}
            $height={isMobile ? 184 : 478}
            $loading="lazy"
          />
        </StyledAppPreviewImgContainer>
      </BackgroundContainer>
    </StyledCol>
  );
};
