import { StyledCard, StyledCol, StyledSecondaryText, StyledTitle } from '@/shared/styles';
import { ClientDetailsForm } from '@/shared/components/Forms/VendorForms/ClientDetails';
import { useTranslation } from 'react-i18next';

export const ClientDetailsStep: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledCard $width="100%" $gap={32} $padding={40}>
      <StyledCol $justify="start" $align="start" $gap={8}>
        <StyledTitle $size={18} level={2}>
          {t('vendor.panel.deals.create_deal.client_details')}
        </StyledTitle>
        <StyledSecondaryText fontSize={14} fontWeight={400} flex="start">
          {t('vendor.panel.deals.create_deal.complete_data')}
        </StyledSecondaryText>
      </StyledCol>
      <ClientDetailsForm />
    </StyledCard>
  );
};
