import { PinkGradientRouterLink } from '@/shared/components/Link/PinkGradientRouterLink';
import { ColumnsType } from 'antd/es/table';
import { INVOICE_STATUS, invoiceStatusName } from '../../Billing.consts';
import { downloadItem } from '@/shared/utils';
import { t } from 'i18next';

export interface InvoiceDataType {
  key: string;
  invoice: string;
  date: string;
  amount: string;
  status: string;
}

export const getColumns = (): ColumnsType<InvoiceDataType> => [
  {
    title: t('billing.billing_history.table.date'),
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: t('billing.billing_history.table.amount'),
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: t('billing.billing_history.table.status'),
    dataIndex: 'status',
    key: 'status',
    render: (el) => <>{invoiceStatusName[el]}</>,
  },
  {
    title: t('billing.billing_history.table.invoice'),
    dataIndex: 'invoice',
    key: 'invoice',
  },
  {
    key: 'download',
    render: (el) =>
      el.status === INVOICE_STATUS.PROCEEDED && (
        <PinkGradientRouterLink
          dataTestId="downloadInvoiceButton"
          onClick={() => downloadItem(`orders/${el.key}/receipts/download`)}
          text={t('billing.billing_history.table.download')}
          isButton={true}
          ariaLabel={`${t('billing.billing_history.table.download')} ${t(
            'billing.billing_history.table.invoice'
          )} ${el.invoice}`}
        />
      ),
  },
];
