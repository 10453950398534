import { Icon } from '@/shared/components/Icon';
import React from 'react';
import { StyledRowDetails } from '../Package.styles';
import { RowDetailProps } from './Details.consts';

export const RowDetail: React.FC<RowDetailProps> = ({ $icon, $size, details }: RowDetailProps) => {
  return (
    <StyledRowDetails>
      {$icon && <Icon $src={$icon} $size={$size} />} {details}
    </StyledRowDetails>
  );
};
