import { StyledSecondaryText, StyledText } from '@/shared/styles';
import styled from 'styled-components';

export const StyledDealListItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledDealList = styled.ul`
  list-style: none;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
`;

export const StyledClientTitle = styled(StyledSecondaryText)`
  font-size: 16px;
  font-weight: 400;
  align-self: start;
`;

export const StyledClientText = styled(StyledText)`
  font-size: 16px !important;
  font-weight: 400 !important;
  display: flex;
  gap: 10px;
`;

export const StyledCircleFlag = styled.div`
  svg {
    border-radius: 20px;
  }
`;

export const StyledPackageUl = styled.ul`
  color: ${(props) => props.theme.textColor};
  list-style-type: disc;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  li::marker {
    font-size: 0.7rem;
  }
`;

export const StyledPackageLi = styled.li``;

export const StyledSpan = styled.span<{ alignRight?: boolean }>`
  float: ${(props) => (props.alignRight ? 'right' : 'left')};
  ${(props) => props.alignRight && 'font-weight: 600'};
`;
