import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { GlobalStyle } from './globalStyles';
import { CookiesProvider } from 'react-cookie';
import { U1Scrollbar } from './shared/components/Scrollbar/U1Scrollbar';
import ThemeWrapper from './shared/themes/ThemeWrapper';
import { useLangStore } from './stores/LangStore/LangStore';

(async () => {
  // Load all supported language before loading app
  await useLangStore.getState().getAllLanguages();

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <CookiesProvider>
      <ThemeWrapper>
        <GlobalStyle />
        <BrowserRouter>
          <U1Scrollbar>
            <App />
          </U1Scrollbar>
        </BrowserRouter>
      </ThemeWrapper>
    </CookiesProvider>
  );
})();
