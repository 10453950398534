import {
  getCurrentDay,
  getCurrentMonth,
  getCurrentYear,
} from '@/shared/components/Datepicker/Datepicker.utils';
import { create } from 'zustand';
import { DatepickerStore } from './DatepickerStore.consts';
import { stringifyDate } from './DatepickerStore.utils';
import { DAY_TYPE } from '@/shared/components/Datepicker/Datepicker.consts';

export const useDatepickerStore = create<DatepickerStore>((set, get) => ({
  month: getCurrentMonth(),
  year: getCurrentYear(),
  isOpen: false,
  setIsOpen: (isOpen: boolean) => {
    set((state) => ({ ...state, isOpen }));
  },
  setStartDay: (startDay: number) => {
    set((state) => ({
      ...state,
      startDay: { day: startDay, month: get().month, year: get().year },
    }));
  },
  setEndDay: (endDay?: number) => {
    set((state) => ({
      ...state,
      endDay: endDay ? { day: endDay, month: get().month, year: get().year } : undefined,
    }));
  },
  setMonth: (month: number) => {
    set((state) => ({ ...state, month }));
  },
  setYear: (year: number) => {
    set((state) => ({ ...state, year }));
  },
  clearDates: () => {
    set((state) => ({ ...state, startDay: undefined, endDay: undefined }));
  },
  getSelectedDates: () => {
    const startDay = get().startDay;
    const endDay = get().endDay;
    const startDate = stringifyDate(startDay?.day, startDay?.month, startDay?.year);
    const endDate = stringifyDate(endDay?.day, endDay?.month, endDay?.year);
    return { startDate, endDate };
  },
  isCurrentDay: (day: number) => {
    return (
      getCurrentDay() === day &&
      getCurrentMonth() === get().month &&
      getCurrentYear() === get().year
    );
  },
  isSelectedDay: (dayType: DAY_TYPE, day: number, end?: boolean) => {
    const currentDay = end ? get().endDay : get().startDay;
    let comparetoDate;
    if (dayType === DAY_TYPE.CURRENT_MONTH) {
      comparetoDate = { month: get().month, year: get().year };
    } else {
      comparetoDate = { ...get().getMonthYearByDateType(dayType) };
    }
    const isSelectedDay = currentDay?.day === day;
    const isSelectedMonth = currentDay?.month === comparetoDate.month;
    const isSelectedYear = currentDay?.year === comparetoDate.year;
    return isSelectedDay && isSelectedMonth && isSelectedYear;
  },
  isInRange: (day: number, dayType: DAY_TYPE) => {
    const startDay = get().startDay;
    const endDay = get().endDay;
    if (!startDay || !endDay) return false;
    const startDate = new Date(stringifyDate(startDay.day, startDay.month, startDay.year));
    const endDate = new Date(stringifyDate(endDay.day, endDay.month, endDay.year));
    const { month, year } = get().getMonthYearByDateType(dayType);
    const selectedDate = new Date(stringifyDate(day, month, year));
    return selectedDate > startDate && selectedDate < endDate;
  },
  changeMonth: (dayType: DAY_TYPE) => {
    const selectedMonth = get().month;
    if (selectedMonth > 1 && selectedMonth < 12) {
      set((state) => ({ ...state, month: selectedMonth + dayType }));
    } else {
      set((state) => ({ ...state, month: dayType === DAY_TYPE.NEXT_MONTH ? 1 : 12 }));
      set((state) => ({ ...state, year: get().year + dayType }));
    }
  },
  getMonthYearByDateType: (dayType: DAY_TYPE) => {
    let month,
      year = get().year;
    const currentMonth = get().month;
    if (currentMonth >= 1 && currentMonth <= 12) {
      month = currentMonth + dayType;
    } else {
      month = dayType === DAY_TYPE.NEXT_MONTH ? 1 : 12;
      year = get().year + dayType;
    }
    return { month, year };
  },
  isEndDate: (day: number, diff: DAY_TYPE) => {
    const startDay = get().startDay;
    const startDate = new Date(stringifyDate(startDay?.day, startDay?.month, startDay?.year));
    const selectedDate = new Date(stringifyDate(day, get().month + diff, get().year + diff));
    return selectedDate > startDate;
  },
}));
