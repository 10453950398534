import { OrderSummary } from '@/shared/pages/Checkout/OrderSummary';
import { StyledSeparator } from '@/shared/styles';
import { PackageSummary } from '../OrderSummary/SummarySections/PackageSummary';
import { PaymentSummary } from '../OrderSummary/SummarySections/PaymentSummery';

export const FreeCheckoutSummary: React.FC = () => {
  return (
    <OrderSummary>
      <PackageSummary />

      <StyledSeparator $margin={'10px 0px 30px 0px'} />

      <PaymentSummary isFree={true} />
    </OrderSummary>
  );
};
