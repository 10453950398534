import { FrameworkPollStats } from '@/stores/StatsStore.tsx/StatsStore.consts';

export const fwStatsColor = [
  '#B0F2B4',
  '#BED558',
  '#BAD7F2',
  '#FDFFFC',
  '#F76F8E',
  '#F76F8E',
  '#C5BAF2',
  '#587BD5',
];

export type FrameworkPollProps = FrameworkPollStats & {
  index: number;
  isHovered: boolean;
  setisHovered: React.Dispatch<React.SetStateAction<boolean>>;
};

export type StyledPollProps = {
  $height?: number;
  $color?: string;
  $hovered?: number;
};
