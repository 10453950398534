import { backArrow } from '@/assets/images/icons';
import { IconButton } from '@/shared/components/Button/IconButton';
import { StyledCol, StyledRow, StyledTitle } from '@/shared/styles';
import { useVendorsStore } from '@/stores/VendorsStore';
import { DEALS_VIEW } from '../Deals.consts';
import { CreateDealProgress } from './CreateDealProgress';
import { PackageStep } from './Steps/Package/Package';
import { ClientDetailsStep } from './Steps/ClientDetails/ClientDetails';
import { DealPayment } from './Steps/Payment/DealPayment';
import { usePaymentStore } from '@/stores/PaymentStore';
import { SuccessfulPayment } from './Steps/Payment/PaymentMessages/SuccessfulPayment';
import { FailedPayment } from './Steps/Payment/PaymentMessages/FailedPayment';
import { useDealStore } from '@/stores/DealStore/DealStore';
import { Payment_Status } from '@/stores/Stores.consts';
import { DealSteps } from '@/stores/DealStore/DealsStore.consts';
import { useTranslation } from 'react-i18next';

export const CreateDeal: React.FC = () => {
  const paymentStatus = usePaymentStore((state) => state.paymentStatus);
  const vendorsStore = useVendorsStore();
  const step = useDealStore((state) => state.dealStep);
  const { t } = useTranslation();

  return paymentStatus === Payment_Status.SUCCESS ? (
    <SuccessfulPayment />
  ) : paymentStatus === Payment_Status.FAILED ? (
    <FailedPayment />
  ) : (
    <StyledCol $gap={32}>
      <StyledRow $gap={12} $align="center">
        <IconButton
          $size={24}
          $icon={backArrow}
          $alt={t('vendor.deals.create_deal.payment.view_deal.back')}
          onClick={() => {
            vendorsStore.setDealsView(DEALS_VIEW.MAIN);
          }}
        />
        <StyledTitle $size={24} $margin="0px">
          {t('vendor.deals.create_deal')}
        </StyledTitle>
      </StyledRow>
      <CreateDealProgress step={step} />
      {step === DealSteps.PRODUCT && <PackageStep />}
      {step === DealSteps.CLIENT && <ClientDetailsStep />}
      {step === DealSteps.PAYMENT && <DealPayment />}
    </StyledCol>
  );
};
