import React, { useEffect, useState } from 'react';
import { getTrustedCompaneis } from '@/Views/BeforeLogin/MainPage/MainPageContent/TrustedBy/TrustedBy.consts';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import { U1Button, U1Carousel } from '@u1/react-a11y-hooks';
import { StyledCarousel, StyledPlayBtnsContainer, StyledSlideRow } from './LogoSlider.styles';
import { Icon } from '@/shared/components/Icon';
import { isMobile } from 'react-device-detect';
import { useThemeStore } from '@/stores/ThemeStore';
import { THEME_ICONS } from '@/shared/consts';
import { useTranslation } from 'react-i18next';

export const LogoSlider: React.FC = () => {
  const [groupedLogos, setGroupedLogos] = useState<any[][]>([]);
  const [autoPlay, setAutoPlay] = useState(true);
  const themeStore = useThemeStore();
  const { t } = useTranslation();

  useEffect(() => {
    const result: any[][] = [];
    const numOfItemsInSlide = isMobile ? 3 : 6;
    const trustedCompanies = getTrustedCompaneis(
      themeStore.isDarkMode ? THEME_ICONS.WHITE : THEME_ICONS.DARK
    );
    for (let i = 0; i < trustedCompanies.length; i += numOfItemsInSlide) {
      result.push(trustedCompanies.slice(i, i + numOfItemsInSlide));
    }
    setGroupedLogos(result);
  }, [getTrustedCompaneis]);

  return (
    <U1Carousel>
      <StyledCarousel
        autoplay={autoPlay}
        infinite={true}
        autoplaySpeed={3000}
        speed={2000}
        draggable={true}
      >
        {groupedLogos.map((comps, index) => (
          <StyledSlideRow key={`group` + index}>
            {comps.map((comp, i) => (
              <Icon key={i} $src={comp.src} $alt={comp.alt} $loading="lazy" />
            ))}
          </StyledSlideRow>
        ))}
      </StyledCarousel>
      <StyledPlayBtnsContainer>
        {autoPlay ? (
          <U1Button>
            <PauseOutlined
              onClick={() => setAutoPlay(false)}
              aria-label={t('landing_page.trusted.slider.pause')}
              data-u1-button="button"
            />
          </U1Button>
        ) : (
          <U1Button>
            <CaretRightOutlined
              onClick={() => setAutoPlay(true)}
              aria-label={t('landing_page.trusted.slider.play')}
              data-u1-button="button"
            />
          </U1Button>
        )}
      </StyledPlayBtnsContainer>
    </U1Carousel>
  );
};
