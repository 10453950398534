import { create } from 'zustand';
import { FrameworkPollStats, StatsStore, initialCountryItems } from './StatsStore.consts';
import { _axios } from '@/services/apiClient';
import {
  calcTotalCount,
  getDatesByFilter,
  mapDealsProgress,
  mapTopCountries,
} from './StatsStore.utils';
import {
  ChartItem,
  DonutChartProps,
} from '@/shared/components/Charts/DonutChart/DonutChart.consts';
import { AxisProps } from '@/shared/components/Charts/LineChart/LineChart.consts';

export const useStatsStore = create<StatsStore>((set, get) => ({
  filterIndex: 4,
  totalFrameworks: 0,
  dealsStats: {
    activeDeals: { sum: 0, count: 0 },
    averagePrice: 0,
    draftDeals: 0,
  },
  frameworksStats: [],
  topCountries: initialCountryItems,
  dealsProgress: [],
  setFilterBy: (filterBy: number) => {
    set((state) => ({ ...state, filterBy }));
  },
  getGeneralStats: (dates?: string) => {
    _axios.get(`/deal-statistics/general${dates ?? ''}`).then((res) => {
      const dealsStats = res.data;
      set((state) => ({ ...state, dealsStats }));
    });
  },
  getFrameworksStats: (dates?: string) => {
    _axios.get(`/deal-statistics/frameworks${dates ?? ''}`).then((res) => {
      const frameworksStats = res.data.filter((fw: FrameworkPollStats) => fw.count > 0);
      const totalFrameworks = calcTotalCount(frameworksStats);
      set((state) => ({ ...state, totalFrameworks, frameworksStats }));
    });
  },
  getCountryStats: (dates?: string) => {
    _axios.get(`/deal-statistics/countries${dates ?? ''}`).then((res) => {
      const totalItems = calcTotalCount(res.data);
      const items: ChartItem[] = totalItems
        ? mapTopCountries(res.data, totalItems)
        : initialCountryItems.items;
      const topCountries: DonutChartProps = {
        items,
        totalItems,
      };
      set((state) => ({ ...state, topCountries }));
    });
  },
  getActivityStats: (dates?: string) => {
    _axios.get(`/deal-statistics/activities${dates ?? ''}`).then((res) => {
      const dealsProgress: AxisProps[] = mapDealsProgress(res.data);
      set((state) => ({ ...state, dealsProgress }));
    });
  },
  getStatsByDate: (startDate?: string, endDate?: string) => {
    set((state) => ({ ...state, filterIndex: -1 }));
    let dates = '';
    if (startDate && endDate) {
      dates += `?start=${startDate}&end=${endDate}`;
    }
    get().getGeneralStats(dates);
    get().getFrameworksStats(dates);
    get().getCountryStats(dates);
    get().getActivityStats(dates);
  },
  getStatsByFilter: (filterIndex: number) => {
    const dates = getDatesByFilter(filterIndex);
    get().getStatsByDate(dates?.start, dates?.end);
    set((state) => ({ ...state, filterIndex }));
  },
}));
