import { NoFoundStyleProps } from '@/shared/props';
import { StyledCol } from '@/shared/styles';
import styled from 'styled-components';
import { StyledOrangeGradTitle } from '../Pricing/Intro/Intro.styles';
import { isMobile } from 'react-device-detect';

export const Styled404BG = styled.div`
  width: 100%;
  height: 100%;
`;

export const Styled404Layout = styled(StyledCol)<NoFoundStyleProps>`
  justify-content: center;
  width: ${() => (isMobile ? '100%' : '400px')};

  text-align: center;
  background-color: ${(props) => props.theme.backgroundColor};
  margin: auto;
  span {
    text-shadow: 1px 1px ${(props) => props.theme.backgroundColor};
  }

  ${(props) => !isMobile && props.$height && `height: ${props.$height};`}
`;

export const Styled404Title = styled(StyledOrangeGradTitle)`
  align-self: center;
`;

export const StyledLine = styled.span`
  display: block;
`;
