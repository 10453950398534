import { StyledVendorCard } from '@/Views/AfterLogin/Main/Main.styles';
import { StyledTitle } from '@/shared/styles';
import React, { useEffect, useState } from 'react';
import { FrameworkPoll } from './FrameworkPoll';
import { StyledPollList } from './FrameworkStats.styles';
import { useStatsStore } from '@/stores/StatsStore.tsx/StatsStore';
import { useTranslation } from 'react-i18next';

export const FrameworkStats: React.FC = () => {
  const [isHovered, setisHovered] = useState(false);
  const stats = useStatsStore();
  const { t } = useTranslation();

  useEffect(() => {
    stats.getFrameworksStats();
  }, []);

  return (
    <StyledVendorCard $height="361px">
      <StyledTitle level={2} $size={16} $weight={400}>
        {t('pricing.package.framework.multiple')}
      </StyledTitle>
      <StyledPollList>
        {stats.frameworksStats.map((fw, i) => (
          <FrameworkPoll
            key={i}
            index={i}
            framework={fw.framework}
            count={fw.count}
            isHovered={isHovered}
            setisHovered={setisHovered}
          />
        ))}
      </StyledPollList>
    </StyledVendorCard>
  );
};
