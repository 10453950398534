import { DealsActionsProps } from '@/Views/AfterLogin/Main/SearchPanel/Actions/UserActions.consts';
import { StyledMenuItem, StyledMenuItemRow } from '@/shared/styles/dropdownMenu.styles';
import { useDealStore } from '@/stores/DealStore/DealStore';
import { useVendorsStore } from '@/stores/VendorsStore';
import { DEALS_VIEW } from '../../../Deals.consts';
import { useTranslation } from 'react-i18next';

export const ViewDealItem: React.FC<DealsActionsProps> = ({ deal }: DealsActionsProps) => {
  const dealsStore = useDealStore();
  const vendorsStore = useVendorsStore();
  const { t } = useTranslation();

  const viewDeal = () => {
    dealsStore.clearDeal();
    dealsStore.setDealId(deal.id);
    vendorsStore.setDealsView(DEALS_VIEW.VIEW_DEAL);
  };

  return (
    <StyledMenuItemRow>
      <StyledMenuItem onClick={() => viewDeal()}>
        {t('vendor.deals.create_deal.payment.view_deal')}
      </StyledMenuItem>
    </StyledMenuItemRow>
  );
};
