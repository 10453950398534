import { PAGES, PackageTypes } from '@/shared/consts';
import { Pricing } from '@/shared/pages/Pricing/Pricing';
import { useSelectionStore } from '@/stores/SelectionStore';
import { useProducts } from '@/hooks/useProducts';
import { useEffect } from 'react';
import { Loading } from '@/shared/components/Loading/Loading';
import { FreeSelection } from './FreeCheckout/FreeSelection';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { Checkout } from './Checkout';
import { SUB_STATUS } from '@/stores/Stores.consts';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const CheckoutNavigator: React.FC = () => {
  const subscription = useSubscriptionStore();
  const selection = useSelectionStore();
  const hasSelectedPackage = !!selection.name;
  const { getProducts, productsLoading } = useProducts();
  const { navigate } = useU1Navigation();

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (subscription.isActive && !subscription.isFreemium) {
      navigate(PAGES.SHARED.DEFAULT);
    }
  }, [subscription]);

  return productsLoading || subscription.subscriptionStatus === SUB_STATUS.NOT_INITIATED ? (
    <Loading />
  ) : hasSelectedPackage ? (
    selection.type === PackageTypes.FREE ? (
      <FreeSelection />
    ) : (
      <Checkout />
    )
  ) : (
    <Pricing />
  );
};
