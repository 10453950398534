import { u1Logo } from '@/assets/images/landingPage/icons';
import { useU1Navigation } from '@/hooks/useU1Navigation';
import { GradientRoundedButton } from '@/shared/components/Button/GradientRoundedButton';
import { Icon } from '@/shared/components/Icon';
import { PAGES } from '@/shared/consts';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { StyledLPHeader } from './Header.styles';
import { StyledRow } from '@/shared/styles';
import { ThemeSwitcher } from '@/shared/components/ToggleButton/ThemeSwitcher/ThemeSwitcher';
import { LangSwitch } from '@/Views/BeforeLogin/Header/Lang/LangSwitch';

export const LandingPageHeader: React.FC = () => {
  const { t } = useTranslation();
  const { navigate } = useU1Navigation();

  return (
    <StyledLPHeader>
      <Icon
        $src={u1Logo}
        $alt={t('landing_page.logo')}
        $width={isMobile ? 111 : 190}
        $height={isMobile ? 38 : 66}
      />
      <StyledRow $gap={32} $align="center">
        <ThemeSwitcher />
        <GradientRoundedButton
          text={t('header.sign_in')}
          onClick={() => navigate(PAGES.BEFORE_LOGIN.SIGN_IN)}
        />
        <LangSwitch />
      </StyledRow>
    </StyledLPHeader>
  );
};
