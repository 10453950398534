import { Form } from 'antd';
import { StyledForm } from '../../Forms.styles';
import { StyledCol, StyledSecondaryText, StyledTitle } from '@/shared/styles';
import { useEffect } from 'react';
import { useAxios } from '@/services/apiClient';
import { AdminInnerForm } from '../InnerForm';
import { RES_STATUS } from '@/shared/consts';
import { useAdminsStore } from '@/stores/AdminsStore';
import { FormError } from '../../FormError';
import { extractErrors } from '@/shared/utils';
import { useFormMessages } from '@/hooks/useFormMessages';
import { EditFormProps } from '../../Forms.consts';
import { useTranslation } from 'react-i18next';

export const EditAdminForm: React.FC<EditFormProps> = ({
  onCancel,
  selectedUser,
}: EditFormProps) => {
  const { message, setMessage, serverError, setError, clearAllMessages } = useFormMessages();
  const [adminForm] = Form.useForm();
  const getAdmins = useAdminsStore((state) => state.getAdmins);
  const { t } = useTranslation();

  const [{ response, error }, putUpdateAdmin] = useAxios(
    { url: `/admins/${selectedUser.id}`, method: 'put' },
    { manual: true }
  );

  const closeAndResetForm = (onCancel: () => void) => {
    onCancel();
    adminForm.resetFields();
    clearAllMessages();
    getAdmins();
  };

  useEffect(() => {
    if (response?.status === RES_STATUS.OK) {
      setMessage(t('saved_successfully'));
      setTimeout(() => {
        onCancel && closeAndResetForm(onCancel);
      }, 1000);
    }
  }, [response]);

  useEffect(() => {
    if (error && error.response?.data.properties) {
      const errors = extractErrors(error.response);
      setError(errors);
    }
  }, [error]);

  const onFinish = (values: any) => {
    putUpdateAdmin({
      data: {
        email: values.email,
        userName: values.fullName,
      },
    });
  };

  const closeDialog = (e: Event) => {
    e.preventDefault();
    onCancel && onCancel();
  };

  return message ? (
    <StyledSecondaryText fontWeight={400} fontSize={16}>
      {message}
    </StyledSecondaryText>
  ) : (
    <StyledForm
      name="basic"
      onFinish={onFinish}
      autoComplete="off"
      form={adminForm}
      initialValues={{ email: selectedUser.email, fullName: selectedUser.userName }}
    >
      {serverError && <FormError message={serverError} />}
      <StyledCol>
        <StyledTitle $size={18}>{t('admin.edit.title')}</StyledTitle>
      </StyledCol>
      <AdminInnerForm onCancel={(e) => closeDialog(e)} />
    </StyledForm>
  );
};
