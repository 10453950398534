import { SCOPE_PH } from '../../../../../consts';
import { Component } from '../../../../../types';
import { LinkProps } from '@u1/fixers';
import { A11Y_DIRECTIVE_SELECTOR } from '../../../../../consts';

const LINK_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-link`;

export const link: Component<LinkProps> = {
  selector: `[${LINK_ATTRIBUTE}]`,
  props: {
    selectors: {
      element: `${SCOPE_PH} [${LINK_ATTRIBUTE}="link"]`,
    },
  },
  handleSpecialCases: (props, el) => {
    if (typeof el.id === 'string' && el.dataset.u1Link !== undefined) {
      props.selectors.element = props.selectors.element.split(' ')[1];
    }
  },
};
