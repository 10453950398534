import { StyledOrangeGradTitle } from '@/shared/styles';
import React from 'react';
import { StyleBenefits, StyledBenefitsCard, StyledBenefitsCardsGrid } from '../../MainPage.styles';
import { getBenefitsArr } from './U1Benefits.utils';
import { useTranslation } from 'react-i18next';

export const U1Benefits: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyleBenefits>
      <StyledOrangeGradTitle level={2}>{t('homepage.benefits.title')}</StyledOrangeGradTitle>
      <StyledBenefitsCardsGrid role="list">
        {getBenefitsArr().map((el: string, index: number) => (
          <StyledBenefitsCard key={index} role="listitem">
            {el}
          </StyledBenefitsCard>
        ))}
      </StyledBenefitsCardsGrid>
    </StyleBenefits>
  );
};
