import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

export const StyledCheckout = styled.form`
  display: flex;
  flex-direction: ${() => (isMobile ? 'column' : 'row')};
  justify-content: center;
  gap: 40px;
  ${() => isMobile && 'align-items: center;'}
`;
