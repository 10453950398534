import { LinkProps } from '@/shared/props';
import { StyledPinkGradientLink } from './Links.styles';
import { v4 as uuidv4 } from 'uuid';
import { U1Button } from '@u1/react-a11y-hooks';

export const PinkGradientLink: React.FC<LinkProps> = ({
  text,
  href,
  dataTestId,
  children,
  inRow,
  weight,
  onClick,
  isButton,
  triggerRef,
  shouldOpenTab = true,
  excludeEl = false,
}: LinkProps) => {
  const openTab = shouldOpenTab ? { target: '_blank' } : {};
  const trigger = triggerRef ? { 'data-a11y-dialog': 'trigger', 'data-a11y-ref': triggerRef } : {};
  const props = {
    'data-testid': dataTestId,
    href,
    key: uuidv4(),
    inRow,
    weight,
    onClick,
    'data-u1-exclude': excludeEl,
    ...openTab,
    ...trigger,
  };

  return isButton ? (
    <U1Button>
      <StyledPinkGradientLink {...props} data-u1-button="button">
        {children && <div>{children}</div>}
        <span>{text}</span>
      </StyledPinkGradientLink>
    </U1Button>
  ) : (
    <StyledPinkGradientLink {...props}>
      {children && <div>{children}</div>}
      <span>{text}</span>
    </StyledPinkGradientLink>
  );
};
