import { U1Button } from '@/shared/components/Button/U1Button';
import { StyledColOffset, StyledSeparator } from '@/shared/styles';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { PackgeDetailsProps } from '../../Pricing.consts';
import { StyledDetailsContainer } from '../Package.styles';
import { PackageDetails } from '../PackageDetails/PackageDetails';
import { useTranslation } from 'react-i18next';

export const FreePackageDetails: React.FC<PackgeDetailsProps> = ({
  users,
  frameworks,
  supportedFrameworks,
  domains,
  training,
  choosePackage,
}: PackgeDetailsProps) => {
  const subscription = useSubscriptionStore();
  const { t } = useTranslation();
  return (
    <StyledColOffset>
      <StyledSeparator />
      <StyledDetailsContainer>
        <PackageDetails
          users={users.quantityTitle}
          frameworks={frameworks.quantityTitle}
          domains={domains.quantityTitle}
          training={training?.quantityTitle ?? ''}
          supportedFrameworks={supportedFrameworks}
        />
        <U1Button
          dataTestId="selectFreePackageButton"
          text={
            subscription.isFreemium ? t('pricing.package.current') : t('pricing.package.select')
          }
          $height={'40px'}
          onClick={choosePackage}
          disabled={subscription.isFreemium}
          isLink={true}
        />
      </StyledDetailsContainer>
    </StyledColOffset>
  );
};
