import { CACHE_TTL_SECONDS } from '@/shared/consts';

export const putToCache = (
  key: string,
  value: unknown,
  ttlInSeconds: number = CACHE_TTL_SECONDS.DEFAULT
) => {
  const now = Date.now();
  const ttlInMilliseconds = ttlInSeconds * 1000;

  const item = {
    value: value,
    expiry: now + ttlInMilliseconds,
  };

  localStorage.setItem(key, JSON.stringify(item));
};

export const getFromCache = <T>(key: string): T | null => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  try {
    const item = JSON.parse(itemStr);
    const now = Date.now();

    if (now > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value as T;
  } catch (e) {
    console.error('Failed to parse localStorage item:', e);
    localStorage.removeItem(key);
    return null;
  }
};
