import { Component } from '../../../../../types';
import { FormProps } from '@u1/fixers';

export const form: Component<FormProps> = {
  selector: 'form.ant-form',
  props: {
    selectors: {
      form: 'form.ant-form',
      formLabelAbsolute: '.manual-form-container h1',
      requiredField: '.ant-input',
      errorMsg: 'form.ant-form .ant-form-item-explain-error',
    },
  },
};
