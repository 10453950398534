import React, { useState } from 'react';
import { StyledFilterBar, StyledPeriodFilter } from './StatsFilters.styles';
import { StyledDivider } from '@/shared/styles';
import { getStatsFilters } from './StatsFilters.consts';
import { useStatsStore } from '@/stores/StatsStore.tsx/StatsStore';
import { DatepickerTrigger } from '@/shared/components/Datepicker/Sections/DatepickerTrigger';
import { U1Button } from '@u1/react-a11y-hooks';
import { useTranslation } from 'react-i18next';

export const FilterBar: React.FC = () => {
  const stats = useStatsStore();
  const [status, setStatus] = useState('');
  const { t } = useTranslation();

  const selectFilter = (i: number) => {
    stats.getStatsByFilter(i);
    setStatus(`${t('vendor.stats.stats_filters.sr_message')} ${getStatsFilters()[i].label}`);
  };

  return (
    <U1Button>
      <span className="u1st-sr-only" role="status">
        {status}
      </span>
      <StyledFilterBar $gap={10}>
        {getStatsFilters().map((filter, i) => (
          <StyledPeriodFilter
            key={filter.text.toLowerCase()}
            isSelected={stats.filterIndex === i}
            aria-current={stats.filterIndex === i}
            onClick={() => selectFilter(i)}
            aria-label={`${t('vendor.stats.deals_stats.filter_by')} ${filter.label}`}
            className="u1-button"
          >
            {filter.text}
          </StyledPeriodFilter>
        ))}
        <StyledDivider $align="center" />
        <StyledPeriodFilter>
          <DatepickerTrigger />
        </StyledPeriodFilter>
      </StyledFilterBar>
    </U1Button>
  );
};
