import { StyledCol, StyledPackageTitle } from '@/shared/styles';
import {
  StyledContent,
  StyledTitle,
  StyledCard,
  StyledSeparator,
  StyledSecondaryText,
} from '@/shared/styles';
import { getPriceFormat } from '@/shared/utils';
import { useSelectionStore, useExtraSelectionStore } from '@/stores/SelectionStore';
import React, { useEffect, useState } from 'react';
import { calcExtras, calcTotalPrice } from '../Checkout/Checkout.utils';
import {
  StyledPackageName,
  StyledPayment,
  StyledPaymentDetails,
  StyledPaymentSection,
  StyledText,
} from './Payment.styles';
import { isMobile } from 'react-device-detect';
import { StripeCardPayment } from '@/shared/stripe/StripeCardPayment';
import { useTranslation } from 'react-i18next';

export const PaymentDetails: React.FC = ({}) => {
  const userSelection = useExtraSelectionStore();
  const selectedPackage = useSelectionStore();
  const [hasExtra, setHasExtra] = useState(false);
  const titleSize = isMobile ? 16 : 18;
  const { t } = useTranslation();

  useEffect(() => {
    setHasExtra(calcExtras(userSelection) > 0);
  }, []);

  return (
    <StyledPayment>
      <StyledCol $gap={12} $align="center">
        <StyledTitle $size={28}>{t('payment.title')}</StyledTitle>
        <StyledContent fontSize={18}>{t('payment.content')}</StyledContent>
      </StyledCol>
      <StyledCard $width={'60%'} $padding={isMobile ? 24 : 40} $gap={32}>
        <StyledPaymentDetails>
          <StyledPaymentSection>
            <StyledTitle level={2} $size={titleSize}>
              {t('payment.selected_package')}
            </StyledTitle>
            <StyledPackageName>
              <StyledText data-testid="packageName" $size={titleSize}>
                {selectedPackage.name}
              </StyledText>
              {hasExtra && (
                <StyledSecondaryText
                  fontSize={titleSize}
                  fontWeight={isMobile ? 400 : 600}
                  flex="end"
                >
                  {t('payment.extra')}
                </StyledSecondaryText>
              )}
            </StyledPackageName>
          </StyledPaymentSection>
          <StyledSeparator />
          <StyledPaymentSection>
            <StyledTitle level={2} $size={titleSize}>
              {t('total')}
            </StyledTitle>
            <StyledPackageTitle data-testid="paymentAmount">
              ${getPriceFormat(calcTotalPrice(userSelection, selectedPackage))}
            </StyledPackageTitle>
          </StyledPaymentSection>
        </StyledPaymentDetails>
        <StripeCardPayment />
      </StyledCard>
    </StyledPayment>
  );
};
