import React, { useEffect, useState } from 'react';
import { StyledMenuItem } from '@/shared/styles/dropdownMenu.styles';
import { StyledContent, StyledSecondaryText } from '@/shared/styles';
import { useAxios } from '@/services/apiClient';
import { RES_STATUS } from '@/shared/consts';
import { useFormMessages } from '@/hooks/useFormMessages';
import { DeleteUserProps } from './UserModals.consts';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { useTranslation } from 'react-i18next';

export const DeleteUserModal: React.FC<DeleteUserProps> = ({
  id,
  userName,
  userType,
  getUsers,
}: DeleteUserProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { serverError, setError, clearAllMessages } = useFormMessages();
  const { t } = useTranslation();

  const [{ response }, deleteAdmin] = useAxios(
    { url: `/${userType}s/${id}`, method: 'delete' },
    { manual: true }
  );

  useEffect(() => {
    if (response) {
      if (response?.status === RES_STATUS.NO_CONTENT) {
        setIsOpen(false);
        getUsers();
      } else {
        setError(response?.data.message);
      }
    }
  }, [response]);

  const showModal = () => {
    setIsOpen(true);
  };

  const onConfirm = () => {
    deleteAdmin();
  };

  const onCancel = () => {
    setIsOpen(false);
    clearAllMessages();
  };

  return (
    <ConfirmModal
      title={`${t('admin.actions.delete')} ${userType}`}
      trigger={
        <StyledMenuItem onClick={showModal} data-a11y-ref="confirm" data-a11y-dialog="trigger">
          {t('admin.actions.delete')}
        </StyledMenuItem>
      }
      confirmBtnText={t('admin.delete.yes_delete')}
      cancelBtnText={t('cancel')}
      onConfirm={onConfirm}
      onCancel={onCancel}
      error={serverError}
      isOpen={isOpen}
    >
      <StyledSecondaryText fontWeight={400} fontSize={'16px'}>
        {t('admin.delete.content')}
      </StyledSecondaryText>
      <StyledContent fontWeight={400} fontSize={'16px'}>
        {userName}
      </StyledContent>
    </ConfirmModal>
  );
};
