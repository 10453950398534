import { StyledbulletList, StyledCol, StyledRowOffset } from '@/shared/styles';
import { StyledFrameworks, StyledLeftAligned } from '../OrderSummary.styles';
import { calcExtraDomains, calcExtraFrameworks, calcExtraTraining } from '../../Checkout.utils';
import { useSelectionStore, useExtraSelectionStore } from '@/stores/SelectionStore';
import { usePackagesStore } from '@/stores/PackagesStore';
import {
  calcUpgradeDomains,
  calcUpgradeFrameworks,
} from '../../../UpgradePackage/UpgradePackage.utils';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { ExtraSummaryProps } from '../../Checkout.consts';
import { useEffect, useState } from 'react';
import { useSubscription } from '@/hooks/useSubscription';
import { useTranslation } from 'react-i18next';

export const ExtraSummary: React.FC<ExtraSummaryProps> = ({ isUpgrade }: ExtraSummaryProps) => {
  const selectedPackage = useSelectionStore();
  const userSelection = useExtraSelectionStore();
  const packages = usePackagesStore();
  const subscription = useSubscriptionStore();
  const { getSubscription } = useSubscription();
  const [shouldShowFW, setShouldShowFW] = useState(false);
  const [shouldShowDomains, setShouldShowDomains] = useState(false);
  const [shouldShowServices, setShouldShowServices] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getSubscription();
  }, []);

  useEffect(() => {
    setShouldShowFW((isUpgrade && !!userSelection.frameworks.length) || !isUpgrade);
    setShouldShowDomains((isUpgrade && !!userSelection.domains) || !isUpgrade);
    setShouldShowServices((isUpgrade && !!userSelection.additionalService) || !isUpgrade);
  }, [userSelection, subscription]);

  return (
    <StyledbulletList data-testid="extraSummaryBlock">
      {shouldShowFW && (
        <li>
          <StyledRowOffset>
            <StyledCol>
              <StyledLeftAligned>
                {t('billing.package_details.upgrade.framework_selected')}
              </StyledLeftAligned>
              {userSelection.frameworks.map((fw) => {
                return <StyledFrameworks key={fw}>{fw}</StyledFrameworks>;
              })}
            </StyledCol>
            {!isUpgrade && (
              <span>
                $
                {subscription.id && !subscription.isFreemium
                  ? calcUpgradeFrameworks(userSelection, packages, subscription)
                  : calcExtraFrameworks(userSelection, selectedPackage, packages)}
              </span>
            )}
          </StyledRowOffset>
        </li>
      )}
      {shouldShowDomains && (
        <li>
          <StyledRowOffset>
            <StyledLeftAligned data-testid="extraDomainsNumber">
              {t('billing.upgarde_package.domains.title')} {userSelection.domains}
            </StyledLeftAligned>
            {!isUpgrade && (
              <span>
                $
                {subscription.id && !subscription.isFreemium
                  ? calcUpgradeDomains(userSelection, packages, subscription)
                  : calcExtraDomains(userSelection, selectedPackage, packages)}
              </span>
            )}
          </StyledRowOffset>
        </li>
      )}
      {shouldShowServices && (
        <li>
          <StyledRowOffset>
            <StyledLeftAligned>
              {t('billing.upgarde_package.additional_services_20h.title')}
            </StyledLeftAligned>
            {!isUpgrade && (
              <span>
                $
                {calcExtraTraining(
                  userSelection,
                  subscription.id && !subscription.isFreemium ? subscription : selectedPackage,
                  packages
                )}
              </span>
            )}
          </StyledRowOffset>
        </li>
      )}
    </StyledbulletList>
  );
};
