import { DAY_TYPE, startYear } from './Datepicker.consts';

export const numOfDaysInMonth = (year: number, month: number) => {
  const date = new Date(year, month, 0);
  return date.getDate();
};

export const getFirstDayOfMonth = (year: number, month: number) => {
  const firstDay = new Date(year, month - 1, 1);
  return firstDay.getDay();
};

export const getLastDayOfMonth = (year: number, month: number, daysInMonth: number) => {
  const lastDay = new Date(year, month - 1, daysInMonth);
  return lastDay.getDay();
};

export const getCurrentDay = () => {
  const currentDate = new Date();
  return currentDate.getDate();
};

export const getCurrentMonth = () => {
  const currentDate = new Date();
  return currentDate.getMonth() + 1;
};

export const getCurrentYear = () => {
  const currentDate = new Date();
  return currentDate.getFullYear();
};

export const getAllYears = () => {
  const years = [];
  const lastYear = getCurrentYear();
  for (let currYear = startYear; currYear <= lastYear; currYear++) {
    years.push(currYear);
  }
  return years;
};

export const getDayType = (className: string): DAY_TYPE => {
  return className.includes('next-month')
    ? DAY_TYPE.NEXT_MONTH
    : className.includes('prev-month')
    ? DAY_TYPE.PREV_MONTH
    : DAY_TYPE.CURRENT_MONTH;
};

export const isDayDisabled = (className: string): boolean => {
  return className.includes('disabled-day');
};
