import { PAGES, USER_ROLE } from '@/shared/consts';
import { MainNotFound } from '@/shared/pages/NotFound/MainNotFound';
import { SUB_STATUS } from '@/stores/Stores.consts';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { ProjectsPage } from './ProjectsPage/ProjectsPage';
import { useUserStore } from '@/stores/UserStore';

export const Projects: React.FC = () => {
  const userRole = useUserStore((state) => state.role);
  const subscriptionStatus = useSubscriptionStore((state) => state.subscriptionStatus);

  return subscriptionStatus === SUB_STATUS.HAS_SUBSCRIPTION ||
    subscriptionStatus === SUB_STATUS.SUBSCRIPTION_DISABLED ||
    userRole === USER_ROLE.ENTERPRISE ? (
    <ProjectsPage />
  ) : (
    <MainNotFound $homePath={PAGES.SHARED.DEFAULT} />
  );
};
