import { Cookies } from 'react-cookie';
import { COOKIES } from '../shared/consts';
import { TokenDto } from '../shared/dto';

const cookie = new Cookies();

export const setToken = (token: TokenDto) => {
  const expireDate = new Date();
  expireDate.setSeconds(expireDate.getSeconds() + token.expiresIn);
  cookie.set(COOKIES.ACCESS_TOKEN, token.accessToken, {
    path: '/',
    expires: expireDate,
    secure: true,
  });
};

export const getToken = () => {
  return cookie.get(COOKIES.ACCESS_TOKEN);
};

export const removeToken = () => {
  cookie.remove(COOKIES.ACCESS_TOKEN, { path: '/' });
};
