import { StyledCol, StyledRow, StyledRowOffset, StyledTitle } from '@/shared/styles';
import React from 'react';
import { StatsFilters } from './Sections/StatsFilters/StatsFilters';
import { FrameworkStats } from './Sections/FrameworkStats/FrameworkStats';
import { TopCountries } from './Sections/TopCountires/TopCountries';
import { DealsProgress } from './Sections/DealsProgress/DealsProgress';
import { DealsStats } from './Sections/DealsStats/DealsStats';
import { useTranslation } from 'react-i18next';

export const StatsSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledCol $gap={24}>
      <StyledRowOffset>
        <StyledTitle>{t('vendor.stats.title')}</StyledTitle>
        <StatsFilters />
      </StyledRowOffset>
      <DealsStats />
      <StyledRow $gap={24}>
        <FrameworkStats />
        <TopCountries />
      </StyledRow>
      <DealsProgress />
    </StyledCol>
  );
};
