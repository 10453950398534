import { useSubscription } from '@/hooks/useSubscription';
import { PAGES, USER_ROLE } from '@/shared/consts';
import { MainNotFound } from '@/shared/pages/NotFound/MainNotFound';
import { StyledCol, StyledContent, StyledRowOffset, StyledTitle } from '@/shared/styles';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { BillingSections } from './Billing.styles';
import { BillingLoading } from './BillingLoading';
import { BillingHistoy } from './BillingSections/BillingHistory/BillingHistory';
import { CurrentPackage } from './BillingSections/CurrentPackage';
import { PackageDetails } from './BillingSections/PackageDetails';
import { PaymentMethod } from './BillingSections/PaymentMethod';
import { NoActivePackages } from './NoActiveSubscription/NoActivePackages';
import { SUB_STATUS } from '@/stores/Stores.consts';
import { useUserStore } from '@/stores/UserStore';
import { useTranslation } from 'react-i18next';
import { useLangStore } from '@/stores/LangStore/LangStore';

export const Billing: React.FC = () => {
  const userDetails = useUserStore();
  const subscription = useSubscriptionStore();
  const { subscriptionLoading } = useSubscription();
  const { t } = useTranslation();
  const isRtl = useLangStore((state) => state.isRtl);

  return subscriptionLoading || subscription.subscriptionStatus === SUB_STATUS.NOT_INITIATED ? (
    <BillingLoading />
  ) : subscription.subscriptionStatus === SUB_STATUS.NO_SUBSCRIPTION ||
    userDetails.role === USER_ROLE.ENTERPRISE ? (
    <MainNotFound $homePath={PAGES.SHARED.DEFAULT} />
  ) : (
    <StyledCol $gap={4}>
      <StyledTitle>{t('billing.title.h1')}</StyledTitle>
      <StyledContent fontWeight={400} fontSize={'18px'} textAlign={`${isRtl() ? 'right' : 'left'}`}>
        {t('billing.billing_history.title.content')}
      </StyledContent>

      <BillingSections>
        <StyledCol $gap={20}>
          <StyledRowOffset $gap={20}>
            <StyledCol $gap={20}>
              {subscription.isActive ? <CurrentPackage /> : <NoActivePackages />}
              {!subscription.isFreemium && <PackageDetails />}
            </StyledCol>
            <StyledCol>
              {subscription.isFreemium ? <PackageDetails /> : <PaymentMethod />}
            </StyledCol>
          </StyledRowOffset>
        </StyledCol>
        <BillingHistoy />
      </BillingSections>
    </StyledCol>
  );
};
