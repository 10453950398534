import React, { useEffect } from 'react';
import {
  StyledDeleteButton,
  StyledLoading,
  StyledProfileImg,
  StyledUpload,
  StyledUploadButton,
} from './UploadImage.styles';
import { StyledImg, StyledText } from '@/shared/styles';
import { whiteDeleteIcon, uploadImageIcon } from '@/assets/images/icons';
import { UploadImageButtonProps } from './UploadImage.consts';
import { useUploadImage } from '@/hooks/useUploadImage';
import { beforeUpload } from './UploadImage.utils';
import { Form } from 'antd';
import { useAxios } from '@/services/apiClient';
import { RES_STATUS } from '@/shared/consts';
import { useTranslation } from 'react-i18next';

export const UploadImageButton: React.FC<UploadImageButtonProps> = ({
  setIsAvatarValid,
}: UploadImageButtonProps) => {
  const { loading, imageUrl, handleChange, removeImg } = useUploadImage();
  const { t } = useTranslation();

  const [{ response }, deleteAvatar] = useAxios(
    {
      method: 'delete',
      url: 'vendors-settings/avatars',
    },
    { manual: true }
  );

  const customRequest = ({ onSuccess }: any) => {
    onSuccess();
  };

  useEffect(() => {
    if (response?.status === RES_STATUS.NO_CONTENT) {
      setIsAvatarValid(false);
      removeImg();
    }
  }, [response]);

  const uploadButton = (
    <StyledUploadButton>
      {loading ? <StyledLoading /> : <StyledImg src={uploadImageIcon} $size={24} />}
      <StyledText textAlign="center" fontWeight={400} fontSize={16}>
        {t('vendor.settings.personal_details.update.upload_image')}
      </StyledText>
    </StyledUploadButton>
  );

  const uploadedImg = (
    <>
      <StyledProfileImg src={imageUrl} alt="avatar" />
      <StyledDeleteButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          deleteAvatar();
        }}
        aria-label={t('vendor.update_details.remove_avatar')}
        className="remove-avatar"
      >
        <StyledImg src={whiteDeleteIcon} />
      </StyledDeleteButton>
    </>
  );

  return (
    <Form.Item
      name="upload"
      valuePropName="fileList"
      getValueFromEvent={(e) => {
        setIsAvatarValid(true);
        return e && e.fileList;
      }}
    >
      <StyledUpload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        customRequest={customRequest}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl ? uploadedImg : uploadButton}
      </StyledUpload>
    </Form.Item>
  );
};
