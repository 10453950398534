import { CheckboxGroup } from '@/shared/components/Checkbox/CheckboxGroup';
import { RadioGroup } from '@/shared/components/Radio/Radio';
import { StyledInput } from '@/shared/styles';
import { CheckboxGroupProps } from 'antd/es/checkbox/Group';
import { AdditionalHoursRadioGroup } from './AddionalHoursRadioGroup';
import { blockNanValues, capitalizeStr } from '@/shared/utils';
import { DealStore } from '@/stores/DealStore/DealsStore.consts';
import { t } from 'i18next';

export type AdditionalHoursProps = {
  additionalHours?: number[];
};

export type FrameworksObj = {
  titles: string[];
  icons: string[];
};

export type PackgeSections = {
  title: string;
  text?: string;
  optional?: boolean;
  render: (
    deals?: DealStore,
    frameworks?: FrameworksObj,
    subscriptinTypes?: string[],
    additionalHours?: number[]
  ) => JSX.Element;
};

export const buildPackageSections = (): PackgeSections[] => [
  {
    title: t('vendor.deals.create_deal.select_subscription'),
    render: (deals?: DealStore, _?: FrameworksObj, subscriptinTypes?: string[]) => (
      <RadioGroup
        radioDirection="horizontal"
        value={deals?.subscriptionType ?? 0}
        setValue={(val: number) => deals?.setSubscriptionType(val)}
        radioValues={subscriptinTypes ?? []}
      />
    ),
  },
  {
    title: t('pricing.package.user.multiple'),
    text: t('vendor.deals.create_deal.select_users'),
    render: (deals?: DealStore) => (
      <StyledInput
        placeholder={t('form.placeholder.amount')}
        onKeyDown={(e) => blockNanValues(e)}
        onChange={(e) => deals?.setUsers(e.target.value)}
        maxLength={10}
        value={deals?.users}
      />
    ),
  },
  {
    title: t('pricing.package.framework.multiple'),
    text: t('vendor.deals.create_deal.select_frameworks'),
    render: (deals?: DealStore, frameworks?: FrameworksObj) => (
      <CheckboxGroup
        $isVertical={true}
        onChange={(values: CheckboxGroupProps[]) => {
          const selectedFrameworks = values.map((val) => (val as string).toUpperCase());
          deals?.setFrameworks(selectedFrameworks as CheckboxGroupProps[]);
        }}
        options={frameworks?.titles ?? []}
        icons={frameworks?.icons}
        defaultValues={deals?.frameworks.map((fw) => capitalizeStr(fw as string)) ?? []}
      />
    ),
  },
  {
    title: t('pricing.package.domain.multiple'),
    text: t('vendor.deals.create_deal.select_domains'),
    render: (deals?: DealStore) => (
      <StyledInput
        placeholder={t('form.placeholder.amount')}
        onKeyDown={(e) => blockNanValues(e)}
        onChange={(e) => deals?.setDomains(e.target.value)}
        value={deals?.domains}
        maxLength={10}
      />
    ),
  },
  {
    title: t('billing.package_details.additional_services'),
    text: t('vendor.deals.create_deal.select_additional'),
    optional: true,
    render: (_?: DealStore, __?: FrameworksObj, ___?: string[], additionalHours?: number[]) => (
      <AdditionalHoursRadioGroup additionalHours={additionalHours} />
    ),
  },
];
