import { StyledbulletList, StyledCol, StyledRowOffset, StyledTitle } from '@/shared/styles';
import { StyledFrameworks } from '../../../Checkout/OrderSummary/OrderSummary.styles';
import React from 'react';
import { UpgradePackageProps } from '../../UpgradePackage.consts';
import { FRAMEWORKS } from '@/shared/consts';
import { t } from 'i18next';

export const CurrentPackage: React.FC<UpgradePackageProps> = ({
  currentPlan,
}: UpgradePackageProps) => {
  return (
    <StyledCol>
      <StyledTitle level={3} $size={15}>
        {t('billing.package_details.current')} {currentPlan?.name}{' '}
        {t('billing.package_details.package')}
      </StyledTitle>
      <StyledbulletList>
        <li>
          <StyledRowOffset>
            <span>{t('billing.package_details.users')}</span>
            <span>{currentPlan?.users}</span>
          </StyledRowOffset>
        </li>
        <li>
          <StyledRowOffset>
            <span>{t('billing.package_details.frameworks')}</span>
            <span>{currentPlan?.frameworks.length}</span>
          </StyledRowOffset>
        </li>
        <li>
          <StyledRowOffset>
            <StyledCol>
              <span>{t('billing.package_details.frameworks_selected')}</span>
              {currentPlan?.frameworks.map((fw) => {
                return <StyledFrameworks key={fw}>{FRAMEWORKS[fw]}</StyledFrameworks>;
              })}
            </StyledCol>
            <span>{currentPlan?.frameworks.length}</span>
          </StyledRowOffset>
        </li>
        <li>
          <StyledRowOffset>
            <span>{t('billing.package_details.domains')}</span>
            <span>{currentPlan?.domains}</span>
          </StyledRowOffset>
        </li>
        <li>
          <StyledRowOffset>
            <span>{t('billing.package_details.upgrade.training_and_auditing')}</span>
            <span>{currentPlan?.additionalServices ? t('yes') : t('no')}</span>
          </StyledRowOffset>
        </li>
      </StyledbulletList>
    </StyledCol>
  );
};
