import styled from 'styled-components';

export const StyledFooter = styled.footer`
  background-color: ${(props) => props.theme.cardsBgColor};
  width: 100%;
`;

export const StyledBorder = styled.div`
  background: ${(props) => props.theme.borderColor};
  height: 15%;
`;
