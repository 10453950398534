import { SignInProps } from '@/shared/props';
import React from 'react';
import { isBrowser } from 'react-device-detect';
import { StyledLayout } from './Signin.styles';
import { SignInLeftSection } from './SignInLeftSection';
import { SignInRightSection } from './SignInRightSection';

export const SignIn: React.FC<SignInProps> = ({ isFromInvite }) => {
  return (
    <StyledLayout>
      <SignInLeftSection isFromInvite={isFromInvite} />
      {isBrowser && <SignInRightSection />}
    </StyledLayout>
  );
};
