/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Component } from '../../../../../types';
import { RadioProps } from '@u1/fixers';
import { removeTabindex } from '../utils';

const handleSpecialCases = (props: RadioProps, el: HTMLElement) => {
  const disabledEls = el.querySelectorAll('input, .ant-radio-wrapper>span:not(.ant-radio)');
  disabledEls?.forEach((el: Element) => removeTabindex(el));

  document.querySelectorAll('.ant-radio-wrapper')?.forEach((el: Element) => {
    el.querySelector('span.ant-radio')?.setAttribute(
      'aria-label',
      (el as HTMLElement).innerText.trim()
    );
  });
};

export const radio: Component<RadioProps> = {
  selector: '.ant-radio-wrapper',
  handleSpecialCases,
  props: {
    selectors: {
      radioGroup: '.ant-radio-group',
      radioButton: 'span.ant-radio',
      checkedState: 'span.ant-radio.ant-radio-checked',
      uncheckedState: 'span.ant-radio:not(.ant-radio-checked)',
    },
    dynamic: true,
  },
};
