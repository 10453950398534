import { StyledCol, StyledRow, StyledText, StyledTitle } from '@/shared/styles';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const StyledFooterCol = styled(StyledCol)<{ $maxWidth?: number }>`
  padding: 0px 1rem;
  gap: 24px;
  max-width: ${(props) => props.$maxWidth ?? '205'}px;
`;

export const StyledFooterTitle = styled(StyledTitle)`
  font-weight: 600 !important;
  font-size: 16px !important;
  word-break: normal;
  color: ${(props) => props.theme.sameColorText} !important;
`;

export const StyledFooterContent = styled(StyledText)`
  font-weight: 400 !important;
  font-size: 14px !important;
  color: ${(props) => props.theme.sameColorText} !important;
`;

export const StyledFooterTrigger = styled.button`
  font-weight: 400 !important;
  font-size: 16px !important;
  background: none;
  border: none;
  color: ${(props) => props.theme.textColor};
  text-align: left;
  padding: 0;
`;

export const StyledDivider = styled.hr`
  border-top: 1px solid ${(props) => props.theme.textColor};
  width: 100%;
`;

export const StyledLinksSection = styled(StyledCol)`
  gap: 16px;

  a:hover,
  a:focus {
    text-decoration: underline;
  }

  a:hover,
  button:hover {
    cursor: pointer !important;
  }
`;

export const StyledAdaptiveRow = styled(StyledRow)`
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  gap: ${isMobile ? `60` : `16`}px;
`;
