import { VisibilityProps } from '@/shared/props';
import { StyledCol, StyledRow } from '@/shared/styles';
import styled from 'styled-components';

export const StyledEditIcons = styled(StyledRow)<VisibilityProps>`
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  width: 100%;
  position: absolute;
  justify-content: end;
`;

export const StyledEditText = styled(StyledCol)`
  gap: 12px;
  position: relative;
`;
