import { FcWithChildren } from '@/shared/props';
import { StyledCard } from '@/shared/styles';
import {
  StyledMarginTitle,
  StyledOrderSummary,
} from '@/Views/AfterLogin/Main/Client/Checkout/OrderSummary/OrderSummary.styles';
import { useTranslation } from 'react-i18next';

export const OrderSummary: React.FC<FcWithChildren> = ({ children }: FcWithChildren) => {
  const { t } = useTranslation();
  return (
    <StyledCard $width={'40%'}>
      <StyledOrderSummary data-testid="orderSummaryBlock">
        <StyledMarginTitle level={2} $size={25}>
          {t('billing.package_details.summary.title')}
        </StyledMarginTitle>
        {children}
      </StyledOrderSummary>
    </StyledCard>
  );
};
