import styled from 'styled-components';

export const StyledUserHeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledLicences = styled.div`
  color: ${(props) => props.theme.textColor};
  font-weight: 500;
  align-self: center;
`;

export const StyledActions = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 24px;
`;
