import { Component } from '../../../../../types';
import { PaginationProps } from '@u1/fixers';

export const pagination: Component<PaginationProps> = {
  selector: '.ant-pagination-item',
  props: {
    selectors: {
      container: '#tableResults',
      pageButtons: '.ant-pagination-item a',
      prevBtn: '.ant-pagination-prev',
      nextBtn: '.ant-pagination-next',
      prevSkip: '.ant-pagination-jump-prev',
      nextSkip: '.ant-pagination-jump-next',
      results: '.ant-table-row-level-0',
    },
    skipAmount: 5,
  },
  handleSpecialCases: () => {
    const linkPages = document.querySelectorAll('li.ant-pagination-item');
    linkPages.forEach((link) => link.setAttribute('tabindex', '-1'));
  },
};
