/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { AbstractProps } from '@u1/fixers';
import Utils from './utils';
import { LIBRARIES } from './libraries';
import cloneDeep from 'lodash.clonedeep';
import {
  Component,
  ComponentName,
  Framework,
  Library,
  LibraryData,
  LibraryName,
  RefId,
} from './types';
import { DATA_COMPONENT } from './consts';

export type UiLibraryProps = {
  props: AbstractProps;
  selector: string;
};

const KEYWORD_REGEX = {
  [LibraryName.ANTD]: new RegExp('ant-'),
  [LibraryName.U1]: new RegExp(`data-u1-`),
};

const getLibraryName = (el: HTMLElement): LibraryName | null => {
  const stringEl = el.outerHTML;
  const result = Object.entries(KEYWORD_REGEX).find(([_, regex]) => regex.test(stringEl));
  return result ? result[0] : null;
};

const getComponent = (
  libraryName: LibraryName,
  el: HTMLElement,
  framework: Framework
): [string, Library[ComponentName]] | undefined => {
  const component = Object.entries(LIBRARIES[framework][libraryName]).find(
    ([componentName, _]) => el.getAttribute(DATA_COMPONENT) === componentName
  ) as [string, Component<any>];
  return component;
};

export const getUiLibrary = (el: HTMLElement, framework: Framework): LibraryData | null => {
  const libraryName = getLibraryName(el);

  if (!libraryName) {
    console.log('Library Not Found');
    return null;
  }

  const [componentName, componentData] = getComponent(libraryName, el, framework);

  if (!componentName) {
    console.log('Component Not Found');
    return null;
  }

  const { props, handleSpecialCases, selector } = componentData;

  return {
    libraryName,
    componentName,
    props,
    handleSpecialCases,
    selector,
  };
};

export const getUiLibraryProps = <T extends AbstractProps>(
  el: HTMLElement,
  framework: Framework,
  refId?: RefId
): UiLibraryProps | null => {
  const libraryData = getUiLibrary(el, framework);
  if (!libraryData) return null;

  let props: T = cloneDeep(libraryData.props);
  if (libraryData.libraryName === LibraryName.U1)
    props = Utils.getU1Props(props, el, framework, refId);

  if (libraryData.handleSpecialCases) {
    libraryData.handleSpecialCases(props, el, refId);
  }

  return { props, selector: libraryData.selector };
};
