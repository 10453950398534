import { ColumnsType } from 'antd/es/table';
import { AdminActions } from './AdminActions/AdminActions';
import { AdminDetails } from '@/stores/Stores.consts';
import { t } from 'i18next';

export interface AdminsDataType {
  key: string;
  userName: string;
  email: string;
  actions: string;
}

export const getColumns = (): ColumnsType<AdminsDataType> => [
  {
    title: t('admin.table.name'),
    dataIndex: 'userName',
    key: 'userName',
  },
  {
    title: t('admin.table.email'),
    dataIndex: 'email',
    key: 'email',
  },
  {
    key: 'actions',
    render: (admin: AdminDetails) => {
      return <AdminActions admin={admin} />;
    },
  },
];
