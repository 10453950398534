export type DatepickerDayProps = {
  day: number;
  className: string;
};

export const weekDays = ['Mo', 'Tu', 'We', 'Th', 'Fri', 'Sa', 'Su'];

export const monthsNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const startYear = 2023;

export enum DAY_TYPE {
  PREV_MONTH = -1,
  NEXT_MONTH = 1,
  CURRENT_MONTH = 0,
}
