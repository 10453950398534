import { StyledCol } from '@/shared/styles';
import React from 'react';
import { TopFooter } from './TopFooter';
import { MainFooter } from './MainFooter';
import { FooterProps } from './Footer.consts';

export const MainPageFooter: React.FC<FooterProps> = ({ topPageRef }) => {
  return (
    <StyledCol>
      <TopFooter />
      <MainFooter topPageRef={topPageRef} />
    </StyledCol>
  );
};
