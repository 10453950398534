import { A11Y_DIRECTIVE_SELECTOR } from '../../../../../consts';

const DATEPICKER_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-datepicker`;

export const DIRECTIVE_SELECTORS = {
  DATEPICKER: `${DATEPICKER_ATTRIBUTE}`,
  CONTAINER: `${DATEPICKER_ATTRIBUTE}="modal"`,
  TRIGGER: `${DATEPICKER_ATTRIBUTE}="trigger"`,
  MONTH: {
    TEXT: {
      SELECTOR: `${DATEPICKER_ATTRIBUTE}="month"`,
    },
    PREV_BUTTON: `${DATEPICKER_ATTRIBUTE}="month-prev"`,
    NEXT_BUTTON: `${DATEPICKER_ATTRIBUTE}="month-next"`,
  },
  YEAR: {
    TEXT: {
      SELECTOR: `${DATEPICKER_ATTRIBUTE}="year"`,
    },
    PREV_BUTTON: `${DATEPICKER_ATTRIBUTE}="year-prev"`,
    NEXT_BUTTON: `${DATEPICKER_ATTRIBUTE}="year-next"`,
  },
  DAYS: {
    TABLE: `${DATEPICKER_ATTRIBUTE}="calendar"`,
    DAY: `${DATEPICKER_ATTRIBUTE}="day"`,
    SELECTED: `${DATEPICKER_ATTRIBUTE}="selected-day"`,
    DISABLE: `${DATEPICKER_ATTRIBUTE}="disable"`,
  },
};
