/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { DialogProps, DialogType } from '@u1/fixers';
import { Component, RefId } from '../../../../../types';
import { removeAllAccessibilityAttributes } from '../utils';

const handleSpecialCases = (props: DialogProps, el: HTMLElement) => {
  removeAllAccessibilityAttributes(el);
  const { selectors } = props;
  const trigger = el.querySelector(selectors.trigger as string) as HTMLElement;
  trigger?.addEventListener('click', () => {
    setTimeout(() => {
      const dialogs = document.querySelectorAll(selectors.dialog);
      dialogs?.forEach((dialog: Element) => {
        removeAllAccessibilityAttributes(dialog as HTMLElement);
      });
      trigger.setAttribute('u1st-trigger-clicked', 'true');
    }, 200);
  });
};

export const handleSpecialCasesForContactUs = (
  props: DialogProps,
  el: HTMLElement,
  refId: RefId
) => {
  handleSpecialCases(props, el);
  const uuid = `[id='${el.id}']`;
  const scope = refId ? '' : uuid;
  const refVal = el.querySelector('[data-a11y-ref]')?.getAttribute('data-a11y-ref');
  props.selectors.trigger = `${scope} [data-a11y-dialog='trigger'][data-a11y-ref="${refVal}"]`;
  props.selectors.dialog = `[data-a11y-ref="${refVal}"] .ant-modal`;
};

const restOfProps = {
  closeBtn: `.ant-modal-close`,
  heading: `.ant-modal-header`,
  textContent: `.ant-modal-content`,
  dynamicContent: `.ant-modal-content`,
};

export const dialog: Component<DialogProps> = {
  selector: `[data-a11y-dialog='trigger']:not([data-a11y-ref])`,
  handleSpecialCases,
  props: {
    options: {
      type: DialogType.MODAL,
    },
    selectors: {
      trigger: `[data-a11y-dialog='trigger']:not([data-a11y-ref])`,
      dialog: `.ant-modal`,
      ...restOfProps,
    },
  },
};

export const contactUsDialog: Component<DialogProps> = {
  selector: `[data-a11y-dialog='trigger'][data-a11y-ref^="contact-us"]`,
  handleSpecialCases: handleSpecialCasesForContactUs,
  props: {
    options: {
      type: DialogType.MODAL,
    },
    selectors: {
      trigger: `[data-a11y-dialog='trigger'][data-a11y-ref^="contact-us"]`,
      dialog: `[data-a11y-ref^="contact-us"] .ant-modal`,
      ...restOfProps,
    },
  },
};

export const updatePaymentDialog: Component<DialogProps> = {
  selector: `[data-a11y-dialog='trigger'][data-a11y-ref="update-payment"]`,
  handleSpecialCases,
  props: {
    options: {
      type: DialogType.MODAL,
    },
    selectors: {
      trigger: `[data-a11y-dialog='trigger'][data-a11y-ref="update-payment"]`,
      dialog: `[data-a11y-ref="update-payment"] .ant-modal`,
      ...restOfProps,
    },
  },
};

export const inviteDialog: Component<DialogProps> = {
  selector: `[data-a11y-dialog='trigger'][data-a11y-ref="invite-users"]`,
  handleSpecialCases,
  props: {
    options: {
      type: DialogType.MODAL,
    },
    selectors: {
      trigger: `[data-a11y-dialog='trigger'][data-a11y-ref="invite-users"]`,
      dialog: `[data-a11y-ref="invite-users"] .ant-modal`,
      ...restOfProps,
    },
  },
};

export const renewSubscriptionDialog: Component<DialogProps> = {
  selector: `[data-a11y-dialog='trigger'][data-a11y-ref="renew-subscription"]`,
  handleSpecialCases,
  props: {
    options: {
      type: DialogType.MODAL,
    },
    selectors: {
      trigger: `[data-a11y-dialog='trigger'][data-a11y-ref="renew-subscription"]`,
      dialog: `[data-a11y-ref="renew-subscription"] .ant-modal`,
      ...restOfProps,
    },
  },
};

export const cancelSubscriptionDialog: Component<DialogProps> = {
  selector: `[data-a11y-dialog='trigger'][data-a11y-ref="cancel-subscription"]`,
  handleSpecialCases,
  props: {
    options: {
      type: DialogType.MODAL,
    },
    selectors: {
      trigger: `[data-a11y-dialog='trigger'][data-a11y-ref="cancel-subscription"]`,
      dialog: `[data-a11y-ref="cancel-subscription"] .ant-modal`,
      ...restOfProps,
    },
  },
};

export const mobileMenuDialog: Component<DialogProps> = {
  selector: `[data-a11y-dialog='trigger'][data-a11y-ref="mobile-menu"]`,
  handleSpecialCases,
  props: {
    options: {
      type: DialogType.MODAL,
    },
    selectors: {
      trigger: `[data-a11y-dialog='trigger'][data-a11y-ref="mobile-menu"]`,
      dialog: `[data-a11y-ref="mobile-menu"] .ant-modal`,
      ...restOfProps,
    },
  },
};

export const addUserDialog: Component<DialogProps> = {
  selector: `[data-a11y-dialog='trigger'][data-a11y-ref="add-user"]`,
  handleSpecialCases,
  props: {
    options: {
      type: DialogType.MODAL,
    },
    selectors: {
      trigger: `[data-a11y-dialog='trigger'][data-a11y-ref="add-user"]`,
      dialog: `[data-a11y-ref="add-user"] .ant-modal`,
      focusTo: `.listbox-trigger[u1st-trigger-element]`,
      ...restOfProps,
    },
  },
};

export const editUserDialog: Component<DialogProps> = {
  selector: `[data-a11y-dialog='trigger'][data-a11y-ref="edit-user"]`,
  handleSpecialCases,
  props: {
    options: {
      type: DialogType.MODAL,
    },
    selectors: {
      trigger: `[data-a11y-dialog='trigger'][data-a11y-ref="edit-user"]`,
      dialog: `[data-a11y-ref="edit-user"] .ant-modal`,
      focusTo: `.listbox-trigger[u1st-trigger-element]`,
      ...restOfProps,
    },
  },
};

export const confirmDialog: Component<DialogProps> = {
  selector: `[data-a11y-dialog='trigger'][data-a11y-ref="confirm"]`,
  handleSpecialCases,
  props: {
    options: {
      type: DialogType.MODAL,
    },
    selectors: {
      trigger: `[data-a11y-dialog='trigger'][data-a11y-ref="confirm"]`,
      dialog: `[data-a11y-ref="confirm"] .ant-modal`,
      ...restOfProps,
    },
  },
};
