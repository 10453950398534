export const useSessionStorage = () => {
  const setStorage = (key: string, value: object | string) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  };

  const getStorage = (key: string) => {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  };

  const removeStorage = (key: string) => {
    sessionStorage.removeItem(key);
  };

  return { setStorage, getStorage, removeStorage };
};
