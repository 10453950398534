import { SCOPE_PH } from '../../../../../consts';
import { Component } from '../../../../../types';
import { GridProps } from '@u1/fixers';
import { A11Y_DIRECTIVE_SELECTOR } from '../../../../../consts';

export const GRID_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-grid`;

const DIRECTIVE_SELECTORS = {
  GRID: `${GRID_ATTRIBUTE}="grid"`,
  ROW: `${GRID_ATTRIBUTE}="row"`,
  CELL: `${GRID_ATTRIBUTE}="cell"`,
  COL_HEADER: `${GRID_ATTRIBUTE}="col-header"`,
};

export const grid: Component<GridProps> = {
  selector: `[${GRID_ATTRIBUTE}]`,
  props: {
    selectors: {
      grid: SCOPE_PH,
      row: `[${DIRECTIVE_SELECTORS.ROW}]`,
      cell: `[${DIRECTIVE_SELECTORS.CELL}]`,
      columnheader: `[${DIRECTIVE_SELECTORS.COL_HEADER}]`,
    },
  },
};
