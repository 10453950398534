export const SUPPORT_EMAIL = `support@user1st.com`;
export const PRIVACY_POLICY_TITLE = `User1st `;

export const PRIVACY_POLICY_SUBTITLE = `PRIVACY POLICY `;

export const INTRODUCTION = `User1st Ltd. (a company established in Israel) and/or User1st Inc. (a company established in the US) (“we”, “our”, “us”) are committed to protecting and respecting your privacy.

We created this privacy policy ("Privacy Policy") to set out how we collect, use, disclose, and retain personal data when you access or use our website (the “Website”).
Please review this Privacy Policy carefully so that you understand your rights in relation to your personal data. By accessing or using our Website in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy, and you hereby consent to the collection, use, and disclosure of your Personal Information in accordance with this Privacy Policy.`;

export const COLLECTION_OF_PERSONAL_DATA = `In this Privacy Policy, "personal data" means any information that identifies, relates to, describes, is reasonably capable of being associated with, or can be used to identify an individual and other data linked to personal data.  The personal data we collect about you depends on your interactions with us and are described in more detail below.`;

export const PERSONAL_DATA_YOU_PROVIDED_DIRECTLY_TO_US = `We collect personal data directly from you in a variety of ways, including when you:`;

export const PERSONAL_DATA_YOU_PROVIDED_DIRECTLY_TO_US_LIST = [
  'When you contact User1st (via the numbers and addresses listed on the Contact page of this website or when requesting a product demonstration).',
  'When you link to, like, or comment upon User1st social media pages (i.e., LinkedIn, Facebook, Twitter) (via the social media icons set forth throughout this website).',
  'When you comment on an article or blog post.',
];

export const PERSONAL_DATA_WE_COLLECT_AUTOMATICALLY = `We automatically collect personal data when you access and use our Website. The types of information we collect may include:`;

export const PERSONAL_DATA_WE_COLLECT_AUTOMATICALLY_LIST = [
  'Networking and device information, such as your browser type, IP address, and operating system version, language settings.',
  'Information about your activity on our website, such as your access times, pages viewed, and the routes you access our website.',
  'Basic analytics for user experience improvement (such as button clicked, usage (such as logging info), and crash reporting).',
];

export const PERSONAL_DATA_WE_COLLECT_FROM_OTHER_SOURCES = `If you disclose your name, title, business name, address, phone, fax, personal handle or alias, or email to User1st through one of the mechanisms set forth above, please know that any non-public information goes directly to User1st personnel and is not provided to any third party. Such information is used solely for the purpose of furthering any potential business relationships between you and User1st.`;

export const PERSONAL_DATA_WE_DERIVE = `We may derive information or draw inferences about you based on the other types of personal data we collect. For example, we may infer your location based on your IP address.`;

export const USE_OF_PERSONAL_DATA = `We collect and use personal data for business and commercial purposes, including to:`;

export const USE_OF_PERSONAL_DATA_LIST = [
  'Communicate with you.',
  'Administer and provide you with support as you requested.',
  'Enforce our terms and conditions.',
  'Provide a mailing list to facilitate a forum to discuss our services.',
  'If you have opted into marketing, we communicate with you about products, services, promotions, events, and other news and information we think will be of interest to you.',
];

export const DISCLOSURE_OF_PERSONAL_DATA = `We do not sell, rent or lease your personal information to others except as described in this Privacy Policy. We share your information with selected recipients. These categories of recipients include:`;

export const DISCLOSURE_OF_PERSONAL_DATA_LIST = [
  'Cloud storage is in Europe and/or the USA to store the personal data you provide. For disaster recovery services and for the performance of any contract we enter with you.',
  'Provided you have consented, advertisers and advertising networks that require the data to select and serve relevant adverts to you and others.',
  'Analytics and search engine providers that assist us in improving and optimizing the website.',
];

export const DISCLOSURE_OF_PERSONAL_DATA2 =
  'We may share your information with law enforcement agencies, public authorities or other organizations if legally required to do so or if we have a good faith belief that such use is reasonably necessary to:';

export const DISCLOSURE_OF_PERSONAL_DATA_LIST2 = [
  'Comply with a legal obligation, process, or request.',
  'Enforce our terms and conditions and other agreements, including investigation of any potential violation thereof.',
  'Detect, prevent, or otherwise address security, fraud, or technical issues; or',
  'Protect the rights, property, or safety of us, our users, a third party, or the public as required or permitted by law (exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction).',
  'If we or substantially all our assets are acquired by a third party, in which case information held by us about our users will be one of the transferred assets.',
];

export const LEGAL_BASIS = `We will only collect, use and share your Personal Information where we are satisfied that we have an appropriate legal basis to do this. This may be because:`;

export const LEGAL_BASIS_LIST = [
  'You have provided your consent to us using the Personal Information (e.g. where you provide us with marketing consents or opt-in to optional additional services or functionality);',
  'Our use of your Personal Information is necessary to perform a contract or take steps to enter into a contract with you (e.g. providing you with our Products and services, creating your user account);',
  'Our use of your Personal Information is in our legitimate interest as a commercial organization to market and support our products and services, subject to your interests and fundamental rights. In these cases, we will always look after your information in a way that is proportionate and respects your privacy rights, and you have a right to object to the processing as explained in the “Your Rights” section below.',
  'our use of your Personal Information is necessary to comply with a relevant legal or regulatory obligation that we have (e.g. the disclosure of information to law enforcement or tax authorities).',
];

export const MARKETING_COMMUNICATIONS = `To the extent permitted by applicable laws, we may send you and/or your organization periodic marketing messages, including newsletters, promotions, and special offers. We will use your name, postal address, telephone number, fax number, and email address to send these messages to you and/or your organization. You can opt-out of personally receiving such messages without any negative effect on your relationship with us by following the unsubscribe instructions in each marketing email. However, please note that opting out may prevent you and/or your organization from receiving email messages regarding updates, improvements, or special offers. We also reserve the right to contact you when we believe it necessary, including where required by law.`;

export const COOKIES = `Like many websites, we use “cookies” to analyze visits to our website and help us improve our Website and services. Most web browsers are set to accept cookies by default. You can usually set your browser to remove or reject cookies if you prefer. Please follow your browser’s process for doing so. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our Website.

We use web analytics services such as those provided by Google (Google Privacy Policy), which both uses JavaScript and cookies to help us analyze how visitors use the Website. These services may use cookies and other technologies (such as pixel tags) to collect information, including personal information, for analytics. We will also share with them some of the information we collected from you that is required for analytic purposes. You can opt-out of Google Analytics without affecting how you use our site – for more information on being tracked by Google Analytics across all websites you use, visit this page: https://tools.google.com/dlpage/gaoptout. `;

export const DATA_SECURITY_TRANSFERS_AND_RETENTION_TITLE = `DATA SECURITY, TRANSFERS, AND RETENTION`;

export const DATA_SECURITY = `We use third-party vendors and hosting partners to provide the software, networking, storage, and related technology required to maintain our website and communicate with you. We use commercially reasonable physical and technical safeguards to secure your data and protect it against unauthorized access, alteration, or destruction. We encrypt highly confidential information communication by using end-to-end encryption methods. However, we cannot guarantee the security of your data, which may be compromised by unauthorized entry or use, hardware or software failure, and other factors.`;

export const DATA_TRANSFERS = `Since we operate globally, it may be necessary to transfer your Personal Information to countries outside the European Union. In such cases, we will transfer your data only to countries approved by the European Commission as providing an adequate level of data protection or enter into legal agreements ensuring an adequate level of data protection.`;

export const RETENTION_OF_PERSONAL_DATA = `We retain personal data in accordance with applicable law.  Unless otherwise required by applicable law, we take reasonable steps to destroy or permanently de-identify the personal data it holds if such personal data is no longer needed for the purpose for which it was collected.`;

export const CHILDRENS_PRIVACY = `We do not use our services to knowingly solicit data from or market to children under age 13 and children under 18 without the parent or guardian's permission. If a parent or guardian becomes aware that their child has provided us with information without their consent, they should contact us by email. We will delete such information from our files within a reasonable time.`;

export const YOUR_RIGHTS = `You have certain rights with respect to how we treat your personal data, as described below.`;

export const CORRECTION_BEFORE_LINK = `You may review and modify your profile information by contacting us by . And request that we update your profile information.  `;

export const CORRECTION_AFTER_LINK = ` And request that we update your profile information.`;

export const DATA_ACCESS = `In some jurisdictions, applicable law may entitle you to request copies of your personal information held by us. You may also be entitled to request copies of personal information that you have provided to us in a structured, commonly used, and machine-readable format by sending us a request via email, at `;

export const DATA_PORTABILITY = `You may request a copy of your personal data in an electronic format that you can take to other service providers. We will respond to your request within 30 days by providing a link to a location where the data can be downloaded.`;

export const OBJECTION_TO_MARKETING = `At any time, you have the right to object to our processing of data about you in order to send you marketing materials, including where we build profiles for such purposes, and we will stop processing the data for that purpose.`;

export const OPT_OUT_RIGHT = `You can stop all collection of information by the Website easily by stop surfing our Website.`;

export const TERMS_OF_USE_UNTIL_LINK = `Please also visit our applicable `;

export const TERMS_OF_USE_LINK = `Terms of Use,`;

export const TERMS_OF_USE_AFTER_LINK = ` which govern our website's use.`;

export const COMPLAINTS_UNTIL_FIRST_LINK = `If you wish to complain about how we process your personal data, please get in touch with us in the first instance by `;

export const COMPLAINTS_AFTER_FIRST_LINK = ` and we will endeavor to deal with your request as soon as possible. This is without prejudice to your right to launch a claim with the data protection supervisory authority in the EU country in which you live or work where you think we have infringed data protection laws.

To exercise any of these rights above, don't hesitate to get in touch with us at`;
export const COMPLAINTS_AFTER_SECOND_LINK = ` In addition, you have the right to complain to any applicable data protection supervisory authority.

Please note that these rights are limited, for example, where fulfilling your request would adversely affect other individuals or our trade secrets or intellectual property, where there are overriding public interest reasons, or where we are required by law to retain your personal data.
`;

export const CHANGES = `We reserve the right to change this Privacy Policy from time to time. Any changes we will make to this policy in the future will be posted on this page. Please check back frequently to see any updates or changes to this policy.`;

export const CONTACT_US = `Questions, comments, and requests regarding this policy are welcomed and should be addressed by
`;

export const LAST_UPDATED = `Last updated: January 1st, 2023`;
