import { USER_ROLE, USER_TYPE } from '@/shared/consts';
import { create } from 'zustand';
import { UserStore } from './Stores.consts';
import { UserDto } from '@/shared/dto';
import { DefaultAvatar } from '@/assets/images/icons';

export const useUserStore = create<UserStore>((set) => ({
  type: USER_TYPE.CLIENT,
  id: '',
  firstName: '',
  fullName: '',
  phone: '',
  email: '',
  avatarUrl: DefaultAvatar,
  accountType: '',
  country: '',
  companyName: '',
  role: USER_ROLE.USER,
  setUser: (userData: UserDto) => {
    const userDetails = userData.user;
    const avatarUrl = userDetails.avatarUrl ? { avatarUrl: userDetails.avatarUrl } : {};
    const user = {
      ...userDetails,
      ...avatarUrl,
      type: userData.type,
      accountType: userDetails.type?.toLowerCase() ?? '',
    };
    set(() => ({
      ...user,
    }));
  },
  setAvatar: (avatarUrl: string) => {
    set(() => ({
      avatarUrl,
    }));
  },
}));
