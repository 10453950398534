import { AbstractProps, MenuPropsV2 } from '@u1/fixers';
import { Component } from '../../../../../types';
import { removeTabindex } from '../utils';

const handleSpecialCases = (props: AbstractProps, el: HTMLElement) => {
  const menu = el.querySelector(props.selectors.menu);
  const CURRENT_ATTR = 'aria-current';
  removeTabindex(menu);

  setTimeout(() => {
    const selected = el.querySelector('.ant-menu-item-selected');
    selected?.setAttribute(CURRENT_ATTR, 'true');
    const items = el.querySelectorAll(props.selectors.items);
    items?.forEach((item) => {
      item.addEventListener('click', (e: Event) => {
        const lastCurr = el.querySelector(`[${CURRENT_ATTR}]`);
        lastCurr?.removeAttribute(CURRENT_ATTR);
        (e.target as HTMLElement).setAttribute(CURRENT_ATTR, 'true');
      });
    });
  }, 2000);
};

export const sideMenu: Component<MenuPropsV2> = {
  selector: '.ant-menu-item-selected',
  handleSpecialCases,
  props: {
    selectors: {
      menu: '#side-menu',
      items: '.ant-menu-item',
    },
  },
};
