import { create } from 'zustand';
import { PaymentStore, Payment_Status } from './Stores.consts';

export const usePaymentStore = create<PaymentStore>((set) => ({
  clientSecret: '',
  setClientSecret: (clientSecret: string) => set((state) => ({ ...state, clientSecret })),
  removeClientSecret: () => set((state) => ({ ...state, clientSecret: '' })),
  setPaymentStatus: (paymentStatus: Payment_Status) =>
    set((state) => ({ ...state, paymentStatus })),
}));
