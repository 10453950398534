import { getThemeCookie, setThemeCookie } from '@/services/themeService';
import { create } from 'zustand';
import { ThemeState } from './Stores.consts';

export const useThemeStore = create<ThemeState>((set, get) => ({
  isDarkMode: getThemeCookie()
    ? getThemeCookie() === 'dark_mode'
    : !getThemeCookie() && (setThemeCookie('dark_mode'), true),
  toggleTheme: () => {
    const currentTheme = get().isDarkMode;
    const newTheme = !currentTheme;
    setThemeCookie(newTheme ? 'dark_mode' : 'light_mode');
    set((state) => ({
      ...state,
      isDarkMode: newTheme,
    }));
  },
  getTheme: () => (get().isDarkMode ? 'dark_mode' : 'light_mode'),
}));
