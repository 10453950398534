import { useState } from 'react';
import { LineChartProps } from './LineChart.consts';
import { useTheme } from 'styled-components';

export const useLineChart = ({ data }: LineChartProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const theme = useTheme();

  const series = [
    {
      data: data.map((item) => item.y),
      name: '',
    },
  ];

  const options = {
    chart: {
      id: 'line-chart',
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      x: {
        show: false,
      },
      y: {
        formatter: (value: string) => `${value} - Active`,
      },
      marker: {
        show: false,
      },
    },
    xaxis: {
      categories: data.map((item) => item.x),
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          colors: theme.secondaryTextColor,
          fontSize: '16px',
          fontFamily: 'Poppins',
          fontWeight: 400,
        },
      },
      crosshairs: {
        width: 99,
        stroke: {
          color: theme.marginsBorder,
          width: 1,
          dashArray: '5 5',
        },
        fill: {
          color: theme.graphCrosshair,
          opacity: 0.5,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 20,
      tickAmount: 5,
      labels: {
        show: true,
        style: {
          colors: theme.secondaryTextColor,
          fontSize: '16px',
          fontFamily: 'Poppins',
          fontWeight: 400,
        },
      },
    },
    grid: {
      show: true,
      borderColor: theme.marginsBorder,
      strokeDashArray: 8,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        left: 32,
      },
    },
    stroke: {
      width: 2,
      curve: 'smooth',
      colors: [isHovered ? theme.marginsBorder : theme.graphStrokeEndColor],
      dashArray: isHovered ? 8 : 0,
    },
    fill: {
      type: isHovered ? 'solid' : 'gradient',
      gradient: {
        gradientToColors: [theme.graphStrokeStartColor],
      },
    },
    markers: {
      size: isHovered ? 0 : 6,
      colors: [theme.graphMarkerColor],
      strokeColors: theme.marginsColor,
      strokeWidth: 4,
      hover: {
        size: 8,
      },
    },
    title: {
      align: 'left',
      style: {
        fontSize: '16px',
        fontWeight: '400',
        color: theme.textColor,
      },
    },
  };

  return {
    series,
    options,
    setIsHovered,
  };
};
