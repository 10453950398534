import { DatepickerDayProps } from '../Datepicker.consts';
import { StyledTd } from '../Datepicker.styles';
import { useDatepickerStore } from '@/stores/DatepickerStore/DatepickerStore';
import { getDayType, isDayDisabled } from '../Datepicker.utils';

export const DatepickerDay: React.FC<DatepickerDayProps> = ({
  day,
  className,
}: DatepickerDayProps) => {
  const {
    startDay,
    endDay,
    setStartDay,
    setEndDay,
    isCurrentDay,
    isSelectedDay,
    isInRange,
    changeMonth,
    isEndDate,
  } = useDatepickerStore();
  const isDisabled = isDayDisabled(className);
  const dayType = getDayType(className);

  const handleSelection = () => {
    const isEndDay = isEndDate(day, dayType);
    if (isDisabled) {
      changeMonth(dayType);
    }
    if (startDay && endDay) {
      setStartDay(day);
      setEndDay();
    } else if (!startDay) {
      setStartDay(day);
    } else if (isEndDay) {
      setEndDay(day);
    } else {
      setEndDay(startDay.day);
      setStartDay(day);
    }
  };

  const getClassName = () => {
    const allClasses = `day ${className}${
      isSelectedDay(dayType, day)
        ? ' start-day'
        : isInRange(day, dayType)
        ? ' selected-day'
        : isSelectedDay(dayType, day, true)
        ? ' end-day'
        : ''
    }`;
    if (isDisabled) {
      return allClasses;
    }
    return `${allClasses}${isCurrentDay(day) ? ' current-day' : ''}`;
  };

  return (
    <StyledTd onClick={handleSelection} className={getClassName()}>
      {day}
    </StyledTd>
  );
};
