import { StyledRow } from '@/shared/styles';
import { GradientBorderButton } from '../../Button/GradientBorderButton';
import { U1Button } from '../../Button/U1Button';
import { useDatepickerStore } from '@/stores/DatepickerStore/DatepickerStore';
import { useStatsStore } from '@/stores/StatsStore.tsx/StatsStore';

export const DatepickerFooter: React.FC = () => {
  const { clearDates, setIsOpen, getSelectedDates } = useDatepickerStore();
  const { getStatsByDate } = useStatsStore();

  const closeDatepicker = () => {
    clearDates();
    setIsOpen(false);
  };

  const selectDates = () => {
    const dates = getSelectedDates();
    getStatsByDate(dates.startDate, dates.endDate);
    closeDatepicker();
  };

  return (
    <StyledRow $width="100%" $gap={24}>
      <GradientBorderButton
        onClick={closeDatepicker}
        text="Cancel"
        $width="50%"
        $height="50px"
        $padding="0px"
      />
      <U1Button text="Select" $width="50%" onClick={selectDates} />
    </StyledRow>
  );
};
