import React from 'react';
import * as TERMS from './TermsAndConditions.consts';
import { TermsAndConditionsDictionary } from './TermsAndConditionsDictionary';
import { TitleAndContentItem } from '../TitleAndContentItem';
import { StyledStaticPageContainer } from '../StaticPage.styles';

export const TermsAndConditions: React.FC = () => {
  return (
    <StyledStaticPageContainer>
      <TitleAndContentItem title={TERMS.TERMS_AND_CONDITIONS_TITLE} />
      {TermsAndConditionsDictionary &&
        TermsAndConditionsDictionary.map((item) => (
          <TitleAndContentItem
            key={item.title}
            title={item.title}
            content={item.content}
            titleLevel={2}
            $flex="start"
          >
            {item.children}
          </TitleAndContentItem>
        ))}
    </StyledStaticPageContainer>
  );
};
