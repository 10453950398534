import { useState } from 'react';
import { PasswordError, getPwdErrors } from '../../Forms.consts';

export const usePassword = () => {
  const [pwd, setPwd] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {
      const val = e.target.value;
      setPwd(val);
    }, 500);
  };

  const isPasswordValid = (passwords: any) => {
    const isValid = !getPwdErrors()?.find(
      (err: PasswordError) => !err.regex.test(passwords.create)
    );
    const isMatching = passwords.create === passwords.repeat;
    return isValid && isMatching;
  };

  return { pwd, handleInputChange, isPasswordValid };
};
