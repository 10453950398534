import React, { useState } from 'react';
import { logOutIcon, mobileMenuIcon } from '@/assets/images/icons';
import { IconButton } from '@/shared/components/Button/IconButton';
import { StyledCol, StyledTitle } from '@/shared/styles';
import { getMobileMenuItems } from './MobileMenu.utils';
import {
  StyledMenuList,
  StyledMobileMenu,
  StyledTryNowMobile,
  StyleMobileMenu,
} from './MobileMenu.styles';
import { U1Button } from '../../Button/U1Button';
import { PAGES } from '@/shared/consts';
import { Link } from 'react-router-dom';
import { useAuthStore } from '@/stores/AuthStore';
import { useAuth } from '@/hooks/useAuth';
import { UserDetails } from '@/Views/AfterLogin/Header/UserDropdown/UserDetails';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { SUB_STATUS } from '@/stores/Stores.consts';
import { U1Dialog } from '@u1/react-a11y-hooks';
import { ComponentName } from '@u1/ui-library-mapper';
import { useThemeStore } from '@/stores/ThemeStore';
import { useTranslation } from 'react-i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';
import { useMenuItems } from '@/hooks/MenuItems/useMenuItems';
import { LangSwitch } from '@/Views/BeforeLogin/Header/Lang/LangSwitch';

export const MobileMenu: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { navigate } = useU1Navigation();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn());
  const subscription = useSubscriptionStore();
  const { logout } = useAuth();
  const isDarkMode = useThemeStore((state) => state.isDarkMode);
  const { t } = useTranslation();
  const { getMainMenuItems } = useMenuItems();

  const showModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <U1Dialog name={ComponentName.MOBILE_MENU_DIALOG}>
      <IconButton
        $icon={mobileMenuIcon}
        onClick={showModal}
        $ariaLabel={t('site_navigation')}
        triggerRef="mobile-menu"
        $invert={!isDarkMode}
      />
      <StyledMobileMenu
        width={550}
        open={open}
        onCancel={closeModal}
        footer={[]}
        data-a11y-ref="mobile-menu"
      >
        <StyledCol $gap={32}>
          <StyleMobileMenu>
            <LangSwitch />

            {isLoggedIn && <UserDetails />}
            <StyledTitle level={2} $size={16} $padding="12px">
              {t('menu')}
            </StyledTitle>
            <StyledMenuList>
              {isLoggedIn ? (
                <li>
                  <IconButton
                    $icon={logOutIcon}
                    onClick={logout}
                    text={t('header.signed_in.log_out')}
                    $padding="0px"
                  />
                </li>
              ) : (
                <>
                  {getMobileMenuItems(getMainMenuItems())}
                  <li>
                    <Link to={PAGES.BEFORE_LOGIN.SIGN_IN}>{t('sign_in.title')}</Link>
                  </li>
                </>
              )}
            </StyledMenuList>
          </StyleMobileMenu>
          {(subscription.subscriptionStatus === SUB_STATUS.NOT_INITIATED ||
            subscription.isFreemium) && (
            <StyledTryNowMobile>
              <div>{t('mobile.get_full_access')}</div>
              <U1Button
                text={t('header.try_now')}
                onClick={() => {
                  navigate(PAGES.BEFORE_LOGIN.PRICING);
                  closeModal();
                }}
              />
            </StyledTryNowMobile>
          )}
        </StyledCol>
      </StyledMobileMenu>
    </U1Dialog>
  );
};
