import { StyledLangSwitch } from './LangSwitch.styles';
import { SimpleSelect } from '@/shared/components/SimpleSelect/SimpleSelect';
import { useLangStore } from '@/stores/LangStore/LangStore';
import { useLocation } from 'react-router-dom';
import { GlobalOutlined } from '@ant-design/icons';
import i18n from '@/services/localization';
import { useU1Navigation } from '@/hooks/useU1Navigation';
import { useEffect } from 'react';
import { defaultLanguage } from '@/stores/LangStore/LangStore.consts';

export const LangSwitch: React.FC = () => {
  const { availableLangs, getLanguageCode, isLanguageSupported } = useLangStore();
  const location = useLocation();
  const { navigate, removeLanguageFromPath } = useU1Navigation();

  const changeLanguage = (id: string) => {
    const currentLang = i18n.language;
    const selectedLang = getLanguageCode(id);

    if (selectedLang === currentLang) {
      return;
    }

    navigate(removeLanguageFromPath(location.pathname), selectedLang);
    window.location.reload();
  };

  useEffect(() => {
    if (!isLanguageSupported(i18n.language)) {
      // If language is not suppported redirect to default one
      const path = removeLanguageFromPath(location.pathname);
      i18n.changeLanguage(defaultLanguage);
      navigate(path, defaultLanguage);
    }
  }, []);

  return (
    <StyledLangSwitch>
      <GlobalOutlined />
      <SimpleSelect
        label="select language"
        value={i18n.language.toUpperCase()}
        options={availableLangs}
        onSelect={(value: string) => changeLanguage(value)}
        showIcon={false}
      />
    </StyledLangSwitch>
  );
};
