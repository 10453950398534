import { U1Button } from '@/shared/components/Button/U1Button';
import { StyledCol, StyledImg, StyledOrangeGradTitle, StyledText } from '@/shared/styles';
import { failedImg } from './Images';
import { usePaymentStore } from '@/stores/PaymentStore';
import { Payment_Status } from '@/stores/Stores.consts';
import { useTranslation } from 'react-i18next';

export const FailedPayment: React.FC = () => {
  const paymentStore = usePaymentStore();
  const { t } = useTranslation();

  const goBackToPayment = () => {
    paymentStore.setPaymentStatus(Payment_Status.NOT_PAYED);
  };

  return (
    <StyledCol $align="center">
      <StyledCol $maxWidth="375px" $gap={36}>
        <StyledImg src={failedImg} width={300} />
        <StyledCol $gap={12} $align="center">
          <StyledOrangeGradTitle>
            {t('vendor.deals.create_deal.payment.failed_payment')}
          </StyledOrangeGradTitle>
          <StyledText textAlign="center" fontWeight={400} fontSize="18px">
            {t('vendor.deals.create_deal.payment.failed_payment.content')}
          </StyledText>
        </StyledCol>
        <U1Button text="Try Again" onClick={goBackToPayment} />
      </StyledCol>
    </StyledCol>
  );
};
