import { VisaIcon } from '@/assets/images/icons';

export const CreditCardDictionary: Record<string, string> = {
  visa: VisaIcon,
};

export type PaymentDetails = {
  paymentAmount: number;
  nextPayment: string;
};

export enum CREDIT_CARD {
  VISA = 'Visa',
}

export type PackageDetailsProps = {
  subscriptionType: string;
  expiration: string;
  frameworks: string[];
  additionalServices: boolean;
  domains: number;
};

export enum INVOICE_STATUS {
  DRAFT = 'DRAFT',
  PROCEEDED = 'PROCEEDED',
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  REFUNDED = 'REFUNDED',
}

export const invoiceStatusName: Record<string, string> = {
  DRAFT: 'Draft',
  PROCEEDED: 'Proceeded',
  IN_PROGRESS: 'In Progress',
  CANCELED: 'Canceled',
  FAILED: 'Failed',
  REFUNDED: 'Refunded',
};
