import { StyledCol, StyledTitle } from '@/shared/styles';
import styled from 'styled-components';

export const StyledFeatureText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.sameColorText};
`;

export const StyledFeatureCard = styled(StyledCol)`
  gap: 16px;
  background: ${(props) => props.theme.sameColorBG};
  border: 1px solid ${(props) => props.theme.sameColorBorder};
  border-radius: 15px;
  padding: 24px;
  min-height: 254px;
`;

export const StyledFeatureTitle = styled(StyledTitle)`
  font-size: 16px;
  color: ${(props) => props.theme.sameColorText} !important;
`;
