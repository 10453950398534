import { Icon } from '@/shared/components/Icon';
import { StyledOrangeGradTitle } from '@/shared/styles';
import React from 'react';
import { isMobile } from 'react-device-detect';
import {
  StyledFrameWroksIconsRow,
  StyledMainSectionsText,
  StyledSectionContainer,
  StyledTopDescription,
} from '../../MainPage.styles';
import { supportedFrameworks } from './FrameworkSupport.consts';
import { useTranslation } from 'react-i18next';

export const FrameworkSupport: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledSectionContainer>
      <StyledTopDescription>
        <StyledOrangeGradTitle level={2}>
          {t('homepage.framework_support.title')}
        </StyledOrangeGradTitle>
        <StyledMainSectionsText>{t('homepage.framework_support.content')}</StyledMainSectionsText>
      </StyledTopDescription>

      <StyledFrameWroksIconsRow>
        {supportedFrameworks.map((fw) => (
          <Icon
            key={fw.alt}
            $alt={fw.alt}
            $src={fw.src}
            $size={isMobile ? 50 : 95}
            $loading="lazy"
          />
        ))}
      </StyledFrameWroksIconsRow>
    </StyledSectionContainer>
  );
};
