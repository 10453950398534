import { StyledCol, StyledRow, StyledTitle } from '@/shared/styles';
import { PersonalDetails } from './PersonalDetails';
import { MfaSettings } from './MfaSettings';
import { VendorPassword } from './VendorPassword';
import { useTranslation } from 'react-i18next';

export const SettingsMain: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledCol $gap={24}>
      <StyledTitle>{t('vendor.settings')}</StyledTitle>
      <StyledRow $gap={24}>
        <StyledCol $gap={24}>
          <PersonalDetails />
          <MfaSettings />
        </StyledCol>
        <StyledCol>
          <VendorPassword />
        </StyledCol>
      </StyledRow>
    </StyledCol>
  );
};
