import { ChartItem } from '@/shared/components/Charts/DonutChart/DonutChart.consts';
import { AxisProps } from '@/shared/components/Charts/LineChart/LineChart.consts';
import { DealProgressDto, FrameworkStatsDto, TopCountriesDto } from '@/shared/dto';
import countryList from 'react-select-country-list';
import { DateType, datesDictionary } from './StatsStore.consts';

export const getFormattedDate = (currentDate: Date) => {
  return currentDate.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  });
};

export const getDatesByFilter = (filterIndex: number): { start: string; end: string } | null => {
  const currentDate = new Date();
  const exactTime = [currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes()];
  const endDate = getFormattedDate(currentDate);
  switch (filterIndex) {
    case 0:
      return {
        start: getFormattedDate(new Date(currentDate.getTime() - 24 * 60 * 60 * 1000)),
        end: endDate,
      };
    case 1:
      return {
        start: getFormattedDate(
          new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, ...exactTime)
        ),
        end: endDate,
      };
    case 2:
      return {
        start: getFormattedDate(
          new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, ...exactTime)
        ),
        end: endDate,
      };
    case 3:
      return {
        start: getFormattedDate(
          new Date(currentDate.getFullYear() - 1, currentDate.getMonth() + 1, 1)
        ),
        end: endDate,
      };
    default:
      return null;
  }
};

export const calcTotalCount = (data: TopCountriesDto[]) => {
  return data.reduce(
    (acc: number, item: TopCountriesDto | FrameworkStatsDto) => acc + item.count,
    0
  );
};

export const mapTopCountries = (data: TopCountriesDto[], totalItems: number): ChartItem[] => {
  return data.map((item: TopCountriesDto, i: number) => {
    const name = countryList().getLabel(item.country);
    return {
      name,
      key: i + 1,
      amount: item.count,
      value: (item.count / totalItems) * 100,
    };
  });
};

const getGraphDate = (date: string): { day: string; month: string; year: string } => {
  const splittedDate = date.split('-');
  const year = splittedDate[0].substring(splittedDate[0].length - 2);
  const month = datesDictionary[splittedDate[1]];
  const day = splittedDate[2];
  return { day, month, year };
};

const mapDates = (data: DealProgressDto[], dateType: DateType): AxisProps[] => {
  const datesMap = new Map<string, number>();

  data.forEach((data) => {
    const mapKey = dateType === DateType.YEAR ? data.date.split('-')[0] : data.date.slice(0, 7);
    const count = datesMap.get(mapKey) ?? 0;
    datesMap.set(mapKey, count + data.count);
  });

  return Array.from(datesMap.entries()).map(([key, value]) => {
    if (dateType === DateType.MONTH) {
      const { month, year } = getGraphDate(key);
      key = `${month} ${year}`;
    }
    return {
      x: key,
      y: value,
    };
  });
};

const mapDays = (data: DealProgressDto[]): AxisProps[] => {
  return data.map((item: DealProgressDto) => {
    const { day, month, year } = getGraphDate(item.date);
    return {
      x: `${day} ${month} ${year}`,
      y: item.count,
    };
  });
};

export const mapDealsProgress = (data: DealProgressDto[]): AxisProps[] => {
  if (data.length > 12) {
    const monthsList = mapDates(data, DateType.MONTH);
    if (monthsList.length > 12) {
      return mapDates(data, DateType.YEAR);
    }
    return monthsList;
  }
  return mapDays(data);
};
