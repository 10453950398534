import React, { useState } from 'react';
import { UserActions } from '../../../../SearchPanel/Actions/UserActions';
import { AdminActionsDropdown } from './AdminActionsDropdown';
import { AdminActionsProps } from '@/Views/AfterLogin/Main/SearchPanel/Actions/UserActions.consts';

export const AdminActions: React.FC<AdminActionsProps> = ({ admin }: AdminActionsProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <UserActions
      actionsDropdown={<AdminActionsDropdown setIsOpen={setIsOpen} admin={admin} />}
      name={admin.userName}
      dataTestId="manageUserButton"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};
