﻿import { A11Y_DIRECTIVE_SELECTOR } from '../../../../../consts';

export const TABS_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-tabs`;

export const SELECTORS = {
  TABS: `${TABS_ATTRIBUTE}="tabs"`,
  TAB: `${TABS_ATTRIBUTE}="tab"`,
  TAB_PANEL: `${TABS_ATTRIBUTE}="tab-panel"`,
  TAB_LIST: `${TABS_ATTRIBUTE}="tab-list"`,
  TAB_DESCRIPTOR: `${TABS_ATTRIBUTE}="tab-descriptor"`,
};
