import { SelectionProps } from '@/shared/props';
import { StyledCard, StyledCol } from '@/shared/styles';
import styled from 'styled-components';

export const StyledDocsCard = styled(StyledCard)<SelectionProps>`
  position: relative;
  width: 200px;
  height: 200px;
  padding: 20px;
  gap: 24px;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.inputBorder};
`;

export const StyledDocsBtnsGroup = styled(StyledCol)`
  position: absolute;
  gap: 12px;
  padding: 20px;
  background: ${(props) => props.theme.darkShadow};
  height: 100%;
  justify-content: center;
  border-radius: 5px;
`;

export const StyledDocsBtn = styled.a`
  border: 1px solid ${(props) => props.theme.secondaryTextColor};
  border-radius: 5px;
  background: ${(props) => props.theme.darkShadow};
  color: ${(props) => props.theme.secondaryTextColor};
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;

  &:hover {
    color: ${(props) => props.theme.textColor};
    border-color: ${(props) => props.theme.textColor};
  }
`;
