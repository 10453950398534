import { Component, RefId } from '../../../../../types';
import { CarouselProps } from '@u1/fixers';
import { A11Y_DIRECTIVE_SELECTOR, A11Y_REF_SELECTOR } from '../../../../../consts';

const CAROUSEL_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-carousel`;

export const carousel: Component<CarouselProps> = {
  selector: `[${CAROUSEL_ATTRIBUTE}]`,
  props: {
    selectors: {
      carouselContainer: `[${CAROUSEL_ATTRIBUTE}='container']`,
      absoluteCarouselContainerLabel: `[${CAROUSEL_ATTRIBUTE}='label']`,
      slide: `[${CAROUSEL_ATTRIBUTE}='slide']`,
      prevButton: `[${CAROUSEL_ATTRIBUTE}='prev']`,
      nextButton: `[${CAROUSEL_ATTRIBUTE}='next']`,
      slidePickerButtons: `[${CAROUSEL_ATTRIBUTE}='picker']`,
    },
  },
  handleSpecialCases: (props: CarouselProps, el: HTMLElement, refId: RefId) => {
    const { selectors } = props;

    const uuid = `[id='${el.id}']`;
    const scope = refId ? '' : uuid;
    const suffix = refId ? `[${A11Y_REF_SELECTOR}='${refId}']` : '';

    selectors.carouselContainer = `${uuid}`;
    selectors.absoluteCarouselContainerLabel = `${scope} ${selectors.absoluteCarouselContainerLabel}${suffix}`;
  },
};
