import { UserActions } from '@/Views/AfterLogin/Main/SearchPanel/Actions/UserActions';
import React, { useState } from 'react';
import { DealsActionsProps } from '@/Views/AfterLogin/Main/SearchPanel/Actions/UserActions.consts';
import { DealsActionsDropdown } from './DealsActionsDropdown';
import { useTranslation } from 'react-i18next';

export const DealsActions: React.FC<DealsActionsProps> = ({ deal }: DealsActionsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <UserActions
      actionsDropdown={<DealsActionsDropdown setIsOpen={setIsOpen} deal={deal} />}
      name={`${t('vendor.deals.create_deal.deal_number')} ${deal.id}`}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};
