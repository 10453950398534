import { StyledRow } from '@/shared/styles';
import styled from 'styled-components';

export const StyledLeftSection = styled(StyledRow)`
  justify-content: space-between;
`;

export const StyledRightSection = styled(StyledRow)`
  justify-content: space-between;
  gap: 24px;

  display: flex;
  align-items: center;

  .ant-btn {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;
