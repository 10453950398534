import { DocsProps } from '../../../../Client/Projects/projects.consts';
import React from 'react';
import { Page1 } from './Page1';
import { Page2 } from './Page2';
import { Page3 } from './Page3';
import { Page4 } from './Page4';

export const GettingStarted: React.FC<DocsProps> = ({ page }: DocsProps) => {
  const renderPage = () => {
    switch (page) {
      case 1:
        return <Page1 />;
      case 2:
        return <Page2 />;
      case 3:
        return <Page3 />;
      case 4:
        return <Page4 />;
      default:
        return <Page1 />;
    }
  };

  return <>{renderPage()}</>;
};
