/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { Component } from '../../../../../types';
import { CheckboxProps } from '@u1/fixers';
import { removeTabindex } from '../utils';

const handleSpecialCases = (props: CheckboxProps, el: HTMLElement) => {
  const disabledEls = el.querySelectorAll('input, .ant-checkbox-disabled');
  disabledEls?.forEach((el: Element) => removeTabindex(el as HTMLElement));

  const disabledCheckboxes = el.querySelectorAll('.ant-checkbox-disabled');
  disabledCheckboxes?.forEach((el: Element) =>
    (el as HTMLElement).setAttribute('aria-disabled', 'true')
  );

  document.querySelectorAll('.ant-checkbox-wrapper')?.forEach((el: Element) => {
    const checkbox = el as HTMLElement;
    checkbox
      .querySelector('span.ant-checkbox')
      ?.setAttribute('aria-label', checkbox.innerText.trim());
  });
};

export const checkbox: Component<CheckboxProps> = {
  selector: '.ant-checkbox-wrapper',
  handleSpecialCases,
  props: {
    selectors: {
      element: '.ant-checkbox-wrapper span.ant-checkbox',
      checkedState: '.ant-checkbox.ant-checkbox-checked',
      uncheckedState: '.ant-checkbox:not(.ant-checkbox-checked) span.ant-checkbox',
      disabled: '.ant-checkbox-disabled',
    },
  },
};
