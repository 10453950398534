import { StyledCheckout } from './Checkout.styles';
import { CheckoutSummary } from './OrderSummary/CheckoutSummary';
import { PackageDetails } from './PackageDetails/PackageDetails';

export const Checkout: React.FC = () => {
  return (
    <StyledCheckout>
      <PackageDetails />
      <CheckoutSummary />
    </StyledCheckout>
  );
};
