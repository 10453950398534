import { StyledRow, StyledShimmer } from '@/shared/styles';

export const DocumentationPageLoading: React.FC = () => {
  return (
    <StyledRow $gap={32}>
      <StyledShimmer $width={404} $height={450} />
      <StyledShimmer $width={834} $height={704} />
    </StyledRow>
  );
};
