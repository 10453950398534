import { useSessionStorage } from '@/hooks/useSessionStorage';
import { U1Button } from '@/shared/components/Button/U1Button';
import { Loading } from '@/shared/components/Loading/Loading';
import { PAGES, COOKIES } from '@/shared/consts';
import { PageNotFoundProps } from '@/shared/props';
import { useAuthStore } from '@/stores/AuthStore';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSubscription } from '@/hooks/useSubscription';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { SUB_STATUS } from '@/stores/Stores.consts';
import { NotFound } from './NotFound';
import { useTranslation } from 'react-i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';
import { useMenuItems } from '@/hooks/MenuItems/useMenuItems';

export const MainNotFound: React.FC<PageNotFoundProps> = ({ $homePath }: PageNotFoundProps) => {
  const { navigate } = useU1Navigation();
  const location = useLocation();
  const authStore = useAuthStore();
  const isLoggedIn = authStore.isLoggedIn();
  const [loading, setIsLoading] = useState(true);
  const { setStorage } = useSessionStorage();
  const { subscriptionLoading } = useSubscription();
  const { getAllowedMenuItems } = useMenuItems();
  const subscriptionStatus = useSubscriptionStore((state) => state.subscriptionStatus);
  const { t } = useTranslation();

  const searchInPages = (searchPathsGroup: Record<string, string>) => {
    for (const property in searchPathsGroup) {
      if (location.pathname === searchPathsGroup[property]) {
        return true;
      }
    }
  };

  useEffect(() => {
    const isPathExistAfterLogin = searchInPages(PAGES.AFTER_LOGIN) || searchInPages(PAGES.SHARED);
    const isPagesAllowed = getAllowedMenuItems().includes(location.pathname);
    if (!isLoggedIn) {
      if (isPathExistAfterLogin) {
        setStorage(COOKIES.REDIRECT_URL, location.pathname);
        navigate(PAGES.BEFORE_LOGIN.SIGN_IN);
      }
      setIsLoading(false);
    } else if (isLoggedIn && subscriptionStatus !== SUB_STATUS.NOT_INITIATED) {
      if (!isPathExistAfterLogin || !isPagesAllowed) setIsLoading(false);
    }
  }, [subscriptionStatus]);

  return loading || subscriptionLoading ? (
    <Loading />
  ) : (
    <NotFound
      text={t('not_found.main.text')}
      backButton={
        <U1Button
          dataTestId="homepageButton"
          text={t('not_found.main.back_button')}
          $height="50px"
          onClick={() => navigate($homePath)}
        />
      }
    />
  );
};
