import { useState } from 'react';
import { StyledEditText } from '../../../../Client/Projects/Projects.styles';
import { ListSectionProps } from './DocsSection.consts';
import { getList } from '../../DocumentationPage.utils';
import { StyledCompText } from '../../DocumentationPage.styles';
import { StyledCol, StyledInput, StyledRow, StyledTitle } from '@/shared/styles';
import { EditIcons } from './EditIcons';
import { IconButton } from '@/shared/components/Button/IconButton';
import { addIcon, deleteIcon } from '@/assets/images/icons';
import { USER_TYPE } from '@/shared/consts';
import { useUserStore } from '@/stores/UserStore';
import { useTranslation } from 'react-i18next';

export const ListSection: React.FC<ListSectionProps> = ({
  title,
  listItems,
  objkeys,
}: ListSectionProps) => {
  const [isIconsVisible, setIsIconsVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editableItems, setItems] = useState<string[]>(listItems);
  const userType = useUserStore((state) => state.type);
  const { t } = useTranslation();

  const handleInputChange = (i: number, newValue: string) => {
    const newArray = [...editableItems];
    newArray[i] = newValue;
    setItems(newArray);
  };

  const handleAddInput = () => {
    setItems([...editableItems, '']);
  };

  const handleDeleteInput = (i: number) => {
    const newArray = [...editableItems];
    newArray.splice(i, 1);
    setItems(newArray);
  };

  return (
    <StyledEditText
      onMouseEnter={() => setIsIconsVisible(true)}
      onMouseLeave={() => setIsIconsVisible(false)}
    >
      <StyledRow>
        <StyledCol $gap={12}>
          {title && (
            <StyledTitle level={2} $weight={600} $size={18}>
              {title}
            </StyledTitle>
          )}
        </StyledCol>
        {userType === USER_TYPE.ADMIN && editableItems && (
          <EditIcons
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            isVisible={isIconsVisible}
            objkeys={objkeys}
            editableContent={editableItems}
            setContent={setItems as React.Dispatch<React.SetStateAction<string | string[]>>}
            originalContent={listItems ?? ''}
          />
        )}
      </StyledRow>
      <StyledCol $gap={12}>
        <StyledCompText>{t('projects.docs.component.how_to_test.content')}</StyledCompText>
        {isEditMode ? (
          <>
            {editableItems?.map((item, i) => (
              <StyledRow $gap={12} key={i} $align="center">
                <StyledInput
                  value={item}
                  onChange={(e) => {
                    handleInputChange(i, e.target.value);
                  }}
                />
                <IconButton
                  $alt={`${t('delete')} ${item}`}
                  onClick={() => handleDeleteInput(i)}
                  $icon={deleteIcon}
                />
              </StyledRow>
            ))}
            <IconButton
              $alt={t('projects.docs.component.edit.add')}
              onClick={handleAddInput}
              $icon={addIcon}
            />
          </>
        ) : (
          listItems && <StyledCompText>{getList(JSON.stringify(listItems))}</StyledCompText>
        )}
      </StyledCol>
    </StyledEditText>
  );
};
