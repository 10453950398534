import { TitleProps } from '@/shared/props';
import { gradientTitle, StyledCol } from '@/shared/styles';
import { useThemeStore } from '@/stores/ThemeStore';
import Title from 'antd/lib/typography/Title';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const StyledGetStartedTitle = styled(Title)<TitleProps>`
  background: ${(props) => props.theme.sameColorGradient} !important;
  font-size: 16px !important;
  font-weight: 600 !important;

  margin-top: 0px !important;
  margin: 0px !important;
  ${gradientTitle};
`;

export const StyledSpan = styled.span`
  color: ${(props) => props.theme.sameColorText} !important;
`;

export const StyledGetStarted = styled(StyledCol)`
  border: 1px solid ${(props) => props.theme.borderColor};
  border-radius: 24px;
  align-items: center;
  padding: 40px;

  ${(props) => !useThemeStore.getState().isDarkMode && `background: ${props.theme.sameColorBG};`}

  ${isMobile &&
  `
  [data-component='contactUsDialog'] {
    width: 100%;
  }`};
`;

export const StyledGetStartedText = styled.div`
  text-align: center;
  font-weight: 200;
  font-size: ${isMobile ? '14' : '16'}px;
  width: ${isMobile ? '100' : '75'}%;
  color: ${(props) => props.theme.sameColorText};
`;
