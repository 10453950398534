import { StyledLayout, StyledRow } from '@/shared/styles';
import styled from 'styled-components';

export const StyledLayoutAfterLogin = styled(StyledLayout)`
  display: flex;
  flex-direction: column;
`;
export const StyledRowLayout = styled(StyledRow)`
  width: 100%;
  height: fit-content;
  background-color: ${(props) => props.theme.backgroundColor};
`;
