import { Component } from '../../../../../types';
import { RadioProps } from '@u1/fixers';
import { A11Y_DIRECTIVE_SELECTOR } from '../../../../../consts';

const RADIO_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-radio`;

export const radio: Component<RadioProps> = {
  selector: `[${RADIO_ATTRIBUTE}]`,
  props: {
    selectors: {
      radioGroup: `[${RADIO_ATTRIBUTE}='radio-group']`,
      radioButton: `[${RADIO_ATTRIBUTE}='radio-btn']`,
      checkedState: `[${RADIO_ATTRIBUTE}-checked='true']`,
      uncheckedState: `[${RADIO_ATTRIBUTE}-checked='false']`,
    },
  },
};
