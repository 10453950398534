import { StyledAdditonalHours, StyledHourButton } from '../Package.styles';
import { useDealStore } from '@/stores/DealStore/DealStore';
import { U1RadioGroup } from '@u1/react-a11y-hooks';
import { AdditionalHoursProps } from './BuildPackage.consts';

export const AdditionalHoursRadioGroup: React.FC<AdditionalHoursProps> = ({
  additionalHours,
}: AdditionalHoursProps) => {
  const additonalServices = useDealStore((state) => state.additonalServices);
  const setAdditonalServices = useDealStore((state) => state.setAdditonalServices);
  return (
    <U1RadioGroup>
      <StyledAdditonalHours data-u1-radio="radio-group">
        {additionalHours?.map((val) => {
          return (
            <StyledHourButton
              key={val}
              isActive={additonalServices === val}
              onClick={() => setAdditonalServices(val)}
              data-u1-radio="radio-btn"
              data-u1-checked={additonalServices === val}
            >
              {val}
            </StyledHourButton>
          );
        })}
      </StyledAdditonalHours>
    </U1RadioGroup>
  );
};
