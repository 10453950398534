import { Component } from '../../../../../types';
import { TableProps } from '@u1/fixers';
import { A11Y_DIRECTIVE_SELECTOR } from '../../../../../consts';

const TABlE_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-table`;

const DIRECTIVE_SELECTORS = {
  TABLE: `[${TABlE_ATTRIBUTE}='table']`,
  ROW: `[${TABlE_ATTRIBUTE}='row']`,
  CELL: `[${TABlE_ATTRIBUTE}='cell']`,
  COLUMNHEADER: `[${TABlE_ATTRIBUTE}='columnheader']`,
  ROWHEADER: `[${TABlE_ATTRIBUTE}='rowheader']`,
};

export const table: Component<TableProps> = {
  selector: `[${TABlE_ATTRIBUTE}]`,
  props: {
    selectors: {
      table: DIRECTIVE_SELECTORS.TABLE,
      row: DIRECTIVE_SELECTORS.ROW,
      cell: DIRECTIVE_SELECTORS.CELL,
      columnheader: DIRECTIVE_SELECTORS.COLUMNHEADER,
      rowheader: DIRECTIVE_SELECTORS.ROWHEADER,
    },
  },
};
