import { PAGES } from '@/shared/consts';
import { MainNotFound } from '@/shared/pages/NotFound/MainNotFound';
import * as React from 'react';
import { StyledNotFound } from './BeforeLogin.styles';
import { StyledMainBeforeLogin } from '../../BeforeLogin.styles';

export const BeforeLogin404: React.FC = () => {
  return (
    <StyledMainBeforeLogin>
      <StyledNotFound>
        <MainNotFound $homePath={PAGES.BEFORE_LOGIN.MAIN_PAGE} />
      </StyledNotFound>
    </StyledMainBeforeLogin>
  );
};
