import { Modifications_Code, PackageTypes } from '@/shared/consts';
import { ProductData } from '@/shared/dto';
import { getActiveFrameworks, getModification, getPricing } from '@/shared/utils';
import { PackagesProps } from './Pricing.consts';

export const extractPackageDetails = (productsData: ProductData[]) => {
  const packageDetails: PackagesProps[] = [];
  productsData.forEach((prod) => {
    const domains = getModification(prod, Modifications_Code.DOMAINS);
    const frameworks = getModification(prod, Modifications_Code.FRAMEWORKS);
    const users = getModification(prod, Modifications_Code.USERS);
    const training = getModification(
      prod,
      prod.type === PackageTypes.FREE
        ? Modifications_Code.ACCESSIBILITY_COMPONENTS
        : Modifications_Code.TRAINING
    );

    const prodPriceMonthly = getPricing('MONTH', prod);
    const prodPriceAnnually = getPricing('YEAR', prod);

    const domainsMonthly = getPricing('MONTH', domains);
    const domainsAnnually = getPricing('YEAR', domains);

    const frameworksMonthly = getPricing('MONTH', frameworks);
    const frameworksAnnually = getPricing('YEAR', frameworks);

    const trainingPrice = getPricing('ONETIME', training);

    packageDetails.push({
      id: prod.id,
      name: prod.name,
      imageUrl: prod.imageUrl,
      type: prod.type,
      domains: {
        quantity: domains?.quantity as number,
        title: domains?.title as string,
        quantityTitle: domains?.quantityTitle as string,
      },
      frameworks: {
        quantity: frameworks?.quantity as number,
        title: frameworks?.title as string,
        quantityTitle: frameworks?.quantityTitle as string,
      },
      users: {
        quantity: users?.quantity as number,
        title: users?.title as string,
        quantityTitle: users?.quantityTitle as string,
      },
      training: {
        quantity: training?.quantity as number,
        title: training?.title as string,
        quantityTitle: training?.quantityTitle as string,
      },
      prices: {
        MONTH: {
          default: prodPriceMonthly,
          domain: domainsMonthly,
          framework: frameworksMonthly,
          training: trainingPrice,
        },
        YEAR: {
          default: prodPriceAnnually,
          domain: domainsAnnually,
          framework: frameworksAnnually,
          training: trainingPrice,
        },
      },
      supportedFrameworks: getActiveFrameworks(frameworks),
      priceTitle: prod.priceTitle,
    });
  });
  return packageDetails;
};
