﻿import { ListboxApi, ListboxProps, ListboxOptions } from '@u1/fixers';
import { useU1Base } from './base';
import { allowedOptionsKeys } from './consts';
import { BaseOptions } from '../shared/types';
import { ComponentName } from '@u1/ui-library-mapper';

export const useU1Listbox = (baseOptions?: BaseOptions<ListboxProps, ListboxOptions>) => {
  return useU1Base(
    ListboxApi,
    false,
    allowedOptionsKeys.listbox,
    ComponentName.LISTBOX,
    baseOptions
  );
};
