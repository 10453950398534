import { StyledCard, StyledCol, StyledOrangeGradTitle, StyledText } from '@/shared/styles';
import { StyledSecurityAlert } from './SecurityAlert.styles';
import { U1Button } from '@/shared/components/Button/U1Button';
import { StyledPlainLink } from '@/shared/components/Link/Links.styles';
import { useAuthStore } from '@/stores/AuthStore';
import { LOGIN_STATE } from '@/stores/Stores.consts';
import { BeforeLogin404 } from '../BeforeLogin404/BeforeLogin404';
import { useTranslation } from 'react-i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const SecurityAlert: React.FC = () => {
  const { navigate } = useU1Navigation();
  const loginState = useAuthStore((state) => state.loginState);
  const { t } = useTranslation();

  return loginState === LOGIN_STATE.SECURITY_ALERT ? (
    <StyledSecurityAlert>
      <StyledCard $padding={40} $width="813px" $gap={36}>
        <StyledCol $gap={12}>
          <StyledOrangeGradTitle $weight={600} $size={32} $align="center">
            {t('security_alert.title')}
          </StyledOrangeGradTitle>
          <StyledText fontWeight={400} fontSize={'18px'} textAlign="center">
            {t('security_alert.content')} (
            {
              <StyledPlainLink href="mailto:support@user1st.com">
                support@user1st.com
              </StyledPlainLink>
            }
            ).
            <br />
            <br />
            {t('security_alert.content2')}
          </StyledText>
        </StyledCol>
        <U1Button text={`${t('security_alert.back_button')} 🌐`} onClick={() => navigate('/')} />
      </StyledCard>
    </StyledSecurityAlert>
  ) : (
    <BeforeLogin404 />
  );
};
