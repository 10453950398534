import { StyledCol, StyledPurpleGradText, StyledPurpleGradTitle } from '@/shared/styles';
import { StyledHybridTitle, StyledLPText } from '../../LandingPage.styles';
import { U1Button } from '@/shared/components/Button/U1Button';
import { ContactUsModal } from '@/shared/components/Modals/ContactUsModal/ContactUsModal';
import { isMobile } from 'react-device-detect';
import { StyleNoBreakSpan } from './Intro.styles';
import { useTranslation } from 'react-i18next';

export const Intro: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledCol $gap={32} $width={isMobile ? '100%' : '50%'}>
      <StyledCol $gap={8}>
        <StyledPurpleGradTitle $size={20} $weight={600} $align="left">
          {t('landing_page.intro.title1.welcome')}:{isMobile ? <br /> : <></>}{' '}
          {t('landing_page.intro.title1.your_partner')}
        </StyledPurpleGradTitle>
        <StyledHybridTitle level={2} $break={true}>
          <StyleNoBreakSpan>{t('landing_page.intro.title2.empowering')}</StyleNoBreakSpan>
          <StyledPurpleGradText>{t('landing_page.intro.title2.in_code')}</StyledPurpleGradText>
        </StyledHybridTitle>
        <StyledLPText>{t('landing_page.intro.content')}</StyledLPText>
      </StyledCol>
      <ContactUsModal
        refName="contact-us-intro"
        trigger={
          <U1Button text={t('landing_page.request_demo')} $width={isMobile ? '100%' : '167px'} />
        }
      />
    </StyledCol>
  );
};
