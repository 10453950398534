import React, { useState } from 'react';
import { StyledModal } from '../Modals.styles';
import { IconButton } from '../../Button/IconButton';
import { editIcon } from '@/assets/images/icons';
import { EditDocsModalProps } from './EditDocsModal.consts';
import { U1Button } from '../../Button/U1Button';
import { GradientBorderButton } from '../../Button/GradientBorderButton';
import { StyledCol, StyledRow } from '@/shared/styles';

export const EditDocsModal: React.FC<EditDocsModalProps> = ({
  children,
  onSave,
}: EditDocsModalProps) => {
  const [open, setOpen] = useState(false);

  const savePreview = () => {
    onSave();
    setOpen(false);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    // <U1Dialog name={ComponentName.CONTACT_US_DIALOG}>
    <>
      <IconButton $alt="Edit text" onClick={showModal} $icon={editIcon} triggerRef="edit-docs" />
      <StyledModal
        data-a11y-ref="edit-docs"
        open={open}
        onCancel={handleCancel}
        footer={[]}
        $width={1000}
        $closeBtn={false}
      >
        {
          <StyledCol $gap={12}>
            {children}
            <StyledRow $gap={12}>
              <U1Button text="Preview" onClick={savePreview} />
              <GradientBorderButton text="Cancel" onClick={handleCancel} />
            </StyledRow>
          </StyledCol>
        }
      </StyledModal>
    </>
    // </U1Dialog>
  );
};
