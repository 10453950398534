import { StyledSeparator } from '@/shared/styles';
import React from 'react';
import { StyledMenuItemRow } from '@/shared/styles/dropdownMenu.styles';
import { ActionsDropdown } from '../../../../SearchPanel/Actions/ActionsDropdown';
import { EditUserModal } from '@/shared/components/Modals/UserActionModals/EditUserModal';
import { EditAdminForm } from '@/shared/components/Forms/AdminsForms/EditAdmin/EditAdmin';
import { DeleteUserModal } from '@/shared/components/Modals/UserActionModals/DeleteUserModal';
import { USER_TYPE } from '@/shared/components/Modals/UserActionModals/UserModals.consts';
import { useAdminsStore } from '@/stores/AdminsStore';
import { AdminActionsProps } from '@/Views/AfterLogin/Main/SearchPanel/Actions/UserActions.consts';

export const AdminActionsDropdown: React.FC<AdminActionsProps> = ({
  setIsOpen,
  admin,
}: AdminActionsProps) => {
  const getAdmins = useAdminsStore((state) => state.getAdmins);

  return (
    <ActionsDropdown setIsOpen={setIsOpen}>
      <StyledMenuItemRow>
        <EditUserModal form={<EditAdminForm selectedUser={admin} />} />
      </StyledMenuItemRow>
      <StyledSeparator />
      <StyledMenuItemRow>
        <DeleteUserModal
          id={admin.id}
          userName={admin.userName}
          userType={USER_TYPE.ADMIN}
          getUsers={getAdmins}
        />
      </StyledMenuItemRow>
    </ActionsDropdown>
  );
};
