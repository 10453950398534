import { create } from 'zustand';
import { AuthStore, LOGIN_STATE } from './Stores.consts';

export const useAuthStore = create<AuthStore>((set, get) => ({
  loginState: LOGIN_STATE.UNINITIATED,
  isLoggedIn: () => {
    return get().loginState === LOGIN_STATE.LOGGED_IN;
  },
  setLoginState: (isLoggedIn: LOGIN_STATE) => set(() => ({ loginState: isLoggedIn })),
}));
