import { StyledDatepicker } from './Datepicker.styles';
import { DatepickerGrid } from './Sections/DatepickerGrid';
import { DatepickerHeader } from './Sections/DatepickerHeader';
import { DatepickerFooter } from './Sections/DatepickerFooter';
import { U1Datepicker } from '@u1/react-a11y-hooks';

export const Datepicker: React.FC = () => {
  return (
    <U1Datepicker>
      <StyledDatepicker $gap={32}>
        <DatepickerHeader />
        <DatepickerGrid />
        <DatepickerFooter />
      </StyledDatepicker>
    </U1Datepicker>
  );
};
