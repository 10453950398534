import React, { useState } from 'react';
import { UserActions } from '../../../../SearchPanel/Actions/UserActions';
import { VendorActionsDropdown } from './VendorActionsDropdown';
import { VendorActionsProps } from '@/Views/AfterLogin/Main/SearchPanel/Actions/UserActions.consts';

export const VendorActions: React.FC<VendorActionsProps> = ({ vendor }: VendorActionsProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <UserActions
      actionsDropdown={<VendorActionsDropdown setIsOpen={setIsOpen} vendor={vendor} />}
      name={vendor.userName}
      dataTestId="manageUserButton"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};
