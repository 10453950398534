import React from 'react';
import { StyledLineChart } from './LineChart.styles';
import { LineChartProps } from './LineChart.consts';
import { useLineChart } from './useLineChart';
import { useTranslation } from 'react-i18next';

export const LineChart: React.FC<LineChartProps> = ({ data }: LineChartProps) => {
  const { series, options, setIsHovered } = useLineChart({ data });
  const { t } = useTranslation();

  return (
    <StyledLineChart
      options={options}
      series={series}
      height={464}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      role="img"
      aria-label={t('vendor.stats.total_deals_progress.line_chart.aria_label')}
    />
  );
};
