import { useThemeStore } from '@/stores/ThemeStore';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { FcWithChildren } from '../props';
import { whiteTheme } from './whiteTheme';
import { darkTheme } from './darkTheme';

const ThemeWrapper: React.FC<FcWithChildren> = ({ children }) => {
  const isDarkMode = useThemeStore((state) => state.isDarkMode);
  return <ThemeProvider theme={isDarkMode ? darkTheme : whiteTheme}>{children}</ThemeProvider>;
};

export default ThemeWrapper;
