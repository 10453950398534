import { U1Dialog } from '@u1/react-a11y-hooks';
import { StyledModal } from '../Modals.styles';
import { ComponentName } from '@u1/ui-library-mapper';
import React, { useState } from 'react';
import { EditUserProps } from './UserModals.consts';
import { StyledMenuItem } from '@/shared/styles/dropdownMenu.styles';
import { useTranslation } from 'react-i18next';

export const EditUserModal: React.FC<EditUserProps> = ({ form }: EditUserProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <U1Dialog name={ComponentName.EDIT_DIALOG}>
      <StyledMenuItem onClick={showModal} data-a11y-dialog="trigger" data-a11y-ref="edit-user">
        {t('admin.actions.edit')}
      </StyledMenuItem>
      <StyledModal
        data-a11y-ref="edit-user"
        open={open}
        onCancel={handleCancel}
        footer={[]}
        $width={557}
      >
        {React.cloneElement(form, {
          onCancel: handleCancel,
        })}
      </StyledModal>
    </U1Dialog>
  );
};
