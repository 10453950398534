import { StyledLoading, StyledShimmer } from '@/shared/styles';
import { isMobile } from 'react-device-detect';

export const Loading = () => {
  const loadingWidth = isMobile ? 300 : 795;
  const loadingHeight = isMobile ? 500 : 294;
  return (
    <StyledLoading>
      <StyledShimmer $width={loadingWidth} $height={loadingHeight} />
    </StyledLoading>
  );
};
