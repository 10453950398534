/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { DialogProps, DialogType } from '@u1/fixers';
import { Component } from '../.././../../../types';
import { addRolePresentation, removeAllAccessibilityAttributes } from '../utils';

export const userDropdown: Component<DialogProps> = {
  selector: `a.ant-dropdown-trigger`,
  handleSpecialCases: (props: DialogProps, el: HTMLElement) => {
    removeAllAccessibilityAttributes(el);
    const { selectors } = props;
    const trigger = el.querySelector(selectors.trigger as string) as HTMLElement;

    trigger?.addEventListener('click', () => {
      setTimeout(() => {
        const dropdown = document.querySelector(selectors.dialog) as HTMLElement;
        dropdown && removeAllAccessibilityAttributes(dropdown);
        const listElements = Array.prototype.slice.call(dropdown.querySelectorAll('ul, li'));
        addRolePresentation(listElements);
      }, 200);
    });

    trigger.setAttribute('aria-label', 'User Actions');
  },
  props: {
    options: {
      type: DialogType.NON_MODAL,
    },
    selectors: {
      trigger: 'a.ant-dropdown-trigger',
      dialog: `.ant-dropdown.user-dropdown`,
      heading: `#user-dropdown-title`,
      textContent: `#user-dropdown-content`,
    },
  },
};
