import twitter from '@/assets/images/landingPage/footer/twitter.svg';
import facebook from '@/assets/images/landingPage/footer/facebook.svg';
import linkedin from '@/assets/images/landingPage/footer/linkedIn.svg';
import youtube from '@/assets/images/landingPage/footer/youtube.svg';
import { PAGES } from '@/shared/consts';
import ios from '@/assets/images/landingPage/footer/ios.svg';
import android from '@/assets/images/landingPage/footer/android.svg';
import react from '@/assets/images/landingPage/footer/react.svg';
import flutter from '@/assets/images/landingPage/footer/flutter.svg';
import { t } from 'i18next';

export const compliences = [
  'ADA',
  'AODA',
  'ATAG',
  'COPPA',
  'CVAA',
  'EAA',
  'EN 301-549',
  'FERPA',
  'GDPR',
  'HIPAA',
  'WCAG',
];

export const certifications = ['ISO 27001', 'ISO 27018', 'ISO 27017', 'ISO 27701', 'W3C Member'];

export const platforms = [
  'React',
  'Angular',
  'Vue',
  'JS',
  'Shopify',
  'Wordpress (+Elementor)',
  'Umbraco',
];

export const mobilePlatforms = ['Native Android', 'Native iOS', 'Flutter', 'React Native'];

export const getAboutSections = () => [
  { text: t('header.menu.about_us'), route: PAGES.BEFORE_LOGIN.ABOUT_US },
  { text: t('header.menu.our_team'), route: PAGES.BEFORE_LOGIN.OUR_TEAM },
  { text: t('header.menu.pricing'), route: PAGES.BEFORE_LOGIN.PRICING },
  { text: t('header.menu.vendor'), route: PAGES.BEFORE_LOGIN.VENDOR_SIGN_IN },
  { text: t('header.menu.faq'), route: PAGES.BEFORE_LOGIN.FAQ },
  {
    text: t('landing_page.footer.about.enterprise_solution'),
  },
  {
    text: t('pricing.package.contact_us'),
  },
];

export const socialMedia = [
  { name: 'twitter', href: 'https://twitter.com/user1st', icon: twitter },
  { name: 'facebook', href: 'https://www.facebook.com/user1st/', icon: facebook },
  { name: 'linkedin', href: 'https://www.linkedin.com/company/user1st/', icon: linkedin },
  {
    name: 'youtube',
    href: 'https://www.youtube.com/@AccessibilityUser1st',
    icon: youtube,
  },
];

export const mobilePlatformsImages = [
  { src: ios, alt: 'IOS' },
  { src: android, alt: 'android' },
  { src: react, alt: 'react' },
  { src: flutter, alt: 'flutter' },
];
