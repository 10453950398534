import { StyledTitle } from '@/shared/styles';
import { StyledDocsBtn, StyledDocsBtnsGroup, StyledDocsCard } from './AdminsDocs.styles';
import { Icon } from '@/shared/components/Icon';
import { FrameworkButtonProps } from './AdminDocs.consts';
import { useState } from 'react';
import { fileIcon } from '@/assets/images/icons';
import { PAGES } from '@/shared/consts';
import { useTranslation } from 'react-i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const FrameworkButton: React.FC<FrameworkButtonProps> = ({
  frameworkData,
}: FrameworkButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const { navigate } = useU1Navigation();
  const { t } = useTranslation();

  return (
    <StyledDocsCard onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <StyledTitle level={2} $weight={600} $size={16}>
        {frameworkData.title}
      </StyledTitle>
      <Icon $src={frameworkData.icon ? frameworkData.icon : fileIcon} $size={100} />
      {isHovered && (
        <StyledDocsBtnsGroup>
          <StyledDocsBtn
            onClick={() => {
              navigate(
                PAGES.AFTER_LOGIN.ADMIN_DOCUMENTATION.replace(':projectIdParam', frameworkData.id)
              );
            }}
          >
            {t('admin.docs.documentation')}
          </StyledDocsBtn>
          <StyledDocsBtn
            onClick={() => {
              navigate(
                PAGES.AFTER_LOGIN.ADMIN_CONFIGURATION.replace(':projectIdParam', frameworkData.id)
              );
            }}
          >
            {t('admin.docs.config')}
          </StyledDocsBtn>
        </StyledDocsBtnsGroup>
      )}
    </StyledDocsCard>
  );
};
