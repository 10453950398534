import { StyledHybridTitle } from '../../LandingPage.styles';
import { U1Button } from '@/shared/components/Button/U1Button';
import { ContactUsModal } from '@/shared/components/Modals/ContactUsModal/ContactUsModal';
import { isMobile } from 'react-device-detect';
import {
  StyledGetStarted,
  StyledGetStartedText,
  StyledGetStartedTitle,
  StyledSpan,
} from './GetStarted.styles';
import { StyledPurpleGradText } from '@/shared/styles';
import { useTranslation } from 'react-i18next';

export const GetStarted: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledGetStarted $gap={16}>
      <StyledGetStartedTitle level={2} $size={16}>
        {t('landing_page.get_started.title1')}
      </StyledGetStartedTitle>
      <StyledHybridTitle
        level={3}
        $size={isMobile ? 24 : 28}
        $weight={600}
        $align="center"
        $width={isMobile ? '100%' : '85%'}
      >
        <StyledSpan> {t('landing_page.get_started.title2.solutions')} </StyledSpan>
        <StyledPurpleGradText>
          {t('landing_page.get_started.title2.make_accessible')}
        </StyledPurpleGradText>
      </StyledHybridTitle>
      <StyledGetStartedText>{t('landing_page.get_started.content')}</StyledGetStartedText>
      <ContactUsModal
        refName="contact-us-get-started"
        trigger={
          <U1Button
            text={isMobile ? t('landing_page.request_demo') : t('landing_page.schedule_demo')}
            $width={isMobile ? '100%' : '305px'}
          />
        }
      />
    </StyledGetStarted>
  );
};
