import React from 'react';
import { LandingPageHeader } from './Areas/Header/Header';
import { StyledMainLandingPage } from './LandingPage.styles';
import { Trusted } from './Areas/Trusted/Trusted';
import { OurSolution } from './Areas/OurSolution/OurSolution';
import { StyledCol } from '@/shared/styles';
import { Institutions } from './Areas/Institutions/Institutions';
import { OurFeatures } from './Areas/Features/Features';
import { Importance } from './Areas/Importance/Importance';
import { GetStarted } from './Areas/GetStarted/GetStarted';
import { LandingPageFooter } from './Areas/Footer/Footer';
import { isMobile } from 'react-device-detect';
import { MobileIntro } from './Areas/Intro/MobileIntro';
import { DesktopIntro } from './Areas/Intro/DesktopInto';

export const LandingPage: React.FC = () => {
  return (
    <>
      <LandingPageHeader />
      <StyledMainLandingPage>
        <StyledCol $gap={isMobile ? 80 : 240}>
          {isMobile ? <MobileIntro /> : <DesktopIntro />}
          <Trusted />
        </StyledCol>
        <OurSolution />
        <Institutions />
        <OurFeatures />
        <Importance />
        <GetStarted />
      </StyledMainLandingPage>
      <LandingPageFooter />
    </>
  );
};
