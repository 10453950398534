import { Component } from '../../../../../types';
import { ListboxProps } from '@u1/fixers';

export const listbox: Component<ListboxProps> = {
  selector: '.listbox-trigger, .ant-select .ant-select-selector',
  props: {
    selectors: {
      listbox: `ul.listbox-element, .rc-virtual-list`,
      options: 'li.ant-dropdown-menu-item, .ant-select-item-option',
      trigger: `.listbox-trigger, .ant-select .ant-select-selector`,
      label: `.simple-select-label`,
    },
    closeOnSelect: false,
    disableDownArrowOpen: true,
  },
  handleSpecialCases: (_, el) => {
    const input = el.querySelector('input.ant-select-selection-search-input');
    input?.setAttribute('u1st-avoid-auto-tabbable', 'true');
    input?.setAttribute('aria-hidden', 'true');
    input?.setAttribute('tabindex', '-1');
  },
};
