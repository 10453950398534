import { StyledCapitalized } from '@/shared/styles';
import { ColumnsType } from 'antd/lib/table';
import { VendorActions } from './VendorActions/VendorActions';
import { VendorDetails } from '@/stores/Stores.consts';
import { t } from 'i18next';

export interface VendorsDataType {
  key: string;
  companyName: string;
  userName: string;
  email: string;
  phone: string;
  status: string;
  dealSum: string;
  dealCount: string;
  actions: string;
}

export const getColumns = (): ColumnsType<VendorsDataType> => [
  {
    title: t('admin.table.company'),
    dataIndex: 'companyName',
    key: 'companyName',
  },
  {
    title: t('admin.table.admin'),
    dataIndex: 'userName',
    key: 'userName',
  },
  {
    title: t('admin.table.email'),
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: t('admin.table.phone'),
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: t('admin.table.account_status'),
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => {
      return <StyledCapitalized>{status}</StyledCapitalized>;
    },
  },
  {
    title: t('admin.table.deals'),
    dataIndex: 'dealSum',
    key: 'dealSum',
  },
  {
    title: t('admin.table.amount'),
    dataIndex: 'dealCount',
    key: 'dealCount',
  },
  {
    key: 'actions',
    render: (vendor: VendorDetails) => {
      return <VendorActions vendor={vendor} />;
    },
  },
];
