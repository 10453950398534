import { ProjectData } from '@/shared/dto';
import { ReactNode } from 'react';

export type TreeItemProps = {
  compName: string;
};

export interface ProjectListItemModel {
  projectName: string;
  projectDomain: string;
  projectFramework: string;
  projectKey?: string;
}

export interface ConfigListItemModel {
  name: string;
  instructions: string;
  code: string;
}

export interface ConfigListModel {
  configList: ConfigListItemModel[];
}

export interface TitleAndContentModel {
  children?: ReactNode;
  title?: string;
  content?: string;
  list?: string[];
}

export interface ApiTabelRowObjectModal {
  property: string;
  description: string;
  example: string;
  value?: string;
  key?: string;
}

export interface ApiTableObjectModal {
  name: string;
  table: ApiTabelRowObjectModal[];
}

export type TableContent = {
  property: string;
  description: string;
  value?: string;
  key?: string;
};

export type TableTypes = TableContent & {
  selectors?: TableContent[];
  options?: TableContent[];
};

export type CompDescription = {
  text: string;
  example?: string & Example;
  table?: TableTypes;
  aria?: LandmarkOption;
};

export type Example = {
  valid?: string;
  invalid?: string;
};

export type LandmarkOption = {
  before: string;
  example: string;
  after: string;
};

export type LandmarksSection = {
  text: string;
  aria: LandmarkOption;
  native: LandmarkOption;
};

export type CompIcon = {
  default: string;
  selected: string;
};

export type CompUsage = {
  description: CompDescription;
  test: string[] | CompDescription;
  attributes?: CompDescription;
  manual?: CompDescription;
  keyboard?: string;
  screenReader?: string;
  landmarks?: LandmarksSection;
  headings?: LandmarksSection & CompDescription;
  images?: CompDescription;
  colors?: CompDescription;
};

export type CompDataObjectModal = {
  name: string;
  category: string;
  icon: CompIcon;
  framework: string;
  accessibility: string;
  usage: CompUsage;
};

export type DocsProps = {
  page: number;
};

export type PageSliderProps = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  docsRef: React.MutableRefObject<null>;
  numOfPages: number;
};

export type ApiTableCellProps = {
  index: number;
  columnName: string;
  cellData: string;
  objkeys: string[];
};

export enum ColumnType {
  PROPERTY = 'property',
  VALUE = 'value',
  DESCRIPTION = 'description',
}

export type StyledColorComp = { $isValid?: boolean };

export type EditContentProps = StyledColorComp & {
  index?: number;
  title?: string;
  secondTitle?: string;
  content?: string;
  objkeys: string[];
};

export type EditIconsProps = {
  index?: number;
  isVisible: boolean;
  editableContent: string | string[];
  originalContent: string | string[];
  objkeys?: string[];
  isEditMode: boolean;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setContent: React.Dispatch<React.SetStateAction<string | string[]>>;
};

export type ProjectsListProps = {
  projectsList: ProjectData[];
};

export type ProjectsListItemProps = {
  projectsListItem: ProjectData;
};

export type ConfigurationCardProps = {
  index: number;
  instructions: string;
  code: string[];
  name?: string;
};
