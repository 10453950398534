import { PackageTypes, getSubscriptionType } from '@/shared/consts';
import { StyledBilling } from '@/shared/pages/Pricing/Packages/Package.styles';
import { StyledPackageTitle } from '@/shared/styles';
import { StyledbulletList, StyledRow, StyledRowOffset, StyledTitle } from '@/shared/styles';
import { getPriceFormat } from '@/shared/utils';
import { useSelectionStore } from '@/stores/SelectionStore';
import { calcPackageDomains } from '../../Checkout.utils';
import { useTranslation } from 'react-i18next';

export const PackageSummary: React.FC = () => {
  const selectedPackage = useSelectionStore();
  const { t } = useTranslation();

  return (
    <>
      <StyledRowOffset $wrap="wrap">
        <StyledTitle level={3} $size={15}>
          {selectedPackage.name} Package
        </StyledTitle>
        <StyledRow>
          <StyledPackageTitle>${getPriceFormat(selectedPackage.price)}</StyledPackageTitle>
          {selectedPackage.type !== PackageTypes.FREE && (
            <StyledBilling>
              / {t('pricing.billed')} {getSubscriptionType()[selectedPackage.pricingMethod]}
            </StyledBilling>
          )}
        </StyledRow>
      </StyledRowOffset>
      <StyledbulletList>
        <li>
          <StyledRowOffset>
            <span>Users</span>
            <span data-testid="usersCounter">{selectedPackage.users.quantity}</span>
          </StyledRowOffset>
        </li>
        <li>
          <StyledRowOffset>
            <span>Frameworks</span>
            <span data-testid="frameworksCounter">{selectedPackage.frameworks.quantity}</span>
          </StyledRowOffset>
        </li>
        <li>
          <StyledRowOffset>
            <span>Domains</span>
            <span data-testid="domainsCounter">{calcPackageDomains(selectedPackage)}</span>
          </StyledRowOffset>
        </li>
      </StyledbulletList>
    </>
  );
};
