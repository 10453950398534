import { StyledCol } from '@/shared/styles';
import { ClipboardSyntaxHighlighter } from '../../../ClipboardSyntaxHighlighter/ClipboardSyntaxHighlighter';
import { useDocsStore } from '@/stores/ProjectStore/ProjectStore';
import { DocsSection } from '../DocsSection/DocsSection';
import { useTranslation } from 'react-i18next';

export const Page1: React.FC = () => {
  const selectedComponent = useDocsStore((state) => state.selectedComponentDoc);
  const usage = selectedComponent?.usage;
  const test = usage?.test as { text: string; example: string };
  const { t } = useTranslation();

  return (
    <StyledCol $gap={32}>
      {/* Accessibility */}
      {selectedComponent?.accessibility && (
        <DocsSection
          title={t('projects.docs.component.accessibility.title')}
          objkeys={['accessibility']}
          content={selectedComponent.accessibility}
        />
      )}

      {/* Usage */}
      {usage?.description?.text && (
        <DocsSection
          title={t('projects.docs.component.usage.title.h2')}
          objkeys={['usage', 'description', 'text']}
          content={usage.description.text}
        />
      )}

      {/* KB */}
      {usage?.keyboard && (
        <DocsSection
          secondTitle={t('projects.docs.component.getting_started.keyboard.title')}
          objkeys={['usage', 'keyboard']}
          content={usage.keyboard}
        />
      )}

      {/* SR */}
      {usage?.screenReader && (
        <DocsSection
          secondTitle={t('projects.docs.component.getting_started.screen_reader.title')}
          objkeys={['usage', 'screenReader']}
          content={usage.screenReader}
        />
      )}

      {/* HOW TO */}
      {test?.text && (
        <DocsSection
          secondTitle={t('projects.docs.component.getting_started.how_to_know.title')}
          objkeys={['test', 'text']}
          content={test.text}
        />
      )}
      {test?.example && (
        <ClipboardSyntaxHighlighter
          language="html"
          content={test.example}
          objkeys={['test', 'example']}
        />
      )}
    </StyledCol>
  );
};
