import { useSubscription } from '@/hooks/useSubscription';
import { PAGES } from '@/shared/consts';
import { MainNotFound } from '@/shared/pages/NotFound/MainNotFound';
import { useExtraSelectionStore } from '@/stores/SelectionStore';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import React, { useEffect } from 'react';
import { StyledCheckout } from '../Checkout/Checkout.styles';
import { PackageDetails } from '../Checkout/PackageDetails/PackageDetails';
import { UpgradeSummary } from './OrderSummary/UpgradeSummary';

export const UpgradePackage: React.FC = () => {
  const subscription = useSubscriptionStore();
  const cleanUserSelection = useExtraSelectionStore((state) => state.cleanSelection);

  useEffect(() => {
    cleanUserSelection && cleanUserSelection();
  }, []);

  return subscription.isActive ? (
    <StyledCheckout>
      <PackageDetails currentPlan={subscription} />
      <UpgradeSummary currentPlan={subscription} />
    </StyledCheckout>
  ) : (
    <MainNotFound $homePath={PAGES.SHARED.DEFAULT} />
  );
};
