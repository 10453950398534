import { StyledCard, StyledTitle } from '@/shared/styles';
import { StyledPackageLi, StyledPackageUl, StyledSpan } from '../DealsPayment.styles';
import { useDealStore } from '@/stores/DealStore/DealStore';
import { getFrameworksWithComma } from '@/shared/utils';
import { SUBSCRIPTION_TYPE } from '../../../../Deals.consts';
import { SUBSCRIPTION_TYPE2 } from '@/shared/consts';
import { useTranslation } from 'react-i18next';
import { useLangStore } from '@/stores/LangStore/LangStore';

export const PackagesDetailsSection: React.FC = () => {
  const deals = useDealStore();
  const { t } = useTranslation();
  const isRtl = useLangStore((state) => state.isRtl);

  return (
    <StyledCard $width="100%" $padding={40} $align="start" $gap={32}>
      <StyledTitle $size={18} level={2}>
        {t('billing.package_details.title')}:
      </StyledTitle>
      <StyledPackageUl>
        <StyledPackageLi>
          <StyledSpan alignRight={isRtl()}>
            {t('vendor.deals.package_details.subscription')}
          </StyledSpan>
          <StyledSpan alignRight={!isRtl()}>
            {SUBSCRIPTION_TYPE2[SUBSCRIPTION_TYPE[deals.subscriptionType]]}
          </StyledSpan>
        </StyledPackageLi>
        <StyledPackageLi>
          <StyledSpan alignRight={isRtl()}>
            {t('vendor.deals.package_details.frameworks_selected')}
          </StyledSpan>
          <StyledSpan alignRight={!isRtl()}>{getFrameworksWithComma(deals.frameworks)}</StyledSpan>
        </StyledPackageLi>
        <StyledPackageLi>
          <StyledSpan alignRight={isRtl()}>{t('vendor.deals.package_details.users')}</StyledSpan>
          <StyledSpan alignRight={!isRtl()}>{deals.users}</StyledSpan>
        </StyledPackageLi>
        <StyledPackageLi>
          <StyledSpan alignRight={isRtl()}>{t('vendor.deals.package_details.domains')}</StyledSpan>
          <StyledSpan alignRight={!isRtl()}>{deals.domains}</StyledSpan>
        </StyledPackageLi>
        <StyledPackageLi>
          <StyledSpan alignRight={isRtl()}>{t('vendor.deals.package_details.training')}</StyledSpan>
          <StyledSpan alignRight={!isRtl()}>{deals.additonalServices}</StyledSpan>
        </StyledPackageLi>
      </StyledPackageUl>
    </StyledCard>
  );
};
