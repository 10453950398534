export const allowedOptionsKeys = {
  accordion: ['headingLevel', 'collapseOthers'],
  button: [],
  carousel: [
    'autoMoving',
    'sliderPickersStyle',
    'styleElementSelector',
    'activePickerClassList',
    'nonActivePickerClassList',
  ],
  checkbox: ['stateHandler'],
  combobox: ['isAutocompleteList', 'closeFunc', 'openFunc'],
  datepicker: ['isMonthClickable', 'isYearClickable'],
  dialog: ['type', 'onOpen', 'onClose', 'blockNavigation', 'avoidFocusOnOpen'],
  form: [],
  grid: ['isColumnHeaderFocusable', 'isRowHeaderFocusable', 'isRowClickable'],
  link: [],
  listbox: ['closeFunc'],
  menu: ['menuDescription'],
  radio: [],
  table: [],
  tabs: ['isVertical'],
  canvas: [],
  tooltip: [],
  pagination: ['autoFocus', 'skipAmount'],
};

export const DATA_COMPONENT = 'data-component';
