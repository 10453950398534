﻿import { A11Y_DIRECTIVE_SELECTOR, A11Y_REF_SELECTOR } from '../../../../../consts';
import { Component, RefId } from '../../../../../types';
import { FormProps } from '@u1/fixers';

const FORM_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-form`;

export const form: Component<FormProps> = {
  selector: `[${FORM_ATTRIBUTE}]`,
  props: {
    selectors: {
      form: `[${FORM_ATTRIBUTE}='form']`,
      formLabelAbsolute: `[${FORM_ATTRIBUTE}='label']`,
      requiredField: `[${FORM_ATTRIBUTE}='required']`,
      errorMsg: `[${FORM_ATTRIBUTE}='err']`,
    },
  },
  handleSpecialCases: (props: FormProps, el: HTMLElement, refId: RefId) => {
    const { selectors } = props;

    const uuid = `[id='${el.id}']`;
    const scope = refId ? '' : uuid;
    const suffix = refId ? `[${A11Y_REF_SELECTOR}='${refId}']` : '';

    selectors.form = `${uuid} ${selectors.form}`;
    selectors.formLabelAbsolute = `${scope} ${selectors.formLabelAbsolute}${suffix}`;
    selectors.errorMsg = `${scope} ${selectors.errorMsg}${suffix}`;
  },
};
