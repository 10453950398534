﻿import { CarouselApi, CarouselProps, CarouselOptions } from '@u1/fixers';
import { useU1Base } from './base';
import { BaseOptions } from '../shared/types';
import { allowedOptionsKeys } from './consts';
import { ComponentName } from '@u1/ui-library-mapper';

export const useU1Carousel = (baseOptions?: BaseOptions<CarouselProps, CarouselOptions>) => {
  return useU1Base(
    CarouselApi,
    false,
    allowedOptionsKeys.carousel,
    ComponentName.CAROUSEL,
    baseOptions
  );
};
