import { useProjects } from '@/hooks/useProjects';
import { U1Button } from '@/shared/components/Button/U1Button';
import { Loading } from '@/shared/components/Loading/Loading';
import { PAGES, RES_STATUS, USER_TYPE } from '@/shared/consts';
import { ConfigListItemModel } from '@/Views/AfterLogin/Main/Client/Projects/projects.consts';
import { StyledCard, StyledCol, StyledRow, StyledRowOffset, StyledTitle } from '@/shared/styles';
import {
  useProjectsDataStore,
  useConfigDataStore,
  useDocsStore,
} from '@/stores/ProjectStore/ProjectStore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StyledConfigPageLayout } from './ConfigurationPage.styles';
import { handleDynamicVariables } from './ConfigurationPage.utils';
import { CongifurationCard } from './CongifurationCard';
import { useUserStore } from '@/stores/UserStore';
import { useTranslation } from 'react-i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';
import { ConfirmModal } from '@/shared/components/Modals/ConfirmModal/ConfirmModal';
import { useAxios } from '@/services/apiClient';

export const ConfigurationPage: React.FC = () => {
  const {
    getDetailedProject,
    getFrameworkConfigData,
    getDocsById,
    projectLoading,
    adminDocsLoading,
  } = useProjects();
  const [successMsg, setSuccessMsg] = useState('');
  const { projectIdParam } = useParams();
  const { navigate } = useU1Navigation();
  const projectsDataStore = useProjectsDataStore();
  const configContent = useConfigDataStore((state) => state.content);
  const userType = useUserStore((state) => state.type);
  const isAdmin = userType === USER_TYPE.ADMIN;
  const { t } = useTranslation();
  const config = useConfigDataStore();
  const docs = useDocsStore();

  useEffect(() => {
    if (projectIdParam) {
      if (isAdmin) {
        getDocsById({ url: `frameworks/${projectIdParam}` });
      } else {
        getDetailedProject({ url: `projects/${projectIdParam}` });
        getFrameworkConfigData({ url: `projects/${projectIdParam}/config` });
      }
    }
  }, [projectIdParam]);

  const [{ response: publishedData }, publishChanges] = useAxios(
    {
      method: 'put',
      url: `frameworks/${projectIdParam}`,
      data: {
        config: config.content,
        docs: docs.docsObj,
      },
    },
    { manual: true }
  );

  useEffect(() => {
    if (publishedData?.status === RES_STATUS.OK) {
      setSuccessMsg(t('projects.docs.component.published'));
    }
  }, [publishedData]);

  return projectLoading || adminDocsLoading ? (
    <Loading />
  ) : (
    <StyledConfigPageLayout>
      <StyledRowOffset>
        <StyledTitle $weight={600} $size={28}>
          {t('projects.configuration.title')}
        </StyledTitle>
        {userType === USER_TYPE.ADMIN && (
          <ConfirmModal
            trigger={<U1Button text={t('projects.docs.component.publish')} />}
            onConfirm={() => {
              publishChanges();
            }}
            title={t('projects.docs.component.sure_you_want_to_publish')}
            successMsg={successMsg}
            setSuccessMsg={setSuccessMsg}
          />
        )}
      </StyledRowOffset>

      <StyledCard $gap={32} $padding={40} $width="100%">
        <StyledCol $gap={32}>
          {configContent?.map((el: ConfigListItemModel, i: number) => {
            return (
              <CongifurationCard
                key={i}
                index={i}
                instructions={el.instructions}
                code={handleDynamicVariables(el, projectsDataStore)}
                name={el.name}
              />
            );
          })}
        </StyledCol>
        <U1Button
          $width="100%"
          $height="50px"
          $padding="8px 0px"
          text={t('close')}
          onClick={() =>
            navigate(isAdmin ? PAGES.AFTER_LOGIN.ADMIN_DOCS : PAGES.AFTER_LOGIN.PROJECTS)
          }
          dataTestId="closeButton"
          $ariaLabel={t('projects.configuration.close')}
        />
      </StyledCard>
    </StyledConfigPageLayout>
  );
};
