import { StyledActiveText } from '@/shared/styles';
import { DealsActions } from './ActionsDropdown/DealsActions';
import { DealsDetails } from '@/stores/Stores.consts';
import { ColumnsType } from 'antd/es/table';
import { t } from 'i18next';

export interface DealsDataType {
  key: string;
  id: string;
  company: string;
  subscription: string;
  period: string;
  price: string;
  admin: string;
  status: string;
  actions: string;
}

export const getColumns = (): ColumnsType<DealsDataType> => [
  {
    title: t('vendor.deals.table.deal_id'),
    dataIndex: 'idNumber',
    key: 'id',
  },
  {
    title: t('vendor.deals.company_name'),
    dataIndex: 'companyName',
    key: 'company',
  },
  {
    title: t('vendor.deals.table.subscription_type'),
    dataIndex: 'subscriptionType',
    key: 'subscription',
  },
  {
    title: t('vendor.deals.table.period'),
    dataIndex: 'period',
    key: 'period',
  },

  {
    title: t('vendor.deals.table.price'),
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: t('vendor.deals.table.admin'),
    dataIndex: 'admin',
    key: 'admin',
  },
  {
    title: t('vendor.deals.table.status'),
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => {
      return status === t(DEAL_STATUS.ACTIVE) ? (
        <StyledActiveText>{status}</StyledActiveText>
      ) : (
        <>{status}</>
      );
    },
  },
  {
    key: 'actions',
    render: (deal: DealsDetails) => {
      return <DealsActions deal={deal} />;
    },
  },
];

export enum DEAL_STATUS {
  DRAFT = 'vendor.deals.table.status.draft',
  ACTIVE = 'vendor.deals.table.status.active',
}
