﻿import { A11Y_DIRECTIVE_SELECTOR } from '../../../../../consts';

export const MENU_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-menu`;

export const DIRECTIVE_SELECTORS = {
  MENU: `${MENU_ATTRIBUTE}~="menu"`,
  MENU_ITEM: `${MENU_ATTRIBUTE}~="item"`,
  SUBMENU: `${MENU_ATTRIBUTE}~="submenu"`,
  TRIGGERS: `${MENU_ATTRIBUTE}~="open-submenu"`,
  RIGHT_TO_LEFT: `${MENU_ATTRIBUTE}~="rtl"`,
  HORIZONTAL_MENU: `${MENU_ATTRIBUTE}~="horizontal"`,
  OPEN_BY_FOCUS: `${MENU_ATTRIBUTE}~="focus"`,
  OPEN_BY_MOUSEOVER: `${MENU_ATTRIBUTE}~="mouseover"`,
};
