import { editIcon } from '@/assets/images/icons';
import { useAxios } from '@/services/apiClient';
import { usePaymentStore } from '@/stores/PaymentStore';
import React, { useEffect, useState } from 'react';
import { IconButton } from '../../Button/IconButton';
import { UpdatePaymentForm } from '../../Forms/UpdatePayment/UpdatePaymentForm';
import { StyledModal } from '../Modals.styles';
import { U1Dialog } from '@u1/react-a11y-hooks';
import { ComponentName } from '@u1/ui-library-mapper';

export const UpdatePaymentModal: React.FC = () => {
  const [open, setOpen] = useState(false);
  const clientSecret = usePaymentStore((state) => state.clientSecret);
  const setClientSecret = usePaymentStore((state) => state.setClientSecret);

  const [{ data: paymentData }, postUpdatePayment] = useAxios(
    {
      method: 'post',
      url: 'payments/methods',
    },
    { manual: true }
  );

  useEffect(() => {
    if (paymentData) {
      setClientSecret(paymentData.token);
    }
  }, [paymentData]);

  const showModal = () => {
    !clientSecret && postUpdatePayment();
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <U1Dialog name={ComponentName.UPDATE_PAYMENT_DIALOG}>
      <IconButton
        $alt="Edit payment method"
        onClick={showModal}
        $icon={editIcon}
        triggerRef="update-payment"
      />
      <StyledModal
        data-a11y-ref="update-payment"
        width={550}
        open={open}
        onCancel={handleCancel}
        footer={[]}
      >
        <UpdatePaymentForm />
      </StyledModal>
    </U1Dialog>
  );
};
