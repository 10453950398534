import { StyledCapitalizedTitle, StyledCol } from '@/shared/styles';
import { StyledDataItem } from '../../DocumentationPage.styles';
import { ClipboardSyntaxHighlighter } from '../../../ClipboardSyntaxHighlighter/ClipboardSyntaxHighlighter';
import { ApiTable } from '../Api/ApiTable';
import { useDocsStore } from '@/stores/ProjectStore/ProjectStore';
import { DocsSection } from '../DocsSection/DocsSection';
import { useTranslation } from 'react-i18next';

export const Page3: React.FC = () => {
  const selectedComponent = useDocsStore((state) => state.selectedComponentDoc);
  const usage = selectedComponent?.usage;
  const { t } = useTranslation();

  return (
    <StyledCol $gap={32}>
      {/* Manual */}
      {usage?.manual?.text && (
        <DocsSection
          title={t('projects.docs.component.page3.manual.title.h2')}
          objkeys={['usage', 'manual', 'text']}
          content={usage.manual.text}
        />
      )}

      {/* Example */}
      {usage?.manual?.example && (
        <ClipboardSyntaxHighlighter
          language="jsx"
          content={usage.manual.example}
          objkeys={['usage', 'manual', 'example']}
        />
      )}

      {/* Selectors */}
      <StyledDataItem>
        {usage?.manual?.table?.selectors && (
          <StyledCapitalizedTitle level={2} $weight={600} $size={18}>
            {t('projects.docs.component.page3.selectors.title.h2')}
          </StyledCapitalizedTitle>
        )}
        {usage?.manual?.table?.selectors && (
          <ApiTable
            dataTable={usage.manual.table.selectors}
            objkeys={['usage', 'manual', 'table', 'selectors']}
          />
        )}
      </StyledDataItem>

      {/* Options */}
      <StyledDataItem>
        {usage?.manual?.table?.options && (
          <>
            <StyledCapitalizedTitle level={2} $weight={600} $size={18}>
              {t('projects.docs.component.page3.options.title.h2')}
            </StyledCapitalizedTitle>
            <ApiTable
              dataTable={usage.manual.table.options}
              objkeys={['usage', 'manual', 'table', 'options']}
            />
          </>
        )}
      </StyledDataItem>
    </StyledCol>
  );
};
