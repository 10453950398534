import React from 'react';
import { TitleAndContentItem } from '../TitleAndContentItem';
import * as PRIVACY from './PrivacyPolicy.consts';
import { PrivacyPolicyDictionary } from './PrivacyPolicyDictionary';
import { StyledStaticPageContainer } from '../StaticPage.styles';

export const PrivacyPolicy: React.FC = () => {
  return (
    <StyledStaticPageContainer>
      <TitleAndContentItem $flex="center" title={PRIVACY.PRIVACY_POLICY_TITLE} />
      <TitleAndContentItem titleLevel={2} $flex="center" title={PRIVACY.PRIVACY_POLICY_SUBTITLE} />
      {PrivacyPolicyDictionary &&
        PrivacyPolicyDictionary.map((item) => (
          <TitleAndContentItem
            key={item.title}
            title={item.title}
            content={item.content}
            list={item.list}
            titleLevel={item.content || item.children ? 3 : 2}
            $flex="start"
          >
            {item.children}
          </TitleAndContentItem>
        ))}
    </StyledStaticPageContainer>
  );
};
