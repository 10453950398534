import { StyledCol, StyledLoading, StyledShimmer } from '@/shared/styles';
import React from 'react';

export const MfaLoading: React.FC = () => {
  return (
    <StyledLoading $gap={32}>
      <StyledCol $gap={50}>
        <StyledShimmer $width={440} $height={60} />
        <StyledShimmer $width={440} $height={60} />
      </StyledCol>
      <StyledShimmer $width={440} $height={50} />
    </StyledLoading>
  );
};
