import { User } from '@/shared/dto';
import { getDateTemplate } from '@/shared/utils';
import { UserDetails } from './Users.consts';
import { v4 as uuidv4 } from 'uuid';
import { TIME } from '@/shared/consts';
import { t } from 'i18next';

export const extractUsers = (userData: User[]) => {
  const usersDetails: UserDetails[] = [];
  userData.forEach((user) => {
    usersDetails.push({
      key: uuidv4(),
      id: user.id,
      email: user.email,
      fullName: user.fullName,
      lastActivity: getLastActivity(user.lastLoginAt),
      role: user.role.toLowerCase(),
      isActive: user.isActive,
    });
  });
  return usersDetails;
};

const getLastActivity = (lastSeenDate: number | null): string => {
  let lastLogin = t('users.table.last_activity.never');
  if (lastSeenDate) {
    const today = new Date(Date.now());
    const lastSeen = new Date(lastSeenDate);
    const diff = today.getTime() - lastSeen.getTime();
    const lastSeenInMinutes = Math.abs(
      Math.round(diff / TIME.MILLI_IN_SECOND / TIME.MINUTES_IN_HOUR)
    );
    if (lastSeenInMinutes >= TIME.MINUTES_IN_HOUR) {
      const lastSeenInHours = lastSeenInMinutes / TIME.MINUTES_IN_HOUR;
      if (lastSeenInHours >= TIME.HOURS_IN_DAY) {
        const lastSeenInDays = lastSeenInHours / TIME.HOURS_IN_DAY;
        if (lastSeenInDays >= TIME.DAYS_IN_WEEK) {
          lastLogin = getDateTemplate(lastSeen);
        } else {
          lastLogin =
            Math.floor(lastSeenInDays) +
            ` ${
              lastSeenInDays > 1
                ? t('users.table.last_activity.day.multiple')
                : t('users.table.last_activity.day.single')
            } ${t('users.table.last_activity.ago')}`;
        }
      } else {
        lastLogin =
          Math.floor(lastSeenInHours) +
          ` ${
            lastSeenInHours > 1
              ? t('users.table.last_activity.hour.multiple')
              : t('users.table.last_activity.hour.single')
          } ${t('users.table.last_activity.ago')}`;
      }
    } else {
      lastLogin =
        Math.floor(lastSeenInMinutes) +
        ` ${
          lastSeenInMinutes > 1
            ? t('users.table.last_activity.minute.multiple')
            : t('users.table.last_activity.minute.single')
        } ${t('users.table.last_activity.ago')}`;
    }
  }
  return lastLogin;
};
