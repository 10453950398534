import { MenuProps } from 'antd';
import { useState } from 'react';
import { StyledMenu } from './Menu.styles';
import { U1Menu } from '@u1/react-a11y-hooks';
import { useMenuItems } from '@/hooks/MenuItems/useMenuItems';

export const MainMenu: React.FC = () => {
  const [current, setCurrent] = useState('platform');
  const { getMenuItems } = useMenuItems();

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
  };

  return (
    <U1Menu>
      <StyledMenu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={getMenuItems()}
        id="main-menu"
      />
    </U1Menu>
  );
};
