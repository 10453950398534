import React, { useEffect } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { U1Button } from '@/shared/components/Button/U1Button';
import { usePaymentStore } from '@/stores/PaymentStore';
import { Payment_Status } from '@/stores/Stores.consts';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { StyledStripeForm } from './Stripe.styles';
import { PaymentFormProps } from './Stripe.consts';
import { useTranslation } from 'react-i18next';

export const PaymentForm: React.FC<PaymentFormProps> = ({
  paymentStatus,
  children,
}: PaymentFormProps) => {
  const setPaymentStatus = usePaymentStore((state) => state.setPaymentStatus);
  const setActiveSubscription = useSubscriptionStore((state) => state.setIsActive);
  const currentPaymentStatus = usePaymentStore((state) => state.paymentStatus);
  const removeClientSecret = usePaymentStore((state) => state.removeClientSecret);
  const { t } = useTranslation();
  // const country = useDealStore((state) => state.client?.country);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const { error } = children
      ? await stripe.confirmPayment({
          elements,
          redirect: 'if_required',
          // confirmParams: {
          //   payment_method_data: {
          //     billing_details: {
          //       address: {
          //         country,
          //       },
          //     },
          //   },
          // },
        })
      : await stripe.confirmSetup({
          elements,
          redirect: 'if_required',
        });

    if (!error) {
      if (currentPaymentStatus === Payment_Status.UPDATED) {
        setPaymentStatus(Payment_Status.CHANGE_CARD);
      } else if (currentPaymentStatus === Payment_Status.CHANGE_CARD) {
        setPaymentStatus(Payment_Status.UPDATED);
      } else {
        setPaymentStatus(paymentStatus ?? Payment_Status.SUCCESS);
      }
      setActiveSubscription(true);
      removeClientSecret();
    }

    if (error?.type === 'card_error') {
      setPaymentStatus(Payment_Status.FAILED);
    }
  };

  // Keep options in case we need to remove country selection from stripe payment
  return (
    <StyledStripeForm id="payment-form" data-testid="cardPaymentForm" onSubmit={handleSubmit}>
      <PaymentElement
      // options={{
      //   fields: { billingDetails: { address: { country: 'never' } } },
      // }}
      />
      {children ?? (
        <U1Button
          dataTestId="payButton"
          htmlType="submit"
          text={paymentStatus === Payment_Status.UPDATED ? t('update') : t('pay')}
          $width="100%"
          $height="45px"
        />
      )}
    </StyledStripeForm>
  );
};
