import { StyledRow } from '@/shared/styles';
import { Carousel } from 'antd';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const StyledCarousel = styled(Carousel)`
  .slick-slide > div {
    display: flex !important;
    justify-content: center;
  }

  .slick-dots li button {
    background: ${(props) => props.theme.textColor} !important;
  }
`;

export const StyledSlideRow = styled.div`
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  gap: 50px;
  width: 70% !important;
  margin: 50px 0px;

  ${isMobile
    ? `
  img {
    max-width: 85px;
    max-height: 60px;
  }   
    `
    : `img{
  width: 7rem;
  }`}
`;

export const StyledPlayBtnsContainer = styled(StyledRow)`
  justify-content: end;
  svg {
    width: 23px;
    height: 23px;
  }
`;
