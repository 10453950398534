import { Pagination } from 'antd';
import styled from 'styled-components';

export const StyledPagination = styled(Pagination)`
  direction: ltr;

  .ant-pagination-item a,
  .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: ${(props) => props.theme.textColor} !important;
    font-size: 16px;
    font-weight: 400;
  }

  .ant-pagination-item-ellipsis {
    color: ${(props) => props.theme.textColor} !important;
    font-size: 10px;
    font-weight: 400;
  }

  .ant-pagination-item.ant-pagination-item-active {
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.secondBorder};
    background: ${(props) => props.theme.marginsColor};
  }

  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    min-width: unset;
    height: unset;
  }

  .ant-pagination-item {
    background: none;
    border: none;
  }

  .ant-pagination-total-text {
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => props.theme.secondaryTextColor};
  }

  .ant-pagination-item-link:focus-visible {
    display: inline-block;
  }

  .ant-pagination-item-link:focus-visible span.ant-pagination-item-link-icon {
    opacity: 1;
  }

  .ant-pagination-item-link:focus-visible .ant-pagination-item-ellipsis {
    opacity: 0;
  }
`;
