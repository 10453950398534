import React, { useEffect, useState } from 'react';
import { StyledMenuItem } from '@/shared/styles/dropdownMenu.styles';
import { StyledContent, StyledSecondaryText } from '@/shared/styles';
import { useFormMessages } from '@/hooks/useFormMessages';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { ResendUserProps } from './UserModals.consts';
import { useAxios } from '@/services/apiClient';
import { RES_STATUS } from '@/shared/consts';
import { useTranslation } from 'react-i18next';

export const ResendDetailsModal: React.FC<ResendUserProps> = ({
  id,
  userName,
}: ResendUserProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { clearAllMessages } = useFormMessages();
  const { t } = useTranslation();

  const [{ response }, postSendInvite] = useAxios({
    method: 'post',
  });

  useEffect(() => {
    if (response?.status === RES_STATUS.CREATED) {
      setIsOpen(false);
    }
  }, [response]);

  const showModal = () => {
    setIsOpen(true);
  };

  const onConfirm = () => {
    postSendInvite({ url: `vendors/${id}/send-invitation` });
  };

  const onCancel = () => {
    setIsOpen(false);
    clearAllMessages();
  };

  return (
    <ConfirmModal
      title={t('admin.resend.title')}
      trigger={<StyledMenuItem onClick={showModal}>{t('admin.actions.resend')}</StyledMenuItem>}
      confirmBtnText={t('send')}
      cancelBtnText={t('cancel')}
      onConfirm={onConfirm}
      onCancel={onCancel}
      isOpen={isOpen}
    >
      <StyledSecondaryText fontWeight={400} fontSize={'16px'}>
        {t('admin.resend.content')}
      </StyledSecondaryText>
      <StyledContent fontWeight={400} fontSize={'16px'}>
        {userName}
      </StyledContent>
    </ConfirmModal>
  );
};
