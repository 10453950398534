import React, { useState } from 'react';
import { U1Button } from '../../Button/U1Button';
import { InviteUserForm } from '../../Forms/InviteUser/InviteUserForm';
import { InviteModalProps } from '../Modals.consts';
import { StyledModal } from '../Modals.styles';
import { U1Dialog } from '@u1/react-a11y-hooks';
import { ComponentName } from '@u1/ui-library-mapper';
import { useTranslation } from 'react-i18next';

export const InviteModal: React.FC<InviteModalProps> = ({ getUsersData }: InviteModalProps) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { t } = useTranslation();

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  return (
    <U1Dialog name={ComponentName.INVITE_DIALOG}>
      <U1Button
        text={t('users.invite_user')}
        dataTestId="inviteUsersButton"
        $height="50px"
        $width="250px"
        onClick={showModal}
        triggerRef="invite-users"
      />
      <StyledModal
        data-a11y-ref="invite-users"
        width={550}
        open={open}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        footer={[]}
      >
        <InviteUserForm getUsersData={getUsersData} onOK={handleOk} />
      </StyledModal>
    </U1Dialog>
  );
};
