import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { FreeCheckout } from './FreeCheckout';
import { Pricing } from '@/shared/pages/Pricing/Pricing';
import { SUB_STATUS } from '@/stores/Stores.consts';

export const FreeSelection: React.FC = () => {
  const subscription = useSubscriptionStore();
  return subscription.subscriptionStatus === SUB_STATUS.NO_SUBSCRIPTION ? (
    <FreeCheckout />
  ) : (
    <Pricing />
  );
};
