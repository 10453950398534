import { antd } from './frameworks/react/antd';
import { u1_react } from './frameworks/react/u1';
import { Framework, Libraries, LibraryName } from '../types';

export { antd, u1_react };

export const LIBRARIES: Libraries = {
  [Framework.REACT]: {
    [LibraryName.ANTD]: antd,
    [LibraryName.U1]: u1_react,
  },
};
