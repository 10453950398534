import { StyledImg, StyledRow, StyledSecondaryText } from '@/shared/styles';
import React from 'react';
import { PasswordValidationProps } from '../../Forms.consts';
import { vIcon, xIcon } from '@/assets/images/icons';

export const PasswordValidation: React.FC<PasswordValidationProps> = ({
  isValid,
  error,
}: PasswordValidationProps) => {
  return (
    <StyledRow $gap={16}>
      <StyledImg src={isValid ? vIcon : xIcon} />
      <StyledSecondaryText fontSize={'14px'} fontWeight={400}>
        {error}
      </StyledSecondaryText>
    </StyledRow>
  );
};
