import { IconButton } from '@/shared/components/Button/IconButton';
import { cancelIcon, deleteIcon, editIcon, saveIcon } from '@/assets/images/icons';
import { useDocsStore } from '@/stores/ProjectStore/ProjectStore';
import { StyledEditIcons } from './ApiTable.styles';
import { EditIconsProps } from './ApiTable.consts';

export const EditIcons: React.FC<EditIconsProps> = ({
  index,
  objkeys,
  isVisible,
  isEditMode,
  editableData,
  setIsEditMode,
}: EditIconsProps) => {
  const updateDocs = useDocsStore((state) => state.updateDocsObj);
  const removeFromDocs = useDocsStore((state) => state.removeFromDocs);

  const editContent = () => {
    setIsEditMode(true);
  };

  const cancelEdit = () => {
    setIsEditMode(false);
  };

  const saveEdit = () => {
    if (objkeys) {
      updateDocs(objkeys, editableData, index);
      setIsEditMode(false);
    }
  };

  const deleteContent = () => {
    if (objkeys) {
      removeFromDocs(objkeys, index);
      setIsEditMode(false);
    }
  };

  return (
    <StyledEditIcons isVisible={isVisible}>
      {isEditMode ? (
        <>
          <IconButton $alt="cancel edit" onClick={cancelEdit} $icon={cancelIcon} />
          <IconButton $alt="save content" onClick={saveEdit} $icon={saveIcon} />
        </>
      ) : (
        <>
          <IconButton $alt="edit text" onClick={editContent} $icon={editIcon} />
          <IconButton $alt="delete text" onClick={deleteContent} $icon={deleteIcon} />
        </>
      )}
    </StyledEditIcons>
  );
};
