import { StyledCol, StyledInput } from '@/shared/styles';
import { useNewProjectStore } from '@/stores/ProjectStore/ProjectStore';
import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { DomainProperties, getFieldsSettings } from '../Forms.consts';
import { StyledHelperText, StyledLabel, StyledNewProjectForm } from '../Forms.styles';
import { useTranslation } from 'react-i18next';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';

export const NewProjectForm: React.FC = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const newProjectStore = useNewProjectStore();
  const projectFramework = newProjectStore.projectFramework;
  const subscriptionStore = useSubscriptionStore();
  const [registrationDetails, setRegistrationDetails] = useState<DomainProperties[]>();

  useEffect(() => {
    if (projectFramework) {
      const fields = subscriptionStore.requiredFields[projectFramework];
      setRegistrationDetails(getFieldsSettings(fields));
    }
  }, [projectFramework]);

  const onValuesChange = () => {
    newProjectStore.setProject &&
      newProjectStore.setProject({
        ...newProjectStore,
        projectDomain: form.getFieldValue('projectDomain'),
        projectName: form.getFieldValue('projectName'),
      });
  };

  return (
    <StyledNewProjectForm form={form} onValuesChange={onValuesChange}>
      <StyledLabel htmlFor="projectName">{t('projects.new_project.form.name.text')}</StyledLabel>
      <Form.Item
        id="projectName"
        name="projectName"
        rules={[
          { required: true, message: t('projects.new_project.form.name.required'), max: 150 },
        ]}
      >
        <StyledInput
          data-testid="projectNameField"
          placeholder={t('projects.new_project.form.name.placeholder')}
        />
      </Form.Item>
      {registrationDetails?.map((rd) => {
        return (
          <>
            <StyledCol $gap={8}>
              <StyledLabel htmlFor={'projectDomain'}>{rd.label}</StyledLabel>
              <StyledHelperText>{rd.helperText}</StyledHelperText>
            </StyledCol>
            <Form.Item
              id="projectDomain"
              name="projectDomain"
              rules={[
                { required: true, message: rd.requiredMessage },
                { pattern: rd.pattern, message: rd.patternMessage },
              ]}
            >
              <StyledInput data-testid="projectDomainField" placeholder={rd.placeholder} />
            </Form.Item>
          </>
        );
      })}
    </StyledNewProjectForm>
  );
};
