import { Component } from '../../../../../types';
import { CheckboxProps } from '@u1/fixers';
import { A11Y_DIRECTIVE_SELECTOR } from '../../../../../consts';

const CHECKBOX_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-checkbox`;

export const checkbox: Component<CheckboxProps> = {
  selector: `[${CHECKBOX_ATTRIBUTE}]`,
  props: {
    selectors: {
      element: `[${CHECKBOX_ATTRIBUTE}='checkbox']`,
      checkedState: `[${CHECKBOX_ATTRIBUTE}='checkbox'][${CHECKBOX_ATTRIBUTE}-checked='true']`,
      uncheckedState: `[${CHECKBOX_ATTRIBUTE}='checkbox'][${CHECKBOX_ATTRIBUTE}-checked='false']`,
      exclude: `[${CHECKBOX_ATTRIBUTE}='exclude']`,
    },
  },
};
