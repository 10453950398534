import { StyledCol } from '@/shared/styles';
import { isDesktop, isMobile } from 'react-device-detect';
import styled from 'styled-components';

export const StyledAdaptiveInstitutions = styled.div`
  ${isMobile
    ? `
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 14px;
    `
    : ` 
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    align-items: center;
    align-content: center;
    gap: 20px; 
    flex-wrap: wrap;

    @media (max-width: 1535px){
      justify-content: center; 
    }
    `}
`;

export const StyledInstiturionsCol = styled(StyledCol)`
  gap: 32px;
  ${isDesktop && `flex: 0 0 40%;`}
`;

export const StyledInstitutionBox = styled.div`
  display: flex;
  justify-content: center;
  border: 2px solid ${(props) => props.theme.landingPageBorder};
  background: ${(props) => props.theme.sameColorBox};
  border-radius: 15px;
  width: ${isMobile ? '165px' : 'fit-content'};
  height: ${isMobile ? '165px' : 'fit-content'};
  padding: 35px;

  img {
    width: 7rem;
  }
`;
