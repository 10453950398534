import React from 'react';
import { Radio, RadioChangeEvent, Space } from 'antd';
import { RadioGroupProps } from './Radio.consts';
import { StyledRadioGroup } from './Radio.styles';
import { v4 as uuidv4 } from 'uuid';
import { Icon } from '../Icon';
import { U1RadioGroup } from '@u1/react-a11y-hooks';

export const RadioGroup: React.FC<RadioGroupProps> = ({
  radioValues,
  radioDirection,
  value,
  setValue,
  icons,
  dataTestId,
}: RadioGroupProps) => {
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  return (
    <U1RadioGroup>
      <StyledRadioGroup onChange={onChange} value={value} data-testid={dataTestId}>
        <Space direction={radioDirection ?? 'vertical'}>
          {radioValues.map((val, index) => {
            return (
              <Radio key={uuidv4()} value={index}>
                {val}
                {icons && icons[index] && <Icon $src={icons[index]} $size={20} />}
              </Radio>
            );
          })}
        </Space>
      </StyledRadioGroup>
    </U1RadioGroup>
  );
};
