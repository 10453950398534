import { A11Y_DIRECTIVE_SELECTOR, SCOPE_PH, SUFFIX_PH } from '../../../../../consts';
import { Component } from '../../../../../types';
import { ComboboxProps } from '@u1/fixers';

const COMBOBOX_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-combobox`;
const COMBOBOX_COMPONENTS = {
  TEXTBOX: 'textbox',
  OPTION: 'option',
  LABEL: 'label',
  LISTBOX: 'listbox',
  COMBOBOX: 'combobox',
  EMPTY_OPTION: 'empty-option',
};
export const combobox: Component<ComboboxProps> = {
  selector: `[${COMBOBOX_ATTRIBUTE}]`,
  props: {
    selectors: {
      textbox: `${SCOPE_PH} [${COMBOBOX_ATTRIBUTE}=${COMBOBOX_COMPONENTS.COMBOBOX}]${SUFFIX_PH} [${COMBOBOX_ATTRIBUTE}=${COMBOBOX_COMPONENTS.TEXTBOX}]`,
      options: `${SCOPE_PH} [${COMBOBOX_ATTRIBUTE}=${COMBOBOX_COMPONENTS.LISTBOX}]${SUFFIX_PH} [${COMBOBOX_ATTRIBUTE}=${COMBOBOX_COMPONENTS.OPTION}]`,
      listbox: `${SCOPE_PH} [${COMBOBOX_ATTRIBUTE}=${COMBOBOX_COMPONENTS.LISTBOX}]${SUFFIX_PH}`,
      combobox: `${SCOPE_PH} [${COMBOBOX_ATTRIBUTE}=${COMBOBOX_COMPONENTS.COMBOBOX}]${SUFFIX_PH}`,
      label: `${SCOPE_PH} [${COMBOBOX_ATTRIBUTE}=${COMBOBOX_COMPONENTS.LABEL}]${SUFFIX_PH}`,
      emptyOption: `${SCOPE_PH} [${COMBOBOX_ATTRIBUTE}=${COMBOBOX_COMPONENTS.EMPTY_OPTION}]${SUFFIX_PH}`,
    },
    isAutocompleteList: true,
  },
};
