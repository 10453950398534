import { ComponentName, Library } from '../../../../types';
import { datepicker } from './datepicker/datepicker';
import { link } from './link/link';
import { listbox } from './listbox/listbox';
import { menu } from './menu/menu';
import { tabs } from './tabs/tabs';
import {
  addUserDialog,
  cancelSubscriptionDialog,
  confirmDialog,
  contactUsDialog,
  dialog,
  editUserDialog,
  inviteDialog,
  mobileMenuDialog,
  renewSubscriptionDialog,
  updatePaymentDialog,
} from './dialog/dialog';
import { userDropdown } from './dialog/userDropdown';
import { sideMenu } from './menu/sideMenu';
import { checkbox } from './checkbox/Checkbox';
import { grid } from './table/grid';
import { radio } from './radio/radio';
import { confirmPopup } from './dialog/confirmPopup';
import { form } from './form/form';
import { button } from './button/button';
import { search } from './search/search';
import { pagination } from './pagination/pagination';
import { carousel } from './carousel/carousel';

const {
  DATEPICKER,
  DIALOG,
  CONTACT_US_DIALOG,
  CONFIRM_DIALOG,
  UPDATE_PAYMENT_DIALOG,
  INVITE_DIALOG,
  CANCEL_SUBSCRIPTION_DIALOG,
  RENEW_SUBSCRIPTION_DIALOG,
  MOBILE_MENU_DIALOG,
  ADD_DIALOG,
  EDIT_DIALOG,
  LINK,
  BUTTON,
  LISTBOX,
  MENU,
  TABS,
  DROPDOWN,
  SIDMENU,
  CHECKBOX,
  GRID,
  RADIO,
  POPUP,
  FORM,
  SEARCH,
  PAGINATION,
  CAROUSEL,
} = ComponentName;

export const antd: Library = {
  [LISTBOX]: listbox,
  [DATEPICKER]: datepicker,
  [MENU]: menu,
  [TABS]: tabs,
  [LINK]: link,
  [BUTTON]: button,
  [SEARCH]: search,
  [DIALOG]: dialog,
  [CONTACT_US_DIALOG]: contactUsDialog,
  [CONFIRM_DIALOG]: confirmDialog,
  [EDIT_DIALOG]: editUserDialog,
  [ADD_DIALOG]: addUserDialog,
  [UPDATE_PAYMENT_DIALOG]: updatePaymentDialog,
  [INVITE_DIALOG]: inviteDialog,
  [CANCEL_SUBSCRIPTION_DIALOG]: cancelSubscriptionDialog,
  [RENEW_SUBSCRIPTION_DIALOG]: renewSubscriptionDialog,
  [MOBILE_MENU_DIALOG]: mobileMenuDialog,
  [DROPDOWN]: userDropdown,
  [POPUP]: confirmPopup,
  [SIDMENU]: sideMenu,
  [CHECKBOX]: checkbox,
  [GRID]: grid,
  [RADIO]: radio,
  [FORM]: form,
  [PAGINATION]: pagination,
  [CAROUSEL]: carousel,
};
