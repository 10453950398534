import { CardDetails, UserPaymentMethod } from '@/shared/dto';
import { useAxios } from '@/services/apiClient';
import { useEffect, useState } from 'react';

export const usePayment = () => {
  const [cardDetails, setCardDetails] = useState<CardDetails>({
    brand: '',
    last4: '',
  });

  const [{ data: paymentData }, getPayment] = useAxios({
    method: 'get',
    url: '/payments/methods',
  });

  const getTimedOutPayment = () => {
    setTimeout(() => {
      getPayment();
    }, 500);
  };

  useEffect(() => {
    if (paymentData) {
      const paymentMethod: UserPaymentMethod = paymentData.find(
        (data: UserPaymentMethod) => data.isDefault
      );
      setCardDetails({
        brand: paymentMethod.card?.brand,
        last4: paymentMethod.card?.last4,
      });
    }
  }, [paymentData]);

  return { getTimedOutPayment, cardDetails };
};
