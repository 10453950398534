import { Component } from '../../../../../types';
import { CanvasProps } from '@u1/fixers';
import { A11Y_DIRECTIVE_SELECTOR } from '../../../../../consts';

const CANVAS_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-canvas`;

export const canvas: Component<CanvasProps> = {
  selector: `[${CANVAS_ATTRIBUTE}]`,
  props: {
    selectors: {
      element: `[${CANVAS_ATTRIBUTE}="canvas"]`,
      clearBtn: `[${CANVAS_ATTRIBUTE}="clear-btn"]`,
    },
  },
};
