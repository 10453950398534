import { StyledRow, StyledShimmer } from '@/shared/styles';

export const BillingLoading: React.FC = () => {
  return (
    <StyledRow $gap={32}>
      <StyledShimmer $width={525} $height={735} />
      <StyledShimmer $width={715} $height={360} />
    </StyledRow>
  );
};
