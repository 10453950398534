import { StyledCol } from '@/shared/styles';
import { ClipboardSyntaxHighlighter } from '../../../ClipboardSyntaxHighlighter/ClipboardSyntaxHighlighter';
import { useDocsStore } from '@/stores/ProjectStore/ProjectStore';
import { DocsSection } from '../DocsSection/DocsSection';
import { useTranslation } from 'react-i18next';

export const Page2: React.FC = () => {
  const selectedComponent = useDocsStore((state) => state.selectedComponentDoc);
  const usage = selectedComponent?.usage;
  const { t } = useTranslation();

  return (
    <StyledCol $gap={32}>
      {/* Landmarks */}
      {usage?.landmarks?.text && (
        <DocsSection
          title={t('projects.docs.component.getting_started.landmarks.title')}
          objkeys={['usage', 'landmarks', 'text']}
          content={usage.landmarks.text}
        />
      )}

      {/* Native */}
      {usage?.landmarks?.native?.before && (
        <DocsSection
          secondTitle={t('projects.docs.component.getting_started.shared.native.title')}
          objkeys={['usage', 'landmarks', 'native', 'before']}
          content={usage.landmarks.native.before}
        />
      )}
      {usage?.landmarks?.native?.example && (
        <ClipboardSyntaxHighlighter
          language="html"
          content={usage.landmarks.native.example}
          objkeys={['usage', 'landmarks', 'native', 'example']}
        />
      )}
      {usage?.landmarks?.native?.after && (
        <DocsSection
          objkeys={['usage', 'landmarks', 'native', 'after']}
          content={usage.landmarks.native.after}
        />
      )}

      {/* ARIA */}
      {usage?.landmarks?.native?.before && (
        <DocsSection
          secondTitle={t('projects.docs.component.getting_started.shared.aria.title')}
          objkeys={['usage', 'landmarks', 'aria', 'before']}
          content={usage.landmarks.aria.before}
        />
      )}
      {usage?.landmarks?.aria?.example && (
        <ClipboardSyntaxHighlighter
          language="html"
          content={usage.landmarks.aria.example}
          objkeys={['usage', 'landmarks', 'aria', 'example']}
        />
      )}
      {usage?.landmarks?.aria?.after && (
        <DocsSection
          objkeys={['usage', 'landmarks', 'aria', 'after']}
          content={usage.landmarks.aria.after}
        />
      )}
    </StyledCol>
  );
};
