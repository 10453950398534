import { StyledRow } from '@/shared/styles';
import { DealInfo } from './DealInfoBlock/DealInfoBlock';
import { useStatsStore } from '@/stores/StatsStore.tsx/StatsStore';
import { useEffect } from 'react';
import { getPriceFormat } from '@/shared/utils';
import { useTranslation } from 'react-i18next';

export const DealsStats: React.FC = () => {
  const stats = useStatsStore();
  const dealsStats = stats.dealsStats;
  const { t } = useTranslation();

  useEffect(() => {
    stats.getGeneralStats();
  }, []);

  return (
    <StyledRow $gap={24}>
      <DealInfo
        title={t('vendor.stats.deals_stats.active.title')}
        content={`${getPriceFormat(dealsStats.activeDeals.sum)} USD`}
        description={`${dealsStats.activeDeals.count} deals`}
      />
      <DealInfo
        title={t('vendor.stats.deals_stats.average_deal.title')}
        content={`${getPriceFormat(dealsStats.averagePrice)} USD`}
      />
      <DealInfo
        title={t('vendor.stats.deals_stats.draft_deals.title')}
        content={`${dealsStats.draftDeals} ${t('vendor.stats.deals_stats.deals')}`}
      />
    </StyledRow>
  );
};
