import { SimpleSelect } from '../../SimpleSelect/SimpleSelect';
import { useDatepickerStore } from '@/stores/DatepickerStore/DatepickerStore';
import { monthsNames } from '../Datepicker.consts';
import { StyledRowOffset } from '@/shared/styles';
import { getAllYears } from '../Datepicker.utils';

export const DatepickerHeader: React.FC = () => {
  const { month, year, setMonth, setYear } = useDatepickerStore();

  return (
    <StyledRowOffset>
      <SimpleSelect
        className="select-month"
        label={'select a month'}
        options={monthsNames.map((month, i) => ({ value: i + 1, label: month }))}
        value={monthsNames[month - 1]}
        onSelect={(val) => {
          setMonth(Number(val));
        }}
      />
      <SimpleSelect
        className="select-year"
        label={'Select a year'}
        options={getAllYears().map((year) => ({ value: year, label: String(year) }))}
        value={String(year)}
        onSelect={(val) => {
          setYear(Number(val));
        }}
      />
    </StyledRowOffset>
  );
};
