import { MenuItemProps } from '@/hooks/MenuItems/useMenuItems';

export const getItem = (item: MenuItemProps) => {
  return (
    <li key={item.key}>
      <a href={item.href}>
        {item.icon && item.icon} {item.text}
      </a>
    </li>
  );
};

export const getMobileMenuItems = (menuItems: MenuItemProps[]) => {
  return menuItems?.map((mi) => {
    if (mi.children) {
      return (
        <li key={mi.key} className="has-submenu">
          {/* keep expand collapse for the future, when we have more links in submenu */}
          {/* <Collapse ghost expandIcon={() => <></>}>
            <Panel header={mi.text} key="1"> */}
          {mi.text}
          <ul>
            {mi.children.map((child) => {
              return getItem(child);
            })}
          </ul>
          {/* </Panel>
          </Collapse> */}
        </li>
      );
    } else {
      return getItem(mi);
    }
  });
};
