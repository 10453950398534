import { DealsActionsProps } from '@/Views/AfterLogin/Main/SearchPanel/Actions/UserActions.consts';
import { StyledMenuItem, StyledMenuItemRow } from '@/shared/styles/dropdownMenu.styles';
import React from 'react';
import { SendCredentials } from '../../../SendCredentials/SendCredentials';
import { useTranslation } from 'react-i18next';

export const SendCredentialsItem: React.FC<DealsActionsProps> = ({ deal }: DealsActionsProps) => {
  const { t } = useTranslation();
  return (
    <SendCredentials
      deal={deal}
      trigger={
        <StyledMenuItemRow>
          <StyledMenuItem>
            {t('vendor.deals.create_deal.payment.view_deal.action.send_credentials')}
          </StyledMenuItem>
        </StyledMenuItemRow>
      }
    />
  );
};
