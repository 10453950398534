import { computerIcon } from '@/assets/images/icons';
import { Icon } from '@/shared/components/Icon';
import { StyledCard, StyledCol, StyledText, StyledTitle } from '@/shared/styles';
import { MobileDisabled } from './MobileDisabled';
import { useTranslation } from 'react-i18next';

export const UnavailableOnMobile = () => {
  const { t } = useTranslation();
  return (
    <StyledCol $gap={24}>
      <StyledTitle $size={20} $align="center">
        {t('mobile.accessible_from_desktop.title')}
      </StyledTitle>
      <StyledCard $gap={32} $padding={24}>
        <Icon $src={computerIcon} />
        <StyledText textAlign="center">{t('mobile.accessible_from_desktop.content')}</StyledText>
        <MobileDisabled />
      </StyledCard>
    </StyledCol>
  );
};
