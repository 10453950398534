import { ReactNode } from 'react';
import { PackgeDetailsProps, TopSectionProps } from '../Pricing.consts';
import { CustomPackageDetails } from './Custom/CustomPackageDetails';
import { CustomTopSection } from './Custom/CustomTopSection';
import { FreePackageDetails } from './Free/FreePackageDetails';
import { FreeTopSection } from './Free/FreeTopSection';
import { MainPackageDetails } from './Main/MainPackageDetails';
import { MainTopSection } from './Main/MainTopSection';

export const getPackageTopSection = (props: TopSectionProps): Record<string, ReactNode> => {
  return {
    MAIN: <MainTopSection {...props} />,
    CUSTOM: <CustomTopSection {...props} />,
    FREE: <FreeTopSection {...props} />,
  };
};

export const getPackageDetails = (props: PackgeDetailsProps): Record<string, ReactNode> => {
  return {
    MAIN: <MainPackageDetails {...props} />,
    CUSTOM: <CustomPackageDetails {...props} />,
    FREE: <FreePackageDetails {...props} />,
  };
};
