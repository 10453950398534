import { ContactUsModal } from '@/shared/components/Modals/ContactUsModal/ContactUsModal';
import { StyledColOffset } from '@/shared/styles';
import { PackgeDetailsProps } from '../../Pricing.consts';
import { StyledDetailsContainer } from '../Package.styles';
import { PackageDetails } from '../PackageDetails/PackageDetails';
import { useTranslation } from 'react-i18next';
import { U1Button } from '@/shared/components/Button/U1Button';

export const CustomPackageDetails: React.FC<PackgeDetailsProps> = ({
  users,
  frameworks,
  domains,
}: PackgeDetailsProps) => {
  const { t } = useTranslation();
  return (
    <StyledColOffset>
      <StyledDetailsContainer>
        <PackageDetails
          users={users.quantity > -1 ? users.quantityTitle : ''}
          frameworks={frameworks.quantity > -1 ? frameworks.quantityTitle : ''}
          domains={domains.quantity > -1 ? domains.quantityTitle : ''}
          training={''}
        />
        <ContactUsModal
          pricingPage={true}
          refName="contact-us"
          trigger={
            <U1Button
              key="contact-us"
              triggerRef="contact-us"
              dataTestId="contactUsButton"
              text={t('pricing.package.contact_us')}
              $height={'40px'}
            />
          }
        />
      </StyledDetailsContainer>
    </StyledColOffset>
  );
};
