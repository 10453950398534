import { PaginationApi, PaginationProps } from '@u1/fixers';
import { useU1Base } from './base';
import { allowedOptionsKeys } from './consts';
import { BaseOptions } from '../shared/types';
import { ComponentName } from '@u1/ui-library-mapper';
import { PaginationOptions } from '@u1/fixers/Fixers/Pagination/interfaces/PaginationProps.interface';

export const useU1Pagination = (baseOptions?: BaseOptions<PaginationProps, PaginationOptions>) => {
  return useU1Base(
    PaginationApi,
    false,
    allowedOptionsKeys.pagination,
    ComponentName.PAGINATION,
    baseOptions
  );
};
