import { create } from 'zustand';
import { AvailableLang, LangDto, LangStore, defaultLanguages } from './LangStore.consts';
import { _axios } from '@/services/apiClient';
import i18n from '@/services/localization';
import { getFromCache, putToCache } from '@/services/cacheService';
import { STORAGE_KEYS } from '@/shared/consts';

export const useLangStore = create<LangStore>((set, get) => ({
  availableLangs: defaultLanguages,
  getLanguageCode: (value: string) => {
    return get().availableLangs.find((lang) => lang.value === Number(value))?.code;
  },
  getAllLanguages: () => {
    if (get().availableLangs.length > defaultLanguages.length) return;

    const cachedLanguages = getFromCache<AvailableLang[]>(STORAGE_KEYS.AVAILABLE_LANGS);

    if (cachedLanguages) {
      set((state) => ({ ...state, availableLangs: cachedLanguages }));
      return Promise.resolve(cachedLanguages);
    }
    return _axios
      .get('translations/languages')
      .then((data) => {
        const languages = data.data.filter((lang: LangDto) => lang.active);
        const availableLangs: AvailableLang[] = languages.map(
          (lang: LangDto, i: number): AvailableLang => ({
            value: i + 1,
            label: lang.name,
            code: lang.code,
            direction: lang.direction,
          })
        );

        putToCache(STORAGE_KEYS.AVAILABLE_LANGS, availableLangs);

        set((state) => ({ ...state, availableLangs }));
      })
      .catch((e) => {
        // Catch error without crashing the App, don't remove this catch
        console.error(e);
      });
  },
  getSupportedLanguages: () => {
    return get().availableLangs.map(({ code }): string => code);
  },
  isLanguageSupported: (language: string) => {
    return get().getSupportedLanguages().includes(language);
  },
  getSelectedLang: () => {
    return i18n.language;
  },
  getLangDir: () => {
    const selectedLang = get().getSelectedLang();
    return get().availableLangs.find((lang) => lang.code === selectedLang)?.direction;
  },
  isRtl: () => {
    return get().getLangDir() === 'rtl';
  },
}));
