export enum SETTINGS_VIEW {
  MAIN,
  PERSONAL_DETAILS,
  PASSWORD,
}

export type SettingsStore = {
  view: SETTINGS_VIEW;
  setView: (view: SETTINGS_VIEW) => void;
};
