import { DatepickerProps } from '@u1/fixers';
import { DIRECTIVE_SELECTORS } from './consts';
import { SCOPE_PH, SUFFIX_PH } from '../../../../../consts';
import { Component } from '../../../../../types';

const { TRIGGER, MONTH, YEAR, DAYS, DATEPICKER, CONTAINER } = DIRECTIVE_SELECTORS;

const handleSpecialCases = (props: DatepickerProps, el: HTMLElement) => {
  props.selectors.container = `#${el.id} ${props.selectors.container}`;
};

export const datepicker: Component<DatepickerProps> = {
  selector: `[${DATEPICKER}]`,
  handleSpecialCases,
  props: {
    selectors: {
      container: `[${CONTAINER}]`,
      trigger: `${SCOPE_PH} [${TRIGGER}]${SUFFIX_PH}`,
      month: {
        label: `[${MONTH.TEXT.SELECTOR}]`,
        prevButton: `[${MONTH.PREV_BUTTON}]`,
        nextButton: `[${MONTH.NEXT_BUTTON}]`,
      },
      year: {
        label: `[${YEAR.TEXT.SELECTOR}]`,
        prevButton: `[${YEAR.PREV_BUTTON}]`,
        nextButton: `[${YEAR.NEXT_BUTTON}]`,
      },
      days: {
        table: `[${DAYS.TABLE}]`,
        day: `[${DAYS.DAY}]`,
        selected: `[${DAYS.SELECTED}]`,
        disabled: `[${DAYS.DISABLE}]`,
      },
    },
    isMonthClickable: false,
    isYearClickable: false,
  },
};
