import { StyledRow, StyledTitle } from '@/shared/styles';
import styled from 'styled-components';

export const StyledDetailsRow = styled(StyledRow)`
  justify-content: space-between;
  width: 100%;
  position: relative;
  height: 36px;

  a {
    z-index: 1;
  }
`;

export const StyledDetilsTitle = styled(StyledTitle)`
  position: absolute;
  width: 100%;
  text-align: center;
`;
