import { DealStore } from '@/stores/DealStore/DealsStore.consts';
import { SUBSCRIPTION_TYPE } from '../../../../Deals.consts';
import { CheckboxGroupProps } from 'antd/es/checkbox';

export const getDealObject = (deals: DealStore) => {
  const data = {
    product: {
      price: Number(deals.price),
      modifications: [
        {
          code: 'FRAMEWORKS',
          quantity: deals.frameworks.length,
          options: deals.frameworks,
        },
        {
          code: 'USERS',
          quantity: Number(deals.users),
        },
        {
          code: 'DOMAINS',
          quantity: Number(deals.domains),
        },
      ],
    },
    paymentInterval: SUBSCRIPTION_TYPE[deals.subscriptionType],
    step: 'PRODUCT',
  };

  if (deals.additonalServices) {
    const additionalServices = {
      code: 'ADDITIONAL_AUDITING_SERVICE',
      quantity: 1,
      options: [String(deals.additonalServices)] as CheckboxGroupProps[],
    };
    data.product.modifications.push(additionalServices);
  }

  return data;
};
