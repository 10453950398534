import React from 'react';
import { Dropdown } from 'antd';
import { StyledIcon } from '@/shared/styles';
import { calendarIcon } from '@/assets/images/icons';
import { Datepicker } from '../Datepicker';
import { StyledNoStyleButton } from '../Datepicker.styles';
import { useDatepickerStore } from '@/stores/DatepickerStore/DatepickerStore';

export const DatepickerTrigger: React.FC = () => {
  const { isOpen, setIsOpen } = useDatepickerStore();

  return (
    <Dropdown
      open={isOpen}
      onOpenChange={setIsOpen}
      dropdownRender={() => <Datepicker />}
      placement="bottomLeft"
      trigger={['click']}
      overlayClassName="datepicker"
      getPopupContainer={() => document.querySelector('.App') as HTMLElement}
    >
      <StyledNoStyleButton
        className="datepicker-trigger"
        aria-label="Select date range"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        <StyledIcon src={calendarIcon} />
      </StyledNoStyleButton>
    </Dropdown>
  );
};
