import { useProducts } from '@/hooks/useProducts';
import { NUM_OF_DAYS } from '@/shared/consts';
import { StyledOrangeGradTitle } from '@/shared/pages/Pricing/Intro/Intro.styles';
import { StyledbulletList, StyledCol, StyledRowOffset, StyledSecondaryText } from '@/shared/styles';
import { getRemainingDays } from '@/shared/utils';
import { usePackagesStore } from '@/stores/PackagesStore';
import { useExtraSelectionStore } from '@/stores/SelectionStore';
import { SubscriptionDetails } from '@/stores/Stores.consts';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import React, { useEffect, useState } from 'react';
import { calcExtraTraining } from '../../../Checkout/Checkout.utils';
import { StyledFrameworks } from '../../../Checkout/OrderSummary/OrderSummary.styles';
import { calcUpgradeDomains, calcUpgradeFrameworks } from '../../UpgradePackage.utils';
import { UpgradePackageProps } from '../../UpgradePackage.consts';
import { StyledSelectOptions } from '../../UpgradePackage.styles';
import { useTranslation } from 'react-i18next';

export const Upgrading: React.FC<UpgradePackageProps> = ({ currentPlan }: UpgradePackageProps) => {
  const { getProducts } = useProducts();
  const userSelection = useExtraSelectionStore();
  const subscription = useSubscriptionStore();
  const packages = usePackagesStore();
  const { t } = useTranslation();

  const [hasSelection, setHasSelection] = useState(false);
  const [frameworksPrice, setFrameworksPrice] = useState(0);
  const [domainsPrice, setDomainsPrice] = useState(0);
  const [additionalPrice, setAdditionalPrice] = useState(0);

  const calcAllExtras = () => {
    setFrameworksPrice(calcUpgradeFrameworks(userSelection, packages, currentPlan));
    setDomainsPrice(
      calcUpgradeDomains(userSelection, packages, currentPlan as SubscriptionDetails)
    );
    setAdditionalPrice(
      calcExtraTraining(userSelection, currentPlan as SubscriptionDetails, packages)
    );
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (packages.packages.length) {
      const hasAnySelection = !!(
        userSelection.frameworks.length ||
        userSelection.domains ||
        userSelection.additionalService
      );
      setHasSelection(hasAnySelection);
      calcAllExtras();
    }
  }, [userSelection]);

  return (
    <StyledCol data-testid="upgradeSummaryBlock">
      <StyledOrangeGradTitle $size={15} level={3} $flex="start">
        {t('billing.package_details.upgrading')}
      </StyledOrangeGradTitle>
      <StyledSecondaryText fontWeight={100} textAlign={'left'} fontSize="13px">
        {t('billing.package_details.upgrade.cost1')}{' '}
        {subscription.isTrial
          ? NUM_OF_DAYS[subscription.pricingMethod]
          : getRemainingDays(currentPlan?.expiryDate as Date)}{' '}
        {t('billing.package_details.upgrade.cost2')}
      </StyledSecondaryText>
      {hasSelection ? (
        <StyledbulletList>
          {!!frameworksPrice && (
            <li>
              <StyledRowOffset>
                <StyledCol>
                  <span>{t('billing.package_details.upgrade.framework_selected')}</span>
                  {userSelection.frameworks.map((fw) => {
                    return <StyledFrameworks key={fw}>{fw}</StyledFrameworks>;
                  })}
                </StyledCol>
                <span>${frameworksPrice}</span>
              </StyledRowOffset>
            </li>
          )}
          {!!domainsPrice && (
            <li>
              <StyledRowOffset>
                <span>
                  {t('billing.upgarde_package.domains.title')} {userSelection.domains}
                </span>
                <span>${domainsPrice}</span>
              </StyledRowOffset>
            </li>
          )}
          {!!additionalPrice && (
            <li>
              <StyledRowOffset>
                <span>{t('billing.package_details.upgrade.training_and_auditing')}</span>
                <span>${additionalPrice}</span>
              </StyledRowOffset>
            </li>
          )}
        </StyledbulletList>
      ) : (
        <StyledSelectOptions>
          {t('billing.package_details.upgrade.select_options')}
        </StyledSelectOptions>
      )}
    </StyledCol>
  );
};
