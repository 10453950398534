import { OrderSummary } from '@/shared/pages/Checkout/OrderSummary';
import { StyledSeparator } from '@/shared/styles';
import { ExtraSummary } from './SummarySections/ExtraSummary';
import { PackageSummary } from './SummarySections/PackageSummary';
import { PaymentSummary } from './SummarySections/PaymentSummery';
import { StyledFreeTrial } from '@/shared/pages/Pricing/Packages/Package.styles';
import { StyledTitle } from '@/shared/styles';

export const CheckoutSummary: React.FC = () => {
  return (
    <OrderSummary>
      <PackageSummary />

      <StyledSeparator $margin={'0px 0px 20px 0px'} />

      <StyledTitle level={3} $size={15}>
        Extra
      </StyledTitle>

      <ExtraSummary />

      <StyledFreeTrial>
        <span>14 Days Free Trial</span>
      </StyledFreeTrial>

      <StyledSeparator $margin={'10px 0px 30px 0px'} />

      <PaymentSummary />
    </OrderSummary>
  );
};
