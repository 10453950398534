import React from 'react';
import type { MenuProps } from 'antd';
import { PAGES, SIDE_NAVIGATION, STORAGE_KEYS, USER_ROLE } from '@/shared/consts';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { useSubscription } from '@/hooks/useSubscription';
import { SUB_STATUS } from '@/stores/Stores.consts';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { SideMenu } from './SideMenu';
import { useUserStore } from '@/stores/UserStore';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const ClientSideMenu: React.FC = () => {
  const { navigate } = useU1Navigation();
  const subscription = useSubscriptionStore();
  const { getSubscription } = useSubscription();
  const { removeStorage } = useLocalStorage();
  const userRole = useUserStore((state) => state.role);

  const defaultPage =
    subscription.subscriptionStatus === SUB_STATUS.HAS_SUBSCRIPTION ||
    userRole === USER_ROLE.ENTERPRISE
      ? SIDE_NAVIGATION.PROJECTS
      : SIDE_NAVIGATION.PRICING;

  const onClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case SIDE_NAVIGATION.PRICING:
        removeStorage(STORAGE_KEYS.SELECTED_PACKAGE);
        navigate(PAGES.AFTER_LOGIN.PRICING);
        break;
      case SIDE_NAVIGATION.PROJECTS:
        navigate(PAGES.AFTER_LOGIN.PROJECTS);
        getSubscription();
        break;
      case SIDE_NAVIGATION.BILLING:
        navigate(PAGES.AFTER_LOGIN.BILLING);
        getSubscription();
        break;
      case SIDE_NAVIGATION.USERS:
        navigate(PAGES.AFTER_LOGIN.USERS);
        getSubscription();
        break;
    }
  };

  return subscription.subscriptionStatus === SUB_STATUS.NOT_INITIATED ? (
    <></>
  ) : (
    <SideMenu onClick={onClick} defaultPage={defaultPage} />
  );
};
