import React from 'react';
import { StyledSignIn } from '../SignIn.styles';
import { StyledCard, StyledCol, StyledText, StyledTitle } from '@/shared/styles';
import { U1Logo } from '@/shared/components/U1Logo';
import { ForgotPasswordForm } from '@/shared/components/Forms/SignIn/ForgotPasswordForm';
import { SignInFormProps } from '@/shared/components/Forms/Forms.consts';
import { useTranslation } from 'react-i18next';

export const ForgotPassword: React.FC<SignInFormProps> = ({
  userType,
  setView,
}: SignInFormProps) => {
  const { t } = useTranslation();
  return (
    <StyledSignIn>
      <StyledCard $self="center" $padding={40} $gap={32}>
        <U1Logo $size={42} />
        <StyledCol $align="center">
          <StyledTitle $size={28}>{t('form.forgot_password')}</StyledTitle>
          <StyledText fontSize={'16px'} fontWeight={400}>
            {t('form.forgot_password.content')}
          </StyledText>
        </StyledCol>
        <ForgotPasswordForm userType={userType} setView={setView} />
      </StyledCard>
    </StyledSignIn>
  );
};
