import { StyledCol, StyledSecondaryText, StyledTitle } from '@/shared/styles';
import styled from 'styled-components';
import { ConfigSecondaryTextProps } from './ConfigurationPage.consts';

export const StyledConfigPageLayout = styled(StyledCol)`
  width: 100%;
  height: 100%;
  gap: 20px;
`;

export const StyledConigfsListCard = styled(StyledCol)`
  align-items: flex-start;
  gap: 12px;

  &&&& pre {
    background: ${(props) => props.theme.cardsBgColor} !important;
  }
  &&&& pre * {
    color: ${(props) => props.theme.textColor} !important;
    background: none !important;
    font-family: 'Poppins' !important;
    font-weight: 400 !important;
    font-size: 16px !important;
  }
`;

export const StyledSubTitle = styled(StyledTitle)`
  font-size: 18px;
  line-height: 27px;
`;

export const StyledConfigSecondaryText = styled(StyledSecondaryText)<ConfigSecondaryTextProps>`
  font-weight: ${(props) => (props.isBold ? 600 : 400)};
  align-self: start;
`;
