import { isBrowser, isMobile } from 'react-device-detect';
import {
  StyledCenteredCol,
  StyledFooterLeftSection,
  StyledFooterRightSection,
  StyledFooterTopRow,
  StyledFreeText,
  StyledFreeTrial,
  StyledWhiteText,
} from './Footer.styles';
import { StyledOrangeGradText, StyledRow } from '@/shared/styles';
import { U1Button } from '@/shared/components/Button/U1Button';
import { useTranslation } from 'react-i18next';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const TopFooter: React.FC = () => {
  const { navigate } = useU1Navigation();
  const onFreeTrialClick = () => {
    navigate('/pricing');
  };
  const { t } = useTranslation();
  return (
    <StyledFooterTopRow>
      {isBrowser && (
        <StyledFooterLeftSection>
          <StyledWhiteText>{t('footer.title.left.white')}</StyledWhiteText>
          <StyledOrangeGradText>{t('footer.title.left.gradient')}</StyledOrangeGradText>
        </StyledFooterLeftSection>
      )}

      <StyledFooterRightSection>
        <StyledCenteredCol>
          <StyledFreeTrial>
            <StyledFreeText>
              <StyledWhiteText>{t('footer.title.right.white')}</StyledWhiteText>
              <StyledRow $justify={isMobile ? 'center' : 'left'}>
                <StyledOrangeGradText>
                  {t('footer.title.right.gradient')}&nbsp;
                </StyledOrangeGradText>
                <StyledWhiteText>{t('footer.title.right.white2')}</StyledWhiteText>
              </StyledRow>
            </StyledFreeText>
            <U1Button
              dataTestId="tryForFreeButton"
              onClick={onFreeTrialClick}
              $width={isMobile ? '343px' : '250px'}
              $height="50px"
              text={t('footer.try_for_free')}
              isLink={true}
            />
          </StyledFreeTrial>
        </StyledCenteredCol>
      </StyledFooterRightSection>
    </StyledFooterTopRow>
  );
};
