import { GradientBorderButton } from '@/shared/components/Button/GradientBorderButton';
import { U1Button } from '@/shared/components/Button/U1Button';
import { RenewSubscriptionModal } from '@/shared/components/Modals/SubscriptionModal/RenewSubscription';
import { PAGES } from '@/shared/consts';
import {
  StyledBgImg,
  StyledCol,
  StyledColOffset,
  StyledPackageName,
  StyledSecondaryText,
  StyledTitle,
} from '@/shared/styles';
import { getRemainingDays } from '@/shared/utils';
import { useSubscriptionStore } from '@/stores/SubscriptionStore';
import { StyledBillingCard } from '../Billing.styles';
import { useTranslation } from 'react-i18next';
import { useLangStore } from '@/stores/LangStore/LangStore';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const CurrentPackage: React.FC = () => {
  const { navigate } = useU1Navigation();
  const subscription = useSubscriptionStore();
  const { t } = useTranslation();
  const isRtl = useLangStore((state) => state.isRtl);

  return (
    <StyledBillingCard>
      <StyledBgImg
        $bg={subscription.imageUrl}
        $size={200}
        $position={`${isRtl() ? 'left' : 'right'} -5px`}
        $height={subscription.isFreemium ? '260px' : '240px'}
      >
        <StyledColOffset>
          <StyledCol $gap={10}>
            <StyledTitle level={2} $size={20} $weight={600}>
              {t('billing.current_package')}{' '}
              <StyledPackageName data-testid="packageName">{subscription.name}</StyledPackageName>
            </StyledTitle>
            <StyledSecondaryText
              fontWeight={400}
              fontSize={'14'}
              data-testid="daysRemaining"
              flex="start"
            >
              {subscription.isFreemium ? (
                t('active')
              ) : (
                <>
                  {subscription.isTrial && `${t('billing.current_package.free_trial')} - `}
                  {getRemainingDays(subscription.expiryDate)}{' '}
                  {t('billing.current_package.free_trial.remaining_days')}
                </>
              )}
            </StyledSecondaryText>
          </StyledCol>
          {subscription.isFreemium ? (
            <GradientBorderButton
              text={t('billing.current_package.other_plans')}
              $height="50px"
              $width="100%"
              onClick={() => navigate(PAGES.AFTER_LOGIN.PRICING)}
            />
          ) : subscription.isActive && subscription.autoPayment ? (
            <U1Button
              dataTestId="upgradePackageButton"
              text={t('payment.payment_message.upgrade.package_text.title')}
              $height="50px"
              $width="100%"
              onClick={() => navigate(PAGES.AFTER_LOGIN.UPGRADE)}
            />
          ) : (
            <RenewSubscriptionModal $buttonType="renew" />
          )}
        </StyledColOffset>
      </StyledBgImg>
    </StyledBillingCard>
  );
};
