import { StyledShimmer } from '@/shared/styles';
import { StyledBuildPackage } from '../Package.styles';

export const PackgeLoading: React.FC = () => {
  return (
    <StyledBuildPackage $gap={32}>
      <StyledShimmer $width={600} $height={50} />
      <StyledShimmer $width={600} $height={50} />
      <StyledShimmer $width={600} $height={300} />
      <StyledShimmer $width={600} $height={50} />
      <StyledShimmer $width={600} $height={50} />
    </StyledBuildPackage>
  );
};
