import { StyledRowOffset } from '@/shared/styles';
import styled from 'styled-components';
import { StyledInfoProps } from './InfoMessage.consts';

export const StyledInfo = styled(StyledRowOffset)<StyledInfoProps>`
  color: ${(props) => props.theme.textColor};
  background: ${(props) => props.theme.marginsColor};
  font-weight: 300;
  border: 1px solid ${(props) => props.theme.disabledItemColor};
  border-radius: 5px;
  padding: 10px;
  gap: 10px;
  ${(props) => props.$margin && `margin: ${props.$margin}`};
  ${(props) => props.$justify && `justify-content: ${props.$justify}`};
`;
