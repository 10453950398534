import { StyledRow, StyledShimmer } from '@/shared/styles';
import { isMobile } from 'react-device-detect';
import { StyledShimmerPricing } from '../Pricing.styles';

export const PricingLoading: React.FC = () => {
  return (
    <StyledShimmerPricing>
      <StyledShimmer $width={130} $height={42} />
      <StyledShimmer $width={isMobile ? 300 : 508} $height={27} />
      <StyledRow $gap={20}>
        <StyledShimmer $height={450} $width={350} />
        <StyledShimmer $height={450} $width={350} />
        <StyledShimmer $height={450} $width={350} />
      </StyledRow>
    </StyledShimmerPricing>
  );
};
