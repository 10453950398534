import { TitleAndContentModel } from '@/Views/AfterLogin/Main/Client/Projects/projects.consts';
import * as A11Y from './AccessibilityStatement.consts';
import { StyledStaticPageParagraph } from '../StaticPage.styles';
import { PinkGradientRouterLink } from '@/shared/components/Link/PinkGradientRouterLink';

export const AccessibilityStatementDictionary: TitleAndContentModel[] = [
  {
    title: '',
    content: A11Y.INTRODUCTION,
  },
  {
    title: 'Product Overview',
    content: A11Y.PRODUCT_OVERVIEW,
  },
  {
    title: 'Integrated Accessibility Features',
    content: A11Y.INTEGRATED_ACCESSIBILITY_FEATURES,
  },
  {
    title: 'Accessibility At The Core',
    content: A11Y.ACCESSIBILITY_AT_THE_CORE,
  },
  {
    title: 'Regular Testing & Compliance',
    content: A11Y.REGULAR_TESTING_AND_COMPLIANCE,
  },
  {
    title: 'Feedback & Continuous Improvement',
    children: (
      <StyledStaticPageParagraph>
        {A11Y.FEEDBACK_AND_CONTINUOUS_IMPROVEMENT_UNTIL_LINK}
        <PinkGradientRouterLink
          text="support@user1st.com"
          href={A11Y.SUPPORT_EMAIL}
        ></PinkGradientRouterLink>
        {A11Y.FEEDBACK_AND_CONTINUOUS_IMPROVEMENT_AFTER_LINK}
      </StyledStaticPageParagraph>
    ),
  },
];
