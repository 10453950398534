import { Dispatch, SetStateAction } from 'react';

export enum DEALS_VIEW {
  MAIN,
  CREATE_DEAL,
  VIEW_DEAL,
}

export enum SUBSCRIPTION_TYPE {
  TWO_YEARS,
  YEAR,
  MONTH,
}

export type DealStepProps = {
  text: string;
};

export type StepProps = {
  step?: number;
  isActive?: boolean;
  isComplete?: boolean;
};

export type BuildPackagesProps = {
  setIsValid?: Dispatch<SetStateAction<boolean>>;
};
