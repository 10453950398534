export const MENU_ATTRIBUTE = `.ant-menu`;

export const ANTD_SELECTORS = {
  MENU: `${MENU_ATTRIBUTE}.ant-menu-root`,
  HORIZONTAL_MENU: `${MENU_ATTRIBUTE}-horizontal`,
  MENU_ITEM: `${MENU_ATTRIBUTE}-submenu-title:has(a), .ant-menu-item-only-child a`,
  SUBMENU: `${MENU_ATTRIBUTE}-sub:has(>li)`,
  TRIGGERS: `${MENU_ATTRIBUTE}-submenu-title:has(a:not([href]))`,
  OPEN_BY_MOUSEOVER: `${MENU_ATTRIBUTE}-submenu-title`,
  OVERFLOW_KEY: 'rc-menu-more',
};
