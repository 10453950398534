import { ARIA_SELECTORS } from '../../../../consts';

export const removeAllAccessibilityAttributes = (container: HTMLElement) => {
  const allAccessibleElements = container.querySelectorAll(`[role],[tabindex],${ARIA_SELECTORS}`);
  for (const element of allAccessibleElements) {
    removeA11yAttributesFromElement(element);
  }
};

export const removeA11yAttributesFromElement = (el: Element) => {
  Array.prototype.slice.call(el.attributes).forEach((attr) => {
    const attributeName = attr.name;
    if (attributeName === 'tabindex') {
      removeTabindex(el);
    }
    if (attributeName.startsWith('aria-') || attributeName === 'role') {
      el.removeAttribute(attributeName);
    }
  });
};

export const addRolePresentation = (els: HTMLElement[]) => {
  els?.forEach((el) => el.setAttribute('role', 'presentation'));
};

export const removeTabindex = (el: Element) => {
  el.setAttribute('u1st-avoid-auto-tabbable', 'true');
  el.setAttribute('tabindex', '-1');
};

export const hideElement = (el: Element) => {
  removeTabindex(el);
  el.setAttribute('aria-hidden', 'true');
};
