import { Component } from '../../../../../types';
import { DatepickerProps } from '@u1/fixers';

export const datepicker: Component<DatepickerProps> = {
  selector: '.datepicker-trigger',
  props: {
    selectors: {
      container: '.ant-dropdown.datepicker',
      trigger: '.datepicker-trigger',
      year: {
        label: '.select-year.simple-select-trigger .ant-select-selection-item',
      },
      month: {
        label: '.select-month.simple-select-trigger .ant-select-selection-item',
      },
      days: {
        day: 'td.day',
        table: 'table',
        selected: '.start-day, .end-day',
        disabled: '.disabled-day',
      },
    },
    doubleDatepicker: true,
  },
};
