import { StripeCardPayment } from '@/shared/stripe/StripeCardPayment';
import { StyledCol, StyledTitle } from '@/shared/styles';
import { Payment_Status } from '@/stores/Stores.consts';
import { useTranslation } from 'react-i18next';

export const UpdatePaymentForm: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledCol $gap={24}>
      <StyledTitle $size={18}>{t('billing.payment_method.modal.update.title')}</StyledTitle>
      <StripeCardPayment paymentStatus={Payment_Status.UPDATED} />
    </StyledCol>
  );
};
