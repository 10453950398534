/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import { TabsProps } from '@u1/fixers';
import { Component } from '../../../../../types';
import { removeAllAccessibilityAttributes } from '../utils';

export const tabs: Component<TabsProps> = {
  selector: '#tabs-list .ant-menu-item',
  props: {
    selectors: {
      tab: '#tabs-list .ant-menu-item',
      tabList: '#tabs-list ul',
      tabPanel: '#docs-content',
    },
    isVertical: true,
    isDynamic: true,
  },
  handleSpecialCases(props: TabsProps, el: HTMLElement) {
    removeAllAccessibilityAttributes(el);
  },
};
