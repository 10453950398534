import { useState } from 'react';

export const useFormMessages = () => {
  const [message, setMessage] = useState('');
  const [serverError, setError] = useState('');

  const clearAllMessages = () => {
    setMessage('');
    setError('');
  };

  return { message, setMessage, serverError, setError, clearAllMessages };
};
