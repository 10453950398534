import { StyledCol, StyledRow } from '@/shared/styles';
import { ClientDetailsSection } from './Sections/ClientDetails';
import { PackagesDetailsSection } from './Sections/PackageDetailsSection';
import { DealPriceSection } from './Sections/DealPriceSection';

export const DealPayment: React.FC = () => {
  return (
    <StyledRow $gap={24}>
      <StyledCol $gap={24}>
        <ClientDetailsSection />
        <PackagesDetailsSection />
      </StyledCol>
      <DealPriceSection />
    </StyledRow>
  );
};
