import { SCOPE_PH, SUFFIX_PH } from '../../../../../consts';
import { Component } from '../../../../../types';
import { ListboxProps } from '@u1/fixers';
import { A11Y_DIRECTIVE_SELECTOR } from '../../../../../consts';

const LISTBOX_ATTRIBUTE = `${A11Y_DIRECTIVE_SELECTOR}-listbox`;

export const listbox: Component<ListboxProps> = {
  selector: `[${LISTBOX_ATTRIBUTE}]`,
  props: {
    selectors: {
      listbox: `${SCOPE_PH} [${LISTBOX_ATTRIBUTE}='listbox']${SUFFIX_PH}`,
      trigger: `${SCOPE_PH} [${LISTBOX_ATTRIBUTE}='trigger']${SUFFIX_PH}`,
      options: `${SCOPE_PH} [${LISTBOX_ATTRIBUTE}='listbox']${SUFFIX_PH} [${LISTBOX_ATTRIBUTE}='options']`,
      label: `${SCOPE_PH} [${LISTBOX_ATTRIBUTE}='label']${SUFFIX_PH}`,
    },
  },
};
