import { StyledCol, StyledImg } from '@/shared/styles';
import { LoadingOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import styled from 'styled-components';

export const StyledUploadButton = styled(StyledCol)`
  align-items: center;
  gap: 12px;
  padding: 20px;
`;

export const StyledUpload = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    background: transparent;
    border: 1px solid ${(props) => props.theme.marginsColor};
    border-radius: 5px;
    width: 120px;
    height: 120px;
    margin: 0px;
  }

  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    position: relative;
  }
`;

export const StyledProfileImg = styled(StyledImg)`
  border-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const StyledDeleteButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
  border-top-left-radius: 8px;
  padding: 4px;
  background: ${(props) => props.theme.marginsColor};
`;

export const StyledLoading = styled(LoadingOutlined)`
  svg {
    fill: ${(props) => props.theme.textColor};
  }
`;
