import { RadioGroup } from '@/shared/components/Radio/Radio';
import { getFrameworkIcons } from '@/shared/utils';
import { useExtraSelectionStore, useSelectionStore } from '@/stores/SelectionStore';
import { useCallback, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { PackageSectionType } from '../../Checkout.consts';
import { ExtraSelection } from './ExtraSelection';
import { t } from 'i18next';

export const FreeExtraSelection: React.FC = () => {
  const selectedPackage = useSelectionStore();
  const userSelection = useExtraSelectionStore();

  const setFreeFramework = useCallback((key: number) => {
    const newUserSelection = {
      ...userSelection,
      freeFramework: {
        key,
        value: selectedPackage.supportedFrameworks[key].name,
      },
    };
    userSelection.setSelection && userSelection.setSelection(newUserSelection);
  }, []);

  useEffect(() => {
    setFreeFramework(0);
  }, []);

  const getFreeFramework = (): PackageSectionType[] => [
    {
      title: t('pricing.package.framework.single'),
      optional: false,
      content: t('pricing.freemium.framework.content'),
      extraComp: (
        <RadioGroup
          dataTestId="availableFrameworksBlock"
          radioValues={selectedPackage.supportedFrameworks.map((sf) => sf.title) ?? []}
          icons={getFrameworkIcons(selectedPackage.supportedFrameworks)}
          radioDirection={isMobile ? 'vertical' : 'horizontal'}
          value={userSelection.freeFramework?.key ?? 0}
          setValue={(val: number) => {
            setFreeFramework(val);
          }}
        />
      ),
    },
  ];
  return <ExtraSelection extras={getFreeFramework()} />;
};
