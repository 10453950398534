import { StyledVendorCard } from '@/Views/AfterLogin/Main/Main.styles';
import { personalSettingsIcon } from '@/assets/images/icons';
import { GradientBorderButton } from '@/shared/components/Button/GradientBorderButton';
import { StyledCol, StyledIcon, StyledText, StyledTitle } from '@/shared/styles';
import { useSettingsStore } from '@/stores/SettingsStore/SettingsStore';
import { SETTINGS_VIEW } from '@/stores/SettingsStore/SettingsStore.consts';
import { useTranslation } from 'react-i18next';

export const VendorPassword: React.FC = () => {
  const setView = useSettingsStore((state) => state.setView);
  const { t } = useTranslation();

  return (
    <StyledVendorCard>
      <StyledCol $gap={12}>
        <StyledTitle level={2} $size={16} $gap={12}>
          <StyledIcon src={personalSettingsIcon} />
          {t('vendor.settings.password.title')}
        </StyledTitle>
        <StyledText fontSize={14} fontWeight={400}>
          {t('vendor.settings.password.description')}
        </StyledText>
      </StyledCol>
      <StyledCol $align="end">
        <GradientBorderButton text={t('update')} onClick={() => setView(SETTINGS_VIEW.PASSWORD)} />
      </StyledCol>
    </StyledVendorCard>
  );
};
