import { StyledCheckout } from '../Checkout.styles';
import { FreeCheckoutSummary } from './FreeCheckoutSummary';
import { FreePackageDetails } from './FreePackageDetails';

export const FreeCheckout: React.FC = () => {
  return (
    <StyledCheckout>
      <FreePackageDetails />
      <FreeCheckoutSummary />
    </StyledCheckout>
  );
};
