import { AbstractProps, Utils as FixerUtils } from '@u1/fixers';
import {
  A11Y_REF_SELECTOR,
  A11Y_REF_SELECTOR_ANGULAR,
  COMP_ID,
  SCOPE_PH,
  SUFFIX_PH,
} from './consts';
import { Framework, RefId } from './types';

export default class Utils {
  static getIdSelector(element: HTMLElement): string {
    element.id ||= FixerUtils.uuid();
    return `[id='${element.id}']`;
  }

  static manipulateU1Props = (obj: any, scope: string, suffix: string, componentId: string) => {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key]))
        return Utils.manipulateU1Props(obj[key], scope, suffix, componentId);
      if (typeof obj[key] === 'string') {
        obj[key] = obj[key].replaceAll(SCOPE_PH, scope);
        obj[key] = obj[key].replaceAll(SUFFIX_PH, suffix);
        obj[key] = obj[key].replaceAll(COMP_ID, componentId);
      }
    });
  };

  static getU1Props = <T extends AbstractProps>(
    props: T,
    el: HTMLElement,
    framework: Framework,
    refId?: RefId
  ): T => {
    const { suffix, scope, componentId } = Utils.getU1Scoping(el, framework, refId);
    Utils.manipulateU1Props(props.selectors, scope, suffix, componentId);
    return props;
  };

  static getU1Scoping = (
    el: HTMLElement,
    framework: Framework,
    refId?: RefId
  ): { suffix: string; scope: string; componentId: string } => {
    const suffix = refId
      ? `[${
          framework === Framework.REACT ? A11Y_REF_SELECTOR : A11Y_REF_SELECTOR_ANGULAR
        }='${refId}']`
      : '';
    const componentId = `[id='${el.id}']`;
    const scope = refId ? '' : componentId;

    return {
      suffix,
      scope,
      componentId,
    };
  };
}
