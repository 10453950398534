import { StyledDimmedText } from '@/shared/styles';
import React, { useState } from 'react';
import { ResendTimerProps } from '../../Forms.consts';
import { useTranslation } from 'react-i18next';

export const ResendTimer: React.FC<ResendTimerProps> = ({ resendButton }: ResendTimerProps) => {
  const [timer, setTime] = useState(0);
  const { t } = useTranslation();

  const blockForOneMinute = () => {
    let time = 1000 * 60;
    setTime(60);
    const interval = setInterval(() => {
      if (time > 0) {
        setTime((prev) => prev - 1);
        time -= 1000;
      } else {
        clearInterval(interval);
      }
    }, 1000);
  };
  return timer > 0 ? (
    <StyledDimmedText fontWeight={100}>
      {t('2fa.send_in')} {timer} {t('2fa.seconds')}
    </StyledDimmedText>
  ) : (
    React.cloneElement(resendButton, {
      onClick: () => {
        resendButton.props?.onClick();
        blockForOneMinute();
      },
    })
  );
};
