import styled from 'styled-components';
import { LoginRightBackgroundImg } from '@/assets/images/backgrounds';
import { StyledCol, StyledH1, StyledText } from '@/shared/styles';
import { isMobile } from 'react-device-detect';

export const SignInMethodButtonWrapper = styled.a`
  background: ${(props) => props.theme.signInButtonsBgColor};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 5px;
  width: 100%;
  height: 80px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: ${(props) => props.theme.buttonHoverBG};
  }
`;

export const SignInMethodButtonInner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 21px 135px;
  gap: 12px;
  width: 100%;
  height: 100%;
`;

export const SignInMethodText = styled.span`
  color: ${(props) => (props.color ? props.color : props.theme.signInButtonsBasicFontColor)};
  font-size: 20px;
  font-weight: 500;
`;

export const StyledSigninMethodesList = styled(StyledCol)`
  align-items: flex-start;
  gap: 20px;
`;

export const StyledSigninFrame = styled(StyledCol)`
  align-items: flex-start;
`;

export const StyledLayout = styled.div`
  width: 100%;
  min-height: 1000px;
  background-color: ${(props) => props.theme.backgroundColor};
  font-style: normal;
  position: relative;
  display: flex;
  @media (max-width: 1600px) {
    height: unset;
  }
`;

export const StyledLeftSection = styled(StyledCol)`
  align-items: center;
  flex: 50%;
  padding: 90px 140px;
  justify-content: start;

  ${() =>
    isMobile &&
    `
    padding: 90px 16px 16px 16px !important;
    gap: 50px;`}
`;
export const StyledLeftSectionInnerCol = styled.div`
  display: inline-flex;
  flex-direction: column;
  @media (max-width: 1600px) {
    padding: 10% 0px;
  }

  ${() => isMobile && `padding: 16px !important;`}
`;

export const StyledRightSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;
  background-size: cover;
  background-image: url(${LoginRightBackgroundImg});
  position: relative;
`;

export const StyledRightSectionInnerCol = styled(StyledCol)`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const StyledAppTextContainer = styled(StyledCol)`
  gap: 12px;
  align-items: center;
  justify-content: end;
  height: 470px;
`;

export const StyledAppImg = styled.img`
  position: absolute;
  margin: auto;
`;

export const StyledLogoWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  flex: none;
  flex-grow: 0;
  ${() =>
    isMobile &&
    `
  width: 100%;
  justify-content: center;
  `}
`;

export const StyledSignInText = styled(StyledText)`
  font-size: 16px;
  font-weight: 400;
  ${() => isMobile && `text-align: center;`}
`;

export const StyledSignInTitle = styled(StyledH1)`
  font-size: 32px;
  font-weight: 600;
  margin: 0px;
  ${() => isMobile && `text-align: center;`}
`;
